import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import CloseIcon from '@material-ui/icons/Close';
import { Button, TextField, Dialog, Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { CREATE_L7_BLACK_LIST, CREATE_L7_WHITE_LIST } from '../../../../actions/create';
import { useIntl } from 'react-intl';
import { useStyles } from '../../../../theme/Modals';

const ModalAddList = ({ show, hideModal, text, type, query, getVariables, resourcesId }) => {
    const classes = useStyles();
    const { handleSubmit, control } = useForm();
    const { formatMessage } = useIntl();

    const typeQuery = type === 'blackList' ? CREATE_L7_BLACK_LIST : type === 'whiteList' ? CREATE_L7_WHITE_LIST : '';

    const [setUpdateL7List] = useMutation(typeQuery);

    const onSubmit = dataInput => {
        if (type === 'blackList') {
            setUpdateL7List({
                variables: {
                    l7ResourceId: resourcesId,
                    blacklistData: dataInput.ip,
                },
                refetchQueries: [
                    {
                        query: query,
                        variables: getVariables,
                    },
                ],
            })
                .then(() => {
                    toast.success('Success');
                })
                .catch(err => toast.error(err.message));
        }
        if (type === 'whiteList') {
            setUpdateL7List({
                variables: {
                    l7ResourceId: resourcesId,
                    whitelistData: dataInput.ip,
                },
                refetchQueries: [
                    {
                        query: query,
                        variables: getVariables,
                    },
                ],
            })
                .then(() => {
                    toast.success('Success');
                })
                .catch(err => toast.error(err.message));
        }

        hideModal();
    };
    return (
        <div>
            <Dialog
             className={classes.dialog}
                maxWidth="md"
                open={show}
                onClose={() => {
                    hideModal();
                }}
            >
                <div className={classes.modal}>
                    <div className={classes.header}>
                        <Typography variant="h1">{text}</Typography>
                        <Button className={classes.close} onClick={() => hideModal()}>
                            <CloseIcon />
                        </Button>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={classes.modalBody}>
                            <Controller
                                name="ip"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value } }) => (
                                    <TextField onChange={onChange} value={value} label="IP address" variant="outlined" className={classes.input} />
                                )}
                            />
                        </div>
                        <div className={classes.modalFooter}>
                            <Button className={classes.cancel} onClick={() => hideModal()}>
                                {formatMessage({ id: 'webProtection.white.create.cancel' })}
                            </Button>
                            <Button type="submit" className={classes.invite}>
                                {formatMessage({ id: 'webProtection.white.create.accept' })}
                            </Button>
                        </div>
                    </form>
                </div>
            </Dialog>
        </div>
    );
};

export default ModalAddList;
