import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    title: {
        marginBottom: 25,
    },
    exp: {
        width: '100%',
        marginLeft: 10,
        marginBottom: 20,
    },
    formTitle: {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '30px',
        letterSpacing: '0.15px',
        display: 'flex',
        alignItems: 'flex-end',
        color: theme.palette.common.black,
        [theme.breakpoints.only('xs')]: {
            fontSize: '13.5px',
            lineHeight: '24px',
        },
    },
    formSubTitle: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '22px',
        [theme.breakpoints.only('xs')]: {
            fontSize: '11px',
        },
    },

    input: {
        marginBottom: 25,
        [theme.breakpoints.between('xs', 'sm')]: {
            marginBottom: 15,
        },
    },
    inputDomain: {
        marginRight: 70,
        [theme.breakpoints.between('xs', 'sm')]: {
            marginRight: 0,
        },
    },
    inputOriginIp: {
        marginRight: 70,
        [theme.breakpoints.between('xs', 'sm')]: {
            marginRight: 10,
        },
    },
    inputBox: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    ipBox: {
        marginBottom: 20,
    },

    wrapperSwitchContainer: {
        maxWidth: 510,
    },
    switchBox: {
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        marginBottom: 20,
    },
    switch: {
        marginTop: 8,
    },

    button: {
        padding: '11px 30px',
    },
    empty: {
        marginBottom: 15,
        marginTop: 15,
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '30px',
        letterSpacing: '0.15px',
        color: theme.palette.common.black,
    },
    wrapperHash: {
        display: 'flex',
        alignItems: 'center',
    },
    hashTitle: {
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: theme.palette.grey.greyRgba85,
        marginRight: 10,
    },
    hashSubtitle: {
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
        color: theme.palette.grey.grey500,
    },
    select: {
        marginRight: 20,
        '& .MuiOutlinedInput-input': {
            padding: '10.5px 14px',
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            marginRight: 10,
        },
    },
    settings: {
        maxWidth: 1000,
    },
}));
