import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useStyles } from '../../theme/Chart';
import { useIntl } from 'react-intl';

const PieMisuseTypes = ({ data }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const options = {
        stacked100: { enable: true },
        responsive: true,
        animation: false,
        plugins: {
            crosshair: false,
            legend: false,
            filler: {
                propagate: false,
            },
            tooltip: {
                animation: false,
                callbacks: {
                    label: function (context) {
                        return `${context.label}: ${context.raw}`;
                    },
                },
            },
        },
    };

    const series = {
        labels: Object.entries(data).map(item => item[0]),
        datasets: [
            {
                label: formatMessage({ id: 'chartsPieMisuseTypes.title' }),
                data: Object.entries(data).map(item => item[1]),
                backgroundColor: ['#2778C0', '#EC833D','#80B26E', '#72D0E0', '#E8B83D', '#DF4D42'],
                hoverOffset: 4,
            },
        ],
    };

    return !Object.keys(data).length ? (
        <div className={classes.errorCharts}>{formatMessage({ id: 'charts.error' })}</div>
    ) : (
        <Doughnut data={series} options={options}/>
    );
};

export default PieMisuseTypes;
