import React, {useRef} from "react";
import TableScroll from "../../../../components/global/TableScroll";
import {TableContainer, TablePagination, Typography, Table, TableHead, TableBody, TableRow} from "@material-ui/core";
import {TableCellMitigation, useStyles} from "../../../../theme/Table";
import {Description} from "@material-ui/icons";
import {Link} from "react-router-dom";
import {valueTimeCalculation} from "../../../../helpers/calculation";
import {useIntl} from "react-intl";

const TrafficMitigations = ({ data, filter, setFilter }) => {
    const table = useRef(null);
    const classes = useStyles();
    const dateFormat = require('dateformat');
    const { formatMessage } = useIntl();
    const handleChangePage = (event, newPage) => {
        setFilter({...filter, traffic: {...filter.traffic, page: newPage}}, 'FILTER_ACTIVE');
    };
    const handleChangeRowsPerPage = event => {
        setFilter({...filter, traffic: {...filter.traffic, limit: parseInt(event.target.value, 10), page: 0}} , 'FILTER_ACTIVE');
    };

    return(
        <TableScroll tableRef={table}>
            <TableContainer>
                <div className={classes.header}>
                    <Typography variant="h1">{formatMessage({ id: 'mitigations.title' })}</Typography>
                </div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCellMitigation>ID</TableCellMitigation>
                            <TableCellMitigation>Protected IPs</TableCellMitigation>
                            <TableCellMitigation>Started At</TableCellMitigation>
                            <TableCellMitigation>Ended At</TableCellMitigation>
                            <TableCellMitigation>Duration</TableCellMitigation>
                            <TableCellMitigation/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data &&
                            data.arborMitigations.items.map(({ mitigationId, arborAlertId, start, stop, managedObjectName, prefix, duration, id }) => (
                                <TableRow key={mitigationId}>
                                    <TableCellMitigation>{mitigationId}</TableCellMitigation>
                                    <TableCellMitigation>{prefix}</TableCellMitigation>
                                    <TableCellMitigation>{dateFormat(start, 'dd/mm/yy h:MM:ssTT')}</TableCellMitigation>
                                    <TableCellMitigation>{stop ? dateFormat(stop, 'dd/mm/yy h:MM:ssTT') : 'Ongoing'}</TableCellMitigation>
                                    <TableCellMitigation>{stop ? valueTimeCalculation(duration) : valueTimeCalculation((new Date() - new Date(start)) / 1000)}</TableCellMitigation>
                                    <TableCellMitigation align='right'>
                                        <Link
                                            to={`/mitigations-details/l3-protection/${start}/${mitigationId}/${id}/2`}
                                        >
                                            <Description color="action" className={classes.icon}/>
                                        </Link>
                                    </TableCellMitigation>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
                <TablePagination
                    component="div"
                    count={data?.arborMitigations?.info?.totalCount}
                    page={filter?.traffic?.page}
                    onChangePage={handleChangePage}
                    rowsPerPage={filter?.traffic?.limit}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    rowsPerPageOptions={[10, 50, 100]}
                />
            </TableContainer>
        </TableScroll>
    )
};

export default TrafficMitigations;