import React, {useEffect} from 'react';
import {useLazyQuery, useQuery} from '@apollo/client';
import { GET_L7_GRAPHITE_BAND_WIDTH, GET_L7_RESOURCE_VENDOR } from '../../actions/get';
import { useIntl } from 'react-intl';
import Preloader from '../global/Preloader';
import { Line } from 'react-chartjs-2';
import { Chart, Interaction } from 'chart.js';
import { CrosshairPlugin, Interpolate } from 'chartjs-plugin-crosshair';
import { useStyles } from '../../theme/Chart';
import { valueCalculation } from '../../helpers/calculation';

Chart.register(CrosshairPlugin);
Interaction.modes.interpolate = Interpolate;

const AreaChartsBandWidth = ({ id, date, setFilter }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const dateFormat = require('dateformat');

    const [getBandWidth, { data, loading, error }] = useLazyQuery(GET_L7_GRAPHITE_BAND_WIDTH, {
        variables: {
            from: (Date.parse(date.startDate) - (Date.parse(date.startDate) % 1000)) / 1000 || (date.startDate - (date.startDate % 1000)) / 1000,
            until: (Date.parse(date.endDate) - (Date.parse(date.endDate) % 1000)) / 1000 || (date.endDate - (date.endDate % 1000)) / 1000,
            l7ResourceId: id,
        },
    });

    const { data: stream, loading: load } = useQuery(GET_L7_RESOURCE_VENDOR, {
        variables: {
            l7ResourceId: id,
        },
    });

    useEffect(() => {
        getBandWidth();
        setFilter({...date, activeZoomChart: true});
    }, []);

    if (loading) return <Preloader />;
    if (error || data?.l7GraphiteBandWidth?.timestamp.length < 1) return <div className={classes.errorCharts}>{formatMessage({ id: 'charts.error' })}</div>;

    const serviceStream = stream && stream.l7ResourceVendor.serviceStream;

    let series = {};
    if (!serviceStream)
        series = {
            labels: data?.l7GraphiteBandWidth?.timestamp,
            datasets: [
                {
                    label: formatMessage({ id: 'chartsAreaChartsBandWidth.inbound' }),
                    data: data?.l7GraphiteBandWidth?.toSpBandWidth,
                    borderColor: '#448AFF',
                    backgroundColor: 'rgb(64, 196, 255, 0.2)',
                    borderWidth: 1,
                    radius: 0,
                    hoverRadius: 3,
                },
                {
                    label: formatMessage({ id: 'chartsAreaChartsBandWidth.passed' }),
                    data: data?.l7GraphiteBandWidth?.toClientBandWidth,
                    borderColor: '#69F0AE',
                    backgroundColor: 'rgba(105, 240, 174, 0.6)',
                    borderWidth: 0.5,
                    radius: 0,
                    hoverRadius: 3,
                },
                {
                    label: formatMessage({ id: 'chartsAreaChartsBandWidth.outbound' }),
                    data: data?.l7GraphiteBandWidth?.fromClientBandWidth,
                    borderColor: '#9FA8DA',
                    backgroundColor: 'rgba(159, 168, 218, 0.7)',
                    borderWidth: 0.5,
                    radius: 0,
                    hoverRadius: 3,
                },
            ],
        };
    if (serviceStream)
        series = {
            labels: data?.l7GraphiteBandWidth?.timestamp,
            datasets: [
                {
                    label: formatMessage({ id: 'chartsAreaChartsBandWidth.tspFrom' }),
                    data: data?.l7GraphiteBandWidth?.streamReceived,
                    borderColor: '#69F0AE',
                    backgroundColor: 'rgba(105, 240, 174, 0.6)',
                    borderWidth: 0.5,
                    radius: 0,
                    hoverRadius: 3,
                },
                {
                    label: formatMessage({ id: 'chartsAreaChartsBandWidth.tspTo' }),
                    data: data?.l7GraphiteBandWidth?.streamSent,
                    borderColor: '#9FA8DA',
                    backgroundColor: 'rgba(159, 168, 218, 0.7)',
                    borderWidth: 0.5,
                    radius: 0,
                    hoverRadius: 3,
                },
            ],
        };

    const options = {
        interaction: {
            mode: 'index'
        },
        maintainAspectRatio: false,
        elements: {
            line: {
                borderJoinStyle: 'bevel',
                borderCapStyle: 'square',
                fill: true,
            },
        },
        animation: false,
        scales: {
            y: {
                grid: {
                    display: false,
                    drawBorder: false,
                },
                stacked: false,
                ticks: {
                    display: true,
                    callback: function (value) {
                        return valueCalculation(value, 'bps');
                    },
                },
            },
            x: {
                grid: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    display: true,
                    callback: function (value, index, values) {
                        return index % 2 === 0 ? dateFormat(this.getLabelForValue(value) * 1000, 'h:MM:ssTT') : '';
                    },
                },
            },
        },
        plugins: {
            legend: {
                position: 'bottom',
            },
            filler: {
                propagate: false,
            },
            crosshair: {
                line: {
                    color: '#848484',
                    width: 0.5,
                },
                sync: {
                    enabled: false,
                },
                zoom: {
                    enabled: true,
                    zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',
                    zoomboxBorderColor: '#48F',
                    zoomButtonText: 'reset zoom',
                    zoomButtonClass: 'zoom',
                },
                callbacks: {
                    afterZoom: () => function(start, end) {
                        const afterZoomDate = data?.l7GraphiteBandWidth?.timestamp.filter((item, index) => {
                            if(index === start) return item ;
                            else if(index === end) return item;
                        });
                        const startDate = afterZoomDate[0];
                        const endDate = afterZoomDate[1];
                        getBandWidth({
                            variables: {
                                from: startDate,
                                until: endDate,
                                l7ResourceId: id,
                            }
                        });
                        setFilter({...date, activeZoomChart: false});
                    }
                }
            },
            tooltip: {
                animation: false,
                intersect: false,
                callbacks: {
                    title: function (context) {
                        return dateFormat(context[0].label * 1000, 'dd/mm/yy h:MM:ssTT');
                    },
                    label: function (context) {
                        return `${context.dataset.label}: ${valueCalculation(context.raw, 'bps')}`;
                    },
                },
            },
        },
    };

    return (
        <div className={classes.chart}>
            <button className={date.activeZoomChart ? classes.visibleZoomBtn : classes.zoomBtnDashboard}
                    onClick={() => {
                        getBandWidth();
                        setFilter({...date, activeZoomChart: true});
                    }}
            >
                RESET ZOOM
            </button>
            <Line data={series} options={options} width={100} height={40} />
        </div>
    );
};

export default AreaChartsBandWidth;
