import React, {useContext, useRef, useState} from 'react';
import { useReactToPrint } from 'react-to-print';
import { Typography, Grid } from '@material-ui/core';
import HeadCartsReports from './HeadCartsReports';
import TableAnomaliesDuration from './TableAnomaliesDuration';
import TableAnomaliesTraffic from './TableAnomaliesTraffic';
import TableMitigationsDuration from './TableMitigationsDuration';
import ModalMore from '../../../components/modals/modalsTable/more';
import AreaSummaryTraffic from '../../../components/charts/AreaSummaryTraffic';
import { useStyles } from './styles';
import PieSeverityLvl from '../../../components/charts/PieSeverityLvl';
import PieMisuseTypes from '../../../components/charts/PieMisuseTypes';
import { useQuery } from '@apollo/client';
import { GET_ARBOR_ALERTS } from '../../../actions/get';
import Preloader from '../../../components/global/Preloader';
import { AntPaper } from '../../../theme/Wrapper';
import { useIntl } from 'react-intl';
import { PageContext } from "../../../context/pages";
import {StyledTableCell, StyledTableRow} from "../../webProtection/trafficAnalytics/OsDistribution/styles";

const Reports = () => {
    const { formatMessage } = useIntl();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: () => `
          @page {
            margin: 0 20px;
          }`
    });
    const { filterActive, setFilterActive } = useContext(PageContext);
    const classes = useStyles();
    const [anchorElType, setAnchorElType] = useState(null);
    const [popoverValueType, setPopoverValueType] = useState([]);
    const openType = Boolean(anchorElType);
    const idPopoverType = openType ? 'popover-moreType' : undefined;
    const [dataMitigations, setDataMitigations] = useState([]);
    const dateFormat = require('dateformat');
    const componentRef = useRef();

    const { data, loading } = useQuery(GET_ARBOR_ALERTS, {
        variables: {
            limit: 10000,
            sortMaxImpactBps: 'DESC',
            filterStartLte: dateFormat(filterActive?.timeReportStart, 'isoDateTime'),
            filterStartGte: dateFormat(filterActive?.timeReportEnd, 'isoDateTime'),
        },
    });

    if (loading) return <Preloader />;

    const dataSeverityLevel = data?.arborAlerts.items
        ?.map(item => item.importance)
        .reduce((acc, el) => {
            acc[el] = (acc[el] || 0) + 1;
            return acc;
        }, {});

    const dataMisuseType = data?.arborAlerts.items
        ?.map(item => item.misuseTypes)
        .flat(1)
        .reduce((acc, el) => {
            acc[el] = (acc[el] || 0) + 1;
            return acc;
        }, {});

    const target = Object.entries(
        data?.arborAlerts?.items
            ?.map(item => item.alertResourceCidr)
            .reduce((acc, el) => {
                acc[el] = (acc[el] || 0) + 1;
                return acc;
            }, {})
    );

    const backgroundColor = ['#2778C0', '#EC833D','#80B26E', '#72D0E0', '#E8B83D', '#DF4D42'];
    let x = 0;
    const sumSeverityLevel = Object.entries(dataSeverityLevel).map(([key, value]) => ({label: key, c: value})).map(i => (x += i.c), (x = 0)).reverse()[0];
    const sumMisuseType = Object.entries(dataMisuseType).map(([key, value]) => ({label: key, c: value})).map(i => (x += i.c), (x = 0)).reverse()[0];

    return (
        <div ref={componentRef}>
            <ModalMore id={idPopoverType} open={openType} anchorEl={anchorElType} setAnchorEl={setAnchorElType} value={popoverValueType} title="Type list" />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <HeadCartsReports
                        linkToPrint={handlePrint}
                        filter={filterActive}
                        setFilter={setFilterActive}
                        dataAlerts={data?.arborAlerts}
                        target={target}
                        dataMitigations={dataMitigations}
                    />
                </Grid>
                <Grid item xs={12} className={classes.wrapperArea}>
                    <Typography variant="h1">{formatMessage({ id: 'reports.title.summary' })}</Typography>
                    <AreaSummaryTraffic filter={filterActive} setFilter={setFilterActive}/>
                </Grid>
                <Grid container spacing={3} className={classes.wrapperTable}>
                    <Grid item xs={6} className={classes.pie} container alignItems='center'>
                        <Grid container xs={12} direction="column" justifyContent="center" alignItems="center">
                            <Typography variant="h1" className={classes.headerTitle}>
                                {formatMessage({ id: 'reports.title.anomaliesSeverity' })}
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <div className={classes.puiWrapper}>
                                <PieSeverityLvl data={dataSeverityLevel} />
                            </div>
                        </Grid>
                        <Grid item xs={5}>
                            {
                                Object.entries(dataSeverityLevel).map(([key, value]) => ({label: key, c: value})).map(({ c, label }, index) => (
                                    <StyledTableRow key={label + index} className={classes.lineColorWrapper}>
                                        <StyledTableCell>
                                            <div
                                                style={{ backgroundColor: `${backgroundColor[index]}` }}
                                                className={classes.lineColor}>
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell>{label}</StyledTableCell>
                                        <StyledTableCell>{((c / sumSeverityLevel) * 100).toFixed(2)}&nbsp;%</StyledTableCell>
                                    </StyledTableRow>
                                ))
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={6} className={classes.pie} container alignItems='center'>
                        <Grid container xs={12} direction="column" justifyContent="center" alignItems="center">
                            <Typography variant="h1" className={classes.headerTitle}>
                                {formatMessage({ id: 'reports.title.misuseType' })}
                            </Typography>
                        </Grid>
                        <Grid xs={7} item>
                            <div className={classes.puiWrapper}>
                                <PieMisuseTypes data={dataMisuseType} />
                            </div>
                        </Grid>
                        <Grid xs={5} item >
                            {
                                Object.entries(dataMisuseType).map(([key, value]) => ({label: key, c: value})).map(({ c, label }, index) => (
                                    <StyledTableRow key={label + index} className={classes.lineColorWrapper}>
                                        <StyledTableCell>
                                            <div
                                                style={{ backgroundColor: `${backgroundColor[index]}` }}
                                                className={classes.lineColor}>
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell>{label}</StyledTableCell>
                                        <StyledTableCell>{((c / sumMisuseType) * 100).toFixed(2)}&nbsp;%</StyledTableCell>
                                    </StyledTableRow>
                                ))
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.wrapperTable}>
                    <Typography variant="h1">{formatMessage({ id: 'reports.title.topAnomaliesTraffic' })}</Typography>
                    <TableAnomaliesTraffic setAnchorElType={setAnchorElType} setPopoverValueType={setPopoverValueType} filter={filterActive} data={data} />
                </Grid>
                <Grid item xs={12} className={classes.wrapperTable}>
                    <Typography variant="h1">{formatMessage({ id: 'reports.title.topAnomaliesDuration' })}</Typography>
                    <TableAnomaliesDuration setAnchorElType={setAnchorElType} setPopoverValueType={setPopoverValueType} filter={filterActive} />
                </Grid>
                <Grid item xs={12} className={classes.wrapperTable}>
                    <Typography variant="h1">{formatMessage({ id: 'reports.title.topMitigationsDuration' })}</Typography>
                    <TableMitigationsDuration filter={filterActive} setDataMitigations={setDataMitigations} />
                </Grid>
            </Grid>
        </div>
    );
};

export default Reports;
