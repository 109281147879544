import React, { useEffect } from 'react';
import { useStyle } from './styles';
import CloseIcon from '@material-ui/icons/Close';
import { Button, Dialog, FormControl, RadioGroup, Radio, FormControlLabel, Typography, FormHelperText } from '@material-ui/core';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { UPDATE_L7_ALIAS, UPDATE_L7_RESOURCES } from '../../../../actions/update';
import { Controller, useForm } from 'react-hook-form';
import { GET_L7_RESOURCE_VENDOR } from '../../../../actions/get';
import { useIntl } from 'react-intl';
import { useStyles } from '../../../../theme/Modals';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import withWidth from '@material-ui/core/withWidth';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';

const ModalEditSSLCertificate = ({ show, hideModal, l7ResourceId, certificate, type, id, ssl, query, getVariables, width }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const validCharacters = /[^А-Яа-яёЁ]/;
    const { id: urlId } = useParams();
    const validationSchema = Yup.object().shape({
        sslValue: Yup.string(),
        crt: Yup.string().when('sslValue', {
            is: 'Custom',
            then: Yup.string().required('Current Certificate is required').matches(validCharacters, 'Certificate contains invalid characters'),
            otherwise: Yup.string(),
        }),
        key: Yup.string().when('sslValue', {
            is: 'Custom',
            then: Yup.string().required('Current Key is required').matches(validCharacters, 'Key contains invalid characters'),
            otherwise: Yup.string(),
        }),
    });

    const {
        handleSubmit,
        control,
        watch,
        getValues,
        setValue,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });
    const styles = useStyle();

    useEffect(() => {
        setValue('sslValue', ssl);
    }, [ssl]);

    watch('sslValue');

    const [updateL7Resources] = useMutation(UPDATE_L7_RESOURCES);
    const [updateL7Alias] = useMutation(UPDATE_L7_ALIAS);

    const onSubmit = data => {
        const resource =
            data.sslValue === 'None'
                ? { serviceId: l7ResourceId, serviceSsl: 0, serviceSslAuto: 0, serviceSslCrt: '', serviceSslKey: '' }
                : data.sslValue === 'Custom'
                ? { serviceId: l7ResourceId, serviceSsl: 1, serviceSslAuto: 0, serviceSslCrt: btoa(data.crt), serviceSslKey: btoa(data.key) }
                : { serviceId: l7ResourceId, serviceSsl: 0, serviceSslAuto: 1, serviceSslCrt: '', serviceSslKey: '' };
        const aliases =
            data.sslValue === 'General'
                ? { l7ResourceId, aliasSsl: 0, aliasSslAuto: 0, aliasId: id, aliasSslCrt: '', aliasSslKey: '' }
                : data.sslValue === 'Custom'
                ? { l7ResourceId, aliasSsl: 1, aliasSslAuto: 0, aliasSslCrt: btoa(data.crt), aliasSslKey: btoa(data.key), aliasId: id }
                : { l7ResourceId, aliasSsl: 0, aliasSslAuto: 1, aliasId: id, aliasSslCrt: '', aliasSslKey: '' };

        type === 'general'
            ? updateL7Resources({
                  variables: resource,
                  refetchQueries: [
                      {
                          query: GET_L7_RESOURCE_VENDOR,
                          variables: {
                              l7ResourceId: l7ResourceId,
                          },
                      },
                  ],
              })
                  .then(() => {
                      toast.success('Success');
                  })
                  .catch(err => toast.error(err.message))
            : updateL7Alias({
                  variables: aliases,
                  refetchQueries: [{ query: query, variables: getVariables }],
              })
                  .then(() => {
                      toast.success('Success');
                  })
                  .catch(err => toast.error(err.message));
        reset({
            crt: '',
            key: '',
            sslValue: ssl,
        });
        hideModal();
    };

    return (
        <div>
            <Dialog
                className={classes.dialog}
                maxWidth="md"
                open={show}
                onClose={() => {
                    reset({
                        crt: '',
                        key: '',
                        sslValue: ssl,
                    });
                    hideModal();
                }}
            >
                <div className={styles.modal}>
                    <div className={classes.header}>
                        <Typography variant="h1">{formatMessage({ id: 'modalEditSSLCertificate.title' })}</Typography>
                        <Button
                            className={classes.close}
                            onClick={() => {
                                reset({
                                    crt: '',
                                    key: '',
                                    sslValue: ssl,
                                });
                                hideModal();
                            }}
                        >
                            <CloseIcon />
                        </Button>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={classes.modalBody}>
                            <Controller
                                name="sslValue"
                                control={control}
                                defaultValue={ssl}
                                render={({ field: { onChange, value } }) => (
                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label="gender" name="gender1" value={value} onChange={onChange} className={styles.group}>
                                            {type === 'general' ? (
                                                <FormControlLabel value="None" control={<Radio color="primary" />} label="None" />
                                            ) : (
                                                <FormControlLabel value="General" control={<Radio color="primary" />} label="General" />
                                            )}
                                            <FormControlLabel value="Let’s Encrypt" control={<Radio color="primary" />} label="Let’s Encrypt" />
                                            <FormControlLabel value="Custom" control={<Radio color="primary" />} label="Custom" />
                                        </RadioGroup>
                                    </FormControl>
                                )}
                            />
                            <div className={styles.text}>
                                {getValues('sslValue') === 'General' ? (
                                    <div>
                                        {formatMessage({ id: 'modalEditSSLCertificate.general.dsc' })}
                                        <NavLink to={`/settings-account/${urlId}/0`} className={classes.link} onClick={() => hideModal()}>
                                            {formatMessage({ id: 'modalEditSSLCertificate.general.dscLink' })}
                                        </NavLink>
                                    </div>
                                ) : getValues('sslValue') === 'None' ? (
                                    formatMessage({ id: 'modalEditSSLCertificate.none.dsc' })
                                ) : getValues('sslValue') === 'Let’s Encrypt' ? (
                                    formatMessage({ id: 'modalEditSSLCertificate.encrypt.des' })
                                ) : getValues('sslValue') === 'Custom' ? (
                                    <div className={styles.box}>
                                        <span className={styles.areaTitle}>{formatMessage({ id: 'modalEditSSLCertificate.custom.desCertificate' })}</span>
                                        <Controller
                                            name="crt"
                                            control={control}
                                            defaultValue=""
                                            render={({ field: { onChange, value } }) => (
                                                <>
                                                    <TextareaAutosize
                                                        className={styles.area}
                                                        onChange={onChange}
                                                        value={value}
                                                        aria-label="empty textarea"
                                                        placeholder={
                                                            width === 'xs' || width === 'sm'
                                                                ? `-----BEGIN CERTIFICATE----- MIIFxTCCBK2gAwIBAgIQIeg40Pf/A0sJosTPjzELMAkGA1UEBhMCR0IxGzAZBgNVBAgTEkdyA1UEBxMHU2FsZm9yZDEYMBYGA1UEChMPU2VjEy5TZWN0aWdvIFJTQSBEb21haW4gVmFsaWRhMB4XDTIwMDkyOTAwMDAwM`
                                                                : `                                                                                                         -----BEGIN CERTIFICATE----- MIIFxTCCBK2gAwIBAgIQIeg40PfA0sJosTPMjPatzANBgkqhkiG9w0BAQsFADCBjzELMAkGA1UEBhMCR0IxGzAZBgNVBAgTEkdyZWF0ZXIgTWFuY2hlc3RlcjEQMA4GA1UEBxMHU2FsZm9yZDEYMBYGA1UEChMPU2VjdGlnbyBMaW1pdGVkMTcwNQYDVQQDEy5TZWN0aWdvIFJTQSBEb21haW4gVmFsaWRhdGlvbiBTZWN1cmUgU2VydmVyIENBMB4XDTIwMDkyOTAwMDAwMFoXDTIxMTAzMDIzNTk1OVowHTEbMBkGA1UEAwwSKi5r`
                                                        }
                                                    />
                                                    <FormHelperText style={{ marginBottom: '10px' }} error={!!errors?.crt}>
                                                        {errors?.crt?.message ?? ' '}
                                                    </FormHelperText>
                                                </>
                                            )}
                                        />
                                        <span className={styles.areaTitle}>{formatMessage({ id: 'modalEditSSLCertificate.custom.desPrivate' })}</span>
                                        <Controller
                                            name="key"
                                            control={control}
                                            defaultValue=""
                                            render={({ field: { onChange, value } }) => (
                                                <>
                                                    <TextareaAutosize
                                                        className={styles.area}
                                                        onChange={onChange}
                                                        value={value}
                                                        aria-label="empty textarea"
                                                        helperText={errors?.key?.message ?? ' '}
                                                        placeholder={
                                                            width === 'xs' || width === 'sm'
                                                                ? `-----BEGIN RSA PRIVATE KEY----- MIIFxTCCBK2gAwIBAgIQIeg40Pf/A0sJosTPjzELMAkGA1UEBhMCR0IxGzAZBgNVBAgTEkdyA1UEBxMHU2FsZm9yZDEYMBYGA1UEChMPU2VjEy5TZWN0aWdvIFJTQSBEb21haW4gVmFsaWRhMB4XDTIwMDkyOTAwMDAwM`
                                                                : `                                                                                                          -----BEGIN RSA PRIVATE KEY----- MIIFxTCCBK2gAwIBAgIQIeg40PfA0sJosTPMjPatzANBgkqhkiG9w0BAQsFADCBjzELMAkGA1UEBhMCR0IxGzAZBgNVBAgTEkdyZWF0ZXIgTWFuY2hlc3RlcjEQMA4GA1UEBxMHU2FsZm9yZDEYMBYGA1UEChMPU2VjdGlnbyBMaW1pdGVkMTcwNQYDVQQDEy5TZWN0aWdvIFJTQSBEb21haW4gVmFsaWRhdGlvbiBTZWN1cmUgU2VydmVyIENBMB4XDTIwMDkyOTAwMDAwMFoXDTIxMTAzMDIzNTk1OVowHTEbMBkGA1UEAwwSKi5r`
                                                        }
                                                    />
                                                    <FormHelperText error={!!errors?.key}>{errors?.key?.message ?? ' '}</FormHelperText>
                                                </>
                                            )}
                                        />
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        <div className={classes.modalFooter}>
                            <Button
                                className={classes.cancel}
                                onClick={() => {
                                    reset({
                                        crt: '',
                                        key: '',
                                        sslValue: ssl,
                                    });
                                    hideModal();
                                }}
                            >
                                {formatMessage({ id: 'modalEditSSLCertificate.btn.cancel' })}
                            </Button>
                            <Button type="submit" className={classes.invite}>
                                {formatMessage({ id: 'modalEditSSLCertificate.btn.save' })}
                            </Button>
                        </div>
                    </form>
                </div>
            </Dialog>
        </div>
    );
};

export default withWidth()(ModalEditSSLCertificate);
