import { gql } from 'graphql-tag';

export const Login = gql`
    mutation login($email: String!, $password: String!, $type: ActorTypeForAuthEnum!) {
        login(input: { email: $email, password: $password, type: $type }) {
            accessToken
        }
    }
`;

export const UPDATE_CLIENT_ACCOUNT_EMPLOYEE = gql`
    mutation updateClientAccountEmployee(
        $first: String
        $last: String
        $id: Float!
        $notificationSubscribe: [NotificationEventEnum!]
        $lang: UserLangEnum
        $role: ActorRoleEnum
    ) {
        updateClientAccountEmployee(
            input: { name: { first: $first, last: $last }, id: $id, notificationSubscribe: $notificationSubscribe, lang: $lang, role: $role }
        ) {
            name {
                first
                last
            }
            role
            email
            notificationSubscribe
            lang
        }
    }
`;

export const SET_PASSWORD = gql`
    mutation setPassword($newPassword: String!, $oldPassword: String, $email: String!, $type: ActorTypeEnum!, $verifyToken: String, $resetToken: String) {
        setPassword(
            input: { oldPassword: $oldPassword, newPassword: $newPassword, email: $email, type: $type, verifyToken: $verifyToken, resetToken: $resetToken }
        ) {
            email
        }
    }
`;

export const SET_ACCESS = gql`
    mutation setAccess(
        $clientAccountEmployeeId: Float
        $l3Delete: [Float!]
        $l3Read: [Float!]
        $l3Update: [Float!]
        $l7Delete: [Float!]
        $l7Read: [Float!]
        $l7Update: [Float!]
        $create: [CreateEmployeeAccessEnum!]
    ) {
        setAccess(
            input: {
                clientAccountEmployeeId: $clientAccountEmployeeId
                l3Delete: $l3Delete
                l3Read: $l3Read
                l3Update: $l3Update
                l7Delete: $l7Delete
                l7Read: $l7Read
                l7Update: $l7Update
                create: $create
            }
        ) {
            id
        }
    }
`;

export const RESET_PASSWORD = gql`
    mutation resetPassword($email: String!, $type: ActorTypeEnum!) {
        resetPassword(input: { email: $email, type: $type }) {
            email
        }
    }
`;

export const UPDATE_L7_RESOURCES = gql`
    mutation updateL7Resource(
        $serviceGlobalWhitelist: Float
        $serviceHttp2: Float
        $serviceHttp2https: Float
        $serviceWwwredir: Float
        $serviceSsl: Float
        $serviceSslAuto: Float
        $serviceSslCrt: String
        $serviceSslKey: String
        $serviceId: Float!
        $serviceGeoipMode: Float
        $serviceGeoipList: String
        $serviceIphash: Float
        $serviceForcessl: Float
        $serviceHttps2http: Float
        $serviceName: String
        $iauthDisableL7: Float
    ) {
        updateL7Resource(
            input: {
                serviceId: $serviceId
                serviceGlobalWhitelist: $serviceGlobalWhitelist
                serviceHttp2: $serviceHttp2
                serviceHttp2https: $serviceHttp2https
                serviceWwwredir: $serviceWwwredir
                serviceSsl: $serviceSsl
                serviceSslAuto: $serviceSslAuto
                serviceSslCrt: $serviceSslCrt
                serviceSslKey: $serviceSslKey
                serviceGeoipMode: $serviceGeoipMode
                serviceGeoipList: $serviceGeoipList
                serviceIphash: $serviceIphash
                serviceForcessl: $serviceForcessl
                serviceHttps2http: $serviceHttps2http
                serviceName: $serviceName
                iauthDisableL7: $iauthDisableL7
            }
        ) {
            iauthDisableL7
            serviceGlobalWhitelist
            serviceHttp2
            serviceHttp2https
            serviceWwwredir
            serviceSsl
            serviceSslAuto
            serviceSslCrt
            serviceSslKey
            serviceGeoipList
            serviceGeoipMode
            serviceIphash
            serviceForcessl
            serviceHttps2http
            serviceDisableL7
        }
    }
`;

export const UPDATE_L7_ALIAS = gql`
    mutation updateL7Alias(
        $aliasId: Float!
        $aliasSsl: Float
        $aliasSslAuto: Float
        $aliasSslCrt: String
        $aliasSslExpire: Float
        $aliasSslKey: String
        $l7ResourceId: Float!
    ) {
        updateL7Alias(
            input: {
                aliasId: $aliasId
                aliasSsl: $aliasSsl
                aliasSslAuto: $aliasSslAuto
                aliasSslCrt: $aliasSslCrt
                aliasSslExpire: $aliasSslExpire
                aliasSslKey: $aliasSslKey
                l7ResourceId: $l7ResourceId
            }
        ) {
            aliasId
        }
    }
`;

export const UPDATE_L7_ORIGIN = gql`
    mutation updateL7Origin($l7ResourceId: Float!, $originId: Float!, $originMode: String, $originWeight: Float) {
        updateL7Origin(input: { l7ResourceId: $l7ResourceId, originId: $originId, originMode: $originMode, originWeight: $originWeight }) {
            originId
            originMode
            originWeight
        }
    }
`;

export const UPDATE_L7_WHITE_LISTS = gql`
    mutation updateL7WhiteLists($l7ResourceId: Float!, $whitelistId: Float!, $whitelistComment: String, $whitelistExpire: Float) {
        updateL7WhiteList(
            input: { l7ResourceId: $l7ResourceId, whitelistId: $whitelistId, whitelistComment: $whitelistComment, whitelistExpire: $whitelistExpire }
        ) {
            whitelistId
        }
    }
`;

export const UPDATE_L7_BLACK_LISTS = gql`
    mutation updateL7BlackList($l7ResourceId: Float!, $blackListId: Float!, $blacklistComment: String, $blacklistExpire: Float) {
        updateL7BlackList(
            input: { l7ResourceId: $l7ResourceId, blackListId: $blackListId, blacklistComment: $blacklistComment, blacklistExpire: $blacklistExpire }
        ) {
            blacklistId
        }
    }
`;

export const TWO_FACTOR_AUTH = gql`
    mutation twoFactorAuth($code: String!, $email: String!) {
        twoFactorAuth(input: {
            code: $code,
            email: $email,
            type: CLIENT_ACCOUNT_EMPLOYEE,
        }) {
            accessToken
        }
    }
`;

export const DEACTIVE_TWO_FACTOR_AUTH = gql`
    mutation {
        deActivateTwoFactorAuth {
            email
        }
    }
`;

export const LOAD_ALERT_DETAILS = gql`
    mutation loadAlertsDetailsByAlerts($ids: [Float!]!){
        loadAlertsDetailsByAlerts(input: { ids: $ids }) {
            success
        }
    }
`;