import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    wrapperCards: {
        display: 'flex',
        alignItems: 'stretch',
    },
    cards: {
        padding: 14,
        width: '100%',
    },
    wrapperDatePiker: {
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        height: '100%',
    },
    btnDwl: {
        cursor: 'pointer',
        textDecoration: 'underline',
        fontSize: 16,
        letterSpacing: 0.15,
    }
}));
