import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useStyles } from '../../../theme/Table';
import { TableContainer } from '@material-ui/core';
import ModalAddList from '../../../components/modals/modalsWebProtection/addList';
import { useLazyQuery } from '@apollo/client';
import { GET_L7_BLACK_LISTS } from '../../../actions/get';
import ModalAddCommentBlack from '../../../components/modals/modalsWebProtection/addCommentBlack';
import ModalDeleteL7List from '../../../components/modals/modalsWebProtection/deleteList';
import { useDebouncedEffect } from '../../../hooks/useDebouncedEffect';
import { useIntl } from 'react-intl';
import { TablePaginationComponent } from '../../../components/global/TablePagination';
import { PageContext } from '../../../context/pages';
import Preloader from '../../../components/global/Preloader';
import withWidth from '@material-ui/core/withWidth';
import BlackListsDesktop from './BlackListsDesktop';
import BlackListsMobile from '../../../components/mobile/BlackListsMobile';

const BlackList = ({ width }) => {
    const classes = useStyles();
    const [isEdit, setIsEdit] = useState(false);
    const { formatMessage } = useIntl();
    const [isEditComment, setIsEditComment] = useState({
        edit: false,
        id: '',
    });
    const [isDeleted, setIsDeleted] = useState({
        deleted: false,
    });
    const { id } = useParams();
    const { secondaryFilter, setSecondaryFilter } = useContext(PageContext);

    const [getFilter, { data, loading }] = useLazyQuery(GET_L7_BLACK_LISTS);
    const getVariables = () => {
        const proxy = {
            l7ResourceId: Number(id),
            limit: secondaryFilter.limit,
            page: secondaryFilter.page + 1,
            blacklistIp: secondaryFilter?.ip || '',
        };

        return proxy;
    };

    useDebouncedEffect(
        () => {
            getFilter({ variables: getVariables() });
        },
        500,
        [secondaryFilter]
    );

    if (loading) return <Preloader />;

    return (
        <>
            <ModalDeleteL7List
                show={isDeleted.deleted}
                hideModal={() => setIsDeleted(!isDeleted.deleted)}
                text="Delete IP from the black list"
                ip={isDeleted.ip}
                id={isDeleted.id}
                resourcesId={Number(id)}
                query={GET_L7_BLACK_LISTS}
                getVariables={getVariables()}
                type="blackList"
            />
            <ModalAddList
                show={isEdit}
                hideModal={() => setIsEdit(false)}
                text={formatMessage({ id: 'webProtection.black.create.title' })}
                type="blackList"
                query={GET_L7_BLACK_LISTS}
                getVariables={getVariables()}
                resourcesId={Number(id)}
            />
            <ModalAddCommentBlack
                show={isEditComment.edit}
                hideModal={() => setIsEditComment(!isEditComment.edit)}
                ip={isEditComment.ip}
                id={isEditComment.id}
                resourcesId={Number(id)}
                query={GET_L7_BLACK_LISTS}
                getVariables={getVariables()}
            />
            <div>
                <TableContainer>
                    {width === 'xs' ? (
                        <BlackListsMobile data={data} setIsEditComment={setIsEditComment} setIsDeleted={setIsDeleted} setIsEdit={setIsEdit} />
                    ) : (
                        <BlackListsDesktop
                            data={data}
                            filter={secondaryFilter}
                            setFilter={setSecondaryFilter}
                            setIsEditComment={setIsEditComment}
                            setIsDeleted={setIsDeleted}
                            setIsEdit={setIsEdit}
                        />
                    )}
                    <TablePaginationComponent
                        width={width}
                        filter={secondaryFilter}
                        setFilterActive={setSecondaryFilter}
                        count={data && data.l7BlackLists.info.totalCount}
                        getFilter={getFilter}
                        getVariables={getVariables()}
                        type={'SECONDARY_FILTER'}
                    />
                </TableContainer>
            </div>
        </>
    );
};

export default withWidth()(BlackList);
