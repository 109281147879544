import React, {useContext} from 'react';
import { Redirect, Route } from 'react-router-dom';
import {AuthContext} from "../../../context/auth";

const MyPrivateRoute = ({ children, ...props }) => {
    const { user } = useContext(AuthContext);
    if(!user) {
        return <Redirect to='/'/>
    }

    return <Route {...props}>{children}</Route>;
};

export default MyPrivateRoute;
