import React, {useContext, useEffect, useState} from 'react';
import { Typography, Grid, Container, TextField } from '@material-ui/core';
import { useStyles, MyTabs } from './styles';
import { Autocomplete } from '@material-ui/lab';
import { useQuery } from '@apollo/client';
import { GET_L7_RESOURCES_VENDOR } from '../../../actions/get';
import Preloader from '../../../components/global/Preloader';
import { AntTab } from '../../../theme/Tabs';
import TabPanel from '../../../components/global/TabPanel';
import OsDistribution from './OsDistribution';
import BotsIpAddresses from './BotsIpAddresses';
import BotsRequests from './BotsRequests';
import UaDistribution from './UaDistribution';
import { useIntl } from 'react-intl';
import { PageContext } from '../../../context/pages';
import TableDateTabs from '../../../components/global/TableDateTabs';
import TableDatePicker from '../../../components/global/TableDatePicker';
import BotsSubnets from "./BotsSubnets";
import AreaChartsCaptcha from "../../../components/charts/AreaChartsCaptcha";

const TrafficAnalytics = () => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const [tab, setTab] = useState(0);
    const [select, setSelect] = useState(null);
    const { filterActive, setFilterActive } = useContext(PageContext);
    const { data, loading } = useQuery(GET_L7_RESOURCES_VENDOR);
    const [abpMode, setAbpMode] = useState(null);

    useEffect(() => {
        if (data && !select) setAbpMode(data?.l7ResourcesVendor?.items[0]?.serviceAbpMode)
    }, [data]);

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    const a11yProps = index => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    if (loading) return <Preloader />;

    const {
        l7ResourcesVendor: { items },
    } = data;

    const options = items.map(({ l7ResourceId, serviceName, serviceAbpMode }) => {
        return {
            l7ResourceId,
            serviceName,
            serviceAbpMode,
        };
    });

    return (
        <div>
            <Grid item xs={12}>
                <Typography variant="h1" className={classes.headerTitle}>
                    {formatMessage({ id: 'webProtection.analytic.title' })}
                </Typography>
            </Grid>
            <Grid container direction="row" alignItems="flex-start">
                <Grid item xs>
                    <Autocomplete
                        size="small"
                        className={classes.closeIcon}
                        id="resellerId"
                        renderInput={params => <TextField {...params} variant="outlined" size="small" className={classes.select} />}
                        options={options}
                        defaultValue={options[0]}
                        getOptionLabel={option => `(${option.l7ResourceId}) ${option.serviceName}`}
                        renderOption={option => `(${option.l7ResourceId}) ${option.serviceName}`}
                        onChange={(event, value) => {
                            setSelect(value.l7ResourceId);
                            setAbpMode(value.serviceAbpMode);
                        }}
                    />
                </Grid>
                <Grid item>
                    <div className={classes.date}>
                        <TableDateTabs filter={filterActive} setFilter={setFilterActive} dosGate={true} disabled={abpMode === 0} />
                        <TableDatePicker filter={filterActive} setFilter={setFilterActive} minDate={true} disabled={abpMode === 0}/>
                    </div>
                </Grid>
            </Grid>
            <div position="static" className={classes.bar}>
                <Container disableGutters>
                    <MyTabs value={tab} onChange={handleChange}>
                        <AntTab label={'Captcha statistic'} {...a11yProps(0)} disabled={abpMode === 0}/>
                        <AntTab label={formatMessage({ id: 'webProtection.analytic.tab.osDist' })} {...a11yProps(1)} disabled={abpMode === 0}/>
                        <AntTab label={formatMessage({ id: 'webProtection.analytic.tab.uaDist' })} {...a11yProps(2)} disabled={abpMode === 0}/>
                        <AntTab label={formatMessage({ id: 'webProtection.analytic.tab.botIp' })} {...a11yProps(3)} disabled={abpMode === 0}/>
                        <AntTab label={formatMessage({ id: 'webProtection.analytic.tab.botSunb' })} {...a11yProps(4)} disabled={abpMode === 0}/>
                        <AntTab label={formatMessage({ id: 'webProtection.analytic.tab.botReq' })} {...a11yProps(5)} disabled={abpMode === 0}/>
                    </MyTabs>
                </Container>
            </div>
            {abpMode === 0 ? (
                <div className={classes.notAnalytics}>{formatMessage({ id: 'webProtection.analytic.disabled' })}</div>
            ) : (
                <>
                    <TabPanel value={tab} index={0}>
                        <AreaChartsCaptcha date={filterActive} l7ResourceId={!select ? data?.l7ResourcesVendor?.items[0]?.l7ResourceId : select} setFilterActive={setFilterActive}/>
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        <OsDistribution date={filterActive} l7ResourceId={!select ? data?.l7ResourcesVendor?.items[0]?.l7ResourceId : select} />
                    </TabPanel>
                    <TabPanel value={tab} index={2}>
                        <UaDistribution date={filterActive} l7ResourceId={!select ? data?.l7ResourcesVendor?.items[0]?.l7ResourceId : select} />
                    </TabPanel>
                    <TabPanel value={tab} index={3}>
                        <BotsIpAddresses date={filterActive} l7ResourceId={!select ? data?.l7ResourcesVendor?.items[0]?.l7ResourceId : select} />
                    </TabPanel>
                    <TabPanel value={tab} index={4}>
                        <BotsSubnets date={filterActive} l7ResourceId={!select ? data?.l7ResourcesVendor?.items[0]?.l7ResourceId : select} />
                    </TabPanel>
                    <TabPanel value={tab} index={5}>
                        <BotsRequests date={filterActive} l7ResourceId={!select ? data?.l7ResourcesVendor?.items[0]?.l7ResourceId : select} />
                    </TabPanel>
                </>
            )}
        </div>
    );
};

export default TrafficAnalytics;
