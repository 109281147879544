import React from 'react';
import { useStyles, AntTableCell } from '../../../theme/Table';
import { TableRow, TableHead, Table, TableBody, Grid, Button } from '@material-ui/core';
import { useIntl } from 'react-intl';
import BlackList from './BlackList';

const BlackListsMobile = ({ data, setIsEditComment, setIsDeleted, setIsEdit }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();

    return (
        <>
            <Grid container>
                <Grid item xs={12} sm="auto">
                    <Button color="primary" variant="contained" className={classes.button} onClick={() => setIsEdit(true)}>
                        {formatMessage({ id: 'webProtection.white.btn' })}
                    </Button>
                </Grid>
            </Grid>
            <Table>
                <TableHead>
                    <TableRow>
                        <AntTableCell align="left" className={classes.title}>
                            {formatMessage({ id: 'webProtection.white.ip' })}
                        </AntTableCell>
                        <AntTableCell align="left" className={classes.title}>
                            {formatMessage({ id: 'webProtection.white.comment' })}
                        </AntTableCell>

                        <AntTableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data &&
                        data.l7BlackLists.items.map(({ blacklistData, blacklistComment, blacklistId }) => (
                            <TableRow key={blacklistId}>
                                <BlackList
                                    blacklistId={blacklistId}
                                    blacklistData={blacklistData}
                                    blacklistComment={blacklistComment}
                                    setIsEditComment={setIsEditComment}
                                    setIsDeleted={setIsDeleted}
                                />
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </>
    );
};

export default BlackListsMobile;
