import React from 'react';
import { useStyles } from '../styles';
import { TextField, Button, InputAdornment, Typography } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { GET_CLIENT_ACCOUNT_EMPLOYEE } from "../../../../actions/get";
import { UPDATE_CLIENT_ACCOUNT_EMPLOYEE } from "../../../../actions/update";

const Info = ({ data, token }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const [setInfo] = useMutation(UPDATE_CLIENT_ACCOUNT_EMPLOYEE);

    const onSubmit = inputData => {
        setInfo({
            variables: {
                first: inputData.firstName,
                last: inputData.lastName,
                id: token && token.id,
            },
            refetchQueries: [
                {
                    query: GET_CLIENT_ACCOUNT_EMPLOYEE,
                    variables: {
                        id: token && token.id,
                    },
                },
            ],
        })
            .then(() => toast.success('Success'))
            .catch(err => toast.error(err.message));
    };

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('Current First Name is required'),
        lastName: Yup.string().required('Current Last Name is required'),
    });

    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    return (
        <>
            <Typography variant="h1" className={classes.title}>
                {formatMessage({ id: 'profile.info.title' })}
            </Typography>
            {data && (
                <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        name="firstName"
                        control={control}
                        defaultValue={data?.clientAccountEmployee?.name?.first}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                onChange={onChange}
                                value={value}
                                label={formatMessage({ id: 'profile.info.name1' })}
                                variant="filled"
                                className={classes.input}
                                error={!!errors.firstName}
                                helperText={errors?.firstName?.message ?? ' '}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{errors.firstName ? <Error color="error" /> : ''}</InputAdornment>,
                                }}
                            />
                        )}
                    />
                    <Controller
                        name="lastName"
                        control={control}
                        defaultValue={data?.clientAccountEmployee?.name?.last}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                onChange={onChange}
                                value={value}
                                label={formatMessage({ id: 'profile.info.name2' })}
                                variant="filled"
                                className={classes.input}
                                error={!!errors.lastName}
                                helperText={errors?.lastName?.message ?? ' '}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{errors.lastName ? <Error color="error" /> : ''}</InputAdornment>,
                                }}
                            />
                        )}
                    />
                    <TextField disabled defaultValue={data?.clientAccountEmployee?.email} label="Email" variant="filled" className={classes.input} />
                    <div className={classes.buttonWrapper}>
                        <Button type="submit" color="primary" variant="contained" className={classes.button}>
                            {formatMessage({ id: 'profile.info.btn' })}
                        </Button>
                    </div>
                </form>
            )}
        </>
    );
};

export default Info;