import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    pickerWrapper: {
        border: '1.5px solid transparent',
        borderRadius: 4,
        padding: '0 10px ',
        marginTop: 20,
        background: 'linear-gradient(180deg, #F6F7FB 0%, #FBFCFD 100%)',
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: '0.25px',
        color: theme.palette.grey.grey500,
    },
    dataPicker: {
        border: 0,
        padding: '8px 5px',
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: '0.25px',
        color: theme.palette.grey.grey500,
        outline: 'none',
        maxWidth: 250,
        '&:focus': {
            color: theme.palette.common.black,
        },
    },
    popper: {
        '& .react-datepicker__header': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
        '& .react-datepicker__navigation--previous': {
            borderRightColor: '#FAFAFA',
        },
        '& .react-datepicker__navigation--next': {
            borderLeftColor: '#FAFAFA',
        },
        '&  .react-datepicker__month-text--keyboard-selected': {
            backgroundColor: theme.palette.primary.main,
        },
        '& .react-datepicker__current-month': {
            color: theme.palette.common.white,
        },
        '&   .react-datepicker__month-text--today': {
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.common.black,
            fontWeight: 700,
        },
    },
}));
