import React, {useEffect, useState} from "react";
import {Fab, Grid, MenuItem, TextField} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {toast} from "react-toastify";
import {GET_L7_ORIGINS} from "../../../../actions/get";
import {Add, Remove} from "@material-ui/icons";
import {useStyles} from "../styles";
import {useMutation, useQuery} from "@apollo/client";
import {CREATE_L7_ORIGIN} from "../../../../actions/create";
import {DELETE_L7_ORIGIN} from "../../../../actions/delete";
import {UPDATE_L7_ORIGIN} from "../../../../actions/update";
import Preloader from "../../../../components/global/Preloader";

const GeneralSettingsOrigins = ({ id }) => {
    const classes = useStyles();
    const [originIps, setOriginIps] = useState([]);

    const [createIp] = useMutation(CREATE_L7_ORIGIN);
    const [deleteIP] = useMutation(DELETE_L7_ORIGIN);
    const [updateIp] = useMutation(UPDATE_L7_ORIGIN);

    const { data: origins, loading } = useQuery(GET_L7_ORIGINS, {
        variables: {
            l7ResourceId: Number(id),
        },
    });

    useEffect(() => {
        const arr = [];
        origins &&
        origins.l7Origins.items.map(item => {
            const { originData, originId, originMode, originWeight } = item;
            const proxy = { originData, originId, originMode, originWeight, disabled: true, inputBlock: true };
            arr.push(proxy);
            return arr;
        });
        setOriginIps(arr);
    }, [origins]);

    const createOriginIp = data => {
        createIp({
            variables: {
                l7ResourceId: Number(id),
                originData: data.originData,
                originMode: data.originMode,
                originWeight: data.originWeight,
            },
            refetchQueries: [{ query: GET_L7_ORIGINS, variables: { l7ResourceId: Number(id) } }],
        })
            .then(() => toast.success('Success'))
            .catch(err => toast.error(err.message));
    };

    const addOriginIp = () => {
        setOriginIps(prev => {
            let proxy = [...prev];
            proxy = proxy.map(item => {
                item.disabled = false;
                return item;
            });
            return [...proxy, { originData: '', originId: new Date().getTime(), originMode: 'primary', originWeight: 50, disabled: false, inputBlock: false }];
        });
    };

    const deleteOriginIp = originId => {
        deleteIP({
            variables: {
                l7ResourceId: Number(id),
                originId: Number(originId),
            },
            refetchQueries: [{ query: GET_L7_ORIGINS, variables: { l7ResourceId: Number(id) } }],
        })
            .then(() => setOriginIps(prev => prev.filter(el => el.originId !== originId)))
            .then(() => toast.success('Success'))
            .catch(err => toast.error(err.message));
    };

    if (loading) return <Preloader/>;

    return (
        <>
            {/*non origin */}
            {originIps.length === 0 && (
                <Grid container xs={12}>
                    <Grid item xs={12} className={classes.empty}>
                        The list is empty...
                    </Grid>
                </Grid>
            )}

            {/*origin input*/}
            {originIps.map((item, index) => {
                return (
                    <Grid container key={item.originId}>
                        <Grid item xs={4} md={7}>
                            <div className={classes.inputOriginIp}>
                                <TextField
                                    className={classes.input}
                                    size="small"
                                    variant="outlined"
                                    value={item.originData}
                                    defaultValue={item.originData}
                                    disabled={item.inputBlock}
                                    onChange={({ target: { value } }) =>
                                        setOriginIps(prev => {
                                            const proxy = [...prev];
                                            proxy[index].originData = value;
                                            return proxy;
                                        })
                                    }
                                    onBlur={() => createOriginIp(item)}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={8} md={5}>
                            <Grid container>
                                <Grid xs={4} item>
                                    <FormControl variant="outlined">
                                        <Select
                                            className={classes.select}
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={item.originMode}
                                            onChange={({ target: { value } }) => {
                                                setOriginIps(prev => {
                                                    const proxy = [...prev];
                                                    proxy[index].originMode = value;
                                                    return proxy;
                                                });
                                                updateIp({
                                                    variables: {
                                                        l7ResourceId: Number(id),
                                                        originId: item.originId,
                                                        originMode: value,
                                                    },
                                                })
                                                    .then(() => toast.success('Success'))
                                                    .catch(err => toast.error(err.message))

                                            }}
                                        >
                                            <MenuItem value={'primary'}>primary</MenuItem>
                                            <MenuItem value={'backup'}>backup</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid xs={3} item>
                                    <TextField
                                        className={classes.input}
                                        size="small"
                                        variant="outlined"
                                        defaultValue={item.originWeight}
                                        onBlur={({ target: { value } }) =>
                                            updateIp({
                                                variables: {
                                                    l7ResourceId: Number(id),
                                                    originId: item.originId,
                                                    originWeight: Number(value),
                                                },
                                                refetchQueries: [{ query: GET_L7_ORIGINS, variables: { l7ResourceId: Number(id) } }],
                                            })
                                                .then(() => toast.success('Success'))
                                                .catch(err => toast.error(err.message))
                                        }
                                    />
                                </Grid>

                                <Grid item>
                                    <Grid container>
                                        {originIps.length >= 2 && (
                                            <Grid item>
                                                <Fab
                                                    color="secondary"
                                                    aria-label="remove"
                                                    onClick={() => deleteOriginIp(item.originId)}
                                                >
                                                    <Remove />
                                                </Fab>
                                            </Grid>
                                        )}
                                        <Grid item>
                                            {originIps.length === index + 1 && (
                                                <Fab color="secondary" aria-label="add" onClick={() => addOriginIp()} disabled={!originIps[originIps.length - 1].originData.length}>
                                                    <Add />
                                                </Fab>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                );
            })}
            {/*{originIps.length !== 0 && (*/}
            {/*    <Grid container spacing={10}>*/}
            {/*        <Grid item xs={6} />*/}
            {/*        <Grid item xs={6}>*/}
            {/*            <Grid item xs={12} className={classes.wrapperHash}>*/}
            {/*                <div className={classes.hashTitle}>{formatMessage({ id: 'webProtection.general.hash' })}</div>*/}
            {/*                <div className={classes.hashSubtitle}>Round robin</div>*/}
            {/*                <div>*/}
            {/*                    <BaseSwitch checked={stateSwitch.serviceIphash} onChange={handleChangeHash} name="serviceIphash" />*/}
            {/*                </div>*/}
            {/*                <div className={classes.hashSubtitle}>IP hash</div>*/}
            {/*            </Grid>*/}
            {/*        </Grid>*/}
            {/*    </Grid>*/}
            {/*)}*/}
        </>
    )
};

export default GeneralSettingsOrigins