import React, { useState, useEffect, useContext, useRef } from 'react';
import { useDebouncedEffect } from '../../../hooks/useDebouncedEffect';
import { useLazyQuery } from '@apollo/client';
import { GET_ARBOR_RESOURCES } from '../../../actions/get';
import { PageContext } from '../../../context/pages';
import ResourcesL3Desktop from './ResourcesL3Desktop';
import ResourcesL3Mobile from '../../../components/mobile/ResourcesL3Mobile';
import ModalMore from '../../../components/modals/modalsTable/more';
import Preloader from '../../../components/global/Preloader';
import withWidth from '@material-ui/core/withWidth';
import { TableContainer } from '@material-ui/core';
import TableScroll from '../../../components/global/TableScroll';
import { TablePaginationComponent } from '../../../components/global/TablePagination';

const Resources = ({ width }) => {
    const { filterActive, setFilterActive } = useContext(PageContext);
    const table = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [ipsPopover, setIpsPopover] = useState([]);
    const open = Boolean(anchorEl);
    const idPopover = open ? 'popover-more' : undefined;

    const [getFilter, { data, loading }] = useLazyQuery(GET_ARBOR_RESOURCES);
    const getVariables = () => {
        const proxy = {
            limit: filterActive.limit,
            page: filterActive.page + 1,
            name: filterActive?.name || '',
        };
        if (filterActive?.id) proxy.manageObjectId = Number(filterActive?.id);
        return proxy;
    };

    useEffect(() => {
        getFilter({ variables: getVariables() });
    }, []);

    useDebouncedEffect(
        () => {
            getFilter({ variables: getVariables() });
        },
        1000,
        [filterActive]
    );

    if (loading) return <Preloader />;

    return (
        <div>
            <ModalMore id={idPopover} open={open} anchorEl={anchorEl} setAnchorEl={setAnchorEl} value={ipsPopover} title="IPs list" />
            <TableScroll tableRef={table}>
                <TableContainer ref={table}>
                    {width === 'xs' ? (
                        <ResourcesL3Mobile data={data} setIpsPopover={setIpsPopover} setAnchorEl={setAnchorEl} />
                    ) : (
                        <ResourcesL3Desktop data={data} filter={filterActive} setFilter={setFilterActive} setIpsPopover={setIpsPopover} setAnchorEl={setAnchorEl} />
                    )}
                    <TablePaginationComponent
                        width={width}
                        filter={filterActive}
                        setFilterActive={setFilterActive}
                        count={data && data.arborResources.info.totalCount}
                        getFilter={getFilter}
                        getVariables={getVariables()}
                        type={'FILTER_ACTIVE'}
                    />
                </TableContainer>
            </TableScroll>
        </div>
    );
};
export default withWidth()(Resources);
