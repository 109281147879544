import React from 'react';
import {useIntl} from "react-intl";
import {useStyles} from "./styles";
import Grid from "@material-ui/core/Grid";
import {valueCalculation} from "../../../../helpers/calculation";
import PropTypes from "prop-types";

const NetWorkBoxes = ({ store, data, mitigations }) => {
    const { formatMessage } = useIntl();
    const classes = useStyles();

    return (
        <Grid container direction="column" alignItems="flex-start" style={{ marginTop: 70 }}>
            <Grid item>
                <div className={classes.title}>{formatMessage({ id: 'chartsAreaAllTraffic.95in' })}</div>
                <div className={classes.subtitle}>{valueCalculation(store?.arborGraphiteTrafficForAllResources?.nPercentile95TrafficIn, 'bps')}</div>
            </Grid>
            <Grid item>
                <div className={classes.title}>{formatMessage({ id: 'chartsAreaAllTraffic.96out' })}</div>
                <div className={classes.subtitle}>{valueCalculation(store?.arborGraphiteTrafficForAllResources?.nPercentile95TrafficOut, 'bps')}</div>
            </Grid>
            <Grid item>
                <div className={classes.title}>{formatMessage({ id: 'chartsAreaAllTraffic.anomalies' })}</div>
                <div className={classes.subtitle}>{data?.arborAlerts?.info?.totalCount}</div>
            </Grid>
            <Grid item>
                <div className={classes.title}>{formatMessage({ id: 'chartsAreaAllTraffic.mitigations' })}</div>
                <div className={classes.subtitle}>{mitigations?.arborMitigations?.info?.totalCount}</div>
            </Grid>
        </Grid>
    );
};

NetWorkBoxes.propTypes = {
    store: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    mitigations: PropTypes.object.isRequired,

};

export default NetWorkBoxes;