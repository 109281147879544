import React from 'react';

import ModalMore from './Component.js'

const ConnectedModalMore = (props) => {
    return (
        <ModalMore {...props}/>
    )
}

export default ConnectedModalMore