import React, { useState, useMemo, useEffect } from 'react';
import { useStyles } from './styles';
import { FormControl, FormControlLabel, Radio, RadioGroup, Button, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import countryList from 'react-select-country-list';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@apollo/client';
import { GET_L7_RESOURCE_VENDOR } from '../../../actions/get';
import { UPDATE_L7_RESOURCES } from '../../../actions/update';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import Preloader from '../../../components/global/Preloader';

const AccessPolicy = () => {
    const classes = useStyles();
    const { id } = useParams();
    const { formatMessage } = useIntl();
    const [allExceptCountry, setAllExceptCountry] = useState([]);
    const [nobodyExceptCountry, setNobodyExceptCountry] = useState([]);
    const options = useMemo(() => countryList().getData(), []);

    const {
        control,
        handleSubmit,
        formState: { errors },
        watch,
        getValues,
        reset,
    } = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                radio: Yup.string(),
                nobodyExcept: Yup.array().when('radio', {
                    is: '2',
                    then: Yup.array().min(1, formatMessage({ id: 'webProtection.policy.valid' })),
                    otherwise: Yup.array().min(0),
                }),
                allExcept: Yup.array().when('radio', {
                    is: '1',
                    then: Yup.array().min(1, formatMessage({ id: 'webProtection.policy.valid' })),
                    otherwise: Yup.array().min(0),
                }),
            })
        ),
    });

    watch('radio');

    const { data, loading } = useQuery(GET_L7_RESOURCE_VENDOR, {
        variables: {
            l7ResourceId: Number(id),
        },
    });

    const transformationOptions = dataServiceGeoipList => {
        const re = /\s*,\s*/;
        const arrCountry = dataServiceGeoipList.split(re);
        const listCountry = arrCountry.map(param => {
            const item = { value: param };
            return item;
        });
        return listCountry;
    };

    useEffect(() => {
        if (data && data.l7ResourceVendor.serviceGeoipMode === 1) {
            setAllExceptCountry(transformationOptions(data && data.l7ResourceVendor.serviceGeoipList));
            setNobodyExceptCountry([]);
        } else if (data && data.l7ResourceVendor.serviceGeoipMode === 2) {
            setNobodyExceptCountry(transformationOptions(data && data.l7ResourceVendor.serviceGeoipList));
            setAllExceptCountry([]);
        } else {
            setAllExceptCountry([]);
            setNobodyExceptCountry([]);
        }
    }, [data]);

    const [setUpdateL7Resource] = useMutation(UPDATE_L7_RESOURCES);

    const getVariables = value => {
        const proxy = {
            serviceId: Number(id),
        };
        if (value.radio === '1') {
            proxy.serviceGeoipMode = 1;
            proxy.serviceGeoipList = value.allExcept.map(param => param.value).toString();
        } else if (value.radio === '2') {
            proxy.serviceGeoipMode = 2;
            proxy.serviceGeoipList = value.nobodyExcept.map(param => param.value).toString();
        } else {
            proxy.serviceGeoipMode = 0;
            proxy.serviceGeoipList = '';
        }
        return proxy;
    };

    const onSubmit = inputData => {
        setUpdateL7Resource({
            variables: getVariables(inputData),
            refetchQueries: [
                {
                    query: GET_L7_RESOURCE_VENDOR,
                    variables: {
                        l7ResourceId: Number(id),
                    },
                },
            ],
        })
            .then(() => {
                toast.success('Success');
                if (inputData.radio === '1') {
                    setNobodyExceptCountry([]);
                    reset({
                        allExcept: inputData.allExcept,
                        nobodyExcept: [],
                        radio: '1',
                    });
                } else if (inputData.radio === '2') {
                    setAllExceptCountry([]);
                    reset({
                        allExcept: [],
                        nobodyExcept: inputData.nobodyExcept,
                        radio: '2',
                    });
                } else {
                    setAllExceptCountry([]);
                    setNobodyExceptCountry([]);
                    reset({
                        allExcept: [],
                        nobodyExcept: [],
                        radio: '0',
                    });
                }
            })
            .catch(err => toast.error(err.message));
    };

    if (loading) return <Preloader />;

    const calculationOp = (option, deleteOption) => {
        const newArray = [];
        deleteOption.forEach(i => newArray.push(i.value));
        const result = option.filter(x => newArray.includes(x.value) === false);
        return result;
    };

    const allExcept1 = watch('allExcept') === undefined ? allExceptCountry : watch('allExcept');
    const nobodyExcept1 = watch('nobodyExcept') === undefined ? nobodyExceptCountry : watch('nobodyExcept');

    return (
        <>
            <Typography variant="h1" className={classes.title}>
                {formatMessage({ id: 'webProtection.policy.title' })}
            </Typography>

            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="radio"
                    control={control}
                    defaultValue={String(data && data.l7ResourceVendor.serviceGeoipMode)}
                    render={({ field: { onChange, value } }) => (
                        <FormControl component="fieldset">
                            <RadioGroup value={value} onChange={onChange} className={classes.group}>
                                <FormControlLabel value="0" control={<Radio color="primary" />} label={formatMessage({ id: 'webProtection.policy.radio0' })} />
                                <FormControlLabel value="1" control={<Radio color="primary" />} label={formatMessage({ id: 'webProtection.policy.radio1' })} />
                                <FormControlLabel value="2" control={<Radio color="primary" />} label={formatMessage({ id: 'webProtection.policy.radio2' })} />
                            </RadioGroup>
                        </FormControl>
                    )}
                />
                {getValues('radio') === '1' ? (
                    <>
                        <div className={classes.label}>{formatMessage({ id: 'webProtection.policy.subtitle' })}</div>
                        <Controller
                            name="allExcept"
                            control={control}
                            defaultValue={allExceptCountry}
                            render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                    className={classes.auto}
                                    multiple
                                    filterSelectedOptions
                                    options={calculationOp(options, allExcept1)}
                                    getOptionLabel={option => option.value}
                                    renderOption={option => option.label}
                                    value={value}
                                    onChange={(event, newValue) => {
                                        onChange(newValue);
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            error={!!errors.allExcept}
                                            helperText={errors?.allExcept?.message ?? formatMessage({ id: 'webProtection.policy.input' })}
                                        />
                                    )}
                                />
                            )}
                        />
                        <div className={classes.buttonWrapper}>
                            <Button
                                variant="contained"
                                type="submit"
                                className={classes.button}
                                color="primary"
                                disabled={
                                    getValues('radio') === String(data && data.l7ResourceVendor.serviceGeoipMode) &&
                                    allExcept1.map(param => param.value).toString() === allExceptCountry.map(param => param.value).toString()
                                }
                            >
                                {formatMessage({ id: 'webProtection.policy.save' })}
                            </Button>
                        </div>
                    </>
                ) : getValues('radio') === '2' ? (
                    <>
                        <div className={classes.label}>{formatMessage({ id: 'webProtection.policy.block' })}</div>
                        <div> </div>
                        <Controller
                            name="nobodyExcept"
                            control={control}
                            defaultValue={nobodyExceptCountry}
                            render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                    defaultValue={nobodyExceptCountry}
                                    className={classes.auto}
                                    multiple
                                    filterSelectedOptions
                                    options={calculationOp(options, nobodyExcept1)}
                                    getOptionLabel={option => option.value}
                                    renderOption={option => <span>{option.label}</span>}
                                    value={value}
                                    onChange={(event, newValue) => {
                                        onChange(newValue);
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            error={!!errors.nobodyExcept}
                                            helperText={errors?.nobodyExcept?.message ?? formatMessage({ id: 'webProtection.policy.input' })}
                                        />
                                    )}
                                />
                            )}
                        />
                        <div className={classes.buttonWrapper}>
                            <Button
                                variant="contained"
                                type="submit"
                                className={classes.button}
                                color="primary"
                                disabled={
                                    getValues('radio') === String(data && data.l7ResourceVendor.serviceGeoipMode) &&
                                    nobodyExcept1.map(param => param.value).toString() === nobodyExceptCountry.map(param => param.value).toString()
                                }
                            >
                                {formatMessage({ id: 'webProtection.policy.save' })}
                            </Button>
                        </div>
                    </>
                ) : (
                    <div className={classes.buttonWrapper}>
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            className={classes.button}
                            disabled={getValues('radio') === String(data && data.l7ResourceVendor.serviceGeoipMode)}
                        >
                            {formatMessage({ id: 'webProtection.policy.save' })}
                        </Button>
                    </div>
                )}
            </form>
        </>
    );
};

export default AccessPolicy;
