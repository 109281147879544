import React, { useReducer, createContext } from 'react';

if (localStorage.getItem('jwtToken') === null) {
    localStorage.removeItem('MENU_ACTIVE');
    localStorage.removeItem('MENU_TEXT');
}

export const initialState = {
    menuActive: localStorage.getItem('MENU_ACTIVE') || 'dashboard',
    menuText: localStorage.getItem('MENU_TEXT') || 'Dashboard',
};

const MenuContext = createContext({
    menuActive: 'dashboard',
    menuText: 'Dashboard',
    setMenuActive: data => {},
    setMenuText: data => {},
    deleteMenu: () => {},
});

function menuReducer(state, action) {
    switch (action.type) {
        case 'MENU_ACTIVE':
            return {
                ...state,
                menuActive: action.payload,
            };

        case 'MENU_TEXT':
            return {
                ...state,
                menuText: action.payload,
            };

        case 'DELETE_MENU':
            return {
                ...state,
                menuActive: 'dashboard',
                menuText: 'Dashboard',
            };

        default:
            return state;
    }
}

function MenuProvider(props) {
    const [state, dispatch] = useReducer(menuReducer, initialState);

    function setMenuActive(data) {
        localStorage.setItem('MENU_ACTIVE', data);
        dispatch({
            type: 'MENU_ACTIVE',
            payload: data,
        });
    }

    function setMenuText(data) {
        localStorage.setItem('MENU_TEXT', data);
        dispatch({
            type: 'MENU_TEXT',
            payload: data,
        });
    }

    function deleteMenu() {
        localStorage.removeItem('MENU_ACTIVE');
        localStorage.removeItem('MENU_TEXT');
        dispatch({ type: 'DELETE_MENU' });
    }

    return (
        <MenuContext.Provider
            value={{
                setMenuActive,
                setMenuText,
                deleteMenu,
                menuActive: state.menuActive,
                menuText: state.menuText,
            }}
            {...props}
        />
    );
}

export { MenuProvider, MenuContext };
