import React from 'react';
import { Typography, Box, Paper } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import { AntContainer, AntPaper } from '../../../theme/Wrapper';

const TabPanelLayout = ({ width, children, value, index, ...other }) => {
    const result = width === 'xs';
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            <AntContainer maxWidth={false} disableGutters={result}>
                <AntPaper variant={result ? '' : 'outlined'} square={result} elevation={0}>
                    <Box>
                        <Typography component="div">{children}</Typography>
                    </Box>
                </AntPaper>
            </AntContainer>
        </div>
    );
};

export default withWidth()(TabPanelLayout);
