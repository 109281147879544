import React from 'react';
import {simpleValueCalculation, valueCalculation} from '../../helpers/calculation';
import { Chart, Interaction } from 'chart.js';
import { CrosshairPlugin, Interpolate } from 'chartjs-plugin-crosshair';
import { Line } from 'react-chartjs-2';
import { useStyles } from '../../theme/Chart';

Chart.register(CrosshairPlugin);
Interaction.modes.interpolate = Interpolate;

const AreaChartsBasic = ({ formatter, points, details }) => {
    const classes = useStyles();
    const dateFormat = require('dateformat');
    const type = formatter === 'bps' ? '_bytes' : '_packets';
    let customSeries = [];
    let customTimestamp = [];
    const colors = [
        {
            borderColor: '#69F0AE',
            backgroundColor: 'rgb(64, 196, 255, 0.2)',
        },
        {
            borderColor: '#40C4FF',
            backgroundColor: 'rgba(105, 240, 174, 0.2)',
        },
        {
            borderColor: '#FF6E40',
            backgroundColor: 'rgb(64, 196, 255, 0.2)',
        },
        {
            borderColor: '#E0E0E0',
            backgroundColor: 'rgba(105, 240, 174, 0.2)',
        },
        {
            borderColor: '#D358F7',
            backgroundColor: 'rgba(211, 88, 247, 0.3)',
        },
        {
            borderColor: '#FE2E2E',
            backgroundColor: 'rgba(254, 46, 46, 0.3)',
        },
        {
            borderColor: '#BDBDBD',
            backgroundColor: 'rgba(189, 189, 189, 0.5)',
        },
        {
            borderColor: '#F4FA58',
            backgroundColor: 'rgba(244, 250, 88, 0.3)',
        },
    ];

    if (points.length >= 1) {
        customTimestamp = [new Date(details.start).getTime()];
        const minute = 60000;
        const limit = points[0][`sample${type}`].length;

        for (let i = 0; i < limit; i++) {
            const proxy = customTimestamp[i] + minute;
            customTimestamp.push(proxy);
        }

        customSeries = points.map((item, index) => {
            const proxy = item[`sample${type}`].map(e => Number(e));
            return {
                label: item[`name${type}`],
                data: proxy,
                radius: 0,
                hoverRadius: 3,
                borderWidth: 1,
                ...colors[index],
            };
        });
    }
    const series = {
        labels: customTimestamp,
        datasets: customSeries,
    };

    const options = {
        interaction: {
            mode: 'index'
        },
        maintainAspectRatio: false,
        elements: {
            line: {
                borderJoinStyle: 'bevel',
                borderCapStyle: 'square',
                fill: true,
            },
        },
        animation: false,
        scales: {
            y: {
                stacked: false,
                grid: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    display: true,
                    callback: function (value) {
                        return valueCalculation(value, formatter);
                    },
                },
            },
            x: {
                grid: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    display: true,
                    callback: function (value, index) {
                        return index % 2 === 0 ? dateFormat(this.getLabelForValue(value), 'h:MM:ssTT') : '';
                    },
                },
            },
        },
        plugins: {
            legend: {
                position: 'bottom',
            },
            filler: {
                propagate: false,
            },
            crosshair: {
                line: {
                    color: '#848484',
                    width: 0.5,
                },
                sync: {
                    enabled: false,
                },
                zoom: {
                    enabled: true,
                    zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',
                    zoomboxBorderColor: '#48F',
                    zoomButtonText: 'reset zoom',
                    zoomButtonClass: 'zoom',
                },
            },
            tooltip: {
                animation: false,
                intersect: false,
                callbacks: {
                    title: function(context) {
                        return dateFormat(Number(context[0].label), 'dd/mm/yy h:MM:ssTT');
                    },
                    label: function(context) {
                        return `${context.dataset.label}: ${valueCalculation(context.raw, formatter)} `;
                    },
                },
            },
        },
    };

    return (
        <div className={classes.chart}>
            <Line data={series} options={options} />
        </div>
    )
};

export default AreaChartsBasic;
