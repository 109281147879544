import React, {useEffect, useState} from 'react';
import {useLazyQuery} from '@apollo/client';
import { GET_L7_GRAPHITE_REQUESTS } from '../../actions/get';
import { useIntl } from 'react-intl';
import Preloader from '../global/Preloader';
import {Bar} from 'react-chartjs-2';
import { Chart, Interaction } from 'chart.js';
import ChartjsPluginStacked100 from 'chartjs-plugin-stacked100';
import { CrosshairPlugin, Interpolate } from 'chartjs-plugin-crosshair';
import { useStyles } from '../../theme/Chart';
import {AntTab, AntTabs} from "../../pages/l3Protection/anomalies/anomalyDetails/summary/styles";
import {simpleValueCalculation} from "../../helpers/calculation";

Chart.register(CrosshairPlugin, ChartjsPluginStacked100);
Interaction.modes.interpolate = Interpolate;

const AreaChartsRequest = ({ id, date, setFilter }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const dateFormat = require('dateformat');
    const [indexTab, setIndexTab] = useState('RPM');
    const { startDate, endDate } = date;
    const [getRequests, { data, loading, error }] = useLazyQuery(GET_L7_GRAPHITE_REQUESTS, {
        variables: {
            from: (Date.parse(startDate) - (Date.parse(startDate) % 1000)) / 1000 || (startDate - (startDate % 1000)) / 1000,
            until: (Date.parse(endDate) - (Date.parse(endDate) % 1000)) / 1000 || (endDate - (endDate % 1000)) / 1000,
            l7ResourceId: id,
        },
    });
    useEffect(() => {
        getRequests();
        setFilter({...date, activeZoomChart: true});
    }, [id, startDate, endDate]);

    useEffect(() => {
        setIndexTab(data?.l7GraphiteRequests?.unit);
    }, [data]);

    const handleChange = (event, newValue) => {
        const proxy = newValue ? 'RPS' : 'RPM';
        getRequests({
            variables: {
                from: (Date.parse(date.startDate) - (Date.parse(date.startDate) % 1000)) / 1000 || (date.startDate - (date.startDate % 1000)) / 1000,
                until: (Date.parse(date.endDate) - (Date.parse(date.endDate) % 1000)) / 1000 || (date.endDate - (date.endDate % 1000)) / 1000,
                l7ResourceId: id,
                unit: proxy,
            },
        });
        setIndexTab(proxy);
        setFilter({...date, activeZoomChart: true});
    };
    const a11yProps = index => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    if (loading) return <Preloader />;
    if (error || data?.l7GraphiteRequests?.timestamp.length < 1) return <div className={classes.errorCharts}>{formatMessage({ id: 'charts.error' })}</div>;

    return (
        <div className={classes.chart} style={{marginBottom: 25}}>
            <div className={classes.tabsRpm}>
                <AntTabs value={indexTab === 'RPM' ? 0 : 1} onChange={handleChange}>
                    <AntTab label="RPM" {...a11yProps(0)} />
                    <AntTab label="RPS" {...a11yProps(1)} />
                </AntTabs>
            </div>
            <button className={date.activeZoomChart ? classes.visibleZoomBtn : classes.zoomBtnDashboard}
                    onClick={() => {
                        getRequests();
                        setFilter({...date, activeZoomChart: true});
                    }}
            >
                RESET ZOOM
            </button>
            <Bar data={{
                labels: data?.l7GraphiteRequests?.timestamp,
                datasets: [
                    {
                        label: formatMessage({ id: 'chartsAreaChartsRequest.legitimateRequest' }),
                        data: data?.l7GraphiteRequests?.legitimateRequest,
                        backgroundColor: '#69F0AE',
                    },
                    {
                        label: formatMessage({ id: 'chartsAreaChartsRequest.blockedRequest' }),
                        data: data?.l7GraphiteRequests?.blockedRequest,
                        backgroundColor: '#FF3D00',
                    },
                ],
            }} options={{
                maintainAspectRatio: false,
                indexAxis: "x",
                animation: false,
                scales: {
                    x: {
                        grid: {
                            display: false,
                            drawBorder: false,
                        },
                        stacked: true,
                        ticks: {
                            stepSize: 5,
                            maxTicksLimit: 15,
                            maxRotation: 0,
                            autoSkip: true,
                            display: true,
                            callback: function (value, index, values) {
                                return index % 2 === 0 ? dateFormat(this.getLabelForValue(value) * 1000, 'h:MM:ssTT') : '';
                            }
                        }
                    },
                    y: {
                        grid: {
                            display: false,
                            drawBorder: false,
                        },
                        stacked: true,
                        ticks: {
                            display: true,
                            callback: function (value) {
                                return `${simpleValueCalculation(value)} ${indexTab?.toLowerCase()}`;
                            },
                        },
                    }
                },
                plugins: {
                    legend: {
                        position: 'bottom',
                    },
                    crosshair: {
                        line: {
                            color: '#848484',
                            width: 0.5
                        },
                        sync: {
                            enabled: false
                        },
                        zoom: {
                            enabled: true,
                            zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',
                            zoomboxBorderColor: '#48F',
                            zoomButtonText: 'reset zoom',
                            zoomButtonClass: 'zoom',
                        },
                        callbacks: {
                            afterZoom: () => function(start, end) {
                                const afterZoomDate = data?.l7GraphiteRequests?.timestamp.filter((item, index) => {
                                    if(index === start) return item ;
                                    else if(index === end) return item;
                                });
                                const startDate = afterZoomDate[0];
                                const endDate = afterZoomDate[1];
                                getRequests({
                                    variables: {
                                        from: startDate,
                                        until: endDate,
                                        l7ResourceId: id,
                                        unit: indexTab,
                                    }
                                });
                                setFilter({...date, activeZoomChart: false});
                            }
                        }
                    },
                    tooltip: {
                        animation: false,
                        mode: "interpolate",
                        intersect: false,
                        callbacks: {
                            title: function(context) {
                                return dateFormat(context[0].label * 1000, 'dd/mm/yy h:MM:ssTT');
                            },
                        }
                    }
                },
            }} />
        </div>
    );
};

export default AreaChartsRequest;
