import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    date: {
        display: 'flex',
        alignItems: 'center',
    },
    container: {
        display: 'flex',
        justifyContent: "space-between" ,
        alignItems: "center",
    },
    circular: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center',
        padding: '40px',
    }
}));