import React, { useState, useContext } from 'react';
import { useStyles, CustomMenuItem } from './styles';
import { Grid, Avatar, Typography, IconButton, ListItemIcon, SwipeableDrawer, Divider, Button } from '@material-ui/core';
import { Menu, LockOutlined, Group, NotificationImportant, AssignmentInd, Dashboard, Web } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../context/auth';
import { MenuContext } from '../../../context/menu';
import IconNetwork from './IconNet';
import {PageContext} from "../../../context/pages";

const HeaderMobile = ({ firstName, lastName, email, l3Limit, l7Limit, dosGateLimit, role }) => {
    const classes = useStyles();
    const { user, logout } = useContext(AuthContext);
    const { deleteFilter, deleteSecondaryFilter } = useContext(PageContext);
    const { menuActive, menuText, setMenuActive, setMenuText, deleteMenu } = useContext(MenuContext);
    const [drawer, setDrawer] = useState(false);

    const toggleDrawer = open => event => {
        setDrawer(open);
    };

    const notificationSwitch = (l3Limit !== 0 && l3Limit !== null) || (dosGateLimit !== null && dosGateLimit !== 0);

    return (
        <>
            <Grid container className={classes.wrapper} alignItems="center">
                <Grid item>
                    <IconButton edge="start" className={classes.menuButton} onClick={toggleDrawer(true)}>
                        <Menu className={classes.iconMenu} />
                    </IconButton>
                </Grid>
                <Grid item xs className={classes.account}>
                    <Typography variant="h1">{menuText}</Typography>
                </Grid>
                <Grid item>
                    <Link
                        to="/my-profile/0"
                        onClick={() => {
                            setMenuActive('profile');
                            setMenuText('Account');
                            deleteSecondaryFilter();
                            deleteFilter();
                        }}
                    >
                        <Avatar className={classes.avatar}></Avatar>
                    </Link>
                </Grid>
            </Grid>
            <SwipeableDrawer anchor="left" open={drawer} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)}>
                <div className={classes.menuHeader}>
                    <div className={classes.userName}>
                        {firstName} {lastName}
                    </div>
                    <div className={classes.subtitle}>{email}</div>
                </div>
                <Divider />
                <div className={classes.menuBox}>
                    {dosGateLimit !== null && dosGateLimit !== 0 && (
                        <Link
                            to="#"
                            onClick={() => {
                                setMenuActive('dashboard');
                                setMenuText('Dashboard');
                                setDrawer(false);
                                deleteSecondaryFilter();
                                deleteFilter();

                            }}
                            className={menuActive === 'dashboard' ? `${classes.linkItemActive}` : `${classes.linkItem}`}
                        >
                            <CustomMenuItem selected={menuActive === 'dashboard'}>
                                <ListItemIcon>
                                    <Dashboard />
                                </ListItemIcon>
                                Dashboard
                            </CustomMenuItem>
                        </Link>
                    )}
                    {l3Limit !== 0 && l3Limit !== null && (
                        <Link
                            to="/l3-protection/0"
                            className={menuActive === 'l3' ? `${classes.linkItemActive}` : `${classes.linkItem}`}
                            onClick={() => {
                                setMenuActive('l3');
                                setMenuText('Network Protection');
                                setDrawer(false);
                                deleteSecondaryFilter();
                                deleteFilter();
                            }}
                        >
                            <CustomMenuItem selected={menuActive === 'l3'}>
                                <ListItemIcon>
                                    <IconNetwork />
                                </ListItemIcon>
                                Network Protection
                            </CustomMenuItem>
                        </Link>
                    )}
                    {l7Limit !== 0 && l7Limit !== null && (
                        <Link
                            to="/web-protection/0"
                            onClick={() => {
                                setMenuActive('web');
                                setMenuText('Web Protection');
                                setDrawer(false);
                                deleteSecondaryFilter();
                                deleteFilter();
                            }}
                            className={menuActive === 'web' ? `${classes.linkItemActive}` : `${classes.linkItem}`}
                        >
                            <CustomMenuItem selected={menuActive === 'web'}>
                                <ListItemIcon>
                                    <Web />
                                </ListItemIcon>
                                Web Protection
                            </CustomMenuItem>
                        </Link>
                    )}
                </div>
                <Divider />
                <div className={classes.menuBox}>
                    <div className={classes.subtitle} style={{ padding: '0 0 10px 10px' }}>
                        Account settings
                    </div>
                    <Link
                        to="/my-profile/0"
                        className={menuActive === 'profile' ? `${classes.linkItemActive}` : `${classes.linkItem}`}
                        onClick={() => {
                            setMenuActive('profile');
                            setMenuText('Account');
                            setDrawer(false);
                            deleteSecondaryFilter();
                            deleteFilter();
                        }}
                    >
                        <CustomMenuItem selected={menuActive === 'profile'}>
                            <ListItemIcon>
                                <AssignmentInd />
                            </ListItemIcon>
                            My Profile
                        </CustomMenuItem>
                    </Link>
                    <Link
                        to="/my-profile/1"
                        className={menuActive === 'password' ? `${classes.linkItemActive}` : `${classes.linkItem}`}
                        onClick={() => {
                            setMenuActive('password');
                            setMenuText('Account');
                            setDrawer(false);
                            deleteSecondaryFilter();
                            deleteFilter();
                        }}
                    >
                        <CustomMenuItem selected={menuActive === 'password'}>
                            <ListItemIcon>
                                <LockOutlined />
                            </ListItemIcon>
                            Password
                        </CustomMenuItem>
                    </Link>
                    {role === 'ADMIN' && (
                        <Link
                            to="/my-profile/2"
                            className={menuActive === 'members' ? `${classes.linkItemActive}` : `${classes.linkItem}`}
                            onClick={() => {
                                setMenuActive('members');
                                setMenuText('Account');
                                setDrawer(false);
                                deleteSecondaryFilter();
                                deleteFilter();
                            }}
                        >
                            <CustomMenuItem selected={menuActive === 'members'}>
                                <ListItemIcon>
                                    <Group />
                                </ListItemIcon>
                                Account members
                            </CustomMenuItem>
                        </Link>
                    )}
                    {notificationSwitch && (
                        <Link
                            to="/my-profile/3"
                            className={menuActive === 'notifications' ? `${classes.linkItemActive}` : `${classes.linkItem}`}
                            onClick={() => {
                                setMenuActive('notifications');
                                setMenuText('Account');
                                setDrawer(false);
                                deleteSecondaryFilter();
                                deleteFilter();
                            }}
                        >
                            <CustomMenuItem selected={menuActive === 'notifications'}>
                                <ListItemIcon>
                                    <NotificationImportant />
                                </ListItemIcon>
                                Notifications
                            </CustomMenuItem>
                        </Link>
                    )}
                </div>
                <Divider />
                <div className={classes.buttonBox}>
                    <Button
                        color="default"
                        variant="outlined"
                        fullWidth={true}
                        className={classes.button}
                        onClick={() => {
                            logout();
                            deleteMenu();
                            setDrawer(false);
                        }}
                    >
                        Log out
                    </Button>
                </div>
            </SwipeableDrawer>
        </>
    );
};

export default HeaderMobile;
