import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import CloseIcon from '@material-ui/icons/Close';
import { Button, TextField, Dialog, Typography, FormControl, Select, InputLabel } from '@material-ui/core';
import { useStyles } from '../../../../theme/Modals';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { CREATE_CLIENT_ACCOUNT_EMPLOYEE } from '../../../../actions/create';
import { useIntl } from 'react-intl';
import Preloader from '../../../global/Preloader';

const ModalInviteUser = ({ show, hideModal, clientAccountId, query, getVariables }) => {
    const classes = useStyles();
    const { handleSubmit, control } = useForm();
    const { formatMessage } = useIntl();

    const [setCreateAccount, { loading }] = useMutation(CREATE_CLIENT_ACCOUNT_EMPLOYEE);

    const onSubmit = dataInput => {
        setCreateAccount({
            variables: {
                clientAccountId,
                email: dataInput.email,
                role: dataInput.role,
            },
            refetchQueries: [{ query: query, variables: getVariables }],
        })
            .then(() => toast.success('Success'))
            .catch(err => toast.error(err.message));
        hideModal();
    };

    if (loading) return <Preloader />;

    return (
        <div>
            <Dialog
                className={classes.dialog}
                maxWidth="md"
                open={show}
                onClose={() => {
                    hideModal();
                }}
            >
                <div className={classes.modal}>
                    <div className={classes.header}>
                        <Typography variant="h1">{formatMessage({ id: 'modal.user.invite.title' })}</Typography>
                        <Button className={classes.close} onClick={() => hideModal()}>
                            <CloseIcon />
                        </Button>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={classes.modalBody}>
                            <Controller
                                name="email"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        label="Email"
                                        variant="outlined"
                                        className={classes.input}
                                        style={{ marginBottom: 30 }}
                                    />
                                )}
                            />
                            <Controller
                                name="role"
                                control={control}
                                defaultValue="ADMIN"
                                render={({ field: { onChange, value } }) => (
                                    <FormControl variant="outlined">
                                        <InputLabel>Role</InputLabel>
                                        <Select
                                            onChange={onChange}
                                            value={value}
                                            label="Role"
                                            native
                                            inputProps={{
                                                name: 'name',
                                                id: 'uncontrolled-native',
                                            }}
                                        >
                                            <option value={'ADMIN'}>Administrator</option>
                                            <option value={'ENGINEER'}>Engineer</option>
                                            <option value={'READ_ONLY'}>Read only</option>
                                        </Select>
                                    </FormControl>
                                )}
                            />
                        </div>
                        <div className={classes.modalFooter}>
                            <Button className={`${classes.button} ${classes.cancel}`} onClick={() => hideModal()}>
                                {formatMessage({ id: 'modal.user.invite.cancel' })}
                            </Button>
                            <Button type="submit" className={`${classes.button} ${classes.invite}`}>
                                {formatMessage({ id: 'modal.user.invite.accept' })}
                            </Button>
                        </div>
                    </form>
                </div>
            </Dialog>
        </div>
    );
};

export default ModalInviteUser;
