import React from 'react';
import { useStyles, AntTableCell } from '../../../theme/Table';
import { Button, TableRow, Grid, Table, TableHead, TableBody } from '@material-ui/core';
import { useIntl } from 'react-intl';
import Aliase from './Aliase';

const AliasesMobile = ({ data, setIsEdit, calculationTypeSsl, setIsDeleted, setIsCreated }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();

    return (
        <>
            <Grid container>
                <Grid item xs={12} sm="auto">
                    <Button color="primary" variant="contained" className={classes.button} onClick={() => setIsCreated(true)}>
                        {formatMessage({ id: 'webProtection.aliases.btn' })}
                    </Button>
                </Grid>
            </Grid>

            <Table>
                <TableHead>
                    <TableRow>
                        <AntTableCell align="left" className={classes.title}>
                            {formatMessage({ id: 'webProtection.aliases.main' })}
                        </AntTableCell>
                        <AntTableCell align="left" className={classes.title}>
                            {formatMessage({ id: 'webProtection.aliases.ssl' })}
                        </AntTableCell>
                        <AntTableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data &&
                        data.l7Aliases.items.map(({ aliasId, aliasData, aliasSslAuto, aliasSsl, aliasSslCrt, aliasSslKey }) => (
                            <TableRow key={aliasId}>
                                <Aliase
                                    aliasId={aliasId}
                                    aliasData={aliasData}
                                    aliasSslAuto={aliasSslAuto}
                                    aliasSsl={aliasSsl}
                                    aliasSslCrt={aliasSslCrt}
                                    aliasSslKey={aliasSslKey}
                                    setIsEdit={setIsEdit}
                                    calculationTypeSsl={calculationTypeSsl}
                                    setIsDeleted={setIsDeleted}
                                />
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </>
    );
};

export default AliasesMobile;
