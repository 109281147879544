import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    wrapperCards: {
        display: 'flex',
        alignItems: 'stretch',
    },
    lineColorWrapper: {
        width: 15,
    },
    lineColor: {
        height: 4,
        width: 17,
    },
    puiWrapper: {
        marginRight: 50,
        width: 350,
    },
    wrapperTable: {
        marginBottom: 50,
    },
    wrapperArea: {
        marginBottom: 50,
        backgroundColor: '#F6F7FB',
    },
    cards: {
        padding: 14,
        width: '100%',
    },
    wrapperDatePiker: {
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        height: '100%',
    },
    headerTitle: {
        marginBottom: 90,
    },
    wrapperPie: {
        padding: '40px 60px',
    },
    wrapperPaper: {
        padding: 25,
        marginBottom: 30,
    },

}));
