import React from 'react';
import { AntTableCell, useStyles } from '../../../../theme/Table';
import { Button } from '@material-ui/core';
import { ArrowForwardIos, MoreHoriz } from '@material-ui/icons';
import {
    valueCalculation,
    valueTimeCalculation,
} from '../../../../helpers/calculation';
import { Link } from 'react-router-dom';
import { saveState } from '../../../../helpers/localStorage';

const AnomalyMobile = ({
    alertId,
    importance,
    start,
    alertResourceCidr,
    misuseTypes,
    select,
    alertManagedObjectName,
    stop,
    duration,
    maxImpactBps,
    maxImpactPps,
    id,
    severityPercent,
    threshold,
    unit,
    impactBpsPoints,
    impactPpsPoints,
    impactRecorded,
    setAnchorElMore,
    setTypePopover,
}) => {
    const classes = useStyles();

    const dateFormat = require('dateformat');

    return (
        <>
            <AntTableCell
                style={{ padding: 5 }}
                className={classes[importance]}
            >
                ID {alertId}
                <br />
                {dateFormat(start, 'dd/mm/yy h:MM:ssTT')}
            </AntTableCell>

            {select === 1 && (
                <AntTableCell className={classes[importance]}>
                    {importance}
                </AntTableCell>
            )}
            {select === 2 && (
                <AntTableCell className={classes[importance]}>
                    {alertResourceCidr}
                </AntTableCell>
            )}
            {select === 3 && (
                <AntTableCell className={classes[importance]}>
                    {misuseTypes.length === 1 ? (
                        misuseTypes[0]
                    ) : (
                        <div>
                            {misuseTypes[0]}
                            <Button
                                className={classes.buttonMore}
                                aria-describedby={alertId}
                                onClick={(event) => {
                                    setAnchorElMore(event.currentTarget);
                                    setTypePopover(misuseTypes);
                                }}
                            >
                                <span className={classes.buttonMoreSpan}>
                                    <MoreHoriz
                                        className={classes.buttonMoreIcon}
                                        viewBox="3 0 25 5"
                                    />{' '}
                                    more
                                </span>
                            </Button>
                        </div>
                    )}
                </AntTableCell>
            )}
            {select === 4 && (
                <AntTableCell className={classes[importance]}>
                    {alertManagedObjectName}
                </AntTableCell>
            )}
            {select === 5 && (
                <AntTableCell className={classes[importance]}>
                    {stop ? dateFormat(stop, 'dd/mm/yy h:MM:ssTT') : 'Ongoing'}
                </AntTableCell>
            )}
            {select === 6 && (
                <AntTableCell className={classes[importance]}>
                    {stop
                        ? valueTimeCalculation(duration)
                        : valueTimeCalculation(
                              (new Date() - new Date(start)) / 1000
                          )}
                </AntTableCell>
            )}
            {select === 7 && (
                <AntTableCell className={classes[importance]}>
                    {valueCalculation(maxImpactBps, 'bps')}
                </AntTableCell>
            )}
            {select === 8 && (
                <AntTableCell className={classes[importance]}>
                    {valueCalculation(maxImpactPps, 'pps')}
                </AntTableCell>
            )}
            <AntTableCell className={classes[importance]}>
                <Link
                    to={`/anomaly-details/${alertId}`}
                    onClick={() => {
                        saveState(
                            {
                                id,
                                importance,
                                misuseTypes,
                                alertManagedObjectName,
                                maxImpactBps,
                                maxImpactPps,
                                severityPercent,
                                threshold,
                                alertResourceCidr,
                                unit,
                                impactBpsPoints,
                                impactPpsPoints,
                                impactRecorded,
                                start,
                                stop,
                            },
                            'detailsAlert'
                        );
                    }}
                >
                    <ArrowForwardIos color="action" className={classes.icon} />
                </Link>
            </AntTableCell>
        </>
    );
};

export default AnomalyMobile;
