import React, { useContext, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import { GlobalCss } from './common/theme';
import { Switch, useHistory } from 'react-router-dom';
import { I18nProvider } from './common/i18n';
import Layout from './common/components/global/Layout';
import MyPrivateRoute from './common/components/global/MyPrivateRoute';
import routes from './common/routes';
import Auth from './common/pages/auth/';
import JoinAccountFirst from './common/pages/joinAccount/firstStap';
import JoinAccountSecond from './common/pages/joinAccount/secondStap';
import ForgotPassword from './common/pages/forgotPassword';
import ForgotPasswordDone from './common/pages/forgotPassword/done';
import ResetPassword from './common/pages/forgotPassword/resetPassword';
import MyProfile from './common/pages/myProfile';
import L3Protection from './common/pages/l3Protection';
import AnomalyDetails from './common/pages/l3Protection/anomalies/anomalyDetails';
import AuthRoute from './common/components/global/AuthRoute';
import SettingsAccount from './common/pages/settingAccount';
import WebProtection from './common/pages/webProtection';
import { AuthContext } from './common/context/auth';
import { useQuery } from '@apollo/client';
import { GET_CLIENT_ACCOUNT_EMPLOYEE } from './common/actions/get';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { scheme } from './common/scheme';
import { useLocation } from 'react-router';
import { PageProvider } from './common/context/pages';
import Preloader from './common/components/global/Preloader';
import MitigationsDetails from './common/pages/l3Protection/mitigations/mitigationsDetails';
import Dashboard from './common/pages/dashboard';
import SettingsAccountMembers from './common/pages/settingsAccountMembers';
import Dosgate from './common/pages/dosgate';
import JoinAccountDisabledToken from './common/pages/joinAccount/disabledToken';
import useInterval from 'use-interval'
import {colors} from "./common/branding/scheme";
import {checkHosts} from "./common/branding";

const App = () => {
    const { user } = useContext(AuthContext);
    const theme = createMuiTheme(checkHosts(window.location.host, colors ));
    const params = useLocation();
    const searchParams = new URLSearchParams(params.search);
    const token = searchParams.get('tokenByEmployee');
    const { push } = useHistory();
    const context = useContext(AuthContext);

    const { data, loading } = useQuery(GET_CLIENT_ACCOUNT_EMPLOYEE, {
        variables: {
            id: user && user.id,
        },
    });

    const startTime = new Date();
    useInterval(() => {
        const curTime = new Date();
        const delta = curTime.getTime() - startTime.getTime();
        if (delta > 82800000) {
            context.logout();
        }
    }, user ? 1200000 : null,);


    useEffect(() => {
        if (token) {
            context.login(token);
            push('/my-profile/0');
        }
    }, []);

    if (loading) return <Preloader />;

    return (
        <MuiThemeProvider theme={theme}>
            <I18nProvider locale={data?.clientAccountEmployee?.lang || 'en-US'}>
                <GlobalCss />
                <Switch>
                    <AuthRoute exact path={routes.auth}>
                        <Auth />
                    </AuthRoute>
                    <AuthRoute exact path={routes.joinAccountFirst}>
                        <JoinAccountFirst />
                    </AuthRoute>
                    <AuthRoute exact path={routes.joinAccountSecond}>
                        <JoinAccountSecond />
                    </AuthRoute>
                    <AuthRoute exact path={routes.joinAccountDisabled}>
                        <JoinAccountDisabledToken />
                    </AuthRoute>
                    <AuthRoute exact path={routes.forgotPassword}>
                        <ForgotPassword />
                    </AuthRoute>
                    <AuthRoute exact path={routes.forgotPasswordDone}>
                        <ForgotPasswordDone />
                    </AuthRoute>
                    <AuthRoute exact path={routes.resetPassword}>
                        <ResetPassword />
                    </AuthRoute>
                    <PageProvider>
                        <Layout>
                            <MyPrivateRoute exact path={routes.l3Protection}>
                                <L3Protection dataAccount={data}/>
                            </MyPrivateRoute>
                            <MyPrivateRoute exact path={routes.dosgate}>
                                <Dosgate />
                            </MyPrivateRoute>
                            <MyPrivateRoute exact path={routes.anomalyDetails}>
                                <AnomalyDetails />
                            </MyPrivateRoute>
                            <MyPrivateRoute exact path={routes.myProfile}>
                                <MyProfile />
                            </MyPrivateRoute>
                            <MyPrivateRoute exact path={routes.settingsAccount}>
                                <SettingsAccount />
                            </MyPrivateRoute>
                            <MyPrivateRoute exact path={routes.settingsAccountMembers}>
                                <SettingsAccountMembers />
                            </MyPrivateRoute>
                            <MyPrivateRoute exact path={routes.webProtection}>
                                <WebProtection dataAccount={data}/>
                            </MyPrivateRoute>
                            <MyPrivateRoute exact path={routes.mitigationsDetails}>
                                <MitigationsDetails />
                            </MyPrivateRoute>
                            <MyPrivateRoute exact path={routes.dashboard}>
                                <Dashboard />
                            </MyPrivateRoute>
                        </Layout>
                    </PageProvider>
                </Switch>
            </I18nProvider>
        </MuiThemeProvider>
    );
};

export default App;
