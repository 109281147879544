import { makeStyles } from '@material-ui/core/styles';
import { Paper, Container, withStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({}));

export const AntContainer = withStyles(theme => ({
    root: {
        padding: 35,
        margin: 0,
        boxSizing: 'border-box',
        minWidth: 340,
        [theme.breakpoints.between('xs', 'sm')]: {
            padding: 16,
        },
    },
    disableGutters: {
        padding: 0,
    },
}))(Container);

export const AntContainerHead = withStyles(theme => ({
    root: {
        paddingRight: 35,
        paddingLeft: 35,
        margin: 0,
        boxSizing: 'border-box',
        minWidth: 340,
        [theme.breakpoints.between('xs', 'sm')]: {
            paddingRight: 16,
            paddingLeft: 16,
        },
    },
    disableGutters: {
        padding: 0,
    },
}))(Container);

export const AntPaper = withStyles(theme => ({
    rounded: {
        borderRadius: 20,
    },
}))(Paper);
