import React, { useState } from 'react';
import { useStyles } from './styles';
import { ArrowBack, MoreVert } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { AntContainer } from '../../../../../theme/Wrapper';
import { Grid, Typography, IconButton, Paper, Drawer, MenuItem } from '@material-ui/core';

const BarMobileAnomalyDetails = ({ indexTab, setIndexContentSummary, setIndexContentTraffic }) => {
    const classes = useStyles();
    const [drawer, setDrawer] = useState(false);

    const toggleDrawer = open => event => {
        setDrawer(open);
    };

    const handleCloseSummary = indexBlockContent => {
        setIndexContentSummary(indexBlockContent);
        setDrawer(false);
    };

    const handleCloseTraffic = indexBlockContent => {
        setIndexContentTraffic(indexBlockContent);
        setDrawer(false);
    };

    return (
        <>
            <Paper elevation={1} square className={classes.wrapper}>
                <Grid container alignItems="center">
                    <Grid item>
                        <Link to="/l3-protection/1">
                            <IconButton edge="start">
                                <ArrowBack className={classes.icon} />
                            </IconButton>
                        </Link>
                    </Grid>
                    <Grid item xs>
                        <Typography className={classes.title}>Anomaly details</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton edge="start" onClick={toggleDrawer(true)}>
                            <MoreVert className={classes.icon} />
                        </IconButton>
                    </Grid>
                </Grid>
            </Paper>

            <Drawer anchor="right" open={drawer} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)} className={classes.drawer}>
                {indexTab === 0 && (
                    <>
                        <MenuItem onClick={() => handleCloseSummary('anomalyChart')}>
                            <Typography variant="inherit">Anomaly chart</Typography>
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                handleCloseSummary('anomalyCharacterisation');
                            }}
                        >
                            <Typography variant="inherit">Anomaly characterisation</Typography>
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                handleCloseSummary('anomalyDistribution');
                            }}
                        >
                            <Typography variant="inherit">Packet size distribution</Typography>
                        </MenuItem>
                    </>
                )}
                {indexTab === 1 && (
                    <>
                        <MenuItem
                            onClick={() => {
                                handleCloseTraffic('sourceIp');
                            }}
                        >
                            <Typography variant="inherit">Source IP addresses</Typography>
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                handleCloseTraffic('destinationIp');
                            }}
                        >
                            <Typography variant="inherit">Destination IP addresses</Typography>
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                handleCloseTraffic('sourceTcp');
                            }}
                        >
                            <Typography variant="inherit">Source TCP ports</Typography>
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                handleCloseTraffic('destinationTcp');
                            }}
                        >
                            <Typography variant="inherit">Destination TCP ports</Typography>
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                handleCloseTraffic('sourceUdp');
                            }}
                        >
                            <Typography variant="inherit">Source UDP ports</Typography>
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                handleCloseTraffic('destinationUdp');
                            }}
                        >
                            <Typography variant="inherit">Destination UDP ports</Typography>
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                handleCloseTraffic('sourceCountries');
                            }}
                        >
                            <Typography variant="inherit">Source countries</Typography>
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                handleCloseTraffic('sourceASN');
                            }}
                        >
                            <Typography variant="inherit">Source ASNs</Typography>
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                handleCloseTraffic('protocols');
                            }}
                        >
                            <Typography variant="inherit">Protocols</Typography>
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                handleCloseTraffic('flags');
                            }}
                        >
                            <Typography variant="inherit">TCP flags</Typography>
                        </MenuItem>
                        {/* <MenuItem
                            onClick={() => {
                                handleCloseTraffic('icmpTypes');
                            }}
                        >
                            <Typography variant="inherit">ICMP types</Typography>
                        </MenuItem> */}
                        <MenuItem
                            onClick={() => {
                                handleCloseTraffic('misuseTypes');
                            }}
                        >
                            <Typography variant="inherit">Misuse types</Typography>
                        </MenuItem>
                    </>
                )}
            </Drawer>
        </>
    );
};

export default BarMobileAnomalyDetails;
