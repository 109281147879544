import React, { useRef, useState } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import { Button, FormHelperText, Grid, Table, TableBody, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { GET_L7_ANALYTICS } from '../../../../actions/get';
import Preloader from '../../../../components/global/Preloader';
import TableScroll from '../../../../components/global/TableScroll';
import { AntTableCell } from '../../../../theme/Table';
import { AntTableCellHead, AntTableCellLabel, useStyles } from '../styles';
import { useStyle } from './styles';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const BotsIpAddresses = ({ date, l7ResourceId }) => {
    const classes = useStyles();
    const style = useStyle();
    const table = useRef(null);
    const [num, setNum] = useState(null);
    const { formatMessage } = useIntl();
    const dateFormat = require('dateformat');

    const checkIp = /((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|\s+$|$)){4}/;

    const validationSchema = Yup.object().shape({
        ip: Yup.string()
            .required(formatMessage({ id: 'webProtection.analytic.botIp.validIp.required' }))
            .matches(checkIp, formatMessage({ id: 'webProtection.analytic.botIp.validIp.matches' })),
    });

    const {
        data: addressBot,
        loading: loadingAddressBot,
        error: errorAddressBot,
    } = useQuery(GET_L7_ANALYTICS, {
        variables: {
            from: Math.round(date.startDate / 1000),
            until: Math.round(date.endDate / 1000),
            l7ResourceId: l7ResourceId,
            metric: 'REMOTE_ADDRESS_FOR_BOT',
        },
    });

    const [getAddressNum, { data: addressNum, loading: loadingAddressNum, error: errorAddressNum }] = useLazyQuery(GET_L7_ANALYTICS, {
        onCompleted: data => {
            setNum(prev => ({...prev,
                c: data.l7Analytics[0].c
            }));
        },
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = inputData => {
        setNum(prev => ({...prev,
            from: date.startDate,
            until: date.endDate,
            ip: inputData.ip.replace(/\s/g, ''),
        }));
        getAddressNum({
            variables: {
                from: Math.round(date.startDate / 1000),
                until: Math.round(date.endDate / 1000),
                l7ResourceId: l7ResourceId,
                metric: 'REQUEST_ADDRESS_NUM',
                ip: inputData.ip.replace(/\s/g, ''),
            },
        });
    };

    if (loadingAddressBot) return <Preloader />;

    let x = 0;
    const sumCount = addressBot && addressBot.l7Analytics.map(i => (x += i.c), (x = 0)).reverse()[0];

    return (
        <div className={classes.wrapper}>
            {!loadingAddressBot && (
                <Grid container spacing={5}>
                    <Grid item container xs={12} spacing={1}>
                        <Grid item xs={8}>
                            <Typography className={style.titleIp}> {formatMessage({ id: 'webProtection.analytic.botIp.ip.title' })}</Typography>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid item xs={12} container spacing={2}>
                                    <Grid item xs={8}>
                                        <Controller
                                            name="ip"
                                            control={control}
                                            defaultValue=""
                                            render={({ field: { onChange, value } }) => (
                                                <TextField onChange={onChange}
                                                           value={value.replace(/\s/g, '')}
                                                           variant="outlined"
                                                           error={!!errors?.ip}
                                                />
                                            )}
                                        />
                                        <FormHelperText style={{ height: 10 }} error={!!errors?.ip}>
                                            {errors?.ip?.message}
                                        </FormHelperText>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button color="primary" variant="outlined" type="submit" className={style.buttonIp} disabled={loadingAddressNum}>
                                            {formatMessage({ id: 'webProtection.analytic.botIp.ip.btn1' })}
                                            <br /> {formatMessage({ id: 'webProtection.analytic.botIp.ip.btn2' })}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                        <Grid item xs={4}>
                            {num?.c && (
                                <div className={style.requestWrapper}>
                                    <Typography className={style.requestText} align="center">
                                        IP - address: {num.ip}
                                    </Typography>
                                    <Typography className={style.requestText} align="center">
                                        From: {dateFormat(num.from, 'dd/mm/yy h:MM:ssTT')}
                                    </Typography>
                                    <Typography className={style.requestText} align="center">
                                        To: {dateFormat(num.until, 'dd/mm/yy h:MM:ssTT')}
                                    </Typography>
                                    <Typography className={style.requestText} align="center">
                                        Requests count: {num.c}
                                    </Typography>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {errorAddressBot || (addressBot && addressBot.l7Analytics.length === 0) ? (
                            <div className={classes.errorCharts}>{formatMessage({ id: 'charts.error' })}</div>
                        ) : (
                            <TableScroll tableRef={table}>
                                <TableContainer ref={table}>
                                    <Grid container>
                                        <Grid item>
                                            <Typography className={classes.tableTitle}>
                                                {formatMessage({ id: 'webProtection.analytic.botIp.tableTitle' })}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <AntTableCellHead colSpan={5}/>
                                            </TableRow>
                                        </TableHead>
                                        <TableHead>
                                            <TableRow>
                                                <AntTableCellLabel />
                                                <AntTableCellLabel className={classes.tableLabel}>IP</AntTableCellLabel>
                                                <AntTableCellLabel className={classes.tableLabel}>netname</AntTableCellLabel>
                                                <AntTableCellLabel className={classes.tableLabel}>subnet</AntTableCellLabel>
                                                <AntTableCellLabel className={classes.tableLabel} align="right">
                                                    count
                                                </AntTableCellLabel>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {addressBot &&
                                                addressBot.l7Analytics.map(({ c, remote_addr, netname, subnet }, index) => (
                                                    <TableRow key={index + subnet}>
                                                        <AntTableCell className={classes.numberStyle}>{index + 1}</AntTableCell>
                                                        <AntTableCell>{remote_addr ? remote_addr : '-'}</AntTableCell>
                                                        <AntTableCell>{netname}</AntTableCell>
                                                        <AntTableCell>{subnet}</AntTableCell>
                                                        <AntTableCell align="right">
                                                            {c}&nbsp;(<span className={classes.blueText}>{((c / sumCount) * 100).toFixed(2)}%</span>)
                                                        </AntTableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </TableScroll>
                        )}
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default BotsIpAddresses;
