import React, { useContext, useEffect, useState } from 'react';
import { AntTab, AntTabs, useStyles } from '../../theme/Tabs/index';
import { AntContainerHead } from '../../theme/Wrapper';
import TabPanelLayout from '../../components/global/TabPanelLayout';
import UserInfo from './userInfo';
import AccountMembers from './accountMembers';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../../context/auth';
import NotificationProfile from './notification';
import { useIntl } from 'react-intl';
import { MenuContext } from '../../context/menu';
import { GET_CLIENT_ACCOUNT_EMPLOYEE, GET_CLIENT_ACCOUNT } from '../../actions/get';
import { useQuery } from '@apollo/client';
import Preloader from '../../components/global/Preloader';
import {PageContext} from "../../context/pages";
import TabPanel from "../../components/global/TabPanel";

const MyProfile = () => {
    const classes = useStyles();
    const { tab } = useParams();
    const [indexTab, setIndexTab] = useState(0);
    const { setMenuActive, setMenuText } = useContext(MenuContext);
    const { deleteFilter } = useContext(PageContext);
    const { user } = useContext(AuthContext);
    const { formatMessage } = useIntl();

    const { data } = useQuery(GET_CLIENT_ACCOUNT_EMPLOYEE, {
        variables: {
            id: user && user.id,
        },
    });

    const { data: clientAccount, loading } = useQuery(GET_CLIENT_ACCOUNT, {
        variables: {
            id: data && data.clientAccountEmployee && data.clientAccountEmployee.clientAccountId,
        },
    });

    const handleChange = (event, newValue) => {
        setIndexTab(newValue);
    };
    const a11yProps = index => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };
    useEffect(() => {
        setIndexTab(Number(tab));
    }, [tab]);

    if (loading) return <Preloader />;

    const l3Limit = clientAccount && clientAccount.clientAccount.accountSettings.l3.l3Limit;
    const dosGateLimit = clientAccount && clientAccount.clientAccount.accountSettings.dosGate.dosGateLimit;
    const notificationSwitch = (l3Limit !== 0 && l3Limit !== null) || (dosGateLimit !== null && dosGateLimit !== 0);
    const role = data && data?.clientAccountEmployee?.role;

    return (
        <>
            <div position="static" className={classes.bar}>
                <AntContainerHead maxWidth={false}>
                    <AntTabs value={indexTab} onChange={handleChange} variant="scrollable" scrollButtons="auto">
                        <AntTab
                            component={NavLink}
                            label={formatMessage({ id: 'profile.tab.profile' })}
                            {...a11yProps(0)}
                            to="/my-profile/0"
                            className={classes.link}
                            activeClassName={classes.linkActive}
                            onClickCapture={() => {
                                setMenuActive('profile');
                                setMenuText('Account');
                                deleteFilter();
                            }}
                        />
                        <AntTab
                            component={NavLink}
                            label={formatMessage({ id: 'profile.tab.members' })}
                            {...a11yProps(1)}
                            to="/my-profile/1"
                            className={role === 'ADMIN' ? classes.link : classes.linkDisabled}
                            activeClassName={classes.linkActive}
                            onClickCapture={() => {
                                setMenuActive('members');
                                setMenuText('Account');
                                deleteFilter();
                            }}
                        />
                        {notificationSwitch && (
                            <AntTab
                                component={NavLink}
                                label={formatMessage({ id: 'profile.tab.not' })}
                                {...a11yProps(2)}
                                to="/my-profile/2"
                                className={classes.link}
                                activeClassName={classes.linkActive}
                                onClickCapture={() => {
                                    setMenuActive('notifications');
                                    setMenuText('Account');
                                    deleteFilter();
                                }}
                            />
                        )}
                    </AntTabs>
                </AntContainerHead>
            </div>
            <TabPanel value={indexTab} index={0}>
                <div className={classes.wrapperContainer}>
                    <UserInfo token={user} />
                </div>
            </TabPanel>
            <TabPanel value={indexTab} index={1}>
                <div className={classes.wrapperTable}>
                    <AccountMembers token={user} />
                </div>
            </TabPanel>
            {notificationSwitch && (
                <TabPanel value={indexTab} index={2}>
                    <div className={classes.wrapperContainer}>
                        <NotificationProfile token={user.id} />
                    </div>
                </TabPanel>
            )}
        </>
    );
};

export default MyProfile;
