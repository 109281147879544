import React, { useEffect, useState, useRef } from 'react';
import ModalMore from '../../modals/modalsTable/more';
import ModalThreatLevel from '../../modals/modalsTable/threatLevel';
import { saveState } from '../../../helpers/localStorage';
import { useLazyQuery } from '@apollo/client';
import { GET_ARBOR_ALERTS } from '../../../actions/get';
import { useDebouncedEffect } from '../../../hooks/useDebouncedEffect';
import Preloader from '../Preloader';
import { textWidthCalculation, valueCalculation, valueTimeCalculation } from '../../../helpers/calculation';
import { AntTableCell, AntTableCellFilter, useStyles } from '../../../theme/Table';
import { useIntl } from 'react-intl';
import { Button, FormGroup, Grid, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import TableDateTabs from '../TableDateTabs';
import TableDatePicker from '../TableDatePicker';
import TableSort from '../TableSort';
import TableFilter from '../TableFilter';
import { BaseSwitch } from '../../../theme/Switch';
import { Description, MoreHoriz } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import TableScroll from '../TableScroll';
import { TablePaginationComponent } from '../TablePagination';

const TableAnomalies = ({propsRoute, filter, setFilter }) => {
    const classes = useStyles();
    const table = useRef(null);
    const { formatMessage } = useIntl();
    const [anchorElMore, setAnchorElMore] = useState(null);
    const [anchorElThreatLevel, setAnchorElThreatLevel] = useState(null);
    const [typePopover, setTypePopover] = useState([]);
    const [stateSwitch, setStateSwitch] = useState({
        bps: true,
    });
    const dateFormat = require('dateformat');

    const [getFilter, { data, loading }] = useLazyQuery(GET_ARBOR_ALERTS);
    const getVariables = () => {
        const proxy = {
            limit: filter?.limit,
            page: filter?.page + 1,
            importance: filter?.threatLevel || ['low', 'medium', 'high'],
            filterStartLte: dateFormat(filter.startDate, 'isoDateTime'),
            filterStartGte: dateFormat(filter.endDate, 'isoDateTime'),
            [filter?.sortActive]: filter?.sort?.[filter?.sortActive]?.toUpperCase() || 'DESC',
        };
        if (filter?.id) proxy.alertId = Number(filter?.id);
        if (filter?.resourceName) proxy.alertManagedObjectName = filter?.resourceName;
        if (filter?.durationIps) proxy.alertResourceCidr = filter?.durationIps;
        return proxy;
    };

    useEffect(() => {
        getFilter({ variables: getVariables() });
        filter?.threatLevel &&
        Object.entries(filter?.threatLevel || {})?.reduce((acc, value) => {
            if (value[1]) {
                acc.push(value[0]);
            }
            return acc;
        }, []);
    }, []);

    useDebouncedEffect(
        () => {
            getFilter({ variables: getVariables() });
        },
        1000,
        [filter]
    );

    if (loading) return <Preloader />;

    const openModalMore = Boolean(anchorElMore);
    const openModalThreatLevel = Boolean(anchorElThreatLevel);
    const idPopoverMore = openModalMore ? 'more' : undefined;
    const idPopoverThreatLevel = openModalThreatLevel ? 'threat-Level' : undefined;

    const maxWidth = {
        id: Math.max.apply(Math, data && data.arborAlerts.items.map(items => textWidthCalculation(items.alertId))),
        ips: Math.max.apply(Math, data && data.arborAlerts.items.map(items => textWidthCalculation(items.alertResourceCidr))),
        name: Math.max.apply(Math, data && data.arborAlerts.items.map(items => textWidthCalculation(items.alertManagedObjectName))),
    };
    return (
        <div>
            <ModalMore id={idPopoverMore} open={openModalMore} anchorEl={anchorElMore} setAnchorEl={setAnchorElMore} value={typePopover} title="Type list" />
            <ModalThreatLevel
                id={idPopoverThreatLevel}
                open={openModalThreatLevel}
                anchorEl={anchorElThreatLevel}
                setAnchorEl={setAnchorElThreatLevel}
                filter={filter}
                setFilter={setFilter}
            />
            <TableScroll tableRef={table}>
                <TableContainer ref={table}>
                    <div className={classes.header}>
                        <Typography variant="h1">{formatMessage({ id: 'anomalies.title' })}</Typography>
                        <div className={classes.date}>
                            <TableDateTabs filter={filter} setFilter={setFilter} />
                            <TableDatePicker filter={filter} setFilter={setFilter} />
                        </div>
                    </div>
                    <Table size="small" className={classes.table}>
                        <TableHead>
                            {/* SORT */}
                            <TableRow>
                                <AntTableCell className={classes.title}>
                                    <TableSort
                                        name="sortId"
                                        direction={filter?.sort?.sortId}
                                        label={formatMessage({ id: 'anomalies.id' })}
                                        setFilterActive={setFilter}
                                        filter={filter}
                                        type={'FILTER_ACTIVE'}
                                    />
                                </AntTableCell>
                                <AntTableCell className={classes.title}>{formatMessage({ id: 'anomalies.lvl' })}</AntTableCell>
                                <AntTableCell className={classes.title}>{formatMessage({ id: 'anomalies.ips' })}</AntTableCell>
                                <AntTableCell className={classes.title}>{formatMessage({ id: 'anomalies.type' })}</AntTableCell>
                                <AntTableCell className={classes.title}>{formatMessage({ id: 'anomalies.name' })}</AntTableCell>
                                <AntTableCell className={classes.title}>
                                    <TableSort
                                        name="sortStart"
                                        direction={filter?.sort?.sortStart}
                                        label={formatMessage({ id: 'anomalies.start' })}
                                        setFilterActive={setFilter}
                                        filter={filter}
                                        type={'FILTER_ACTIVE'}
                                    />
                                </AntTableCell>
                                <AntTableCell className={classes.title}>
                                    <TableSort
                                        name="sortStop"
                                        direction={filter?.sort?.sortStop}
                                        label={formatMessage({ id: 'anomalies.end' })}
                                        setFilterActive={setFilter}
                                        filter={filter}
                                        type={'FILTER_ACTIVE'}
                                    />
                                </AntTableCell>
                                <AntTableCell className={classes.title}>
                                    <TableSort
                                        name="sortDuration"
                                        direction={filter?.sort?.sortDuration}
                                        label={formatMessage({ id: 'anomalies.duration' })}
                                        setFilterActive={setFilter}
                                        filter={filter}
                                        type={'FILTER_ACTIVE'}
                                    />
                                </AntTableCell>
                                <AntTableCell className={classes.title}>
                                    <TableSort
                                        name={stateSwitch.bps ? 'sortMaxImpactBps' : 'sortMaxImpactPps'}
                                        direction={stateSwitch.bps ? filter?.sort?.sortMaxImpactBps : filter?.sort?.sortMaxImpactPps}
                                        label={formatMessage({ id: 'anomalies.max' })}
                                        setFilterActive={setFilter}
                                        filter={filter}
                                        type={'FILTER_ACTIVE'}
                                    />
                                </AntTableCell>
                                <AntTableCell className={classes.title} />
                            </TableRow>
                            <TableRow>
                                <AntTableCellFilter>
                                    <TableFilter name="id" filter={filter} setFilter={setFilter} maxWidth={maxWidth.id} />
                                </AntTableCellFilter>
                                <AntTableCellFilter>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        className={classes.btnFilter}
                                        aria-describedby="threat-Level"
                                        onClick={event => {
                                            setAnchorElThreatLevel(event.currentTarget);
                                        }}
                                    >
                                        {formatMessage({ id: 'anomalies.setFilter' })}
                                    </Button>
                                </AntTableCellFilter>
                                <AntTableCellFilter>
                                    <TableFilter name="durationIps" filter={filter} setFilter={setFilter} maxWidth={maxWidth.ips} />
                                </AntTableCellFilter>
                                <AntTableCellFilter />
                                <AntTableCellFilter>
                                    <TableFilter name="resourceName" filter={filter} setFilter={setFilter} maxWidth={maxWidth.name} />
                                </AntTableCellFilter>
                                <AntTableCellFilter />
                                <AntTableCellFilter />
                                <AntTableCellFilter />
                                <AntTableCellFilter style={{ padding: '4.5px 10px' }}>
                                    <FormGroup>
                                        <Grid component="label" className={classes.switcher}>
                                            <Grid item>pps</Grid>
                                            <Grid item>
                                                <BaseSwitch
                                                    checked={stateSwitch.bps}
                                                    onChange={event => {
                                                        setStateSwitch({ ...stateSwitch, [event.target.name]: event.target.checked });
                                                    }}
                                                    name="bps"
                                                />
                                            </Grid>
                                            <Grid item>bps</Grid>
                                        </Grid>
                                    </FormGroup>
                                </AntTableCellFilter>
                                <AntTableCellFilter />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data &&
                                data.arborAlerts.items.map(
                                    ({
                                        alertId,
                                        importance,
                                        alertResourceCidr,
                                        alertManagedObjectName,
                                        misuseTypes,
                                        start,
                                        stop,
                                        duration,
                                        maxImpactBps,
                                        maxImpactPps,
                                        id,
                                        severityPercent,
                                        threshold,
                                        unit,
                                        impactBpsPoints,
                                        impactPpsPoints,
                                        impactRecorded,
                                    }) => (
                                        <TableRow key={alertId}>
                                            <AntTableCell>{alertId}</AntTableCell>
                                            <AntTableCell>{importance}</AntTableCell>
                                            <AntTableCell>{alertResourceCidr}</AntTableCell>
                                            <AntTableCell>
                                                {misuseTypes.length === 1 ? (
                                                    misuseTypes[0]
                                                ) : (
                                                    <div>
                                                        {misuseTypes[0]}
                                                        <Button
                                                            className={classes.buttonMore}
                                                            aria-describedby={alertId}
                                                            onClick={event => {
                                                                setAnchorElMore(event.currentTarget);
                                                                setTypePopover(misuseTypes);
                                                            }}
                                                        >
                                                            <span className={classes.buttonMoreSpan}>
                                                                <MoreHoriz className={classes.buttonMoreIcon} viewBox="3 0 25 5" /> more
                                                            </span>
                                                        </Button>
                                                    </div>
                                                )}
                                            </AntTableCell>
                                            <AntTableCell>{alertManagedObjectName}</AntTableCell>
                                            <AntTableCell>{dateFormat(start, 'dd/mm/yy h:MM:ssTT')}</AntTableCell>
                                            <AntTableCell>{stop ? dateFormat(stop, 'dd/mm/yy h:MM:ssTT') : 'Ongoing'}</AntTableCell>
                                            <AntTableCell>
                                                {stop ? valueTimeCalculation(duration) : valueTimeCalculation((new Date() - new Date(start)) / 1000)}
                                            </AntTableCell>
                                            <AntTableCell>
                                                {stateSwitch.bps ? valueCalculation(maxImpactBps, 'bps') : valueCalculation(maxImpactPps, 'pps')}
                                            </AntTableCell>
                                            <AntTableCell>
                                                <Link
                                                    to={`/anomaly-details/${propsRoute}/${alertId}/1`}
                                                >
                                                    <Description color="action" className={classes.icon} />
                                                </Link>
                                            </AntTableCell>
                                        </TableRow>
                                    )
                                )}
                        </TableBody>
                    </Table>
                    <TablePaginationComponent
                        filter={filter}
                        setFilterActive={setFilter}
                        count={data && data.arborAlerts.info.totalCount}
                        getFilter={getFilter}
                        getVariables={getVariables()}
                        type={'FILTER_ACTIVE'}
                    />
                </TableContainer>
            </TableScroll>
        </div>
    );
};

export default TableAnomalies;
