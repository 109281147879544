import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    popover: {
        zIndex: 99999,
    },
    popoverWrapper: {
        backgroundColor: theme.palette.common.white,
        border: '1px solid',
        borderColor: theme.palette.grey.greyDivider,
    },
    popoverBody: {
        padding: 8,
        display: 'flex',
        flexDirection: 'column',
    },
    linkItem: {
        textDecoration: 'none',
    },
    menuItem: {
        padding: 9,
        borderRadius: 4,
        color: theme.palette.grey.greyInactive,
    },
    icon: {
        color: theme.palette.grey.greyInactive,
    },
    text: {
        color: theme.palette.grey.greyHighEmphasis,
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: '0.1px',
    },
}));
