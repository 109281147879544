import React from 'react';
import { useStyles, AntTableCell } from '../../../theme/Table';
import { TableRow, Table, TableHead, TableBody } from '@material-ui/core';
import { useIntl } from 'react-intl';
import Member from './Member';

const MembersMobile = ({ data, setIsDeleted }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <AntTableCell className={classes.title}>{formatMessage({ id: 'profile.members.email' })}</AntTableCell>
                    <AntTableCell className={classes.title}>{formatMessage({ id: 'profile.members.role' })}</AntTableCell>
                    <AntTableCell align="right" className={classes.title} />
                </TableRow>
            </TableHead>
            <TableBody>
                {data &&
                    data?.clientAccountEmployees?.items.map(({ id, email, role }) => (
                        <TableRow key={id}>
                            <Member id={id} email={email} role={role} setIsDeleted={setIsDeleted} />
                        </TableRow>
                    ))}
            </TableBody>
        </Table>
    );
};
export default MembersMobile;
