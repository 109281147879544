import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
    errorCharts: {
        fontSize: 20,
        margin: '50px 0px',
        textAlign: 'center',
    },
    headerTitle: {
        marginBottom: 30,
    },
    chart: {
        position: 'relative',
        '& .zoom': {
            position: 'absolute',
            padding: '7px 16px',
            color: '#FFFFFF',
            backgroundColor: '#2196f3',
            fontSize: 14,
            minWidth: 64,
            boxSizing: 'border-box',
            transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            fontFamily: "Roboto",
            fontWeight: 500,
            borderRadius: 4,
            textTransform: 'uppercase',
            borderColor: 'transparent',
            cursor: 'pointer',
            top: 20,
            left: 80,
        }
    },
    itemTitle: {
        color: theme.palette.grey.dark,
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        padding: '20px 16px',
        whiteSpace: 'nowrap',
    },
    bottom: {
        marginTop: 55,
    },
    pie: {
        margin: '0 auto',
    },
    linear: {
        minWidth: '80px',
    },
    greyColor: {
        color: theme.palette.grey.main,
    },
    blueTitle: {
        color: '#2196F3',
    }
}));