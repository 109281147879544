import React from 'react';
import { useStyles, AntTableCell } from '../../../../theme/Table';
import { Button } from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';

const ResourceL3 = ({ id, name, state, ips, createdAt, setAnchorEl, setIpsPopover }) => {
    const classes = useStyles();
    const dateFormat = require('dateformat');

    return (
        <>
            <AntTableCell style={{ padding: 5 }}>
                ID {id}
                <br />
                {name}
            </AntTableCell>
            {state === 1 && (
                <AntTableCell>
                    {ips.length === 0 ? (
                        '-'
                    ) : ips.length === 1 ? (
                        ips[0]
                    ) : (
                        <div>
                            {ips[0]}
                            <Button
                                className={classes.buttonMore}
                                aria-describedby={id}
                                onClick={event => {
                                    setAnchorEl(event.currentTarget);
                                    setIpsPopover(ips);
                                }}
                            >
                                <span className={classes.buttonMoreSpan}>
                                    <MoreHoriz className={classes.buttonMoreIcon} viewBox="3 0 25 5" /> more
                                </span>
                            </Button>
                        </div>
                    )}
                </AntTableCell>
            )}
            {state === 2 && <AntTableCell className={classes.nowrap}>{dateFormat(createdAt, 'mm/dd/yy h:MM:ssTT')}</AntTableCell>}
        </>
    );
};
export default ResourceL3;
