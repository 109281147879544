import React from 'react';
import { useStyles, AntTableCell } from '../../../theme/Table';
import { TableRow, TableHead, Table, TableBody, Grid, Button } from '@material-ui/core';
import { useIntl } from 'react-intl';
import WhiteList from './WhiteList';

const WhiteListsMobile = ({ data, setIsEditComment, setIsDeleted, setIsEdit }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Button color="primary" variant="contained" className={classes.button} onClick={() => setIsEdit(true)}>
                        {formatMessage({ id: 'webProtection.white.btn' })}
                    </Button>
                </Grid>
            </Grid>
            <Table>
                <TableHead>
                    <TableRow>
                        <AntTableCell align="left" className={classes.title}>
                            {formatMessage({ id: 'webProtection.white.ip' })}
                        </AntTableCell>
                        <AntTableCell align="left" className={classes.title}>
                            {formatMessage({ id: 'webProtection.white.comment' })}
                        </AntTableCell>
                        <AntTableCell />
                        <AntTableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data &&
                        data.l7WhiteLists.items.map(({ whitelistData, whitelistComment, whitelistId }) => (
                            <TableRow key={whitelistId}>
                                <WhiteList
                                    whitelistId={whitelistId}
                                    whitelistData={whitelistData}
                                    whitelistComment={whitelistComment}
                                    setIsEditComment={setIsEditComment}
                                    setIsDeleted={setIsDeleted}
                                />
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </>
    );
};

export default WhiteListsMobile;
