import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    logo: {
        marginBottom: 64,
    },
    title: {
        marginBottom: 68,
        fontFamily: 'Manrope',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '34px',
        letterSpacing: '0.25px',
        color: '#041613',
        textAlign: 'justify',
        lineHeight: '46px',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    button: {
        marginBottom: 32,
        width: '100%',
        padding: '15px 131px',
        background: theme.palette.primary.main,
        borderRadius: '100px',
    },
    link: {
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',
        textAlign: 'center',
        letterSpacing: '1.25px',
        textTransform: 'uppercase',
        color: theme.palette.grey.grey500,
        textDecoration: 'none',
        marginBottom: 222,
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    input: {
        width: '100%',
        marginBottom: '45px !important',
        [theme.breakpoints.only('xs')]: {
            marginBottom: '28px !important',
        },
    },
    errorNone: {
        display: 'none',
    },
    errorBox: {
        fontFamily: 'Manrope',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '22px',
        letterSpacing: '0.5px',
        color: '#D40E32',
        marginBottom: 40,
    },
    support: {
        fontFamily: 'Manrope',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '19px',
        letterSpacing: '0.15px',
        color: '#828B89',
        marginBottom: 40,
    },
    footer: {
        fontFamily: 'Manrope',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '22px',
        letterSpacing: '0.5px',
        color: '#828B89',
        marginBottom: 20,
    }
}));
