import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TableContainer } from '@material-ui/core';
import { useLazyQuery } from '@apollo/client';
import { GET_L7_ALIASES } from '../../../actions/get';
import ModalCreateAliases from '../../../components/modals/modalsWebProtection/createAliases';
import { useDebouncedEffect } from '../../../hooks/useDebouncedEffect';
import ModalEditSSLCertificate from '../../../components/modals/modalsWebProtection/editSSL';
import ModalDeleteAliases from '../../../components/modals/modalsWebProtection/deleteAliases';
import { TablePaginationComponent } from '../../../components/global/TablePagination';
import { PageContext } from '../../../context/pages';
import Preloader from '../../../components/global/Preloader';
import withWidth from '@material-ui/core/withWidth';
import AliasesDesktop from './AliasesDesktop';
import AliasesMobile from '../../../components/mobile/AliasesMobile';

const Aliases = ({ width }) => {
    const { id } = useParams();
    const [isCreated, setIsCreated] = useState(false);
    const [isDeleted, setIsDeleted] = useState({
        deleted: false,
    });

    const { secondaryFilter, setSecondaryFilter } = useContext(PageContext);
    const [isEdit, setIsEdit] = useState({
        edit: false,
        aliasId: '',
        ssl: '',
        key: '',
        crt: '',
    });

    const [getFilter, { data, loading }] = useLazyQuery(GET_L7_ALIASES);
    const getVariables = () => {
        const proxy = {
            l7ResourceId: Number(id),
            limit: secondaryFilter.limit,
            page: secondaryFilter.page + 1,
            alias: secondaryFilter?.alias || '',
        };

        return proxy;
    };

    useEffect(() => {
        getFilter({ variables: getVariables() });
    }, []);

    useDebouncedEffect(
        () => {
            getFilter({ variables: getVariables() });
        },
        1000,
        [secondaryFilter]
    );

    if (loading) return <Preloader />;

    const calculationTypeSsl = (autoSslValue, SslValue) => {
        const result = autoSslValue ? 'Let’s Encrypt' : SslValue ? 'Custom' : 'General';
        return result;
    };

    return (
        <>
            <ModalCreateAliases show={isCreated} hideModal={() => setIsCreated(false)} id={id} query={GET_L7_ALIASES} getVariables={getVariables()} />
            <ModalEditSSLCertificate
                show={isEdit.edit}
                id={Number(isEdit.aliasId)}
                l7ResourceId={Number(id)}
                hideModal={() => setIsEdit(!isEdit.edit)}
                type={'aliases'}
                ssl={isEdit.ssl}
                certificate={isEdit}
                query={GET_L7_ALIASES}
                getVariables={getVariables()}
            />
            <ModalDeleteAliases
                show={isDeleted.deleted}
                hideModal={() => setIsDeleted(!isDeleted.deleted)}
                query={GET_L7_ALIASES}
                getVariables={getVariables()}
                l7ResourceId={Number(id)}
                name={isDeleted.name}
                aliasId={Number(isDeleted.id)}
            />
            <TableContainer>
                {width === 'xs' ? (
                    <AliasesMobile
                        data={data}
                        setIsCreated={setIsCreated}
                        calculationTypeSsl={calculationTypeSsl}
                        setIsEdit={setIsEdit}
                        setIsDeleted={setIsDeleted}
                    />
                ) : (
                    <AliasesDesktop
                        data={data}
                        setIsCreated={setIsCreated}
                        calculationTypeSsl={calculationTypeSsl}
                        setIsEdit={setIsEdit}
                        setIsDeleted={setIsDeleted}
                    />
                )}
                <TablePaginationComponent
                    width={width}
                    filter={secondaryFilter}
                    setFilterActive={setSecondaryFilter}
                    count={data && data.l7Aliases.info.totalCount}
                    getFilter={getFilter}
                    getVariables={getVariables()}
                    type={'SECONDARY_FILTER'}
                />
            </TableContainer>
        </>
    );
};

export default withWidth()(Aliases);
