import { makeStyles } from '@material-ui/core/styles';

export const myStyles = makeStyles(theme => ({
    input: {
        width: '100%',
        maxWidth: '150px',
        '& input': {
            fontStyle: 'normal',
            fontWeight: 'normal',
            padding: '5px 14px',
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0.15px',
            color: theme.palette.grey.greyHighEmphasis,
        },
    },
    editBtn: {
        padding: '5.5px 16px',
        marginLeft: 10,
    },
    checkColor: {
        color: '#00E676',
    },
    checkWrapp: {
        padding: '4px 10px',
    },
}));
