import React from 'react';
import { useStyles } from '../../theme/Chart';
import { valueCalculation } from '../../helpers/calculation';
import { Line } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { useIntl } from 'react-intl';

Chart.register(annotationPlugin);

const AreaAllTraffic = ({ data, clientAccountId, activeZoom, setActiveZoom, unit, getAllL7Traffic }) => {
    const { formatMessage } = useIntl();
    const classes = useStyles();
    const dateFormat = require('dateformat');

    const prt95 =
        data?.arborGraphiteTrafficForAllResources?.nPercentile95TrafficIn > data?.arborGraphiteTrafficForAllResources?.nPercentile95TrafficOut
            ? {
                  data: data?.arborGraphiteTrafficForAllResources?.nPercentile95TrafficIn,
                  label: formatMessage({ id: 'chartsAreaAllTraffic.95in' }),
              }
            : {
                  data: data?.arborGraphiteTrafficForAllResources?.nPercentile95TrafficOut,
                  label: formatMessage({ id: 'chartsAreaAllTraffic.96out' }),
              };

    const series = {
        labels: data?.arborGraphiteTrafficForAllResources?.timestamp,
        datasets: [
            {
                label: formatMessage({ id: 'chartsAreaAllTraffic.in' }),
                data: data?.arborGraphiteTrafficForAllResources?.trafficIn,
                borderColor: '#40C4FF',
                backgroundColor: 'rgb(64, 196, 255, 0.2)',
                borderWidth: 1,
                radius: 0,
                hoverRadius: 3,
            },
            {
                label: formatMessage({ id: 'chartsAreaAllTraffic.out' }),
                data: data?.arborGraphiteTrafficForAllResources?.trafficOut,
                borderColor: '#69F0AE',
                backgroundColor: 'rgba(105, 240, 174, 0.6)',
                borderWidth: 0.5,
                radius: 0,
                hoverRadius: 3,
            },
        ],
    };

    const options = {
        interaction: {
            mode: 'index'
        },
        maintainAspectRatio: false,
        elements: {
            line: {
                borderJoinStyle: 'bevel',
                borderCapStyle: 'square',
                fill: true,
            },
        },
        animation: false,
        scales: {
            y: {
                grid: {
                    display: false,
                    drawBorder: false,
                },
                stacked: false,
                ticks: {
                    display: true,
                    callback: function (value) {
                        return valueCalculation(value, 'bps');
                    },
                },
            },
            x: {
                grid: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    display: false,
                    callback: function (value, index) {
                        return index % 2 === 0 ? dateFormat(this.getLabelForValue(value) * 1000, 'h:MM:ssTT') : '';
                    },
                },
            },
        },
        plugins: {
            title: {
                align: 'start',
                position: 'top',
                text: formatMessage({ id: 'chartsAreaAllTraffic.totalBand' }),
                display: true,
                color: '#D7D5D5',
                padding: {
                    top: 10,
                    bottom: 30,
                },
                font: {
                    family: 'Roboto',
                    weight: 500,
                    size: 16,
                },
            },
            annotation: {
                annotations: {
                    line1: {
                        type: 'line',
                        yMin: prt95.data,
                        yMax: prt95.data,
                        borderColor: 'black',
                        borderWidth: 1,
                        label: {
                            content: prt95.label,
                            color: 'black',
                            enabled: true,
                            backgroundColor: 'transparent',
                            position: 'start',
                            yAdjust: -10,
                            font: {
                                family: 'Roboto',
                                weight: 500,
                                size: 14,
                            },
                        },
                    },
                },
            },
            legend: {
                position: 'bottom',
            },
            filler: {
                propagate: false,
            },
            crosshair: {
                line: {
                    color: '#848484',
                    width: 0.5,
                },
                sync: {
                    enabled: false,
                },
                zoom: {
                    enabled: true,
                    zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',
                    zoomboxBorderColor: '#48F',
                    zoomButtonText: 'reset zoom',
                    zoomButtonClass: 'zoom-btn',
                },
                callbacks: {
                afterZoom: () => function(start, end) {
                    const afterZoomDate = data?.arborGraphiteTrafficForAllResources?.timestamp.filter((item, index) => {
                        if(index === start) return item ;
                        else if(index === end) return item;
                    });
                    const startDate = afterZoomDate[0];
                    const endDate = afterZoomDate[1];
                    getAllL7Traffic({
                        variables: {
                            from: startDate,
                            until: endDate,
                            clientAccountId,
                            unit,
                        }
                    });
                    setActiveZoom({...activeZoom, activeZoomChart: false});
                }
            }
            },
            tooltip: {
                animation: false,
                intersect: false,
                callbacks: {
                    title: function (context) {
                        return dateFormat(context[0].label * 1000, 'dd/mm/yy h:MM:ssTT');
                    },
                    label: function (context) {
                        return `${context.dataset.label}: ${valueCalculation(context.raw, 'bps')}`;
                    },
                },
            },
        },
    };

    return (
        <div className={`${classes.chart} ${classes.chartAll}`}>
            <button className={activeZoom.activeZoomChart ? classes.visibleZoomBtn : classes.zoomBtnDashboard}
                    onClick={() => {
                        getAllL7Traffic();
                        setActiveZoom({...activeZoom, activeZoomChart: true});
                    }}
            >
                RESET ZOOM
            </button>
            <Line data={series} options={options} />
        </div>
    );
};

export default AreaAllTraffic;
