import React, {useEffect} from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_L7_GRAPHITE_ERRORS } from '../../actions/get';
import { useIntl } from 'react-intl';
import Preloader from "../global/Preloader";
import { Line } from "react-chartjs-2";
import { Chart, Interaction } from 'chart.js';
import { CrosshairPlugin,Interpolate } from 'chartjs-plugin-crosshair';
import { useStyles } from '../../theme/Chart';

Chart.register(CrosshairPlugin);
Interaction.modes.interpolate = Interpolate;

const AreaChartsErrors = ({ id, date, setFilter }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const dateFormat = require('dateformat');
    const [getErrors, { data, loading, error }] = useLazyQuery(GET_L7_GRAPHITE_ERRORS, {
        variables: {
            from: (Date.parse(date.startDate) - (Date.parse(date.startDate) % 1000)) / 1000 || (date.startDate - (date.startDate % 1000)) / 1000,
            until: (Date.parse(date.endDate) - (Date.parse(date.endDate) % 1000)) / 1000 || (date.endDate - (date.endDate % 1000)) / 1000,
            l7ResourceId: id,
        },
    });

    useEffect(() => {
        getErrors();
        setFilter({...date, activeZoomChart: true});
    }, []);

    if (loading) return <Preloader/>;
    if (error || data?.l7Graphite5xxErrors?.timestamp.length < 1) return <div className={classes.errorCharts}>{formatMessage({ id: 'charts.error' })}</div>;

    const series = {
        labels: data?.l7Graphite5xxErrors?.timestamp,
        datasets: [
            {
                label: '504',
                data: data?.l7Graphite5xxErrors?.responseStatus504,
                borderColor: '#B71C1C',
                backgroundColor: 'rgba(183, 28, 28, 0.9)',
                borderWidth: 1,
                radius: 0,
                hoverRadius: 3
            },
            {
                label: '503',
                data: data?.l7Graphite5xxErrors?.responseStatus503,
                borderColor: '#EF5350',
                backgroundColor: 'rgba(239, 83, 80, 0.8)',
                borderWidth: 0.5,
                radius: 0,
                hoverRadius: 3
            },
            {
                label: '502',
                data: data?.l7Graphite5xxErrors?.responseStatus502,
                borderColor: '#E57373',
                backgroundColor: 'rgba(229, 115, 115, 0.7)',
                borderWidth: 0.5,
                radius: 0,
                hoverRadius: 3
            },
            {
                label: '501',
                data: data?.l7Graphite5xxErrors?.responseStatus501,
                borderColor: '#EF9A9A',
                backgroundColor: 'rgba(239, 154, 154, 0.6)',
                borderWidth: 0.5,
                radius: 0,
                hoverRadius: 3
            },
            {
                label: '500',
                data: data?.l7Graphite5xxErrors?.responseStatus500,
                borderColor: '#FFECDD',
                backgroundColor: 'rgba(255, 236, 221, 0.5)',
                borderWidth: 0.5,
                radius: 0,
                hoverRadius: 3
            },
        ],
    };

    const options = {
        interaction: {
            mode: 'index'
        },
        maintainAspectRatio: false,
        elements: {
            line: {
                borderJoinStyle: 'bevel',
                borderCapStyle: "square",
                fill: true,
            }
        },
        animation: false,
        scales: {
            y: {
                grid: {
                    display: false,
                    drawBorder: false,
                },
                stacked: false,
            },
            x: {
                grid: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    display: true,
                    callback: function (value, index, values) {
                        return index % 2 === 0 ? dateFormat(this.getLabelForValue(value) * 1000, 'h:MM:ssTT') : '';
                    }
                }
            }
        },
        plugins: {
            legend: {
                position: 'bottom',
            },
            filler: {
                propagate: false
            },
            crosshair: {
                line: {
                    color: '#848484',
                    width: 0.5
                },
                sync: {
                    enabled: false
                },
                zoom: {
                    enabled: true,
                    zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',
                    zoomboxBorderColor: '#48F',
                    zoomButtonText: 'reset zoom',
                    zoomButtonClass: 'zoom',
                },
                callbacks: {
                    afterZoom: () => function(start, end) {
                        const afterZoomDate = data?.l7Graphite5xxErrors?.timestamp.filter((item, index) => {
                            if(index === start) return item ;
                            else if(index === end) return item;
                        });
                        const startDate = afterZoomDate[0];
                        const endDate = afterZoomDate[1];
                        getErrors({
                            variables: {
                                from: startDate,
                                until: endDate,
                                l7ResourceId: id,
                            }
                        });
                        setFilter({...date, activeZoomChart: false});
                    }
                }
            },
            tooltip: {
                animation: false,
                intersect: false,
                callbacks: {
                    title: function(context) {
                        return dateFormat(context[0].label * 1000, 'dd/mm/yy h:MM:ssTT');
                    },
                }
            }

        },
    };

    return (
        <div className={classes.chart}>
            <button className={date.activeZoomChart ? classes.visibleZoomBtn : classes.zoomBtnDashboard}
                    onClick={() => {
                        getErrors();
                        setFilter({...date, activeZoomChart: true});
                    }}
            >
                RESET ZOOM
            </button>
            <Line data={series} options={options} />
        </div>
    );
};

export default AreaChartsErrors;
