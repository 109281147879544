import React, {useContext, useEffect, useState} from 'react';
import { useStyles } from '../../l3Protection/traffic/styles';
import { Grid, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { useQuery } from '@apollo/client';
import { DOSGATE_RESOURCES } from '../../../actions/get';
import Preloader from '../../../components/global/Preloader';
import TableDateTabs from '../../../components/global/TableDateTabs';
import TableDatePicker from '../../../components/global/TableDatePicker';
import { AntTab, AntTabs } from '../../l3Protection/anomalies/anomalyDetails/summary/styles';
import AreaDosgateTraffic from '../../../components/charts/AreaDosgateTraffic';
import LineTraffic from '../../../components/charts/LineTraffic';
import { useIntl } from 'react-intl';
import {PageContext} from "../../../context/pages";

const DosgateTraffic = () => {
    const classes = useStyles();
    const { filterActive, setFilterActive } = useContext(PageContext);
    const { formatMessage } = useIntl();
    const [select, setSelect] = useState(null);
    const [alwaysOnFiltration, setAlwaysOnFiltration] = useState(null);
    const [indexTab, setIndexTab] = useState('bps' ? 0 : 1);
    const format = indexTab === 0 ? 'BYTES' : 'PACKETS';
    const formatArbor = indexTab === 0 ? 'bps' : 'pps';
    const { data, loading } = useQuery(DOSGATE_RESOURCES);

    useEffect(() => {
        if(data && !select) setAlwaysOnFiltration(data?.dosGateResources?.items[0]?.alwaysOnFiltration)
    }, [data]);

    const handleChange = (event, newValue) => {
        setIndexTab(newValue);
    };

    const a11yProps = index => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    if (loading) return <Preloader />;

    const selectId = data?.dosGateResources?.items.map(({ dosGateName, id, alwaysOnFiltration, arborResource }) => ({
        value: `(${id}) ${dosGateName}`,
        arborId: arborResource.manageObjectId,
        alwaysOnFiltration,
    }));

    return (
        <div>
            <Grid item xs={12}>
                <Typography variant="h1" className={classes.headerTitle}>
                    {formatMessage({ id: 'dosgate.trafficStatistic.title' })}
                </Typography>
            </Grid>
            <Grid container direction="row" justify="space-between" alignItems="flex-start">
                <Grid item xs={4}>
                    {selectId && (
                        <Autocomplete
                            size="small"
                            className={classes.closeIcon}
                            id="resellerId"
                            options={selectId}
                            defaultValue={selectId[0]}
                            getOptionLabel={option => option.value}
                            onChange={(event, reason) => {
                                const regExp = /\(([^)]+)\)/;
                                const matches = regExp.exec(reason.value);
                                setAlwaysOnFiltration(reason.alwaysOnFiltration);
                                setSelect({ ...reason, value: Number(matches[1]) });
                            }}
                            renderInput={params => <TextField {...params} variant="outlined" size="small" className={classes.select} />}
                        />
                    )}
                </Grid>
                <Grid item>
                    <div className={classes.date}>
                        <TableDateTabs filter={filterActive} setFilter={setFilterActive} dosGate={true} />
                        <TableDatePicker filter={filterActive} setFilter={setFilterActive} />
                    </div>
                </Grid>
                <Grid container xs={12}>
                    <div className={classes.tabs}>
                        <AntTabs value={indexTab} onChange={handleChange}>
                            <AntTab label="BPS" {...a11yProps(0)} />
                            <AntTab label="PPS" {...a11yProps(1)} />
                        </AntTabs>
                    </div>
                </Grid>
                {!alwaysOnFiltration && (
                          <Grid container xs={12}>
                              <LineTraffic
                                  dosgate={formatArbor}
                                  filter={filterActive}
                                  setFilter={setFilterActive}
                                  id={!select ? data?.dosGateResources?.items[0]?.arborResource.manageObjectId : select.arborId}
                              />
                          </Grid>
                      )}
                <Grid container xs={12}>
                    <AreaDosgateTraffic
                        format={format}
                        filter={filterActive}
                        id={!select ? data?.dosGateResources?.items[0]?.id : select.value}
                        alwaysOn={!select ? selectId[0].alwaysOnFiltration : select.alwaysOnFiltration}
                        setFilter={setFilterActive}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default DosgateTraffic;
