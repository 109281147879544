import React from 'react';
import { TableSortLabel } from '@material-ui/core';
import { updateSortDirection } from '../../../helpers/calculation';
import { withStyles } from '@material-ui/core/styles';

export const AntTableSortLabel = withStyles({
    icon: {
        opacity: 1,
    },
})(TableSortLabel);

const TableSort = ({ name, label, direction, setFilterActive, filter, type }) => {
    const clickSort = event => {
        setFilterActive({...filter, page: 0, sort: { ...filter?.sort, [name]: updateSortDirection(direction) }, sortActive: name}, type);

    };

    return (
        <AntTableSortLabel active={filter?.sortActive === name} direction={direction} onClick={clickSort}>
            {label}
        </AntTableSortLabel>
    );
};
export default TableSort;
