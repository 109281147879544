import { gql } from 'graphql-tag';

export const GET_CLIENT_ACCOUNT_EMPLOYEE = gql`
    query clientAccountEmployee($id: [Int!]) {
        clientAccountEmployee(where: { id: { in: $id } }) {
            id
            twoFactorAuth
            email
            name {
                first
                last
            }
            role
            notificationSubscribe
            clientAccountId
            lang
            clientAccount {
                accountSettings {
                    l3 {
                        l3Limit
                        trialPeriod
                    }
                    l7 {
                        l7Limit
                        trialPeriod
                    }
                }
            }
        }
    }
`;

export const GET_CLIENT_ACCOUNT_EMPLOYEES = gql`
    query clientAccountEmployees($limit: Float, $page: Float, $id: [Int!]) {
        clientAccountEmployees(paginate: { limit: $limit, page: $page }, order: { id: DESC }, where: { id: { in: $id } }) {
            info {
                hasNextPage
                hasPreviousPage
                limit
                nextPage
                page
                previousPage
                totalCount
                totalPages
            }
            items {
                status
                id
                email
                name {
                    first
                    last
                }
                role
                notificationSubscribe
                clientAccountId
            }
        }
    }
`;

export const GET_CLIENT_ACCOUNT_EMPLOYEES_ACCESS = gql`
    query clientAccountEmployeeAccess($clientAccountEmployeeId: [Int!]) {
        clientAccountEmployeeAccess(where: { clientAccountEmployeeId: { in: $clientAccountEmployeeId } }) {
            clientAccountEmployeeId
            id
            l3Delete
            l3Read
            l3Update
            l7Delete
            l7Read
            l7Update
            create
        }
    }
`;

export const GET_ARBOR_RESOURCES = gql`
    query arborResources($limit: Float, $page: Float, $name: String, $id: [Int!], $manageObjectId: [Int!], $sortId: OrderEnum) {
        arborResources(
            paginate: { limit: $limit, page: $page }, 
            order: { manageObjectId: $sortId }, 
            where: { 
                id: { in: $id },
                manageObjectId: { in: $manageObjectId }, 
                name: { contain: $name } 
            }
        ) {
            info {
                hasNextPage
                hasPreviousPage
                limit
                nextPage
                page
                previousPage
                totalCount
                totalPages
            }
            items {
                createdAt
                id
                ips
                name
                manageObjectId
                dosGateName
                alwaysOnFiltration
                manageObjectName
            }
        }
    }
`;

export const DOSGATE_RESOURCES = gql`
    query dosGateResources($limit: Float, $page: Float, $clientAccountId: Float, $name: String, $id: [Int!], $sortId: OrderEnum) {
        dosGateResources(
            paginate: { limit: $limit, page: $page }
            order: { id: $sortId }
            where: { id: { in: $id }, name: { contain: $name }, clientAccountId: { eq: $clientAccountId } }
        ) {
            info {
                hasNextPage
                hasPreviousPage
                limit
                nextPage
                page
                previousPage
                totalCount
                totalPages
            }
            items {
                arborResource {
                    ips
                    id
                    manageObjectId
                }
                arborResourceId
                clientAccountId
                createdAt
                dosGateName
                id
                name
                partnerClientAccountId
                updatedAt
                alwaysOnFiltration
            }
        }
    }
`;

export const GET_ARBOR_ALERTS = gql`
    query arborAlerts(
        $limit: Float
        $page: Float
        $alertId: [Int!]
        $importance: [String!]
        $alertResourceCidr: String
        $alertManagedObjectName: String
        $sortId: OrderEnum
        $sortDuration: OrderEnum
        $sortStart: OrderEnum
        $sortStop: OrderEnum
        $sortMaxImpactBps: OrderEnum
        $sortMaxImpactPps: OrderEnum
        $filterStartLte: Date
        $filterStartGte: Date
    ) {
        arborAlerts(
            paginate: { limit: $limit, page: $page }
            order: {
                alertId: $sortId
                duration: $sortDuration
                start: $sortStart
                stop: $sortStop
                maxImpactBps: $sortMaxImpactBps
                maxImpactPps: $sortMaxImpactPps
            }
            where: {
                alertId: { in: $alertId }
                importance: { in: $importance }
                alertResourceCidr: { contain: $alertResourceCidr }
                alertManagedObjectName: { contain: $alertManagedObjectName }
                start: { gte: $filterStartLte, lte: $filterStartGte }
            }
        ) {
            info {
                hasNextPage
                hasPreviousPage
                limit
                nextPage
                page
                previousPage
                totalCount
                totalPages
            }
            items {
                alertManagedObjectName
                alertResourceCidr
                alertId
                direction
                duration
                host
                id
                importance
                misuseTypes
                maxImpactBps
                maxImpactPps
                start
                stop
                type
                severityPercent
                threshold
                impactBpsPoints
                impactPpsPoints
                unit
                impactRecorded
                arborResource {
                    name
                    manageObjectId
                    alwaysOnFiltration
                    dosGateName
                }
            }
        }
    }
`;

export const GET_ARBOR_MITIGATIONS = gql`
    query arborMitigations(
        $limit: Float
        $page: Float
        $filterPrefix: String
        $filterManagedObjectName: String
        $filterMitigationId: [Int!]
        $filterAnomalyId: [Int!]
        $filterStartLte: Date
        $filterStartGte: Date
        $sortMitigationId: OrderEnum
        $sortAnomalyId: OrderEnum
        $sortDuration: OrderEnum
        $sortStart: OrderEnum
        $sortStop: OrderEnum
    ) {
        arborMitigations(
            paginate: { limit: $limit, page: $page }
            order: { duration: $sortDuration, start: $sortStart, stop: $sortStop, mitigationId: $sortMitigationId, arborAlertId: $sortAnomalyId }
            where: {
                mitigationId: { in: $filterMitigationId }
                arborAlertId: { in: $filterAnomalyId }
                managedObjectName: { contain: $filterManagedObjectName }
                prefix: { contain: $filterPrefix }
                start: { gte: $filterStartLte, lte: $filterStartGte }
            }
        ) {
            info {
                hasNextPage
                hasPreviousPage
                limit
                nextPage
                page
                previousPage
                totalCount
                totalPages
            }
            items {
                alertId
                arborAlertId
                createdAt
                description
                duration
                host
                id
                isAutomitigation
                managedObjectId
                managedObjectName
                mitigationId
                name
                ongoing
                prefix
                start
                stop
                type
                updatedAt
                user
            }
        }
    }
`;

export const GET_ARBOR_MITIGATION = gql`
    query arborMitigation($id: Float!) {
        arborMitigation(id: $id) {
            drop_bps
            drop_pps
            pass_bps
            pass_pps
            start
        }
    }
`;

export const GET_ARBOR_ALERT_DETAIL = gql`
    query arborAlertDetail($id: Float!) {
        arborAlertDetail(id: $id) {
            id
            arborAlertId
            protos_bytes {
                avg_bytes
                id_bytes
                max_bytes
                name_bytes
            }
            src_addr_bytes {
                avg_bytes
                id_bytes
                max_bytes
                name_bytes
            }
            src_addr_packets {
                avg_packets
                id_packets
                max_packets
                name_packets
            }
            dest_addr_bytes {
                avg_bytes
                id_bytes
                max_bytes
                name_bytes
            }
            dest_addr_packets {
                avg_packets
                id_packets
                max_packets
                name_packets
            }
            src_tcp_ports_bytes {
                avg_bytes
                id_bytes
                max_bytes
                name_bytes
            }
            src_tcp_ports_packets {
                avg_packets
                id_packets
                max_packets
                name_packets
            }
            dest_tcp_ports_bytes {
                avg_bytes
                id_bytes
                max_bytes
                name_bytes
            }
            dest_tcp_ports_packets {
                avg_packets
                id_packets
                max_packets
                name_packets
            }
            src_udp_ports_bytes {
                avg_bytes
                id_bytes
                max_bytes
                name_bytes
            }
            src_udp_ports_packets {
                avg_packets
                id_packets
                max_packets
                name_packets
            }
            dest_udp_ports_bytes {
                avg_bytes
                id_bytes
                max_bytes
                name_bytes
            }
            dest_udp_ports_packets {
                avg_packets
                id_packets
                max_packets
                name_packets
            }
            src_asn_bytes {
                avg_bytes
                id_bytes
                max_bytes
                name_bytes
            }
            src_asn_packets {
                avg_packets
                id_packets
                max_packets
                name_packets
            }
            tcp_flags_bytes {
                avg_bytes
                id_bytes
                max_bytes
                name_bytes
            }
            tcp_flags_packets {
                avg_packets
                id_packets
                max_packets
                name_packets
            }
            misuse_types_bytes {
                avg_bytes
                id_bytes
                max_bytes
                name_bytes
                sample_bytes
            }
            misuse_types_packets {
                avg_packets
                id_packets
                max_packets
                name_packets
                sample_packets
            }
            packet_distribution {
                avg
                type
                sample
            }
            protos_packets {
                avg_packets
                id_packets
                max_packets
                name_packets
            }
            src_countries_bytes {
                avg_bytes
                id_bytes
                max_bytes
                name_bytes
                country_bytes
                country_code_bytes
            }
            src_countries_packets {
                avg_packets
                id_packets
                max_packets
                name_packets
                country_packets
                country_code_packets
            }
            top_patterns_bytes {
                avg_bytes
                dest_ip_bytes
                dest_port_bytes
                max_bytes
                name_bytes
                src_port_bytes
                source_ip_bytes
                src_protocol_bytes
            }
            top_patterns_packets {
                avg_packets
                dest_ip_packets
                dest_port_packets
                max_packets
                name_packets
                src_port_packets
                source_ip_packets
                src_protocol_packets
            }
        }
    }
`;

export const GET_L7_RESOURCES = gql`
    query l7Resources($limit: Float, $page: Float, $id: [Int!], $name: String, $sortId: OrderEnum) {
        l7Resources(
            paginate: { limit: $limit, page: $page }
            order: { id: $sortId }
            where: { l7ResourceId: { in: $id }, l7ResourceName: { contain: $name } }
        ) {
            info {
                hasNextPage
                hasPreviousPage
                limit
                nextPage
                page
                previousPage
                totalCount
                totalPages
            }
            items {
                id
                l7ResourceId
                l7ResourceName
                createdAt
                deletedAt
                serviceIp
                serviceId
                serviceHttp2https
                serviceHttps2http
                serviceWwwredir
                serviceSslKey
                serviceSsl
                serviceSslAuto
                serviceSslCrt
                serviceIphash
            }
        }
    }
`;

export const GET_L7_RESOURCE = gql`
    query l7Resource($l7ResourceId: [Int!]) {
        l7Resource(where: { l7ResourceId: { in: $l7ResourceId } }) {
            l7ResourceName
        }
    }
`;

export const GET_L7_RESOURCES_VENDOR = gql`
    query l7ResourcesVendor($limit: Float, $page: Float, $id: [Int!], $name: String, $sortId: OrderEnum, $createdAt: OrderEnum) {
        l7ResourcesVendor(
            paginate: { limit: $limit, page: $page },
            order: { id: $sortId, createdAt: $createdAt },
            where: { l7ResourceId: { in: $id }, l7ResourceName: { contain: $name } },
        ) {
            info {
                hasNextPage
                hasPreviousPage
                limit
                nextPage
                page
                previousPage
                totalCount
                totalPages
            }
            items {
                id
                l7ResourceId
                l7ResourceName
                serviceAbpMode
                iauthDisableL7
                iauthEnableLabeling
                serviceAbpMode
                serviceBotprotect
                serviceCdnHost
                serviceCdnHost
                serviceCdnProxyHost
                serviceCreated
                serviceCustom_500
                serviceCustom_502
                serviceCustom_503
                serviceCustom_504
                serviceDeleted
                serviceDisableL7
                serviceDopIp
                serviceEnable
                serviceForcessl
                serviceGeoipList
                serviceGeoipMode
                serviceGlobalWhitelist
                serviceHsts
                serviceHttp
                serviceHttp2
                serviceHttp2https
                serviceHttps2http
                serviceHwd
                serviceIauthEndpointPauth
                serviceIauthSsl
                serviceId
                serviceIp
                serviceIphash
                serviceIphash
                serviceIpv6
                serviceIstream
                serviceMethods
                serviceModified
                serviceName
                serviceSsl
                serviceSslAuto
                serviceSslCrt
                serviceSslExpire
                serviceSslKey
                serviceStream
                serviceWaf
                serviceWafId
                serviceWafMode
                serviceWildalias
                serviceWwwredir
                serviceIphash
                serviceAbpMode
                iauthDisableL7
                serviceSslExpire
            }
        }
    }
`;

export const GET_L7_RESOURCE_VENDOR = gql`
    query l7ResourceVendor($l7ResourceId: Float) {
        l7ResourceVendor(where: { l7ResourceId: { eq: $l7ResourceId } }) {
            iauthDisableL7
            iauthEnableLabeling
            serviceAbpMode
            serviceBotprotect
            serviceCdnHost
            serviceCdnHost
            serviceCdnProxyHost
            serviceCreated
            serviceCustom_500
            serviceCustom_502
            serviceCustom_503
            serviceCustom_504
            serviceDeleted
            serviceDisableL7
            serviceDopIp
            serviceEnable
            serviceForcessl
            serviceDisableL7
            serviceGeoipList
            serviceGeoipMode
            serviceGlobalWhitelist
            serviceHsts
            serviceHttp
            serviceHttp2
            serviceHttp2https
            serviceHttps2http
            serviceHwd
            serviceIauthEndpointPauth
            serviceIauthSsl
            serviceId
            serviceIp
            serviceIphash
            serviceIphash
            serviceIpv6
            serviceIstream
            serviceMethods
            serviceModified
            serviceName
            serviceSsl
            serviceSslAuto
            serviceSslCrt
            serviceSslExpire
            serviceSslKey
            serviceStream
            serviceWaf
            serviceWafId
            serviceWafMode
            serviceWildalias
            serviceWwwredir
            serviceIphash
            serviceAbpMode
            iauthDisableL7
            serviceSslExpire
        }
    }
`;

export const GET_L7_ALIASES = gql`
    query l7Aliases($limit: Float, $page: Float, $l7ResourceId: Float!, $alias: String) {
        l7Aliases(paginate: { limit: $limit, page: $page }, order: {}, where: { l7ResourceId: $l7ResourceId, aliasData: $alias }) {
            info {
                hasNextPage
                hasPreviousPage
                limit
                nextPage
                page
                previousPage
                totalCount
                totalPages
            }
            items {
                aliasCreated
                aliasData
                aliasId
                aliasModified
                aliasSsl
                aliasSslAuto
                aliasSslCrt
                aliasSslExpire
                aliasSslKey
                serviceId
            }
        }
    }
`;

export const GET_L7_BLACK_LISTS = gql`
    query l7BlackLists($limit: Float, $page: Float, $l7ResourceId: Float!, $blacklistIp: String) {
        l7BlackLists(paginate: { limit: $limit, page: $page }, order: {}, where: { l7ResourceId: $l7ResourceId, blacklistData: $blacklistIp }) {
            info {
                hasNextPage
                hasPreviousPage
                limit
                nextPage
                page
                previousPage
                totalCount
                totalPages
            }
            items {
                blacklistComment
                blacklistCreated
                blacklistData
                blacklistExpire
                blacklistId
                blacklistModified
            }
        }
    }
`;

export const GET_L7_BLACK_LIST = gql`
    query l7BlackList($blacklistId: Float!, $l7ResourceId: Float!) {
        l7BlackList(input: { l7ResourceId: $l7ResourceId, blacklistId: $blacklistId }) {
            blacklistComment
        }
    }
`;

export const GET_L7_WHITE_LISTS = gql`
    query l7WhiteLists($limit: Float, $page: Float, $l7ResourceId: Float!, $whitelistIp: String) {
        l7WhiteLists(paginate: { limit: $limit, page: $page }, order: {}, where: { l7ResourceId: $l7ResourceId, whitelistData: $whitelistIp }) {
            info {
                hasNextPage
                hasPreviousPage
                limit
                nextPage
                page
                previousPage
                totalCount
                totalPages
            }
            items {
                whitelistComment
                whitelistCreated
                whitelistData
                whitelistExpire
                whitelistId
                whitelistModified
            }
        }
    }
`;

export const GET_L7_WHITE_LIST = gql`
    query l7WhiteList($whitelistId: Float!, $l7ResourceId: Float!) {
        l7WhiteList(input: { l7ResourceId: $l7ResourceId, whitelistId: $whitelistId }) {
            whitelistComment
        }
    }
`;

export const GET_L7_ORIGINS = gql`
    query l7Origins($limit: Float, $page: Float, $l7ResourceId: Float!) {
        l7Origins(paginate: { limit: $limit, page: $page }, order: { originCreated: ASC }, where: { l7ResourceId: $l7ResourceId }) {
            info {
                hasNextPage
                hasPreviousPage
                limit
                nextPage
                page
                previousPage
                totalCount
                totalPages
            }
            items {
                originCreated
                originData
                originId
                originMode
                originModified
                originWeight
            }
        }
    }
`;

export const GET_L7_GRAPHITE_REQUESTS = gql`
    query l7GraphiteRequests($from: Float!, $l7ResourceId: Float!, $until: Float!, $unit: RequestUnitEnum) {
        l7GraphiteRequests(input: { from: $from, until: $until, l7ResourceId: $l7ResourceId, unit: $unit }) {
            blockedRequest
            legitimateRequest
            timestamp
            unit
        }
    }
`;

export const GET_L7_CAPTCHA = gql`
    query l7GraphiteCaptcha($from: Float!, $l7ResourceId: Float!, $until: Float!, $unit: RequestUnitEnum) {
        l7GraphiteCaptcha(input: { from: $from, until: $until, l7ResourceId: $l7ResourceId, unit: $unit }) {
            captchaPass
            captchaShow
            captchaSubmit
            timestamp
        }
    }
`;

export const GET_L7_GRAPHITE_REQUESTS_ANALYTICS = gql`
    query l7GraphiteRequestsAnalytics($from: Float!, $l7ResourceId: Float!, $until: Float!) {
        l7GraphiteRequestsAnalytics(input: { from: $from, until: $until, l7ResourceId: $l7ResourceId }) {
            timestamp
            goodBots
            humans
            badBots
            whiteListed
        }
    }
`;

export const GET_L7_GRAPHITE_BAND_WIDTH = gql`
    query l7GraphiteBandWidth($from: Float!, $l7ResourceId: Float!, $until: Float!) {
        l7GraphiteBandWidth(input: { from: $from, until: $until, l7ResourceId: $l7ResourceId }) {
            streamSent
            streamReceived
            timestamp
            toClientBandWidth
            toSpBandWidth
            fromClientBandWidth
        }
    }
`;

export const GET_L7_GRAPHITE_RESPONSE_TIMES = gql`
    query l7GraphiteResponseTimes($from: Float!, $l7ResourceId: Float!, $until: Float!) {
        l7GraphiteResponseTimes(input: { from: $from, until: $until, l7ResourceId: $l7ResourceId }) {
            lessOneThird
            moreOneThirdLessOne
            moreOneLessThree
            moreThreeLessTen
            moreTen
            timestamp
        }
    }
`;

export const GET_L7_GRAPHITE_ERRORS = gql`
    query l7Graphite5xxErrors($from: Float!, $l7ResourceId: Float!, $until: Float!) {
        l7Graphite5xxErrors(input: { from: $from, until: $until, l7ResourceId: $l7ResourceId }) {
            timestamp
            responseStatus500
            responseStatus501
            responseStatus502
            responseStatus503
            responseStatus504
        }
    }
`;

export const GET_L7_GRAPHITE_RESPONSE_CODES = gql`
    query l7GraphiteResponseCodes($from: Float!, $l7ResourceId: Float!, $until: Float!) {
        l7GraphiteResponseCodes(input: { from: $from, until: $until, l7ResourceId: $l7ResourceId }) {
            timestamp
            response2xx
            response3xx
            response4xx
            response5xx
        }
    }
`;

export const GET_CLIENT_ACCOUNT = gql`
    query clientAccount($id: [Int!]) {
        clientAccount(where: { id: { in: $id } }) {
            id
            name
            accountSettings {
                id
                clientAccountId
                createdAt
                # deletedAt
                l3 {
                    l3Limit
                }
                l7 {
                    l7Limit
                }
                dosGate {
                    dosGateLimit
                }
            }
        }
    }
`;

export const GET_ARBOR_TRAFFIC = gql`
    query arborGraphiteTraffic($from: Float!, $manageObjectId: Float!, $until: Float!, $unit: TrafficUnitEnum!) {
        arborGraphiteTraffic(input: { from: $from, manageObjectId: $manageObjectId, until: $until, unit: $unit }) {
            timestamp
            trafficIn
            trafficOut
        }
    }
`;

export const GET_REQUESTS_GEO = gql`
    query l7GraphiteTopNthCountriesRequestingDataByGeoIp($from: Float!, $l7ResourceId: Float!, $limit: Float, $until: Float!) {
        l7GraphiteTopNthCountriesRequestingDataByGeoIp(input: { from: $from, l7ResourceId: $l7ResourceId, limit: $limit, until: $until }) {
            timestamp
            topNthRequestsByGeoIp {
                requests
                countryName
                totalRequests
                requestsAsPercent
            }
        }
    }
`;

export const GET_ARBOR_GRAPHITE_TRAFFIC_FOR_ALL_RESOURSES = gql`
    query arborGraphiteTrafficForAllResources($from: Float!, $until: Float!, $clientAccountId: Float, $unit: TrafficUnitEnum!) {
        arborGraphiteTrafficForAllResources(input: { from: $from, until: $until, clientAccountId: $clientAccountId, unit: $unit }) {
            timestamp
            trafficIn
            trafficOut
        }
    }
`;

export const GET_ARBOR_ALL_TRAFFIC = gql`
    query arborGraphiteTrafficForAllResources($clientAccountId: Float, $from: Float!, $unit: TrafficUnitEnum!, $until: Float!) {
        arborGraphiteTrafficForAllResources(input: { clientAccountId: $clientAccountId, from: $from, unit: $unit, until: $until }) {
            timestamp
            trafficIn
            trafficOut
            nPercentile95TrafficIn
            nPercentile95TrafficOut
        }
    }
`;

export const GET_ALL_L7_RESOURCES = gql`
    query l7GraphiteBandWidthForAllResources($clientAccountId: Float, $from: Float!, $until: Float!) {
        l7GraphiteBandWidthForAllResources(input: { clientAccountId: $clientAccountId, from: $from, until: $until }) {
            timestamp
            toClientBandWidth
            fromClientBandWidth
            nPercentile95ToClientBandWidth
            nPercentile95FromClientBandWidth
            toSpBandWidth
        }
    }
`;

export const GET_MITIGATION_STATISTIC = gql`
    query arborMitigationStatistic($arborMitigationId: Float!) {
        arborMitigationStatistic(arborMitigationId: $arborMitigationId) {
            ratesTotal {
                pass_bps
                pass_pps
                drop_bps
                drop_pps
            }
            ratesHttp {
                pass_bps
                pass_pps
                drop_bps
                drop_pps
            }
            ratesFilter {
                pass_bps
                pass_pps
                drop_bps
                drop_pps
            }
            ratesProtocolBaseline {
                pass_bps
                pass_pps
                drop_bps
                drop_pps
            }
            ratesSource24Baseline {
                pass_bps
                pass_pps
                drop_bps
                drop_pps
            }
            ratesSynAuth {
                pass_bps
                pass_pps
                drop_bps
                drop_pps
            }
            ratesDnsAuth {
                pass_bps
                pass_pps
                drop_bps
                drop_pps
            }
            ratesDnsMalformed {
                pass_bps
                pass_pps
                drop_bps
                drop_pps
            }
            ratesHttpRegex {
                pass_bps
                pass_pps
                drop_bps
                drop_pps
            }
            ratesDnsRegex {
                pass_bps
                pass_pps
                drop_bps
                drop_pps
            }
            ratesIdleReset {
                pass_bps
                pass_pps
                drop_bps
                drop_pps
            }
            ratesHttpMalformed {
                pass_bps
                pass_pps
                drop_bps
                drop_pps
            }
            ratesSipRatelimit {
                pass_bps
                pass_pps
                drop_bps
                drop_pps
            }
            ratesSipMalformed {
                pass_bps
                pass_pps
                drop_bps
                drop_pps
            }
            ratesZombie {
                pass_bps
                pass_pps
                drop_bps
                drop_pps
            }
            ratesRatelimit {
                pass_bps
                pass_pps
                drop_bps
                drop_pps
            }
            ratesRegex {
                pass_bps
                pass_pps
                drop_bps
                drop_pps
            }
            ratesInvalidPkts {
                pass_bps
                pass_pps
                drop_bps
                drop_pps
            }
            ratesDnsRatelimit {
                pass_bps
                pass_pps
                drop_bps
                drop_pps
            }
            ratesDnsNxRatelimit {
                pass_bps
                pass_pps
                drop_bps
                drop_pps
            }
            ratesGeoipFilter {
                pass_bps
                pass_pps
                drop_bps
                drop_pps
            }
            ratesGeoipRatelimit {
                pass_bps
                pass_pps
                drop_bps
                drop_pps
            }
            ratesSrcnetFilter {
                pass_bps
                pass_pps
                drop_bps
                drop_pps
            }
            ratesConnlimit {
                pass_bps
                pass_pps
                drop_bps
                drop_pps
            }
        }
    }
`;

export const GET_DOSGATE_GRAPHITE = gql`
    query dosGateGraphiteTraffic($from: Float!, $until: Float!, $manageObjectId: Float!, $unit: DosGateTrafficUnitEnum!) {
        dosGateGraphiteTraffic(input: { from: $from, until: $until, manageObjectId: $manageObjectId, unit: $unit }) {
            accept
            drop
            error
            pass
            reply
            timestamp
        }
    }
`;

export const GET_L7_ANALYTICS = gql`
    query l7Analytics($from: Float!, $l7ResourceId: Float!, $metric: L7MetricEnum!, $until: Float!, $ip: String) {
        l7Analytics(input: { from: $from, until: $until, l7ResourceId: $l7ResourceId, metric: $metric, ip: $ip }) {
            c
            http_version
            method
            netname
            path
            remote_addr
            subnet
            ua
            ua_os
        }
    }
`;

export const GET_QR_CODE = gql`
    mutation activateTwoFactorAuth {
        activateTwoFactorAuth {
            qrCode
        }
    }
`;
