import React, {useContext} from 'react';

import ModalThreatLevel from './Component.js'
import {PageContext} from "../../../../context/pages";

const ConnectedModalThreatLevel = (props) => {
    const { filterActive, setFilterActive } = useContext(PageContext);
    return (
        <ModalThreatLevel {...props} filter={filterActive} setFilter={setFilterActive}/>
    )
}

export default ConnectedModalThreatLevel