import React, {useEffect} from 'react';
import { useLazyQuery } from '@apollo/client';
import Preloader from '../../../../components/global/Preloader';
import { GET_REQUESTS_GEO } from '../../../../actions/get';
import { useIntl } from 'react-intl';
import AreaCountryTop10 from '../../../../components/charts/AreaCountryTop10';
import PieCountryTop10 from '../../../../components/charts/PieCountryTop10';
import Grid from '@material-ui/core/Grid';
import { Paper, Table, TableBody, TableHead, TableRow, Typography } from '@material-ui/core';
import { AntTableCell } from '../../../../theme/Table';
import { BorderLinearProgress } from '../../../l3Protection/anomalies/anomalyDetails/summary/styles';
import { useStyles } from './styles';
import TableContainer from '@material-ui/core/TableContainer';
import ReactCountryFlag from 'react-country-flag';
import { AntContainer } from '../../../../theme/Wrapper';

const RequestsGeography = ({ id, date, setFilter }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();

    const [getRequestsGeo, { data, loading, error }] = useLazyQuery(GET_REQUESTS_GEO, {
        variables: {
            from: (Date.parse(date.startDate) - (Date.parse(date.startDate) % 1000)) / 1000 || (date.startDate - (date.startDate % 1000)) / 1000,
            until: (Date.parse(date.endDate) - (Date.parse(date.endDate) % 1000)) / 1000 || (date.endDate - (date.endDate % 1000)) / 1000,
            l7ResourceId: id,
        },
    });

    useEffect(() => {
        getRequestsGeo();
        setFilter({...date, activeZoomChart: true});
    }, []);

    if (loading) return <Preloader />;
    if (error) return <div className={classes.errorCharts}>{formatMessage({ id: 'charts.error' })}</div>;

    const tableData = data?.l7GraphiteTopNthCountriesRequestingDataByGeoIp?.topNthRequestsByGeoIp?.filter(e => {
        return e.totalRequests >= 1;
    });

    return (
        <>
            <div>
                <AreaCountryTop10
                    time={data?.l7GraphiteTopNthCountriesRequestingDataByGeoIp?.timestamp}
                    data={data?.l7GraphiteTopNthCountriesRequestingDataByGeoIp?.topNthRequestsByGeoIp?.slice(0, 10)}
                    setFilter={setFilter}
                    getRequestsGeo={getRequestsGeo}
                    date={date}
                    id={id}
                />
            </div>
            <AntContainer maxWidth={false}>
                <Grid container className={classes.bottom} spacing={4}>
                    <Grid item xs={6}>
                        <Grid item xs={12}>
                            <Typography variant="h1" className={classes.headerTitle}>
                                {formatMessage({ id: 'webProtection.requestsGeography.source' })}
                            </Typography>
                        </Grid>
                        <Paper variant="outlined">
                            <TableContainer style={{ maxHeight: 450 }}>
                                <Table size="small" stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <AntTableCell />
                                            <AntTableCell />
                                            <AntTableCell className={classes.blueTitle}>
                                                {formatMessage({ id: 'webProtection.requestsGeography.requests' })}
                                            </AntTableCell>
                                            <AntTableCell />
                                            <AntTableCell align="right" className={classes.blueTitle}>
                                                {formatMessage({ id: 'webProtection.requestsGeography.total' })}
                                            </AntTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tableData && tableData.map(({ countryName, totalRequests, requestsAsPercent }) => (
                                            <TableRow key={countryName}>
                                                <AntTableCell>
                                                    <ReactCountryFlag className="emojiFlag" countryCode={countryName} svg />
                                                </AntTableCell>
                                                <AntTableCell>{countryName}</AntTableCell>
                                                <AntTableCell>{totalRequests}</AntTableCell>
                                                <AntTableCell className={classes.linear}>
                                                    <BorderLinearProgress variant="determinate" value={100 - requestsAsPercent} />
                                                </AntTableCell>
                                                <AntTableCell align="right" className={classes.greyColor}>
                                                    {requestsAsPercent} %
                                                </AntTableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>
                    <Grid item xs={6} style={{ maxWidth: 480 }} className={classes.pie}>
                        <Grid container xs={12} direction="column" justifyContent="center" alignItems="center">
                            <Typography variant="h1" className={classes.headerTitle}>
                                {formatMessage({ id: 'webProtection.requestsGeography.countries' })}
                            </Typography>
                        </Grid>
                        <PieCountryTop10 data={data?.l7GraphiteTopNthCountriesRequestingDataByGeoIp?.topNthRequestsByGeoIp?.slice(0, 10) || []} />
                    </Grid>
                </Grid>
            </AntContainer>
        </>
    );
};

export default RequestsGeography;
