import React from 'react';
import { useStyles, AntTableCell } from '../../../../theme/Table';
import { DeleteForever } from '@material-ui/icons';

const Member = ({ id, email, role, setIsDeleted }) => {
    const classes = useStyles();

    return (
        <>
            <AntTableCell>{email}</AntTableCell>
            <AntTableCell>{role}</AntTableCell>
            <AntTableCell align="right">
                <div>
                    <DeleteForever
                        viewBox="0 0 24 24"
                        className={classes.iconDelete}
                        onClick={() =>
                            setIsDeleted({
                                deleted: true,
                                id: id,
                            })
                        }
                    />
                </div>
            </AntTableCell>
        </>
    );
};
export default Member;
