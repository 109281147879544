import React, {useContext, useEffect, useState} from 'react';
import { AntTab, AntTabs, useStyles } from '../../theme/Tabs/index';
import { Paper, Grid, IconButton, Typography } from '@material-ui/core';
import { AntContainerHead } from '../../theme/Wrapper';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Link, NavLink } from 'react-router-dom';
import { useParams } from 'react-router';
import TabPanelLayout from '../../components/global/TabPanelLayout';
import GeneralSettings from './generalSettings';
import Aliases from './aliases';
import BlackLists from './blackLists';
import WhiteLists from './whiteLists';
import AccessPolicy from './accessPolicy';
import { useIntl } from 'react-intl';
import { ArrowBack } from '@material-ui/icons';
import {PageContext} from "../../context/pages";
import TabPanel from "../../components/global/TabPanel";
import back from "../../../assets/img/back.svg";

const SettingsAccount = () => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const { tab, id } = useParams();
    const [indexTab, setIndexTab] = useState(0);
    const { deleteSecondaryFilter } = useContext(PageContext);

    const handleChange = (event, newValue) => {
        setIndexTab(newValue);
    };

    const a11yProps = index => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    useEffect(() => {
        setIndexTab(Number(tab));
    }, [tab]);

    return (
        <>
            <Paper elevation={1} square className={classes.wrapper}>
                <Grid container alignItems="center">
                    <Grid item>
                        <Link to="/web-protection/0" onClick={() => deleteSecondaryFilter()}>
                            <IconButton edge="start">
                                <ArrowBack className={classes.icon} />
                            </IconButton>
                        </Link>
                    </Grid>
                    <Grid item xs>
                        <Typography className={classes.title}>Resource settings</Typography>
                    </Grid>
                </Grid>
            </Paper>

            <div className={classes.bar}>
                <AntContainerHead maxWidth={false} className={classes.container}>
                    <div className={classes.itemBackLink}>
                        <Link to={'/web-protection/0'} className={classes.backLink} onClick={() => deleteSecondaryFilter()}>
                            <img src={back} alt="#" className={classes.backIcon}/>
                        </Link>
                    </div>
                    <AntTabs value={indexTab} onChange={handleChange} className={classes.itemTab} variant="scrollable" scrollButtons="auto">
                        <AntTab
                            label={formatMessage({ id: 'webProtection.tabs.general' })}
                            component={NavLink}
                            {...a11yProps(0)}
                            to={`/settings-account/${id}/0`}
                            className={`${classes.link} ${classes.marginLeft}`}
                            activeClassName={classes.linkActive}
                            onClick={() => deleteSecondaryFilter()}
                        />
                        <AntTab
                            label={formatMessage({ id: 'webProtection.tabs.aliases' })}
                            {...a11yProps(1)}
                            component={NavLink}
                            to={`/settings-account/${id}/1`}
                            className={`${classes.link} ${classes.marginLeft}`}
                            activeClassName={classes.linkActive}
                            onClick={() => deleteSecondaryFilter()}
                        />
                        <AntTab
                            label={formatMessage({ id: 'webProtection.tabs.politic' })}
                            {...a11yProps(2)}
                            component={NavLink}
                            to={`/settings-account/${id}/2`}
                            className={`${classes.link} ${classes.marginLeft}`}
                            activeClassName={classes.linkActive}
                            onClick={() => deleteSecondaryFilter()}
                        />
                        <AntTab
                            label={formatMessage({ id: 'webProtection.tabs.white' })}
                            {...a11yProps(3)}
                            component={NavLink}
                            to={`/settings-account/${id}/3`}
                            className={`${classes.link} ${classes.marginLeft}`}
                            activeClassName={classes.linkActive}
                            onClick={() => deleteSecondaryFilter()}
                        />
                        <AntTab
                            label={formatMessage({ id: 'webProtection.tabs.black' })}
                            {...a11yProps(4)}
                            component={NavLink}
                            to={`/settings-account/${id}/4`}
                            className={`${classes.link} ${classes.marginLeft}`}
                            activeClassName={classes.linkActive}
                            onClick={() => deleteSecondaryFilter()}
                        />
                    </AntTabs>
                </AntContainerHead>
            </div>

            <TabPanel value={indexTab} index={0}>
                <div className={classes.wrapperContainer}>
                    <GeneralSettings />
                </div>
            </TabPanel>
            <TabPanel value={indexTab} index={1}>
                <div className={classes.wrapperTable}>
                    <Aliases />
                </div>
            </TabPanel>
            <TabPanel value={indexTab} index={2}>
                <div className={classes.wrapperContainer}>
                    <AccessPolicy />
                </div>
            </TabPanel>
            <TabPanel value={indexTab} index={3}>
                <div className={classes.wrapperTable}>
                    <WhiteLists />
                </div>
            </TabPanel>
            <TabPanel value={indexTab} index={4}>
                <div className={classes.wrapperTable}>
                    <BlackLists />
                </div>
            </TabPanel>
        </>
    );
};
export default SettingsAccount;
