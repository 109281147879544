import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AuthContext } from '../../../context/auth';

const AuthRoute = ({ children, ...props }) => {
    const { user } = useContext(AuthContext);

    if (user) {
        return <Redirect to="/my-profile/0" />;
    }

    return (
        <Route {...props}>
            <ToastContainer position="bottom-right" autoClose={8000} />
            {children}
        </Route>
    );
};

export default AuthRoute;
