import { makeStyles } from '@material-ui/core/styles';
import { Tabs, withStyles } from '@material-ui/core';
import { AntTableCell } from '../../../theme/Table';

export const useStyles = makeStyles(theme => ({
    bar: {
        background: theme.palette.common.white,
        marginBottom: 30,
        borderTopWidth: 0,
        borderBottomWidth: '2px',
        borderColor: theme.palette.grey.greyRgba12,
        borderBlockStyle: 'solid',
    },
    headerTitle: {
        marginBottom: 30,
    },
    date: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 30,
    },
    select: {
        width: '100%',
        maxWidth: '450px',
        padding: '5px 5px 5px 5px',
        '&:focus': {
            background: 'none',
        },
    },
    // components
    wrapper: {
        margin: '30px',
    },
    tableTitle: {
        backgroundColor: '#F6F7FB',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
        padding: '10px',
    },
    tableLabel: {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: '0.1px',
        color: '#9E9E9E',
    },
    notAnalytics: {
        fontSize: 20,
        margin: '150px 0px',
        textAlign: 'center',
    },
    errorCharts: {
        fontSize: 20,
        margin: '50px 0px',
        textAlign: 'center',
    },
    numberStyle: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '15px',
        letterSpacing: '0.4px',
        color: '#9E9E9E',
        width: 15,
    },
    blueText: {
        color: '#2196F3',
    },
}));

export const MyTabs = withStyles(theme => ({
    indicator: {
        backgroundColor: theme.palette.primary.main,
    },
    flexContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}))(Tabs);

export const AntTableCellHead = withStyles(theme => ({
    root: {
        padding: 0,
    },
}))(AntTableCell);

export const AntTableCellLabel = withStyles(theme => ({
    root: {
        borderBottom: 'none',
        padding: '10px 10px 0 10px',
    },
}))(AntTableCell);
