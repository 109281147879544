import React, {useEffect} from 'react';
import {useStyles} from "../network/styles";
import {useIntl} from "react-intl";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Grid, Typography} from "@material-ui/core";
import TableDateTabs from "../../../components/global/TableDateTabs";
import TableDatePicker from "../../../components/global/TableDatePicker";
import AreaAllResource from "../../../components/charts/AreaAllL7Resources";
import ApplicationBoxes from "./ApplicationBoxes";

const DashboardApplication = ({ clientAccountId, secondaryFilter, setSecondaryFilter, getAllL7Res, handleQuery }) => {
    const { data, error, loading} = handleQuery;
    const classes = useStyles();
    const { formatMessage } = useIntl();

    useEffect(() => {
        getAllL7Res();
        setSecondaryFilter({...secondaryFilter, activeZoomChart: true});
    }, []);

    if (loading)
        return (
            <div className={classes.circular}>
                <CircularProgress size="10rem" color="primary" />
            </div>
        );
    if (error || data?.l7GraphiteBandWidthForAllResources?.timestamp.length < 1)
        return <div className={classes.errorCharts}>{formatMessage({ id: 'charts.error' })}</div>;

    return (
        <Grid container spacing={2} style={{ marginTop: 50}}>
            <Grid item xs={12} className={classes.container}>
                <div>
                    <Typography variant="h1" className={classes.headerTitle}>
                        {formatMessage({ id: 'dashboard.app.title' })}
                    </Typography>
                </div>
                <div className={classes.date}>
                    <TableDateTabs filter={secondaryFilter} setFilter={setSecondaryFilter} />
                    <TableDatePicker filter={secondaryFilter} setFilter={setSecondaryFilter} />
                </div>
            </Grid>
            <Grid item xs={11} style={{ backgroundColor: '#F6F7FB'}}>
                <AreaAllResource data={data}
                                 getAllL7Res={getAllL7Res}
                                 clientAccountId={clientAccountId}
                                 activeZoom={secondaryFilter}
                                 setActiveZoom={setSecondaryFilter}
                />
            </Grid>
            <Grid xs={1} style={{ backgroundColor: '#F6F7FB'}}>
                <ApplicationBoxes date={setSecondaryFilter} store={data} />
            </Grid>
        </Grid>
    );
};

export default DashboardApplication