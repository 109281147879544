import React, { useContext, useEffect, useState } from 'react';
import { AntTab, AntTabs, useStyles } from '../../theme/Tabs/index';
import { useParams } from 'react-router';
import { NavLink, Link } from 'react-router-dom';
import { AntContainerHead } from '../../theme/Wrapper';
import { Paper, Grid, IconButton, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import NetworkProtection from './NetworkProtection';
import ApplicationProtection from './ApplicationProtection';
import { AuthContext } from '../../context/auth';
import { useQuery } from '@apollo/client';
import { GET_CLIENT_ACCOUNT_EMPLOYEE } from '../../actions/get';
import Preloader from '../../components/global/Preloader';
import { useIntl } from 'react-intl';
import {PageContext} from "../../context/pages";
import TabPanel from "../../components/global/TabPanel";
import back from "../../../assets/img/back.svg";

const SettingsAccountMembers = () => {
    const { formatMessage } = useIntl();
    const classes = useStyles();
    const { tab, id } = useParams();
    const [indexTab, setIndexTab] = useState(0);
    const { user } = useContext(AuthContext);
    const { deleteSecondaryFilter } = useContext(PageContext);

    const handleChange = (event, newValue) => {
        setIndexTab(newValue);
    };

    const a11yProps = index => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    const { data: accountData } = useQuery(GET_CLIENT_ACCOUNT_EMPLOYEE, {
        variables: {
            id: user && user.id,
        },
    });

    const { data: userMembersData, loading } = useQuery(GET_CLIENT_ACCOUNT_EMPLOYEE, {
        variables: {
            id: Number(id),
        },
    });

    useEffect(() => {
        setIndexTab(Number(tab));
    }, [tab]);

    if (loading) return <Preloader />;

    const accountL3Limit = accountData?.clientAccountEmployee?.clientAccount?.accountSettings?.l3?.l3Limit;
    const accountL7Limit = accountData?.clientAccountEmployee?.clientAccount?.accountSettings?.l7?.l7Limit;

    const userMembersEmail = userMembersData && userMembersData.clientAccountEmployee.email;
    const userMembersRole = userMembersData && userMembersData.clientAccountEmployee.role;

    return (
        <>
            <Paper elevation={1} square className={classes.wrapper}>
                <Grid container alignItems="center">
                    <Grid item>
                        <Link to="/my-profile/1" onClick={() => deleteSecondaryFilter()}>
                            <IconButton edge="start">
                                <ArrowBack className={classes.icon} />
                            </IconButton>
                        </Link>
                    </Grid>
                    <Grid item xs>
                        <Typography className={classes.title}>{formatMessage({ id: 'settingsAccountMembers.title' })}</Typography>
                    </Grid>
                </Grid>
            </Paper>

            <div position="static" className={classes.bar}>
                <AntContainerHead maxWidth={false} className={classes.container}>
                    <div className={classes.itemBackLink}>
                        <Link to={'/my-profile/1'} className={classes.backLink} onClick={() => deleteSecondaryFilter()}>
                            {/*<ChevronLeft className={classes.backIcon} />*/}
                            {/*{formatMessage({ id: 'settingsMembers.back' })}*/}
                            <img src={back} alt="#" className={classes.backIcon}/>
                        </Link>
                    </div>
                    <AntTabs value={indexTab} onChange={handleChange} className={classes.itemTab}>
                        {accountL3Limit !== 0 && accountL3Limit !== null && (
                            <AntTab
                                label={formatMessage({ id: 'settingsMembers.tab.network' })}
                                {...a11yProps(0)}
                                component={NavLink}
                                className={`${classes.link} ${classes.marginLeft}`}
                                activeClassName={classes.linkActive}
                                onClick={() => deleteSecondaryFilter()}
                                to={`/settings-account-members/0/${id}`}
                            />
                        )}
                        {accountL7Limit !== 0 && accountL7Limit !== null && (
                            <AntTab
                                label={formatMessage({ id: 'settingsMembers.tab.application' })}
                                {...a11yProps(1)}
                                component={NavLink}
                                className={`${classes.link} ${classes.marginLeft}`}
                                activeClassName={classes.linkActive}
                                onClick={() => deleteSecondaryFilter()}
                                to={`/settings-account-members/1/${id}`}
                            />
                        )}
                    </AntTabs>
                </AntContainerHead>
            </div>
            {accountL3Limit !== 0 && accountL3Limit !== null && (
                <TabPanel value={indexTab} index={0}>
                    <div className={classes.wrapperTable}>
                        <NetworkProtection email={userMembersEmail} id={id} />
                    </div>
                </TabPanel>
            )}
            <TabPanel value={indexTab} index={accountL3Limit !== 0 && accountL3Limit !== null ? 1 : 0}>
                <div className={classes.wrapperTable}>
                    <ApplicationProtection email={userMembersEmail} id={id} role={userMembersRole} />
                </div>
            </TabPanel>
        </>
    );
};
export default SettingsAccountMembers;
