import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    dialog: {
        '& .MuiDialog-paper': {
            margin: 0,
        },
    },
    modal: {
        maxHeight: '100%',
        background: '#FAFAFA',
        boxShadow: '0px 15px 12px',
        width: 'calc(320px + (500 - 320) * ((100vw - 360px) / (1920 - 360)));',
    },
    header: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        padding: 24,
        borderBottom: '1px solid',
        borderBottomColor: theme.palette.grey.greyDivider,
        [theme.breakpoints.only('xs')]: {
            paddingBottom: 0,
            borderBottom: 0,
        },
    },
    modalBody: {
        padding: '24px',
    },
    modalFooter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flexWrap: 'wrap',
        padding: '20px 0 0 0',
        marginBottom: 24,
    },
    close: {
        padding: 5,
        minWidth: 'auto',
        color: theme.palette.grey.greyInactive,
    },
    qrCode: {
      margin: '20px 0'
    },
    cancel: {
        color: theme.palette.grey.grey500,
        marginRight: 5,
    },
    invite: {
        color: theme.palette.primary.main,
    },
    dsc: {
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.5px',
        color: theme.palette.grey.greyMediumEmphasis,
        '& span': {
            color: theme.palette.common.black,
        },
    },
    input: {
        width: '100%',
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.primary.main,
    },
}));