import React from 'react';
import { Button, Dialog, TextField, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { CREATE_L7_ALIAS } from '../../../../actions/create';
import { toast } from 'react-toastify';
import { useStyles } from '../../../../theme/Modals';
import { useIntl } from 'react-intl';

const ModalCreateAliases = ({ show, hideModal, id, query, getVariables }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();

    const { handleSubmit, control } = useForm();

    const [createAlias] = useMutation(CREATE_L7_ALIAS);

    const onSubmit = data => {
        createAlias({
            variables: {
                aliasData: data.alias,
                l7ResourceId: Number(id),
            },
            refetchQueries: [{ query: query, variables: getVariables }],
        })
            .then(() => toast.success('Success'))
            .then(() => hideModal())
            .catch(err => toast.error(err.message));
    };
    return (
        <div>
            <Dialog
             className={classes.dialog}
                maxWidth="md"
                open={show}
                onClose={() => {
                    hideModal();
                }}
            >
                <div className={classes.modal}>
                    <div className={classes.header}>
                        <Typography variant="h1">{formatMessage({ id: 'webProtection.aliases.create.title' })}</Typography>
                        <Button className={classes.close} onClick={() => hideModal()}>
                            <CloseIcon />
                        </Button>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={classes.modalBody}>
                            <div className={classes.inputLabel}>{formatMessage({ id: 'webProtection.aliases.create.subtitle' })}</div>
                            <Controller
                                name="alias"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        placeholder={formatMessage({ id: 'webProtection.aliases.create.span' })}
                                        variant="outlined"
                                        className={classes.input}
                                    />
                                )}
                            />
                        </div>
                        <div className={classes.modalFooter}>
                            <Button className={classes.cancel} onClick={() => hideModal()}>
                                {formatMessage({ id: 'webProtection.aliases.create.cancel' })}
                            </Button>
                            <Button type="submit" className={classes.invite}>
                                {formatMessage({ id: 'webProtection.aliases.create.accept' })}
                            </Button>
                        </div>
                    </form>
                </div>
            </Dialog>
        </div>
    );
};

export default ModalCreateAliases;
