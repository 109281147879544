import React, { useState } from 'react';
import { useStyles, AntTableCell, AntTableCellFilter } from '../../../../theme/Table';
import { BaseSwitch } from '../../../../theme/Switch';
import { Table, TableHead, TableBody, TableRow, Button, FormGroup, Grid, Typography } from '@material-ui/core';
import TableSort from '../../../../components/global/TableSort';
import TableFilter from '../../../../components/global/TableFilter';
import TableDateTabs from '../../../../components/global/TableDateTabs';
import TableDatePicker from '../../../../components/global/TableDatePicker';
import { textWidthCalculation } from '../../../../helpers/calculation';
import { useIntl } from 'react-intl';
import Anomaly from './Anomaly';

const AnomaliesDesktop = ({
    data,
    filter,
    setFilter,
    setAnchorElMore,
    setAnchorElThreatLevel,
    setTypePopover,
}) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const [stateSwitch, setStateSwitch] = useState({
        bps: true,
    });

    const maxWidth = {
        id: Math.max.apply(Math, data && data.arborAlerts.items.map(items => textWidthCalculation(items.alertId))),
        ips: Math.max.apply(Math, data && data.arborAlerts.items.map(items => textWidthCalculation(items.alertResourceCidr))),
        name: Math.max.apply(Math, data && data.arborAlerts.items.map(items => textWidthCalculation(items.alertManagedObjectName))),
    };

    return (
        <>
            <div className={classes.header}>
                <Typography variant="h1">{formatMessage({ id: 'anomalies.title' })}</Typography>
                <div className={classes.date}>
                    <TableDateTabs filter={filter} setFilter={setFilter} />
                    <TableDatePicker filter={filter} setFilter={setFilter} />
                </div>
            </div>
            <Table size="small" className={classes.table}>
                <TableHead>
                    {/* SORT */}
                    <TableRow>
                        <AntTableCell className={classes.title}>
                            <TableSort
                                name="sortId"
                                direction={filter?.sort?.sortId}
                                label={formatMessage({ id: 'anomalies.id' })}
                                setFilterActive={setFilter}
                                filter={filter}
                                type={'FILTER_ACTIVE'}
                            />
                        </AntTableCell>
                        <AntTableCell className={classes.title}>{formatMessage({ id: 'anomalies.lvl' })}</AntTableCell>
                        <AntTableCell className={classes.title}>{formatMessage({ id: 'anomalies.ips' })}</AntTableCell>
                        <AntTableCell className={classes.title}>{formatMessage({ id: 'anomalies.type' })}</AntTableCell>
                        <AntTableCell className={classes.title}>{formatMessage({ id: 'anomalies.name' })}</AntTableCell>
                        <AntTableCell className={classes.title}>
                            <TableSort
                                name="sortStart"
                                direction={filter?.sort?.sortStart}
                                label={formatMessage({ id: 'anomalies.start' })}
                                setFilterActive={setFilter}
                                filter={filter}
                                type={'FILTER_ACTIVE'}
                            />
                        </AntTableCell>
                        <AntTableCell className={classes.title}>
                            <TableSort
                                name="sortStop"
                                direction={filter?.sort?.sortStop}
                                label={formatMessage({ id: 'anomalies.end' })}
                                setFilterActive={setFilter}
                                filter={filter}
                                type={'FILTER_ACTIVE'}
                            />
                        </AntTableCell>
                        <AntTableCell className={classes.title}>
                            <TableSort
                                name="sortDuration"
                                direction={filter?.sort?.sortDuration}
                                label={formatMessage({ id: 'anomalies.duration' })}
                                setFilterActive={setFilter}
                                filter={filter}
                                type={'FILTER_ACTIVE'}
                            />
                        </AntTableCell>
                        <AntTableCell className={classes.title}>
                            <TableSort
                                name={stateSwitch.bps ? 'sortMaxImpactBps' : 'sortMaxImpactPps'}
                                direction={stateSwitch.bps ? filter?.sort?.sortMaxImpactBps : filter?.sort?.sortMaxImpactPps}
                                label={formatMessage({ id: 'anomalies.max' })}
                                setFilterActive={setFilter}
                                filter={filter}
                                type={'FILTER_ACTIVE'}
                            />
                        </AntTableCell>
                        <AntTableCell className={classes.title} />
                        <AntTableCell className={classes.title} />
                    </TableRow>
                    <TableRow>
                        <AntTableCellFilter>
                            <TableFilter name="id" filter={filter} setFilter={setFilter} maxWidth={maxWidth.id} />
                        </AntTableCellFilter>
                        <AntTableCellFilter>
                            <Button
                                variant="outlined"
                                color="primary"
                                className={classes.btnFilter}
                                aria-describedby="threat-Level"
                                onClick={event => {
                                    setAnchorElThreatLevel(event.currentTarget);
                                }}
                            >
                                {formatMessage({ id: 'anomalies.setFilter' })}
                            </Button>
                        </AntTableCellFilter>
                        <AntTableCellFilter>
                            <TableFilter name="durationIps" filter={filter} setFilter={setFilter} maxWidth={maxWidth.ips} />
                        </AntTableCellFilter>
                        <AntTableCellFilter />
                        <AntTableCellFilter>
                            <TableFilter name="resourceName" filter={filter} setFilter={setFilter} maxWidth={maxWidth.name} />
                        </AntTableCellFilter>
                        <AntTableCellFilter />
                        <AntTableCellFilter />
                        <AntTableCellFilter />
                        <AntTableCellFilter style={{ padding: '4.5px 10px' }}>
                            <FormGroup>
                                <Grid component="label" className={classes.switcher}>
                                    <Grid item>pps</Grid>
                                    <Grid item>
                                        <BaseSwitch
                                            checked={stateSwitch.bps}
                                            onChange={event => {
                                                setStateSwitch({ ...stateSwitch, [event.target.name]: event.target.checked });
                                            }}
                                            name="bps"
                                        />
                                    </Grid>
                                    <Grid item>bps</Grid>
                                </Grid>
                            </FormGroup>
                        </AntTableCellFilter>
                        <AntTableCellFilter />
                        <AntTableCellFilter />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data &&
                        data.arborAlerts.items.map(
                            ({
                                alertId,
                                importance,
                                alertResourceCidr,
                                alertManagedObjectName,
                                misuseTypes,
                                start,
                                stop,
                                duration,
                                maxImpactBps,
                                maxImpactPps,
                                id,
                                severityPercent,
                                threshold,
                                unit,
                                impactBpsPoints,
                                impactPpsPoints,
                                impactRecorded,
                                arborResource
                            }) => (
                                <Anomaly
                                    filter={filter}
                                    setFilter={setFilter}
                                    alertId={alertId}
                                    importance={importance}
                                    alertResourceCidr={alertResourceCidr}
                                    misuseTypes={misuseTypes}
                                    setAnchorElMore={setAnchorElMore}
                                    setTypePopover={setTypePopover}
                                    alertManagedObjectName={alertManagedObjectName}
                                    start={start}
                                    stop={stop}
                                    duration={duration}
                                    stateSwitch={stateSwitch}
                                    maxImpactBps={maxImpactBps}
                                    maxImpactPps={maxImpactPps}
                                    arborResources={arborResource}
                                />
                            )
                        )}
                </TableBody>
            </Table>
        </>
    );
};
export default AnomaliesDesktop;
