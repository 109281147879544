import React from 'react';
import { useStyles } from './styles';
import { Grid } from "@material-ui/core";
import { AntPaper } from "../../../theme/Wrapper";
import Info from "./info";
import Password from "./password";
import TwoFactor from "./twoFactor";
import {useQuery} from "@apollo/client";
import { GET_CLIENT_ACCOUNT_EMPLOYEE } from "../../../actions/get";
import Preloader from "../../../components/global/Preloader";

const UserInfo = ({ token }) => {
    const classes = useStyles();
    const { data, loading } = useQuery(GET_CLIENT_ACCOUNT_EMPLOYEE, {
        variables: {
            id: token && token.id,
        },
    });

    if (loading) return <Preloader />;

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6} container>
                    <div className={classes.wrapperContainer}>
                        <Info token={token} data={data}/>
                    </div>
                </Grid>
                <Grid item xs={6} container spacing={2}>
                    <Grid item xs={12}>
                        <div className={classes.wrapperContainer}>
                            <Password token={token} data={data}/>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.wrapperContainer}>
                            <TwoFactor data={data} token={token} query={GET_CLIENT_ACCOUNT_EMPLOYEE}/>
                        </div>
                    </Grid>
                </Grid>
            </Grid>

        </>
    );
};

export default UserInfo;
