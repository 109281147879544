import React from "react";
import {Text, View} from "@react-pdf/renderer";
import {styles} from "../../styles";
import {percentCalculation, valueCalculation} from "../../../../../../../helpers/calculation";

const PdfAlertInfo = ({ data, store }) => {
    const createData = (name, data, bps) => {
        return { name, data, bps };
    };

    const progressBps =
        data &&
        data.arborAlertDetail.dest_addr_bytes.reduce((p, c) => {
            return p + c.avg_bytes;
        }, 0);

    const columns = {
        destIpBps: data && data?.arborAlertDetail?.dest_addr_bytes[0]?.avg_bytes,
        protocolsBps: data && data.arborAlertDetail?.protos_bytes[0]?.avg_bytes,
        srcCountriesBps: data && data.arborAlertDetail?.src_countries_bytes[0]?.avg_bytes,
        srcAsnBps: data && data.arborAlertDetail?.src_asn_bytes[0]?.avg_bytes,
        srcIpBps: data && data.arborAlertDetail?.src_addr_bytes[0]?.avg_bytes,
    };
    const { destIpBps, protocolsBps, srcCountriesBps, srcAsnBps, srcIpBps } = columns;

    const rows = [
        createData(
            'Severity level',
            store?.importance,
        ),
        createData(
            'Classification',
            'Possible attack',
        ),
        createData(
            'The highest single-minute sum of alert traffic, bps',
            valueCalculation(store?.maxImpactBps, 'bps'),
        ),
        createData(
            'The highest single-minute sum of alert traffic, pps',
            valueCalculation(store?.maxImpactPps, 'pps'),
        ),
        createData(
            ' Destination IP addresses',
            store?.alertResourceCidr,
            `${percentCalculation(destIpBps, progressBps)} %`,
        ),
        createData(
            'Protocols',
            data?.arborAlertDetail?.protos_bytes[0]?.name_bytes,
            `${percentCalculation(protocolsBps, progressBps)} %`,
        ),
        createData(
            'Source countries',
            data?.arborAlertDetail?.src_countries_bytes[0]?.name_bytes,
            `${percentCalculation(srcCountriesBps, progressBps)} %`,
        ),
        createData(
            'Source ASNs',
            data?.arborAlertDetail?.src_asn_bytes[0]?.name_bytes,
            `${percentCalculation(srcAsnBps, progressBps)} %`,
        ),
        createData(
            'Source IP addresses',
            data?.arborAlertDetail?.src_addr_bytes[0]?.name_bytes === '0.0.0.0/0' ? 'Highly Distributed' : data?.arborAlertDetail?.src_addr_bytes[0]?.name_bytes,
            `${percentCalculation(srcIpBps, progressBps)} %`,
        ),
    ];
    return (
        <div>
            {rows.map(row => (
                row.data &&
                <View key={row.name}>
                    <Text style={styles.tableSubtitle}>
                        {row.name}: {row.data} <Text style={styles.grey}>{row.bps}</Text>
                    </Text>
                </View>
            ))}
        </div>
    )
};

export default PdfAlertInfo;