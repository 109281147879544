import AuthTwoStepVerificationModal from "./Component";
import {useContext} from "react";
import {AuthContext} from "../../../../context/auth";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";
import {useMutation} from "@apollo/client";
import {TWO_FACTOR_AUTH} from "../../../../actions/update";

const ConnectedAuthTwoStepVerificationModal = (props) => {
    const context = useContext(AuthContext);
    const { push } = useHistory();
    const [setTwoFactorAuth] = useMutation(TWO_FACTOR_AUTH);

    const onSubmit = dataInput => {
        return setTwoFactorAuth({
            variables: {
                code: dataInput.code,
                email: props.email
            },
        })
            .then(res => {
                context.login(res.data.twoFactorAuth.accessToken);
                push('/dashboard');
            })
            .then(() => props.hideModal())
            .catch(err => toast.error(err.message));
    };

    return (
        <AuthTwoStepVerificationModal {...props} submit={onSubmit}/>
    )
}

export default ConnectedAuthTwoStepVerificationModal;