import React from 'react';
import {useStyles} from "../../../../theme/Modals";
import {useIntl} from "react-intl";
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Popover,
    Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const ModalThreatLevel = ({ id, open, anchorEl, setAnchorEl, filter, setFilter }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();

    const handleListItemClick = value => {
        handleClose(value);
    };

    const handleChange = event => {
        setFilter({...filter, thrLvlState: { ...filter.thrLvlState, [event.target.name]: event.target.checked }});
    };

    const handleClose = value => {
        setAnchorEl(null);
        setFilter({
            ...filter,
            threatLevel: Object.entries(value || {})?.reduce((acc, value) => {
                if (value[1]) {
                    acc.push(value[0]);
                }
                return acc;
            }, []),
        });
    };

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={() => {
                handleClose(filter.threatLevel);
                handleListItemClick(filter.thrLvlState);
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <div className={classes.modal} style={{ width: 170 }}>
                <div className={classes.header}>
                    <Typography variant="h1">{formatMessage({ id: 'table.filter.title' })}</Typography>
                    <Button
                        className={classes.close}
                        onClick={() => {
                            handleClose(filter.threatLevel);
                            handleListItemClick(filter.thrLvlState);
                        }}
                    >
                        <CloseIcon />
                    </Button>
                </div>
                <div className={classes.modalBody}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend" style={{ paddingBottom: 10 }}>
                            {formatMessage({ id: 'table.filter.label' })}
                        </FormLabel>
                        <FormGroup>
                            {['low', 'medium', 'high'].map((el) => {
                                return (
                                    <FormControlLabel
                                        control={<Checkbox
                                            color="primary"
                                            checked={filter.thrLvlState[el]}
                                            onChange={handleChange}
                                            name={el}
                                        />}
                                        label={el}
                                        style={{textTransform: 'capitalize'}}
                                    />
                                )
                            })}
                        </FormGroup>
                    </FormControl>
                </div>
            </div>
        </Popover>
    );
};

export default ModalThreatLevel;