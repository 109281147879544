import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useIntl } from 'react-intl';

const PieCountryTop10 = ({ data }) => {
    const { formatMessage } = useIntl();
    const options = {
        stacked100: { enable: true },
        responsive: true,
        animation: false,
        plugins: {
            crosshair: false,
            legend: false,
            filler: {
                propagate: false,
            },
            tooltip: {
                animation: false,
                callbacks: {
                    label: function (context) {
                        return `${context.label}: ${context.raw} Requests`;
                    },
                },
            },
        },
    };

    const series = {
        labels: data.map(item => item.countryName),
        datasets: [
            {
                label: formatMessage({ id: 'chartsPieCountryTop10.title' }),
                data: data.map(item => item.totalRequests),
                backgroundColor: ['#81DAF5', '#58D3F7', '#2ECCFA', '#00BFFF', '#01A9DB', '#2E9AFE', '#0080FF', '#0174DF', '#045FB4', '#A9E2F3'],
                hoverOffset: 4,
            },
        ],
    };

    return (
        <>
            <Doughnut data={series} options={options} width={100} height={100} />
        </>
    );
};

export default PieCountryTop10;
