import { withStyles } from '@material-ui/core';

export const GlobalCss = withStyles(theme => ({
    '@global': {
        '.Toastify__toast--success': {
            background: theme.palette.common.white,
            color: theme.palette.primary.main,
        },
        '.Toastify__progress-bar': {
            background: theme.palette.primary.main,
        },
        '.Toastify__close-button': {
            color: theme.palette.primary.main,
        },
        '.MuiTab-textColorInherit': {
            opacity: 1,
        },
        '.MuiFormLabel-root.Mui-focused': {
            color: theme.palette.primary.main,
        },
        '.MuiFilledInput-underline.Mui-focused:after': {
            borderBottom: '2px solid #2196F3',
        },
        '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
        },
        html: {
            height: '100%',
        },
        body: {
            margin: 0,
            height: '100%',
            fontFamily: 'Roboto',
            backgroundColor: 'white',
        },
        '.MuiTableCell-sizeSmall': {
            padding: '10px',
            whiteSpace: 'nowrap',
        },
        '.MuiSvgIcon-root': {
            fontSize: '1.2rem',
        },
        '.MuiTable-root': {
            borderCollapse: 'inherit',
        },
        '.MuiFormControl-root': {
            width: '100%',
            minWidth: '60px !important',
        },
        '.MuiFab-root': {
            height: '40px !important',
            width: '40px !important',
            marginLeft: '15px !important',
            [theme.breakpoints.between('xs', 'sm')]: {
                height: '35px !important',
                width: '35px !important',
                marginLeft: '10px !important',
            },
        },
        '.MuiSelect-icon': {
          color: '#01CDCB'
        },
        '.MuiOutlinedInput-root:hover': {
            borderColor: 'transparent'
        },
        '.Mui-error': {
            borderColor: '#D40E32',
        },
        '.makeStyles-input-85, .makeStyles-input-56': {
            marginTop: 0,
        },
    },
}))(() => null);
