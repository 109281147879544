import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    list: {
        maxWidth: 550,
        padding: 0,
        marginBottom: 20,
    },
    listItem: {
        padding: '0',
    },
    listIcon: {
        marginRight: 15,
    },
    active: {
        color: theme.palette.grey.greyMediumEmphasis,
    },
    disable: {
        color: theme.palette.grey.greyDisable,
    },
}));
