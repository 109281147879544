import { gql } from 'graphql-tag';

export const CREATE_CLIENT_ACCOUNT_EMPLOYEE = gql`
    mutation createClientAccountEmployee($email: String!, $clientAccountId: Float!, $role: ActorRoleEnum!) {
        createClientAccountEmployee(input: { role: $role, email: $email, clientAccountId: $clientAccountId }) {
            email
            id
            name {
                first
                last
            }
            role
        }
    }
`;

export const CREATE_L7_ALIAS = gql`
    mutation createL7Alias(
        $aliasData: String!
        $aliasSsl: Float
        $aliasSslAuto: Float
        $aliasSslCrt: String
        $aliasSslExpire: Float
        $aliasSslKey: String
        $l7ResourceId: Float!
    ) {
        createL7Alias(
            input: {
                aliasData: $aliasData
                aliasSsl: $aliasSsl
                aliasSslAuto: $aliasSslAuto
                aliasSslCrt: $aliasSslCrt
                aliasSslExpire: $aliasSslExpire
                aliasSslKey: $aliasSslKey
                l7ResourceId: $l7ResourceId
            }
        ) {
            aliasId
        }
    }
`;

export const CREATE_L7_BLACK_LIST = gql`
    mutation createL7BlackLists($blacklistComment: String, $blacklistData: String!, $blacklistExpire: Float, $l7ResourceId: Float!) {
        createL7BlackLists(
            input: {
                blacklists: { blacklistComment: $blacklistComment, blacklistData: $blacklistData, blacklistExpire: $blacklistExpire }
                l7ResourceId: $l7ResourceId
            }
        ) {
            blacklistComment
        }
    }
`;

export const CREATE_L7_RESOURCE = gql`
    mutation createL7Resource($l7ResourceName: String!, $clientAccountId: Float, $originData: String!) {
        createL7Resource(input: { l7ResourceName: $l7ResourceName, clientAccountId: $clientAccountId, originData: $originData }) {
            serviceId
        }
    }
`;

export const CREATE_L7_ORIGIN = gql`
    mutation createL7Origin($l7ResourceId: Float!, $originData: String!, $originMode: String, $originWeight: Float) {
        createL7Origin(input: { l7ResourceId: $l7ResourceId, originData: $originData, originMode: $originMode, originWeight: $originWeight }) {
            originData
            originWeight
            originId
        }
    }
`;

export const CREATE_L7_WHITE_LIST = gql`
    mutation createL7WhiteLists($whitelistComment: String, $whitelistData: String!, $whitelistExpire: Float, $l7ResourceId: Float!) {
        createL7WhiteLists(
            input: {
                whitelists: { whitelistComment: $whitelistComment, whitelistData: $whitelistData, whitelistExpire: $whitelistExpire }
                l7ResourceId: $l7ResourceId
            }
        ) {
            whitelistComment
        }
    }
`;
