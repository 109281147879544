import React, { useState, useRef, useEffect, useContext } from 'react';
import { useStyles } from '../../../theme/Table';
import { Button, TableContainer, Typography, Grid } from '@material-ui/core';
import ModalDeleteUser from '../../../components/modals/modalsMembers/deleteUser';
import ModalInviteUser from '../../../components/modals/modalsMembers/InviteUser';
import TableScroll from '../../../components/global/TableScroll';
import { useLazyQuery } from '@apollo/client';
import { GET_CLIENT_ACCOUNT_EMPLOYEES } from '../../../actions/get';
import { useIntl } from 'react-intl';
import { TablePaginationComponent } from '../../../components/global/TablePagination';
import { PageContext } from '../../../context/pages';
import Preloader from '../../../components/global/Preloader';
import withWidth from '@material-ui/core/withWidth';
import MembersMobile from '../../../components/mobile/MembersMobile';
import MembersDesktop from './MembersDesktop';
import PopoverBar from './popoverBar';

const AccountMembers = ({ token, width }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const table = useRef(null);
    const { filterActive, setFilterActive } = useContext(PageContext);
    const [isCreated, setIsCreated] = useState(false);
    const [isDeleted, setIsDeleted] = useState({
        deleted: false,
        id: '',
    });

    const [anchorEl, setAnchorEl] = useState(null);
    const [indexId, setIndexId] = useState('');
    const [indexEmail, setIndexEmail] = useState('');
    const [indexRole, setIndexRole] = useState('');

    const [getFilter, { data, loading }] = useLazyQuery(GET_CLIENT_ACCOUNT_EMPLOYEES);
    const getVariables = () => {
        const proxy = {
            limit: filterActive.limit,
            page: filterActive.page + 1,
        };
        return proxy;
    };

    useEffect(() => {
        getFilter({ variables: getVariables() });
    }, []);

    if (loading) return <Preloader />;

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'accountList' : undefined;

    return (
        <>
            <ModalInviteUser
                show={isCreated}
                hideModal={() => setIsCreated(false)}
                query={GET_CLIENT_ACCOUNT_EMPLOYEES}
                getVariables={getVariables()}
                clientAccountId={token && token.clientAccountId}
                type={token && token.type}
            />
            <ModalDeleteUser
                show={isDeleted.deleted}
                hideModal={() => setIsDeleted(!isDeleted.deleted)}
                email={isDeleted.email}
                id={isDeleted.id}
                query={GET_CLIENT_ACCOUNT_EMPLOYEES}
                type={token && token.type}
                getVariables={getVariables()}
            />
            <TableScroll tableRef={table}>
                <TableContainer ref={table}>
                    <Grid container>
                        <Grid item xs>
                            <Typography variant="h1" className={classes.titleHeader}>
                                {formatMessage({ id: 'profile.members.title' })}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm="auto">
                            <Button type="submit" color="primary" variant="contained" className={classes.button} onClick={() => setIsCreated(true)}>
                                {formatMessage({ id: 'profile.members.btn' })}
                            </Button>
                        </Grid>
                    </Grid>
                    {width === 'xs' || width === 'sm' ? (
                        <MembersMobile data={data} setIsDeleted={setIsDeleted} />
                    ) : (
                        <MembersDesktop
                            data={data}
                            setIsDeleted={setIsDeleted}
                            setIndexId={setIndexId}
                            setIndexEmail={setIndexEmail}
                            setIndexRole={setIndexRole}
                            handleClick={handleClick}
                        />
                    )}
                    <TablePaginationComponent
                        width={width}
                        filter={filterActive}
                        setFilterActive={setFilterActive}
                        count={data && data.clientAccountEmployees && data.clientAccountEmployees.info.totalCount}
                        getFilter={getFilter}
                        getVariables={getVariables()}
                        type={'FILTER_ACTIVE'}
                    />
                </TableContainer>
            </TableScroll>
            <PopoverBar
                id={id}
                open={open}
                anchorEl={anchorEl}
                handleClose={handleClose}
                setIsDeleted={setIsDeleted}
                indexId={indexId}
                indexEmail={indexEmail}
                indexRole={indexRole}
            />
        </>
    );
};
export default withWidth()(AccountMembers);
