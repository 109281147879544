import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles(theme => ({
    modal: {
        maxHeight: '100%',
        background: theme.palette.grey.backgroundLight,
        boxShadow: '0px 15px 12px',
        width: 'calc(320px + (700 - 320) * ((100vw - 360px) / (1920 - 360)));',
    },
    group: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        [theme.breakpoints.between('xs', 'sm')]: {
            justifyContent: 'start',
            flexDirection: 'column',
            alignItems: 'normal',
        },
    },
    box: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
    },
    text: {
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '22px',
        color: 'rgba(0, 0, 0, 0.85)',
        margin: '15px 0 0 0',
    },
    areaTitle: {
        fontSize: '20px',
        marginBottom: 10,
        [theme.breakpoints.between('xs', 'sm')]: {
            marginBottom: 5,
        },
    },
    area: {
        width: '100%',
        borderColor: theme.palette.grey.grey500,
        borderRadius: 4,
        '&:focus-visible': {
            outlineColor: theme.palette.primary.main,
            outlineStyle: 'auto',
            outlineWidth: '1px',
        },
    },
}));
