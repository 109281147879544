import React from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Grid } from '@material-ui/core';
import CardsHead from '../../../../../components/global/cards/CardsHead';
import { useStyles } from './styles';
import { valueCalculation } from '../../../../../helpers/calculation';
import { useIntl } from 'react-intl';
import {PdfDocument} from "../PdfGenerator";
import {GetApp} from "@material-ui/icons";
import {PDFDownloadLink} from "@react-pdf/renderer";
import {useMutation, useQuery} from "@apollo/client";
import {GET_ARBOR_ALERT_DETAIL} from "../../../../../actions/get";
import TablesTraffic from "./TablesTraffic";
import {LOAD_ALERT_DETAILS} from "../../../../../actions/update";
import RefreshIcon from '@material-ui/icons/Refresh';
import Preloader from "../../../../../components/global/Preloader";
import {toast} from "react-toastify";

const Traffic = ({ store }) => {
    const classes = useStyles();
    const { id } = useParams();
    const { formatMessage } = useIntl();

    const details = store.arborAlerts.items[0];
    const { data, loading, refetch } = useQuery(GET_ARBOR_ALERT_DETAIL, {
        variables: {
            id: details?.id,
        },
    });
    const [refreshAlertDetails, {loading: refresh}] = useMutation(LOAD_ALERT_DETAILS);
    const startValue = () => {
        return details.importance === 'low' ? 1 : details.importance === 'medium' ? 2 : details.importance === 'high' ? 3 : '';
    };

    if (loading || refresh) return <Preloader />;

    return (
        <div className={classes.wrapper}>
            <button className={classes.download}
                    style={{display: 'flex', justifyContent: 'flex-end', width: '100%', marginBottom: 15, alignItems: 'center'}}
                    onClick={() => {
                        refreshAlertDetails({
                            variables: {
                                ids: details?.id
                            },
                        })
                            .then(res => res.data.success && refetch({ id: details?.id, }))
                            .catch(err => toast.error(err.message))
                    }}>
                <RefreshIcon style={{ verticalAlign: 'middle' }}/>
                Refresh
            </button>
            <Grid container spacing={2} className={classes.headerWrapper}>
                <Grid item xs className={classes.titleWrapper}>
                    <Typography variant="h6">{formatMessage({ id: 'detailsAnomaly.title' })}</Typography>
                    <Typography variant="h6" style={{marginBottom: 15}}>(id {id})</Typography>
                    <PDFDownloadLink document={<PdfDocument store={store} data={data}/>}>
                        <button className={classes.download}>
                            <GetApp style={{ verticalAlign: 'middle' }}/>
                            Download PDF
                        </button>
                    </PDFDownloadLink>
                </Grid>
                <Grid item xs>
                    <CardsHead title={formatMessage({ id: 'detailsAnomaly.lvl' })} data={details.importance} starValue={startValue()} />
                </Grid>
                <Grid item xs>
                    <CardsHead title={formatMessage({ id: 'detailsAnomaly.maxBit' })} data={`${valueCalculation(details.maxImpactBps, 'bps')}`} />
                </Grid>
                <Grid item xs>
                    <CardsHead title={formatMessage({ id: 'detailsAnomaly.maxPacket' })} data={`${valueCalculation(details.maxImpactPps, 'pps')}`} />
                </Grid>
                <Grid item xs md={3}>
                    <CardsHead title={formatMessage({ id: 'detailsAnomaly.name' })} data={details.alertManagedObjectName} />
                </Grid>
                <Grid item xs>
                    <CardsHead title={formatMessage({ id: 'detailsAnomaly.ip' })} data={details.alertResourceCidr} />
                </Grid>
            </Grid>

            <div className={classes.tablesWrapper}>
                <TablesTraffic loading={loading} data={data} title={formatMessage({ id: 'anomalyDetailsTraffic.tableTop.top' })}  />
            </div>
        </div>
    );
};

export default Traffic;
