import Auth from './Component.js'
import {useContext} from "react";
import {AuthContext} from "../../context/auth";
import {useMutation} from "@apollo/client";
import {Login} from "../../actions/update";

const ConnectedAuth = (props) => {
    const context = useContext(AuthContext);

    const [login, { error, loading }] = useMutation(Login);



    return (
        <Auth {...props} context={context} login={login} loading={loading} error={error}/>
    )
}

export default ConnectedAuth