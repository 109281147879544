import { blue } from '@material-ui/core/colors';

export const colors = {
    wellness: {
        breakpoints: {
            values: {
                xs: 0,
                sm: 429,
                md: 768,
                lg: 1024,
                xl: 1200,
            },
        },
        palette: {
            common: {
                black: '#000000',
                white: '#FFFFFF',
                grey: '#F8F9FC',
            },
            primary: {
                main: '#26BF83',
                light: '#4ee6aa',
                dark: '#4ee6aa',
                contrastText: '#FFFFFF',
                primary300: '#26BF83',
                primary200: '#4ee6aa',
                primary100: '#ACDFDF',
                primary50: '#C3E2E1',
            },
            secondary: {
                light: '#C3E2E1',
                main: '#C3E2E1',
                dark: '#C3E2E1',
                contrastText: '#26BF83',
            },
            error: {
                light: '#FCE7E7',
                main: '#E53935',
                dark: '#C62828',
                contrastText: '#FFFFFF',
                backgroundError: '#FFDCDC',
            },
            grey: {
                grey500: '#9E9E9E', // Black/Disabled
                backgroundDark: '#E8E8E8',
                backgroundMain: '#F0F2F5', // auth
                backgroundLight: '#F2F5F8',
                greyDisable: '#C4C4C4',
                greyDivider: '#E5E5E5',
                greyDark: 'rgba(0, 0, 0, 0.24)',
                greyLight: 'rgba(0, 0, 0, 0.04)',
                greyInactive: 'rgba(0, 0, 0, 0.54)',
                greyMediumEmphasis: 'rgba(0, 0, 0, 0.6)',
                greyHighEmphasis: 'rgba(0, 0, 0, 0.87)',
                greyRgba45: 'rgba(0, 0, 0, 0.45)',
                greyRgba12: 'rgba(0, 0, 0, 0.12)',
                greyRgba85: 'rgba(0, 0, 0, 0.85)',
            },
        },
        typography: {
            h1: {
                fontWeight: 500,
                fontSize: '20px',
                lineHeight: '30px',
                letterSpacing: '0.15px',
                color: 'rgba(0, 0, 0, 0.87)',
            },
            button: {
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '16px',
                fontStyle: 'normal',
                textTransform: 'uppercase',
                textAlign: 'center',
                letterSpacing: '1.25px',
                borderRadius: '4px',
            },
        },
    },
    sp: {
        breakpoints: {
            values: {
                xs: 0,
                sm: 429,
                md: 768,
                lg: 1024,
                xl: 1200,
            },
        },
        palette: {
            common: {
                black: '#000000',
                white: '#FFFFFF',
                grey: '#F8F9FC',
            },
            primary: {
                main: '#01CDCB',
                light: '#00E9C6',
                dark: '#51D5D4',
                contrastText: '#FFFFFF',
                primary300: '#72D8D7',
                primary200: '#89DBDA',
                primary100: '#ACDFDF',
                primary50: '#C3E2E1',
            },
            secondary: {
                light: '#C3E2E1',
                main: '#C3E2E1',
                dark: '#C3E2E1',
                contrastText: '#01CDCB',
            },
            error: {
                light: '#FCE7E7',
                main: '#E53935',
                dark: '#C62828',
                contrastText: '#FFFFFF',
                backgroundError: '#FFDCDC',
            },
            grey: {
                grey500: '#9E9E9E', // Black/Disabled
                backgroundDark: '#E8E8E8',
                backgroundMain: '#F0F2F5', // auth
                backgroundLight: '#F2F5F8',
                greyDisable: '#C4C4C4',
                greyDivider: '#E5E5E5',
                greyDark: 'rgba(0, 0, 0, 0.24)',
                greyLight: 'rgba(0, 0, 0, 0.04)',
                greyInactive: 'rgba(0, 0, 0, 0.54)',
                greyMediumEmphasis: 'rgba(0, 0, 0, 0.6)',
                greyHighEmphasis: 'rgba(0, 0, 0, 0.87)',
                greyRgba45: 'rgba(0, 0, 0, 0.45)',
                greyRgba12: 'rgba(0, 0, 0, 0.12)',
                greyRgba85: 'rgba(0, 0, 0, 0.85)',
            },
        },
        typography: {
            h1: {
                fontWeight: 500,
                fontSize: '20px',
                lineHeight: '30px',
                letterSpacing: '0.15px',
                color: 'rgba(0, 0, 0, 0.87)',
            },
            button: {
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '16px',
                fontStyle: 'normal',
                textTransform: 'uppercase',
                textAlign: 'center',
                letterSpacing: '1.25px',
                borderRadius: '4px',
            },
        },

    },
};
