import React, { useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import { InputBase } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useIntl } from "react-intl";

const SelectorDate = ({ filter, setFilter }) => {
    const { formatMessage } = useIntl();
    const [indexOption, setIndexOption] = useState(filter.traffic.indexTab);
    const handleChange = (event) => {
        setIndexOption(event.target.value);
        const dateNow = new Date();
        const time = +event.target.value === 1 ? dateNow.setHours(dateNow.getHours() - 1)
            : +event.target.value === 2 ? dateNow.setHours(dateNow.getHours() - 4) :
                +event.target.value === 3 ? dateNow.setDate(dateNow.getDate() - 1) :
                    +event.target.value === 4 ? dateNow.setDate(dateNow.getDate() - 7) :
                        dateNow.setMonth(dateNow.getMonth() - 1);
        setFilter({
            ...filter,
            startDate: time,
            endDate: new Date().setDate(new Date().getDate()),
            traffic: {
                ...filter.traffic,
                indexTab: +event.target.value,
            }
        });
    };

    const CustomInput = withStyles((theme) => ({
        input: {
            position: 'relative',
            border: '1px solid transparent',
            background: 'linear-gradient(180deg, #F6F7FB 0%, #FBFCFD 100%)',
            borderRadius: '3px',
            fontSize: 16,
            padding: '10px 26px 10px 12px',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            '&:focus': {
                borderRadius: 4,
                borderWidth: 2,
                background: 'none',
                border: '1px solid transparent',
            },
        },
    }))(InputBase);

    return (
        <FormControl>
            <NativeSelect
                id="selectDate"
                value={indexOption}
                onChange={handleChange}
                input={<CustomInput />}
            >
                <option value={false} disabled>Custom</option>
                <option value={1}>{formatMessage({ id: 'date.minute' })}</option>
                <option value={2}>{formatMessage({ id: 'date.4hours' })}</option>
                <option value={3}>{formatMessage({ id: 'date.day' })}</option>
                <option value={4}>{formatMessage({ id: 'date.week' })}</option>
                <option value={5}>{formatMessage({ id: 'date.month' })}</option>
            </NativeSelect>
        </FormControl>
    )
};

export default SelectorDate;