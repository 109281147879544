import React, { useState, useEffect, useContext } from 'react';
import {useLazyQuery} from '@apollo/client';
import { PageContext } from "../../../context/pages";
import {useDebouncedEffect} from "../../../hooks/useDebouncedEffect";
import TableResources from "../../../components/global/TableResources";
import {DOSGATE_RESOURCES} from "../../../actions/get";

const DosgateResource = () => {
    const { filterActive, setFilterActive } = useContext(PageContext);
    const [getFilter, { data, loading }] = useLazyQuery(DOSGATE_RESOURCES);
    const getVariables = () => {
        const proxy = {
            limit: filterActive.limit,
            page: filterActive.page + 1,
            name: filterActive?.name || '',
        };
        if (filterActive?.id) proxy.id = Number(filterActive?.id);
        return proxy;
    };

    useEffect(() => {
        getFilter({ variables: getVariables() });
    }, []);

    useDebouncedEffect(
        () => {
            getFilter({ variables: getVariables() });
        },
        1000,
        [filterActive]
    );

    const customData = data && data.dosGateResources.items.map(item => ({...item, ips: item.arborResource.ips }));

    return <TableResources data={customData}
                           filter={filterActive}
                           setFilter={setFilterActive}
                           count={data && data.dosGateResources.info.totalCount}
                           getFilter={getFilter}
                           getVariables={getVariables()}
                           type={'FILTER_ACTIVE'}
                           loading={loading}
    />
};

export default DosgateResource;