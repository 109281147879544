import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    wrapper: {
        display: 'none',
        padding: '0 5px 0 14px',
        borderBottom: '2px solid rgba(0, 0, 0, 0.12)',
        [theme.breakpoints.only('xs')]: {
            display: 'block',
        },
    },
    title: {
        paddingLeft: 20,
        fontWeight: 500,
        color: 'rgba(0, 0, 0, 0.6)',
    },
    icon: {
        color: theme.palette.grey.greyInactive,
        fontSize: '24px',
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    drawer: {
        '& .MuiDrawer-paper': {
            height: 'auto',
        },
    },
}));
