import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles(theme => ({
    area: {
        width: '100%',
        minHeight: '80px',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
        fontWeight: 'normal',
        fontFamily: 'Roboto',
        marginBottom: '15px',
        borderColor: theme.palette.grey.grey500,
        borderRadius: 4,
        '&:focus-visible': {
            outlineColor: theme.palette.primary.main,
            outlineStyle: 'auto',
            outlineWidth: '1px',
        },
    },
}));
