import { StyleSheet} from '@react-pdf/renderer';

export const styles = StyleSheet.create({
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: '9px',
        marginBottom: 10,
    },
    img: {
      maxHeight: 50,
      maxWidth: 120,
    },
    bar: {
        maxHeight: 280,
        maxWidth: 350,
    },
    h1: {
        fontSize: '24px',
        color: 'rgba(0, 0, 0, 0.87)',
        marginBottom: 20,
        fontWeight: 300,
    },
    h2: {
        fontSize: '16px',
        color: 'rgba(0, 0, 0, 0.87)',
        fontWeight: 'heavy',
        marginBottom: 5
    },
    h3: {
        fontSize: '14px',
        color: 'rgba(0, 0, 0, 0.87)',
        fontWeight: 'heavy',
        marginBottom: 5,
    },
    tableSubtitle: {
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        marginBottom: 3,
    },
    grey: {
      color: '#9E9E9E'
    },
    page: {
        padding: '20px 30px',
    },
    section: {
        margin: '0 auto',
        width: '100%',
    },
    container: {
        marginLeft: '-3px',
        marginRight: '-3px',
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'row',
    },
    wrapperBox4: {
        padding: '1px',
        width: '25%',
        marginBottom: 5,
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
    },

    box: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginBottom: 30,
    },
    tableText: {
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '10px',
        color: '#363636',
    },
});
