import { makeStyles } from '@material-ui/core/styles';
import { withStyles, MenuItem } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: '5px 0',
    },
    account: {
        padding: '0 20px',
    },
    menuButton: {
        padding: '10px',
    },
    iconMenu: {
        color: theme.palette.grey.greyInactive,
        fontSize: '26px',
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    avatar: {
        width: 27,
        height: 27,
    },
    menuHeader: {
        padding: '16px 24px',
        minWidth: 250,
    },
    userName: {
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: '30px',
        letterSpacing: '0.15px',
        color: theme.palette.grey.greyHighEmphasis,
    },
    subtitle: {
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: '0.25px',
        color: theme.palette.grey.greyHighEmphasis,
    },
    menuBox: {
        padding: '12px 8px',
    },
    linkItem: {
        textDecoration: 'none',
        color: theme.palette.grey.greyHighEmphasis,
    },
    linkItemActive: {
        textDecoration: 'none',
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.light,
    },
    menuItem: {
        padding: 9,
        borderRadius: 4,
        color: theme.palette.grey.greyInactive,
    },
    buttonBox: {
        padding: '12px 8px',
    },
    button: {
        padding: 10,
        margin: '22px 0',
    },
}));

export const CustomMenuItem = withStyles(theme => ({
    root: {
        padding: '9px 20px',
        borderRadius: 4,
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: '0.1px',
        '&:focus': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.light,
        },
        '&.Mui-selected': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.light,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.primary.main,
            },
        },
    },
}))(MenuItem);
