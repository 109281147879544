import React from 'react';
import { Typography } from '@material-ui/core';
import { AntCard, useStyles } from './styles';

const CardsHead = ({ title, data, starValue }) => {

    const classes = useStyles();
    return (
        <>
            <AntCard variant="outlined">
                <Typography gutterBottom align="center" className={classes.text} noWrap>
                    {data}
                </Typography>
                <Typography variant="body2" gutterBottom noWrap>
                    {title}
                </Typography>
            </AntCard>
        </>
    );
};

export default CardsHead;
