import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    title: {
        marginBottom: 30,
    },
    form: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column',
        maxWidth: 550,
    },
    input: {
        marginBottom: 15,
        width: '100%',
        [theme.breakpoints.only('xs')]: {
            marginBottom: 5,
        },
    },
    buttonWrapper: {
        marginBottom: 30,
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
    },
    button: {
        padding: '15px 30px',
        marginTop: 19,
        [theme.breakpoints.only('xs')]: {
            width: '100%',
            marginTop: 35,
        },
    },
    wrapperContainer: {
        minHeight: 'calc(100vh - 300px)',
        width: '100%',
        [theme.breakpoints.only('xs')]: {
            minHeight: 'calc(100vh - 140px)',
            padding: '25px 16px 5px 16px',
        },
        [theme.breakpoints.only('sm')]: {
            minHeight: 'calc(100vh - 225px)',
        },
    },
    subtitleTwoFactor: {
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 14,
        lineHeight: '21px',
        letterSpacing: 0.25,
        maxWidth: 350,
        marginBottom: 10,
    },
    statusTwoFactor: {
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: 15,
        lineHeight: '21px',
        letterSpacing: 0.25,
        marginBottom: 10,
        '& span': {
            color: theme.palette.grey.grey500,
        }
    },
}));
