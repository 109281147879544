import { gql } from 'graphql-tag';

export const DELETE_CLIENT_ACCOUNT_EMPLOYEE = gql`
    mutation deleteClientAccountEmployee($id: Float!) {
        deleteClientAccountEmployee(id: $id) {
            id
            deletedAt
        }
    }
`;

export const DELETE_L7_RESOURCE = gql`
    mutation deleteL7Resource($id: Float!) {
        deleteL7Resource(id: $id) {
            id
        }
    }
`;

export const DELETE_L7_ORIGIN = gql`
    mutation deleteL7ROrigin(
        $l7ResourceId: Float!
        $originId: Float!
    ) {
        deleteL7ROrigin(input: {
            l7ResourceId: $l7ResourceId
            originId: $originId
        })
    }
`;


export const DELETE_L7_BLACK_LIST = gql`
    mutation deleteL7BlackList($blacklistId: Float!, $l7ResourceId: Float!) {
        deleteL7BlackList(input: { blacklistId: $blacklistId, l7ResourceId: $l7ResourceId })
    }
`;

export const DELETE_L7_WHITE_LIST = gql`
    mutation deleteL7WhiteList($whitelistId: Float!, $l7ResourceId: Float!) {
        deleteL7WhiteList(input: { whitelistId: $whitelistId, l7ResourceId: $l7ResourceId })
    }
`;

export const DELETE_L7_ALIAS = gql`
    mutation deleteL7Alias($aliasId: Float!, $l7ResourceId: Float!) {
        deleteL7Alias(input: { aliasId: $aliasId, l7ResourceId: $l7ResourceId })
    }
`;
