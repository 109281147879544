import React from 'react';
import { useStyles } from './styles';
import {checkHosts} from "../../../../branding";
import {subtitleAuth, titleAuth} from "../../../../branding/header";

const AuthWrapper = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.wrapper}>
            <div className={classes.form}>
                {children}
            </div>
            <div className={classes.bg}>
                <div className={classes.content}>
                    <div className={classes.title}>{checkHosts(window.location.host, titleAuth )}</div>
                    <div className={classes.dsc}>{checkHosts(window.location.host, subtitleAuth )}</div>
                </div>
            </div>

        </div>
    );
};

export default AuthWrapper;
