import { useQuery } from '@apollo/client';
import { Grid, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import { GET_L7_ANALYTICS } from '../../../../actions/get';
import Preloader from '../../../../components/global/Preloader';
import TableScroll from '../../../../components/global/TableScroll';
import { AntTableCell } from '../../../../theme/Table';
import { AntTableCellHead, AntTableCellLabel, useStyles } from '../styles';

const BotsRequests = ({ date, l7ResourceId }) => {
    const classes = useStyles();
    const table = useRef(null);
    const { formatMessage } = useIntl();

    const { data, loading, error } = useQuery(GET_L7_ANALYTICS, {
        variables: {
            from: Math.round(date.startDate / 1000),
            until: Math.round(date.endDate / 1000),
            l7ResourceId: l7ResourceId,
            metric: 'REQUEST_FOR_BOT',
        },
    });

    if (loading) return <Preloader />;

    let x = 0;
    const sumCount = data && data.l7Analytics.map(i => (x += i.c), (x = 0)).reverse()[0];

    return (
        <div className={classes.wrapper}>
            {!loading && (
                <Grid container>
                    <Grid item xs={12}>
                        {error || (data && data.l7Analytics.length === 0) ? (
                            <div className={classes.errorCharts}>{formatMessage({ id: 'charts.error' })}</div>
                        ) : (
                            <TableScroll tableRef={table}>
                                <TableContainer ref={table}>
                                    <Grid container>
                                        <Grid item>
                                            <Typography className={classes.tableTitle}>
                                                {formatMessage({ id: 'webProtection.analytic.botReq.tableTitle' })}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Table style={{width: '50%'}}>
                                        <TableHead>
                                            <TableRow>
                                                <AntTableCellHead colSpan={3}/>
                                            </TableRow>
                                        </TableHead>
                                        <TableHead>
                                            <TableRow>
                                                <AntTableCellLabel />
                                                <AntTableCellLabel className={classes.tableLabel}>count</AntTableCellLabel>
                                                <AntTableCellLabel className={classes.tableLabel}>request</AntTableCellLabel>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data &&
                                                data.l7Analytics.map(({ c, method, path, http_version }, index) => (
                                                    <TableRow key={index}>
                                                        <AntTableCell className={classes.numberStyle}>{index + 1}</AntTableCell>
                                                        <AntTableCell style={{ width: 80 }}>
                                                            {c}&nbsp;(<span className={classes.blueText}>{((c / sumCount) * 100).toFixed(2)}%</span>)
                                                        </AntTableCell>
                                                        <AntTableCell>
                                                            {method}&nbsp;&nbsp;
                                                            {path}&nbsp;&nbsp;
                                                            {http_version}
                                                        </AntTableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </TableScroll>
                        )}
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default BotsRequests;
