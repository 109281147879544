//Применяется в Logo/index.js
import React from 'react';
import wellnessLogo from '../../../assets/img/logo-welness.svg';
import spLogo from '../../../assets/img/spp_logo_main.svg';

export const logosAuth = {
    wellness: (
        <svg width="261" height="89" viewBox="0 0 261 89" fill="none" xmlns="http://www.w3.org/2000/svg">
            <image href={`${wellnessLogo}`} width="261" height="89" />
        </svg>
    ),
    sp: (
        <svg width="261" height="89" viewBox="0 0 261 89" fill="none" xmlns="http://www.w3.org/2000/svg">
            <image href={`${spLogo}`} width="261" height="89" />
        </svg>
    ),
};
