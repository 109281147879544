import React, { useContext } from 'react';
import { useStyles, AntTableCell } from '../../../../../theme/Table';
import { MoreHoriz } from '@material-ui/icons';
import { FormControl, Select } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { UPDATE_CLIENT_ACCOUNT_EMPLOYEE } from '../../../../../actions/update';
import { GET_CLIENT_ACCOUNT_EMPLOYEES, GET_CLIENT_ACCOUNT_EMPLOYEES_ACCESS } from '../../../../../actions/get';
import { toast } from 'react-toastify';
import { PageContext } from '../../../../../context/pages';

const Member = ({ id, first, last, email, role, status, setIndexId, setIndexEmail, setIndexRole, handleClick }) => {
    const classes = useStyles();
    const { membersPage, membersLimit } = useContext(PageContext);

    const [setRole] = useMutation(UPDATE_CLIENT_ACCOUNT_EMPLOYEE);

    const handleChange = event => {
        setRole({
            variables: {
                id: Number(id),
                role: event.target.value,
            },
            refetchQueries: [
                {
                    query: GET_CLIENT_ACCOUNT_EMPLOYEES,
                    variables: {
                        limit: membersLimit,
                        page: membersPage + 1,
                    },
                },
                {
                    query: GET_CLIENT_ACCOUNT_EMPLOYEES_ACCESS,
                    variables: {
                        clientAccountEmployeeId: Number(id),
                    },
                },
            ],
        })
            .then(() => {
                toast.success('Role changed');
            })
            .catch(err => toast.error(err.message));
    };

    return (
        <>
            <AntTableCell>{id}</AntTableCell>
            <AntTableCell>
                {first} {last}
            </AntTableCell>
            <AntTableCell>{email}</AntTableCell>
            <AntTableCell style={{ padding: '0 14px' }}>
                <FormControl variant="outlined" size="small" style={{ padding: 0, maxWidth: 200 }}>
                    <Select
                        style={{
                            border: '2px solid #01CDCB',
                            borderRadius: '4px',
                            '&:hover': {
                                border: '2px solid red',
                            }
                        }}
                        native
                        defaultValue={role === 'MANAGER' ? 'READ_ONLY' : role}
                        inputProps={{
                            name: 'name',
                            id: 'uncontrolled-native',
                        }}
                        onChange={handleChange}
                    >
                        <option value={'ADMIN'}>Administrator</option>
                        <option value={'ENGINEER'}>Engineer</option>
                        <option value={'READ_ONLY'}>Read only</option>
                    </Select>
                </FormControl>
            </AntTableCell>
            <AntTableCell>{status}</AntTableCell>
            <AntTableCell align="right">
                <MoreHoriz
                    className={classes.icon}
                    aria-describedby={id}
                    variant="contained"
                    onClick={e => {
                        handleClick(e);
                        setIndexId(id);
                        setIndexEmail(email);
                        setIndexRole(role);
                    }}
                />
            </AntTableCell>
        </>
    );
};
export default Member;
