import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    logo: {
        marginBottom: 64,
    },
    title: {
        marginBottom: 40,
        fontFamily: 'Manrope',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '35px',
        letterSpacing: '0.25px',
        color: '#041613',
        textAlign: 'left',
        lineHeight: '46px',
    },
    description: {
        marginBottom: 40,
        fontFamily: 'Manrope',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        letterSpacing: '0.5px',
        color: '#041613',
        textAlign: 'left',
        lineHeight: '22px',
        [theme.breakpoints.only('xs')]: {
            padding: 0,
        },
    },
    text: {
        padding: '0 20px',
        marginBottom: 45,
        color: theme.palette.common.black,
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: '0.25px',
        fontStyle: 'normal',
    },
    link: {
        display: 'flex',
        justifyContent: 'center',
        background: '#01CDCB',
        borderRadius: '100px',
        fontFamily: 'Manrope',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '22px',
        textAlign: 'center',
        letterSpacing: '0.5px',
        textTransform: 'uppercase',
        color: '#FFFFFF',
        textDecoration: 'none',
        padding: '15px 0',
        marginBottom: 222,
        '&:hover': {
            background: theme.palette.primary.light,
        },
    },
    footer: {
        fontFamily: 'Manrope',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '22px',
        letterSpacing: '0.5px',
        color: '#828B89',
        marginBottom: 20,
        textAlign: 'left',
    }
}));
