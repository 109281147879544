import React from 'react';
import { useStyles, AntTableCell } from '../../../../../theme/Table';
import { Button } from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';

const Resource = ({ id, name, ips, createdAt, setAnchorEl, setIpsPopover }) => {
    const classes = useStyles();
    const dateFormat = require('dateformat');

    return (
        <>
            <AntTableCell>{id}</AntTableCell>
            <AntTableCell>{name}</AntTableCell>
            <AntTableCell>
                {ips.length === 0 ? (
                    '-'
                ) : ips.length === 1 ? (
                    ips[0]
                ) : (
                    <div>
                        {ips[0]}
                        <Button
                            className={classes.buttonMore}
                            aria-describedby={id}
                            onClick={event => {
                                setAnchorEl(event.currentTarget);
                                setIpsPopover(ips);
                            }}
                        >
                            <span className={classes.buttonMoreSpan}>
                                <MoreHoriz className={classes.buttonMoreIcon} viewBox="3 0 25 5" /> more
                            </span>
                        </Button>
                    </div>
                )}
            </AntTableCell>
            <AntTableCell className={classes.nowrap}>{dateFormat(createdAt, 'mm/dd/yy h:MM:ssTT')}</AntTableCell>
        </>
    );
};
export default Resource;
