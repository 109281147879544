import React, { useRef } from 'react';
import { Dialog, Button, Typography, TableContainer, Table, TableHead, TableRow, TableBody } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from '../../../../theme/Modals';
import { useStyle } from './styles';
import { AntTableCellGrey } from '../../../../theme/Table';
import TableScroll from '../../../global/TableScroll';

const ModalMoreCardsTable = ({ show, hideModal, title, headTitles, renderRow, data }) => {
    const classes = useStyles();
    const style = useStyle();
    const table = useRef(null);

    return (
        <div>
            <Dialog
                className={classes.dialog}
                maxWidth="md"
                open={show}
                onClose={() => {
                    hideModal();
                }}
            >
                <div className={`${classes.modal} ${style.modalWidth}`}>
                    <div className={classes.header}>
                        <Typography variant="h1">{title}</Typography>
                        <Button className={classes.close} onClick={() => hideModal()}>
                            <CloseIcon />
                        </Button>
                    </div>
                    <div className={style.modalBody}>
                        <TableScroll tableRef={table}>
                            <TableContainer className={style.scrollElement} id="scrollElement">
                                <Table stickyHeader className={style.overflow}>
                                    <TableHead>
                                        <TableRow>
                                            {headTitles.map(item => (
                                                <AntTableCellGrey key={item} className={style.tableCellHead}>
                                                    {item}
                                                </AntTableCellGrey>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>{renderRow(data)}</TableBody>
                                </Table>
                            </TableContainer>
                        </TableScroll>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default ModalMoreCardsTable;
