import { hosts } from './head';

export const checkHosts = (host, obj, field) => {
    if (field) {
        if (host.includes(hosts.wellness)) return obj.wellness?.[field];
        if (host.includes(hosts.sp)) return obj.sp?.[field];
        return obj.sp?.[field];
    }
    if (host.includes(hosts.wellness)) return obj.wellness;
    else if (host.includes(hosts.sp)) return obj.sp;
    return obj.sp;
};
