import React, { useState } from 'react';
import { Table, TableBody, TableRow, TableHead, Typography, Grid } from '@material-ui/core';
import { AntTableCellWhite } from '../../../../theme/Table';
import { useStyles } from './styles';
import TableContainer from '@material-ui/core/TableContainer';
import BaseSwitchPpsBps from '../../BaseSwitchPpsBps';
import { BaseButton } from '../../../../theme/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useIntl } from 'react-intl';
import ModalMoreCardsTable from "../../../modals/modalsWebProtection/moreCardsTable";

const CardsTable = ({ title, headTitles, data, renderRow, switchBpsPps, setSwitchBpsPps, name }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();

    const customData = data && data.filter((item, index) => index < 5);
    const [show, setShow] = useState(false);

    return (
        <>
            <ModalMoreCardsTable
                show={show}
                hideModal={() => setShow(false)}
                title={title}
                headTitles={headTitles}
                renderRow={renderRow}
                data={data}
            />
            <div className={classes.itemWrapper}>
                <Grid container className={classes.titleWrapper}>
                    <Grid item xs>
                        <Typography className={classes.title}>{title}</Typography>
                    </Grid>
                    <Grid item>
                        <BaseSwitchPpsBps
                            switchBpsPps={switchBpsPps}
                            setSwitchBpsPps={setSwitchBpsPps}
                            name={name}
                            disabled={data?.length < 1}
                        />
                    </Grid>
                </Grid>

                {data?.length < 1 ? (
                    <div className={classes.noDataText}>{formatMessage({ id: 'anomalyDetailsTraffic.error' })}</div>
                ) : (
                    <>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {headTitles.map((item, index) => (
                                            <AntTableCellWhite key={index} className={classes.tableCellHead}>
                                                {item}
                                            </AntTableCellWhite>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>{renderRow(customData)}</TableBody>
                            </Table>
                        </TableContainer>
                        <div className={classes.buttonWrapper}>
                            {data?.length > 5 && (
                                <BaseButton className={classes.button} onClick={() => setShow(true)}>
                                    <ExpandMoreIcon fontSize="inherit" />
                                    {formatMessage({ id: 'anomalyDetailsTraffic.more' })}
                                </BaseButton>
                            )}
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default CardsTable;
