import React, { useState, useContext } from 'react';
import { CustomInput, useStyles, CustomSelect, CustomMenuItem } from './styles';
import logo from '../../../../../assets/img/sw_logo.png';
import drop from '../../../../../assets/img/drop.svg';
import sup from '../../../../../assets/img/support.svg';
import user from '../../../../../assets/img/user.svg';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import {useLocation} from 'react-router';
import UserPopover from './UserPopover';
import SupportPopover from './SupportPopover';
import { useIntl } from 'react-intl';
import { PageContext } from "../../../../context/pages";
import {checkHosts} from "../../../../branding";
import {logos} from "../../../../branding/header";

const HeaderDesktop = ({ dosGateLimit, firstName, lastName, email, l3Limit, l7Limit, clientAccountId, userId, query, updateLang, lang, setLang, role }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const { deleteFilter, deleteSecondaryFilter } = useContext(PageContext);
    const [userAnchorEl, setUserAnchorEl] = useState(null);
    const [supportAnchorEl, setSupportAnchorEl] = useState(null);
    const location = useLocation();

    const handleChange = event => {
        setLang(event.target.value);
        updateLang({
            variables: {
                lang: event.target.value,
                id: userId,
            },
            refetchQueries: [
                {
                    query: query,
                    variables: {
                        id: userId,
                    },
                },
            ],
        });
    };

    const openUser = Boolean(userAnchorEl);
    const idUser = openUser ? 'user' : undefined;
    const openSupport = Boolean(supportAnchorEl);
    const idSupport = openSupport ? 'support' : undefined;

    return (
        <>
            <UserPopover
                id={idUser}
                open={openUser}
                anchorEl={userAnchorEl}
                handleClose={() => setUserAnchorEl(null)}
                firstName={firstName}
                lastName={lastName}
                email={email}
                l3Limit={l3Limit}
                dosGateLimit={dosGateLimit}
                role={role}
            />
            <SupportPopover id={idSupport} open={openSupport} anchorEl={supportAnchorEl} handleClose={() => setSupportAnchorEl(null)} />
            <Grid container className={classes.headerItems}>
                <div className={classes.menuHeader}>
                    <div className={classes.imgBoxLogo}>
                        <img
                            src={ checkHosts(window.location.host, logos ) }
                            alt="#"
                            style={{ width: 35}}
                        />
                    </div>
                    <div>
                        <Link
                            to={`/dashboard`}
                            onClickCapture={() => {
                                deleteFilter();
                                deleteSecondaryFilter();
                            }}
                            className={location.pathname.includes('dashboard') ? `${classes.menuLinkActive} ${classes.menuLink}` : `${classes.menuLink}`}
                        >
                            {formatMessage({ id: 'headerTab.dashboard' })}
                        </Link>
                        {l3Limit !== 0 && l3Limit !== null && (
                            <Link
                                to={`/l3-protection/0`}
                                onClickCapture={() => {
                                    deleteFilter();
                                    deleteSecondaryFilter();
                                }}
                                className={location.pathname.includes('l3-protection') ? `${classes.menuLinkActive} ${classes.menuLink}` : `${classes.menuLink}`}
                            >
                                {formatMessage({ id: 'headerTab.l3protection' })}
                            </Link>
                        )}
                        {l7Limit !== 0 && l7Limit !== null && (
                            <Link
                                to={`/web-protection/0`}
                                onClickCapture={() => {
                                    deleteFilter();
                                    deleteSecondaryFilter();
                                }}
                                className={location.pathname.includes('web-protection') ? `${classes.menuLinkActive} ${classes.menuLink}` : `${classes.menuLink}`}
                            >
                                {formatMessage({ id: 'headerTab.webProtection' })}
                            </Link>
                        )}
                        {dosGateLimit !== 0 && dosGateLimit !== null && (
                            <Link
                                to={`/dosgate/0`}
                                onClickCapture={() => {
                                    deleteFilter();
                                    deleteSecondaryFilter();
                                }}
                                className={location.pathname.includes('dosgate') ? `${classes.menuLinkActive} ${classes.menuLink}` : `${classes.menuLink}`}
                            >
                                {formatMessage({ id: 'headerTab.l3protection20' })}
                            </Link>
                        )}
                    </div>
                </div>
                <div className={classes.navBar}>
                    {/*<div className={`${classes.navBarItemHover} ${classes.navBarItem}`}>*/}
                    {/*    <div className={classes.lang}>*/}
                    {/*        <CustomSelect*/}
                    {/*            value={lang}*/}
                    {/*            onChange={handleChange}*/}
                    {/*            MenuProps={{*/}
                    {/*                anchorOrigin: {*/}
                    {/*                    vertical: 'bottom',*/}
                    {/*                    horizontal: 'center',*/}
                    {/*                },*/}
                    {/*                transformOrigin: {*/}
                    {/*                    vertical: 'top',*/}
                    {/*                    horizontal: 'center',*/}
                    {/*                },*/}
                    {/*                getContentAnchorEl: null,*/}
                    {/*            }}*/}
                    {/*            input={<CustomInput />}*/}
                    {/*        >*/}
                    {/*            <CustomMenuItem value={'RU'}>RU</CustomMenuItem>*/}
                    {/*            <CustomMenuItem value={'EN'}>EN</CustomMenuItem>*/}
                    {/*        </CustomSelect>*/}
                    {/*        <div className={classes.langIcon}>*/}
                    {/*            <img src={drop} alt="#" className={classes.dropDown} />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*<div*/}
                    {/*    className={`${classes.navBarItemHover} ${classes.navBarItem}`}*/}
                    {/*    aria-describedby={idSupport}*/}
                    {/*    onClick={event => {*/}
                    {/*        setSupportAnchorEl(event.currentTarget);*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <p className={classes.support}>*/}
                    {/*        <img src={sup} alt="#" className={classes.dropDown} />*/}
                    {/*        <img src={drop} alt="#" className={classes.dropDown} />*/}
                    {/*    </p>*/}
                    {/*</div>*/}

                    <div className={classes.navBarItem}>
                        <p>
                            {formatMessage({ id: 'header.account' })} {clientAccountId}
                        </p>
                    </div>
                    <div
                        className={classes.imgBoxUser}
                        aria-describedby={idUser}
                        onClick={event => {
                            setUserAnchorEl(event.currentTarget);
                        }}
                    >
                        <img src={user} alt="#" className={classes.dropDown} />
                        <img src={drop} alt="#" className={classes.dropDown} />
                    </div>
                </div>
            </Grid>
        </>
    );
};

export default HeaderDesktop;
