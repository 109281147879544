import React from 'react';
import {View, Text, Image} from '@react-pdf/renderer';
import { styles } from '../../styles';
import {valueTimeCalculation} from "../../../../../../../helpers/calculation";
import PdfAlertInfo from "../PdfAlertInfo";

const FirstPage = ({ store, data }) => {
    const anomaly = store?.arborAlerts?.items[0];
    const ChartJsImage = require('chartjs-to-image');
    const dateFormat = require('dateformat');
    const points = data && [...data?.arborAlertDetail?.packet_distribution];
    const pointsArrForBar = data && points.slice(0, points.length - 1);
    const lastPoint = data && data.arborAlertDetail.packet_distribution[data.arborAlertDetail.packet_distribution.length - 1];
    let pointsGroup = [];

    if (points && points.length >= 1) {
        pointsGroup = pointsArrForBar.reduce((acc, item, index) => {
            const proxy = [...acc];
            if (index === 0 || index % 3 === 0) {
                proxy.push(item.sample.reduce((a, b) => Number(a) + Number(b), 0));
            } else {
                proxy[proxy.length - 1] = item.sample.reduce((a, b) => Number(a) + Number(b), 0) + proxy[proxy.length - 1];
            }
            return proxy;
        }, []);
    }

    const myChart = new ChartJsImage();
    myChart.setConfig({
        type: 'bar',
        data: {
            labels: [
                '0-150',
                '151-300',
                '301-450',
                '451-600',
                '601-750',
                '751-900',
                '901-1050',
                '1051-1200',
                '1201-1350',
                '1351-1500',
                'jumbo frames'
            ],
            datasets: [
                {
                    label: 'size',
                    data: [...pointsGroup, lastPoint],
                    backgroundColor: '#01D9C9',
                    borderWidth: 3,
                    borderColor: 'transparent',
                },
            ],
        },
        options: {
            indexAxis: 'y',
            scales: {
                yAxes: [
                    {
                        ticks: {
                            callback: function (value) {
                                if (value >= 1000000) {
                                    return +(value / 1000000).toFixed(2) + `M`;
                                }
                                return +(value / 1000).toFixed(2) + ` K`;
                            },
                        },
                    },
                ],
            },
            plugins: {
                legend: {
                    display: false,
                },
            },
        },
    });

    return (
        <View>
            <Text style={styles.h1}>Anomaly report</Text>
            <View style={styles.box}>
                <View>
                    <Text style={styles.h2}>Anonaly ID: {anomaly?.alertId} </Text>
                </View>
                <View>
                    <Text style={styles.h2}>Start: {dateFormat(anomaly?.start, 'dd.mm.yy h:MM:ssTT')} </Text>
                    <Text style={styles.h2}> Stop: {dateFormat(anomaly?.stop, 'dd.mm.yy h:MM:ssTT')}</Text>
                    <Text style={styles.h2}> Duration: {valueTimeCalculation(anomaly?.duration)}</Text>
                </View>
            </View>
            <View style={{marginBottom: 30}}>
                <Text style={styles.h2}>
                    Resource name: {anomaly?.alertManagedObjectName}
                </Text>
            </View>
            <View style={{marginBottom: 30}}>
                <PdfAlertInfo data={data} store={anomaly}/>
            </View>
            <View>
                <Text style={styles.h2}>
                    Packet Size Distribution
                </Text>
                <View>
                    <Image src={myChart.toDataUrl()} style={styles.bar}/>
                </View>
            </View>
        </View>

    )

};

export default FirstPage;