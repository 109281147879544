import React from 'react';

import ApplicationBoxes from './Component.js'

const ConnectedApplicationBoxes = (props) => {
    return (
        <ApplicationBoxes {...props}/>
    )
}

export default ConnectedApplicationBoxes