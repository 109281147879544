import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    title: {
        marginBottom: 60,
        [theme.breakpoints.only('xs')]: {
            marginBottom: 10,
        },
    },
    group: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        marginBottom: 35,
        [theme.breakpoints.only('xs')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            marginBottom: 15,
        },
    },
    label: {
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '30px',
        letterSpacing: '0.15px',
        color: theme.palette.common.black,
        marginBottom: 10,
    },
    auto: {
        marginBottom: 40,
    },
    button: {
        marginBottom: 30,
        padding: '15px 50px',
        [theme.breakpoints.only('xs')]: {
            marginBottom: 15,
        },
    },
}));
