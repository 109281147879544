import React from 'react';
import { useStyles, AntTableCell } from '../../../../../theme/Table';
import { Chip } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useIntl } from 'react-intl';

const Resource = ({ id, name, ip, created, handleClick, setIndex, iauthDisableL7, serviceAbpMode }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const dateFormat = require('dateformat');

    return (
        <>
            <AntTableCell component="th" scope="row">
                {id}
            </AntTableCell>
            <AntTableCell align="left">{name}</AntTableCell>
            <AntTableCell align="left">{ip}</AntTableCell>
            <AntTableCell align="left">
                {iauthDisableL7 === 1 ? (
                    <Chip label={formatMessage({ id: 'webProtection.resource.dis' })} style={{ padding: '0 20px' }} />
                ) : (
                    <Chip
                        label={formatMessage({ id: 'webProtection.resource.active' })}
                        style={{ color: '#00C853', background: '#ECF9F3', padding: '0 20px' }}
                    />
                )}
            </AntTableCell>
            <AntTableCell align="left">
                {serviceAbpMode === 0 ? (
                    <Chip label={formatMessage({ id: 'webProtection.resource.dis' })} style={{ padding: '0 20px' }} />
                ) : (
                    <Chip
                        label={formatMessage({ id: 'webProtection.resource.active' })}
                        style={{ color: '#00C853', background: '#ECF9F3', padding: '0 20px' }}
                    />
                )}
            </AntTableCell>
            <AntTableCell align="left">{dateFormat(created * 1000, 'mm/dd/yy h:MM:ssTT')}</AntTableCell>
            <AntTableCell align="center">
                <MoreHorizIcon
                    className={classes.icon}
                    aria-describedby={id}
                    variant="contained"
                    onClick={e => {
                        handleClick(e);
                        setIndex(id);
                    }}
                />
            </AntTableCell>
        </>
    );
};
export default Resource;
