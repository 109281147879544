import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { TextField, Button, Grid, Typography } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/client';
import { GET_L7_RESOURCE, GET_L7_RESOURCE_VENDOR } from '../../../actions/get';
import { UPDATE_L7_RESOURCES } from '../../../actions/update';
import ModalEditSSLCertificate from '../../../components/modals/modalsWebProtection/editSSL';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import Preloader from '../../../components/global/Preloader';
import withWidth from '@material-ui/core/withWidth';
import GeneralSettingsSwitchers from './Switchers';
import GeneralSettingsOrigins from './Origins';

const GeneralSettings = ({ width }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const { handleSubmit, control } = useForm();
    const { id } = useParams();
    const dateFormat = require('dateformat');

    const [isEdit, setIsEdit] = useState({
        edit: false,
        aliasId: '',
    });

    const [ssl, setSsl] = useState('None');
    const [objSslKey, setObjSslKey] = useState({
        key: '',
        crt: '',
    });

    const [switches, setSwitches] = useState({
        serviceWwwredir: true,
        serviceGlobalWhitelist: true,
        serviceForcessl: true,
        serviceHttps2http: true,
        iauthDisableL7: true,
    });

    const [stateSwitch, setStateSwitch] = useState({
        serviceIphash: true,
    });

    const { data: Vendor, loading: load } = useQuery(GET_L7_RESOURCE_VENDOR, {
        variables: {
            l7ResourceId: Number(id),
        },
    });

    const { data: resource, loading: loadRes } = useQuery(GET_L7_RESOURCE, {
        variables: {
            l7ResourceId: Number(id),
        },
    });

    useEffect(() => {
        if (Vendor) {
            setSwitches({
                serviceWwwredir: Boolean(Vendor.l7ResourceVendor.serviceWwwredir),
                serviceGlobalWhitelist: Boolean(Vendor.l7ResourceVendor.serviceGlobalWhitelist),
                serviceForcessl: Boolean(Vendor.l7ResourceVendor.serviceForcessl),
                serviceHttps2http: Boolean(Vendor.l7ResourceVendor.serviceHttps2http),
                iauthDisableL7: !Boolean(Vendor.l7ResourceVendor.iauthDisableL7),
            });
            setStateSwitch({
                serviceIphash: Boolean(Vendor.l7ResourceVendor.serviceIphash),
            });
            if (!Vendor.l7ResourceVendor.serviceSsl && !Vendor.l7ResourceVendor.serviceSslAuto) {
                setSsl('None');
            } else if (Vendor.l7ResourceVendor.serviceSslAuto) {
                setSsl('Let’s Encrypt');
            } else if (Vendor.l7ResourceVendor.serviceSsl) {
                setSsl('Custom');
                setObjSslKey({
                    key: Vendor.l7ResourceVendor.serviceSslKey,
                    crt: Vendor.l7ResourceVendor.serviceSslCrt,
                });
            }
        }
    }, [Vendor]);

    const [updateL7Resources] = useMutation(UPDATE_L7_RESOURCES);

    const handleChange = event => {
        const getVariables = () => {
            const proxy = {
                serviceId: Number(id),
            };
            if (event.target.name === 'iauthDisableL7') proxy[event.target.name] = event.target.checked ? 0 : 1;
            else proxy[event.target.name] = event.target.checked ? 1 : 0;
            return proxy;
        };
        setSwitches({ ...switches, [event.target.name]: event.target.checked });
        updateL7Resources({
            variables: getVariables()
        })
            .then(() => toast.success('Success'))
            .catch(err => toast.error(err.message));
    };

    const handleChangeHash = event => {
        setStateSwitch({ ...stateSwitch, [event.target.name]: event.target.checked });
        updateL7Resources({
            variables: {
                serviceId: Number(id),
                [event.target.name]: event.target.checked ? 1 : 0,
            },
        })
            .then(() => toast.success('Success'))
            .catch(err => toast.error(err.message));
    };

    const onSubmit = data => {
        updateL7Resources({
            variables: {
                serviceId: Number(id),
                serviceName: data.nameResource,
            },
            refetchQueries: [
                {
                    query: GET_L7_RESOURCE_VENDOR,
                    variables: {
                        l7ResourceId: Number(id),
                    },
                },
            ],
        })
            .then(() => toast.success('Success'))
            .catch(err => toast.error(err.message));
    };

    if (loadRes && load) return <Preloader />;

    return (
        <>
            <ModalEditSSLCertificate
                show={isEdit.edit}
                id={isEdit.aliasId}
                l7ResourceId={Number(id)}
                hideModal={() => setIsEdit(!isEdit.edit)}
                certificate={objSslKey}
                ssl={ssl}
                type={'general'}
            />
            <Typography variant="h1" className={classes.title}>
                {formatMessage({ id: 'webProtection.general.title' })}
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container className={classes.settings}>
                    <Grid container>
                        <Grid item xs={12} md={7}>
                            <div className={classes.formTitle}>{formatMessage({ id: 'webProtection.general.domen' })}</div>
                            <div className={classes.inputDomain}>
                                <Grid container spacing={2}>
                                    <Grid item xs>
                                        {Vendor && Vendor.l7ResourceVendor && (
                                            <Controller
                                                name="nameResource"
                                                control={control}
                                                defaultValue={Vendor.l7ResourceVendor.serviceName}
                                                render={({ field: { onChange, value } }) => (
                                                    <TextField
                                                        className={classes.input}
                                                        size="small"
                                                        onChange={onChange}
                                                        value={value}
                                                        placeholder="servicepipe.ru"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item>
                                        <Button color="primary" variant="outlined" className={classes.button} type="submit">
                                            save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <div className={classes.formTitle}>{formatMessage({ id: 'webProtection.general.ssl' })}</div>
                            <Grid container spacing={2}>
                                <Grid item xs>
                                    <Controller
                                        name="ssl"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <TextField size="small" onChange={onChange} disabled value={ssl} variant="outlined" />
                                        )}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        className={classes.button}
                                        onClick={() =>
                                            setIsEdit({
                                                edit: true,
                                                aliasId: id,
                                            })
                                        }
                                    >
                                        {formatMessage({ id: 'webProtection.general.edit' })}
                                    </Button>
                                </Grid>
                                {
                                    ssl === 'Custom' &&
                                    <div className={classes.exp}>
                                        Expiration date: {dateFormat(Vendor?.l7ResourceVendor?.serviceSslExpire * 1000, 'dd/mm/yy h:MM:ssTT')}
                                    </div>
                                }
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Title origin */}
                    <Grid container>
                        <Grid item xs={4} md={7} className={classes.formTitle}>
                            {formatMessage({ id: 'webProtection.general.ip' })}
                        </Grid>
                        <Grid item container xs spacing={1}>
                            <Grid item xs={4} className={classes.formTitle}>
                                {formatMessage({ id: 'webProtection.general.type' })}
                            </Grid>
                            <Grid item xs={4} className={classes.formTitle}>
                                {formatMessage({ id: 'webProtection.general.weight' })}
                            </Grid>
                        </Grid>
                    </Grid>
                    <GeneralSettingsOrigins id={id} />
                    <GeneralSettingsSwitchers handleChange={handleChange} resourceName={Vendor && Vendor.l7ResourceVendor.serviceName} switches={switches} />
                </Grid>
            </form>
        </>
    );
};

export default withWidth()(GeneralSettings);
