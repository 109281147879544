import React, {useEffect} from "react";
import {useStyles} from "./styles";
import {useIntl} from "react-intl";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Grid, Typography} from "@material-ui/core";
import TableDateTabs from "../../../components/global/TableDateTabs";
import TableDatePicker from "../../../components/global/TableDatePicker";
import AreaAllTraffic from "../../../components/charts/AreaAllTraffic";
import NetworkBoxes from "./NetworkBoxes";
import PropTypes from "prop-types";

const DashboardNetworkPage = ({ clientAccountId, setFilterActive, getAllL7Traffic, filterActive, handleQuery }) => {
    const { data, loading, error} = handleQuery;
    const classes = useStyles();
    const { formatMessage } = useIntl();

    useEffect(() => {
        getAllL7Traffic();
        setFilterActive({...filterActive, activeZoomChart: true});
    }, []);

    if (loading)
        return (
            <div className={classes.circular}>
                <CircularProgress size="10rem" color="primary" />
            </div>
        );
    if (error || data?.arborGraphiteTrafficForAllResources?.timestamp.length < 1)
        return <div className={classes.errorCharts}>{formatMessage({ id: 'charts.error' })}</div>;

    return (
        <Grid container spacing={2} >
            <Grid item xs={12} className={classes.container}>
                <div>
                    <Typography variant="h1" className={classes.headerTitle}>
                        {formatMessage({ id: 'dashboard.net.title' })}
                    </Typography>
                </div>
                <div className={classes.date}>
                    <TableDateTabs filter={filterActive} setFilter={setFilterActive} />
                    <TableDatePicker filter={filterActive} setFilter={setFilterActive} />
                </div>
            </Grid>
            <Grid item xs={11} style={{ backgroundColor: '#F6F7FB'}}>
                <AreaAllTraffic data={data}
                                getAllL7Traffic={getAllL7Traffic}
                                clientAccountId={clientAccountId}
                                activeZoom={filterActive}
                                setActiveZoom={setFilterActive}
                                unit={'BPS'}
                />
            </Grid>
            <Grid xs={1} style={{ backgroundColor: '#F6F7FB'}}>
                <NetworkBoxes date={filterActive} store={data} />
            </Grid>
        </Grid>
    );
};

DashboardNetworkPage.propTypes = {
    clientAccountId: PropTypes.number.isRequired,
    setFilterActive: PropTypes.func.isRequired,
    getAllL7Traffic: PropTypes.func.isRequired,
    filterActive: PropTypes.object.isRequired,
    handleQuery: PropTypes.object.isRequired

}

export default DashboardNetworkPage;