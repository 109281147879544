import { makeStyles } from '@material-ui/core/styles';
import {Tabs, TextField, withStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    bar: {
        background: theme.palette.common.white,
        marginBottom: 30,
        borderTopWidth: 0,
        borderBottomWidth: '2px',
        borderColor: theme.palette.grey.greyRgba12,
        borderBlockStyle: 'solid',
    },
    cardTitle: {
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#363636',
        marginLeft: 27,
        marginBottom: 25,
    },
    chartBox: {
        padding: 15,
        backgroundColor: '#F6F7FB',
        marginBottom: 10,
    },
    headerTitle: {
        marginBottom: 30,
    },
    date: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 30,
    },
    select: {
        width: '100%',
        maxWidth: '450px',
        padding: '5px 5px 5px 5px',
        border: 'none',
        '&:focus': {
            background: 'none',
        },
    },
    closeIcon: {
        '& .MuiAutocomplete-clearIndicator': {
            display: 'none',
        },
        '& .MuiAutocomplete-popupIndicator': {
            marginTop: '3px',
        },
    },
    tabs: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginBottom: 30,
    },
    wrapperTable: {
        padding: '25px',
        [theme.breakpoints.only('xs')]: {
            padding: '25px 16px 25px 16px',
        },
    },
}));

export const MyTabs = withStyles(theme => ({
    indicator: {
        backgroundColor: theme.palette.primary.main,
    },
    flexContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}))(Tabs);

export const TrafficTextField = withStyles({
    root: {
        background: 'linear-gradient(180deg, #F6F7FB 0%, #FBFCFD 100%)',
        borderRadius: '3px',
        '& .MuiInput-underline:after': {
            border: '1px solid transparent',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: '1px solid transparent',
            },
            '&:hover fieldset': {
                border: '1px solid transparent',
            },
            '&.Mui-focused fieldset': {
                border: '1px solid transparent',
            },
        },
    },
})(TextField);