import React, { useRef } from 'react';
import { TableContainer, Table, TableHead, TableRow, TableBody, Button, Typography } from '@material-ui/core';
import { MoreHoriz, Description } from '@material-ui/icons';
import { useStyles, AntTableCell } from '../../../../theme/Table';
import TableScroll from '../../../../components/global/TableScroll';
import { valueCalculation, valueTimeCalculation } from '../../../../helpers/calculation';
import { saveState } from '../../../../helpers/localStorage';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

const TableAnomaliesTraffic = ({ setAnchorElType, setPopoverValueType, data }) => {
    const { formatMessage } = useIntl();
    const classes = useStyles();
    const table = useRef(null);
    const dateFormat = require('dateformat');
    const customData = data?.arborAlerts?.items?.slice(0, 10);

    return data?.arborAlerts?.items?.length >= 1 ? (
        <TableScroll tableRef={table}>
            <TableContainer ref={table}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <AntTableCell className={classes.title}>ID</AntTableCell>
                            <AntTableCell className={classes.title}>{formatMessage({ id: 'reportsTableAnomaliesTraffic.severity' })}</AntTableCell>
                            <AntTableCell className={classes.title}>{formatMessage({ id: 'reportsTableAnomaliesTraffic.ip' })}</AntTableCell>
                            <AntTableCell className={classes.title}>{formatMessage({ id: 'reportsTableAnomaliesTraffic.type' })}</AntTableCell>
                            <AntTableCell className={classes.title}>{formatMessage({ id: 'reportsTableAnomaliesTraffic.name' })}</AntTableCell>
                            <AntTableCell className={classes.title}>{formatMessage({ id: 'reportsTableAnomaliesTraffic.start' })}</AntTableCell>
                            <AntTableCell className={classes.title}>{formatMessage({ id: 'reportsTableAnomaliesTraffic.ended' })}</AntTableCell>
                            <AntTableCell className={classes.title}>{formatMessage({ id: 'reportsTableAnomaliesTraffic.duration' })}</AntTableCell>
                            <AntTableCell className={classes.title}>{formatMessage({ id: 'reportsTableAnomaliesTraffic.volume' })}</AntTableCell>
                            <AntTableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {customData.map(
                            ({
                                alertId,
                                importance,
                                alertResourceCidr,
                                alertManagedObjectName,
                                misuseTypes,
                                start,
                                stop,
                                duration,
                                maxImpactBps,
                                maxImpactPps,
                                id,
                                severityPercent,
                                threshold,
                                unit,
                                impactBpsPoints,
                                impactPpsPoints,
                                impactRecorded,
                            }) => (
                                <TableRow key={alertId}>
                                    <AntTableCell>{alertId}</AntTableCell>
                                    <AntTableCell>{importance}</AntTableCell>
                                    <AntTableCell>{alertResourceCidr}</AntTableCell>
                                    <AntTableCell>
                                        {misuseTypes.length === 1 ? (
                                            misuseTypes[0]
                                        ) : (
                                            <div>
                                                {misuseTypes[0]}
                                                <Button
                                                    className={classes.buttonMore}
                                                    aria-describedby={alertId}
                                                    onClick={event => {
                                                        setAnchorElType(event.currentTarget);
                                                        setPopoverValueType(misuseTypes);
                                                    }}
                                                >
                                                    <span className={classes.buttonMoreSpan}>
                                                        <MoreHoriz className={classes.buttonMoreIcon} viewBox="3 0 25 5" /> more
                                                    </span>
                                                </Button>
                                            </div>
                                        )}
                                    </AntTableCell>
                                    <AntTableCell>{alertManagedObjectName}</AntTableCell>
                                    <AntTableCell>{dateFormat(start, 'dd/mm/yy h:MM:ssTT')}</AntTableCell>
                                    <AntTableCell>{stop ? dateFormat(stop, 'dd/mm/yy h:MM:ssTT') : 'Ongoing'}</AntTableCell>
                                    <AntTableCell>
                                        {stop ? valueTimeCalculation(duration) : valueTimeCalculation((new Date() - new Date(start)) / 1000)}
                                    </AntTableCell>
                                    <AntTableCell>{valueCalculation(maxImpactBps, 'bps')}</AntTableCell>
                                    <AntTableCell>
                                        <Link
                                            to={`/anomaly-details/l3-protection/${alertId}/4`}
                                        >
                                            <Description color="action" className={classes.icon} />
                                        </Link>
                                    </AntTableCell>
                                </TableRow>
                            )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </TableScroll>
    ) : (
        <Typography variant="h1" className={classes.noData}>
            NO DATA
        </Typography>
    );
};

export default TableAnomaliesTraffic;
