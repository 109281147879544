import React from 'react';
import { Pie } from 'react-chartjs-2';

const PieBotsOS = ({ data }) => {
    const options = {
        stacked100: { enable: true },
        responsive: true,
        animation: false,
        plugins: {
            crosshair: false,
            legend: false,
            filler: {
                propagate: false,
            },
            tooltip: {
                animation: false,
                callbacks: {
                    label: function (context) {
                        return `${context.label}: ${context.raw} Requests`;
                    },
                },
            },
        },
    };

    const series = {
        labels: data && data.map(item => item.ua_os),
        datasets: [
            {
                data: data && data.map(item => item.c),
                backgroundColor: ['#FF4069', '#36A2EB', '#FFC534', '#22CFCF', '#8142FF', '#FF9020', '#0080FF', '#0174DF', '#045FB4', '#A9E2F3'],
                hoverOffset: 4,
            },
        ],
    };

    return (
        <>
            <Pie data={series} options={options} />
        </>
    );
};

export default PieBotsOS;
