import { useQuery } from '@apollo/client';
import { Grid, Table, TableBody, Typography } from '@material-ui/core';
import React from 'react';
import { GET_L7_ANALYTICS } from '../../../../actions/get';
import PieBotsOS from '../../../../components/charts/PieBotsOS';
import Preloader from '../../../../components/global/Preloader';
import { useIntl } from 'react-intl';
import { useStyles, StyledTableRow, StyledTableCell } from './styles';

const OsDistribution = ({ date, l7ResourceId }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();

    const {
        data: l7AnalyticsUaOsBot,
        loading: loadingL7UaOsBot,
        error: errorL7UaOsBot,
    } = useQuery(GET_L7_ANALYTICS, {
        variables: {
            from: Math.round(date.startDate / 1000),
            until: Math.round(date.endDate / 1000),
            l7ResourceId: l7ResourceId,
            metric: 'REQUEST_UA_OS_FOR_BOT',
        },
    });

    const {
        data: l7AnalyticsUaOsHuman,
        loading: loadingL7UaOsHuman,
        error: errorL7UaOsHuman,
    } = useQuery(GET_L7_ANALYTICS, {
        variables: {
            from: Math.round(date.startDate / 1000),
            until: Math.round(date.endDate / 1000),
            l7ResourceId: l7ResourceId,
            metric: 'REQUEST_UA_OS_FOR_HUMAN',
        },
    });

    if (loadingL7UaOsBot && loadingL7UaOsHuman) return <Preloader />;

    let x = 0;
    const sumCountUaOsBot = l7AnalyticsUaOsBot && l7AnalyticsUaOsBot.l7Analytics.map(i => (x += i.c), (x = 0)).reverse()[0];
    const sumCountUaOsHuman = l7AnalyticsUaOsHuman && l7AnalyticsUaOsHuman.l7Analytics.map(i => (x += i.c), (x = 0)).reverse()[0];

    const backgroundColor = ['#FF4069', '#36A2EB', '#FFC534', '#22CFCF', '#8142FF', '#FF9020', '#0080FF', '#0174DF', '#045FB4', '#A9E2F3'];

    return (
        <div className={classes.wrapper}>
            {!loadingL7UaOsBot && !loadingL7UaOsHuman && (
                <Grid container>
                    <Grid item xs={12} lg={6}>
                        <div className={classes.itemWrapper}>
                            {errorL7UaOsBot || (l7AnalyticsUaOsBot && l7AnalyticsUaOsBot.l7Analytics.length === 0) ? (
                                <div className={classes.errorCharts}>{formatMessage({ id: 'charts.error' })}</div>
                            ) : (
                                <>
                                    <Typography variant="h1" align="center" className={classes.title}>
                                        {formatMessage({ id: 'webProtection.analytic.osDist.titleBot' })}
                                    </Typography>
                                    <Grid container>
                                        <Grid item>
                                            <div className={classes.puiWrapper}>
                                                <PieBotsOS data={l7AnalyticsUaOsBot && l7AnalyticsUaOsBot.l7Analytics} />
                                            </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Table className={classes.tableWrapper}>
                                                <TableBody>
                                                    {l7AnalyticsUaOsBot &&
                                                        l7AnalyticsUaOsBot.l7Analytics.map(({ c, ua_os }, index) => (
                                                            <StyledTableRow key={index} className={classes.lineColorWrapper}>
                                                                <StyledTableCell>
                                                                    <div
                                                                        style={{ backgroundColor: `${backgroundColor[index]}` }}
                                                                        className={classes.lineColor}>
                                                                    </div>
                                                                </StyledTableCell>
                                                                <StyledTableCell>{ua_os}</StyledTableCell>
                                                                <StyledTableCell>{((c / sumCountUaOsBot) * 100).toFixed(2)}&nbsp;%</StyledTableCell>
                                                            </StyledTableRow>
                                                        ))}
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <div className={classes.itemWrapper}>
                            {errorL7UaOsHuman || (l7AnalyticsUaOsHuman && l7AnalyticsUaOsHuman.l7Analytics.length === 0) ? (
                                <div className={classes.errorCharts}>{formatMessage({ id: 'charts.error' })}</div>
                            ) : (
                                <>
                                    <Typography variant="h1" align="center" className={classes.title}>
                                        {formatMessage({ id: 'webProtection.analytic.osDist.titleUser' })}
                                    </Typography>
                                    <Grid container>
                                        <Grid item>
                                            <div className={classes.puiWrapper}>
                                                <PieBotsOS data={l7AnalyticsUaOsHuman && l7AnalyticsUaOsHuman.l7Analytics} />
                                            </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Table className={classes.tableWrapper}>
                                                <TableBody>
                                                    {l7AnalyticsUaOsHuman &&
                                                        l7AnalyticsUaOsHuman.l7Analytics.map(({ c, ua_os }, index) => (
                                                            <StyledTableRow key={index} className={classes.lineColorWrapper}>
                                                                <StyledTableCell>
                                                                    <div
                                                                        style={{ backgroundColor: `${backgroundColor[index]}` }}
                                                                        className={classes.lineColor}>
                                                                    </div>
                                                                </StyledTableCell>
                                                                <StyledTableCell>{ua_os}</StyledTableCell>
                                                                <StyledTableCell>{((c / sumCountUaOsHuman) * 100).toFixed(2)}&nbsp;%</StyledTableCell>
                                                            </StyledTableRow>
                                                        ))}
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </div>
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default OsDistribution;
