import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    cardsHeaderWrapper: {
        display: 'flex',
    },
    wrapper: {
      padding: 30
    },
    titleWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    tablesWrapper: {
        marginTop: 40,
        padding: 10,
    },
    download: {
        background: 'transparent',
        border: 'none',
        color: '#26BF83',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '15px',
        lineHeight: '18px',
        letterSpacing: '0.4px',
        cursor: 'pointer',
    },
}));

