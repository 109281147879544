import React from 'react';
import { Dialog, Button, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from '../../../../theme/Modals';
import { useMutation } from "@apollo/client";
import { DEACTIVE_TWO_FACTOR_AUTH } from "../../../../actions/update";
import {toast} from "react-toastify";

const ModalTwoStepVerificationDisabled = ({ show, hideModal, query, token }) => {
    const classes = useStyles();
    const [deActiveTwoFactorAuth] = useMutation(DEACTIVE_TWO_FACTOR_AUTH)

    return (
        <div>
            <Dialog
                className={classes.dialog}
                maxWidth="md"
                open={show}
                onClose={() => {
                    hideModal();
                }}
            >
                <div className={classes.modal}>
                    <div className={classes.header}>
                        <Typography variant="h1">Disable 2-Step Verification</Typography>
                        <Button className={classes.close} onClick={() => hideModal()}>
                            <CloseIcon />
                        </Button>
                    </div>
                    <div className={classes.modalBody}>
                        <div className={classes.dsc}>
                            Are you sure that you want to disable 2-Step verification for your account?
                        </div>
                    </div>
                    <div className={classes.modalFooter}>
                        <Button className={classes.cancel} onClick={() => hideModal()}>
                            Cancel
                        </Button>
                        <Button className={classes.delete}
                                onClick={() => deActiveTwoFactorAuth({
                                    refetchQueries: [
                                        {
                                            query,
                                            variables: {
                                                id: token?.id,
                                            },
                                        },
                                    ],
                                })
                                    .then(() => toast.success('Success'))
                                    .then(() => hideModal())
                                    .catch(err => toast.error(err.message))
                                }
                        >
                            disable
                        </Button>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default ModalTwoStepVerificationDisabled;