import {
    withStyles,
    makeStyles,
} from '@material-ui/core/styles';
import {Input, InputLabel} from "@material-ui/core";
import {checkHosts} from "../../../../branding";
import {bgAuth} from "../../../../branding/header";

export const useStyles = makeStyles(theme => ({
    wrapper: {
        display: 'flex',
        flexWrap: 'no-wrap',
        width: '100%',
        minHeight: '100vh',
    },
    form: {
        width: '30%',
        padding:'128px 64px 20px 64px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent:' space-between',
    },
    bg: {
        backgroundImage: `url(${checkHosts(window.location.host, bgAuth )})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '70%',
    },
    content: {
        padding: '128px 0 0 128px'
    },
    title: {
        fontFamily: 'Manrope',
        fontStyle: 'normal',
        fontWeight: '300',
        fontSize: '70px',
        letterSpacing: '-1.5px',
        color: '#363636',
    },
    subtitle: {
        fontFamily: 'Manrope',
        fontStyle: 'normal',
        fontWeight: '300',
        fontSize: '70px',
        letterSpacing: '-1.5px',
        color: '#363636',
        marginLeft: 77,
    },
    dsc: {
        fontFamily: 'Manrope',
        fontStyle: 'normal',
        fontWeight: '300',
        fontSize: '26px',
        color: '#363636',
        marginLeft: 77,
    }
}));

export const AuthLabel = withStyles((theme) => ({
    root: {
        '&.MuiFormLabel-root': {
            fontFamily: 'Manrope ',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: 20,
            letterSpacing: '0.15px',
            color: '#041614',
            lineHeight: '19px',
        },
        '&.Mui-focused': {
            color: theme.palette.primary.main,
        },
        '&.Mui-error': {
            color: '#D40E32',
        }
    },
}))(InputLabel);

export const AuthTextField = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(4),
        },
        '&.MuiInput-root' : {
            marginBottom: 16,
            padding: '13px 24px',
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 16,
            letterSpacing: '0.5px',
            color: '#828B89',
            borderRadius: 10,
            position: 'relative',
            backgroundColor: '#FFFFFF',
            border: '2px solid #D1DBD9',
            width: '100%',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
        },
        '&.Mui-focused': {
            borderColor: theme.palette.primary.main,
        },
        '&.Mui-error': {
            borderColor: '#D40E32',
        }
    },
}))(Input);
