import { makeStyles } from '@material-ui/core/styles';
import { TableCell, TableRow, withStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    wrapper: {
        margin: '30px',
    },
    itemWrapper: {
        margin: '0 40px',
    },
    errorCharts: {
        fontSize: 20,
        margin: '50px 0px',
        textAlign: 'center',
    },
    title: {
        marginBottom: 40,
    },
    puiWrapper: {
        marginRight: 30,
        width: 350,
    },
    tableWrapper: {
        margin: 30,
    },
    lineColorWrapper: {
        width: 15,
    },
    lineColor: {
        height: 4,
        width: 17,
    },
}));

export const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

export const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
