//Применяется в Header/index.js
import logo from '../../../assets/img/sp_new_logo.svg';
import logoWellness from '../../../assets/img/sw_logo.png';
import bgSp from '../../../assets/img/sp_bg.png';
import bgWellness from '../../../assets/img/bg-wellness.jpg';
import React from "react";

export const logos = {
    sp: logo,
    wellness: logoWellness,
};

export const titleAuth = {
    sp: 'Protecting',
    wellness: 'Security Wellness',
};

export const subtitleAuth = {
    sp: 'your websites',
    wellness: 'Protecting digital business assets from WEB threats',
};

export const bgAuth = {
    sp: bgSp,
    wellness: bgWellness,
};
