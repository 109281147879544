import React, { useContext, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/client';
import { GET_CLIENT_ACCOUNT_EMPLOYEE, GET_CLIENT_ACCOUNT } from '../../../actions/get';
import { AuthContext } from '../../../context/auth';
import { UPDATE_CLIENT_ACCOUNT_EMPLOYEE } from '../../../actions/update';
import Preloader from '../Preloader';
import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from '../../mobile/HeaderMobile';
import withWidth from '@material-ui/core/withWidth';
import { AntContainerHead } from '../../../theme/Wrapper';
import { useStyles } from '../../../theme/Header';

const Header = props => {
    const classes = useStyles();
    const { user } = useContext(AuthContext);
    const [lang, setLang] = React.useState('');

    const [updateLang] = useMutation(UPDATE_CLIENT_ACCOUNT_EMPLOYEE);
    const { data } = useQuery(GET_CLIENT_ACCOUNT_EMPLOYEE, {
        variables: {
            id: user && user.id,
        },
    });

    const { data: clientAccount, loading } = useQuery(GET_CLIENT_ACCOUNT, {
        variables: {
            id: data && data.clientAccountEmployee && data.clientAccountEmployee.clientAccountId,
        },
    });

    useEffect(() => {
        const proxy = data?.clientAccountEmployee?.lang === 'ru-RU' ? 'RU' : 'EN';
        setLang(proxy);
    }, []);

    if (loading) return <Preloader />;

    return (
        <Grid container className={user ? classes.header : classes.disabledHeader}>
            <AntContainerHead maxWidth={false}>
                {props.width === 'xs' || props.width === 'sm' ? (
                    <HeaderMobile
                        firstName={data && data?.clientAccountEmployee?.name?.first}
                        lastName={data && data?.clientAccountEmployee?.name?.last}
                        email={data && data?.clientAccountEmployee?.email}
                        l3Limit={clientAccount && clientAccount?.clientAccount?.accountSettings?.l3?.l3Limit}
                        l7Limit={clientAccount && clientAccount?.clientAccount?.accountSettings?.l7?.l7Limit}
                        dosGateLimit={clientAccount && clientAccount?.clientAccount.accountSettings?.dosGate?.dosGateLimit}
                        role={data && data?.clientAccountEmployee?.role}
                    />
                ) : (
                    <HeaderDesktop
                        firstName={data && data?.clientAccountEmployee?.name?.first}
                        lastName={data && data?.clientAccountEmployee?.name?.last}
                        email={data && data?.clientAccountEmployee?.email}
                        l3Limit={clientAccount && clientAccount?.clientAccount?.accountSettings?.l3?.l3Limit}
                        l7Limit={clientAccount && clientAccount?.clientAccount.accountSettings?.l7?.l7Limit}
                        dosGateLimit={clientAccount && clientAccount?.clientAccount.accountSettings?.dosGate?.dosGateLimit}
                        role={data && data?.clientAccountEmployee?.role}
                        clientAccountId={data && data?.clientAccountEmployee?.clientAccountId}
                        userId={user && user.id}
                        query={GET_CLIENT_ACCOUNT_EMPLOYEE}
                        lang={lang}
                        setLang={setLang}
                        updateLang={updateLang}
                        clientAccount={clientAccount}
                    />
                )}
            </AntContainerHead>
        </Grid>
    );
};

export default withWidth()(Header);
