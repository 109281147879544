import React, {useContext} from "react";
import DashboardPage from "./Component";
import {AuthContext} from "../../context/auth";
import {useQuery} from "@apollo/client";
import {GET_CLIENT_ACCOUNT_EMPLOYEE} from "../../actions/get";

const ConnectedDashboardPage = (props) => {
    const { user } = useContext(AuthContext);
    const { data, loading } = useQuery(GET_CLIENT_ACCOUNT_EMPLOYEE, {
        variables: {
            id: user && user.id,
        },
    });
    return (
        <DashboardPage {...props} data={data} loading={loading}/>
    )
}

export default ConnectedDashboardPage;
