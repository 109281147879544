import React, { useRef } from 'react';
import { TableContainer, Table, TableHead, TableRow, TableBody, Typography } from '@material-ui/core';
import { Description } from '@material-ui/icons';
import { useStyles, AntTableCell } from '../../../../theme/Table';
import TableScroll from '../../../../components/global/TableScroll';
import { valueTimeCalculation } from '../../../../helpers/calculation';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_ARBOR_MITIGATIONS } from '../../../../actions/get';
import Preloader from '../../../../components/global/Preloader';
import { saveState } from '../../../../helpers/localStorage';
import { useIntl } from 'react-intl';

const TableMitigationsDuration = ({ filter, setDataMitigations }) => {
    const classes = useStyles();
    const table = useRef(null);
    const { formatMessage } = useIntl();

    const dateFormat = require('dateformat');
    const { data, loading } = useQuery(GET_ARBOR_MITIGATIONS, {
        variables: {
            sortDuration: 'DESC',
            filterStartLte: dateFormat(filter.timeReportStart, 'isoDateTime'),
            filterStartGte: dateFormat(filter.timeReportEnd, 'isoDateTime'),
        },
    });

    if (loading) return <Preloader />;

    setDataMitigations(data && data.arborMitigations);
    const customData = data && data.arborMitigations.items.slice(0, 10);

    return data.arborMitigations.items.length >= 1 ? (
        <TableScroll tableRef={table}>
            <TableContainer ref={table}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <AntTableCell className={classes.title}>ID</AntTableCell>
                            <AntTableCell className={classes.title}>{formatMessage({ id: 'reportsTableMitigationsDuration.anomalyId' })}</AntTableCell>
                            <AntTableCell className={classes.title}>{formatMessage({ id: 'reportsTableMitigationsDuration.protected' })}</AntTableCell>
                            <AntTableCell className={classes.title}>{formatMessage({ id: 'reportsTableMitigationsDuration.name' })}</AntTableCell>
                            <AntTableCell className={classes.title}>{formatMessage({ id: 'reportsTableMitigationsDuration.start' })}</AntTableCell>
                            <AntTableCell className={classes.title}>{formatMessage({ id: 'reportsTableMitigationsDuration.ended' })}</AntTableCell>
                            <AntTableCell className={classes.title}>{formatMessage({ id: 'reportsTableMitigationsDuration.duration' })}</AntTableCell>
                            <AntTableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {customData.map(({ mitigationId, arborAlertId, start, stop, managedObjectName, prefix, duration, id }) => (
                            <TableRow key={mitigationId}>
                                <AntTableCell>{mitigationId}</AntTableCell>
                                <AntTableCell>{arborAlertId}</AntTableCell>
                                <AntTableCell>{prefix}</AntTableCell>
                                <AntTableCell>{managedObjectName}</AntTableCell>
                                <AntTableCell>{dateFormat(start, 'dd/mm/yy h:MM:ssTT')}</AntTableCell>
                                <AntTableCell>{stop ? dateFormat(stop, 'dd/mm/yy h:MM:ssTT') : 'Ongoing'}</AntTableCell>
                                <AntTableCell>
                                    {stop ? valueTimeCalculation(duration) : valueTimeCalculation((new Date() - new Date(start)) / 1000)}
                                </AntTableCell>
                                <AntTableCell>
                                    <Link
                                        to={`/mitigations-details/l3-protection/${start}/${mitigationId}/${id}/3`}
                                    >
                                        <Description color="action" className={classes.icon} />
                                    </Link>
                                </AntTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </TableScroll>
    ) : (
        <Typography variant="h1" className={classes.noData}>
            NO DATA
        </Typography>
    );
};

export default TableMitigationsDuration;
