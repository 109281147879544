import React, { useContext } from 'react';
import { useStyles } from './styles';
import { Button, Typography } from '@material-ui/core';
import AuthWrapper from '../../../components/global/Wrappers/authWrapper';
import { Controller, useForm } from 'react-hook-form';
import pdf2 from '../../../../assets/pdf/oferta.pdf';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { Login, UPDATE_CLIENT_ACCOUNT_EMPLOYEE } from '../../../actions/update';
import { AuthContext } from '../../../context/auth';
import { loadState } from '../../../helpers/localStorage';
import spLogoMain from "../../../../assets/img/logo-welness.svg";
import {AuthLabel, AuthTextField} from "../../../components/global/Wrappers/authWrapper/styles";
import FormControl from "@material-ui/core/FormControl";
import {checkHosts} from "../../../branding";
import {logosAuth} from "../../../branding/logos";

const JoinAccountSecond = () => {
    const classes = useStyles();
    const { id, email, type, password } = loadState('secondStep');
    const { push } = useHistory();
    const context = useContext(AuthContext);
    // const [checked, setChecked] = useState(true); // ждем документ от юриста эдя чек бокса

    const [login] = useMutation(Login, {
        update(_, result) {
            context.login(result.data.login.accessToken);
            push('/dashboard');
        },
        onError(err) {
            console.log(err);
        },
    });

    const [updateClient] = useMutation(UPDATE_CLIENT_ACCOUNT_EMPLOYEE);

    const onSubmit = data => {
        updateClient({
            variables: {
                email,
                first: data.firstName,
                last: data.lastName,
                id: Number(id),
            },
        })
            .then(() => {
                login({
                    variables: {
                        password,
                        email,
                        type,
                    },
                })
                    .then(() => localStorage.removeItem('secondStep'))
                    .catch(err => console.log(err.message));
            })
            .catch(err => toast.error(err.message));
    };

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('Введите имя').min(3, 'Name must be at least 3 characters'),
        lastName: Yup.string().required('Введите фамилию').min(3, 'Last name must be at least 3 characters long'),
    });

    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    // const handleChange = event => {
    //     setChecked(event.target.checked);
    // };

    return (
        <AuthWrapper>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                <div className={classes.logo}>
                    { checkHosts(window.location.host, logosAuth ) }
                </div>
                <div className={classes.title}>Join account</div>
                <div className={classes.description}>Almost done, just enter your first and last name.</div>
                <FormControl>
                    <AuthLabel shrink htmlFor="firstName" error={!!errors.firstName}>
                        First name
                    </AuthLabel>
                    <Controller
                        name="firstName"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value } }) => (
                            <AuthTextField id="firstName"
                                           placeholder='Enter your first name'
                                           onChange={onChange}
                                           value={value}
                                           error={!!errors.firstName}
                                           disableUnderline={true}
                                           helperText={errors?.firstName?.message ?? ' '}
                            />
                        )}
                    />
                </FormControl>
                <FormControl>
                    <AuthLabel shrink htmlFor="lastName" error={!!errors.lastName}>
                        Last name
                    </AuthLabel>
                    <Controller
                        name="lastName"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value } }) => (
                            <AuthTextField id="lastName"
                                           placeholder='Enter your last name'
                                           onChange={onChange}
                                           value={value}
                                           error={!!errors.lastName}
                                           disableUnderline={true}
                                           helperText={errors?.lastName?.message ?? ' '}
                            />
                        )}
                    />
                </FormControl>
                {/*<div className={classes.checkboxWrapper}>*/}
                {/*    /!* <Checkbox checked={checked} onChange={handleChange} color="primary" /> *!/*/}
                {/*    <div className={classes.checkboxText}>*/}
                {/*        /!* <Typography className={classes.text}>*/}
                {/*            Даю согласие на обработку моих персональных данных в соответсвии с&nbsp;*/}
                {/*            <a href={pdf} target="_blank" rel="noopener noreferrer" className={classes.link}>*/}
                {/*                политикой в отношении обработки персональных данных*/}
                {/*            </a>*/}
                {/*        </Typography> *!/*/}
                {/*        <Typography className={classes.text}>*/}
                {/*            Регистрируясь на портале Servicepipe вы соглашаетесь с правилами&nbsp;*/}
                {/*            <a href={pdf2} target="_blank" rel="noopener noreferrer" className={classes.link}>*/}
                {/*                оферты*/}
                {/*            </a>*/}
                {/*            .*/}
                {/*        </Typography>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <Button type="submit" color="primary" variant="contained" className={classes.button}>
                    join account
                </Button>

            </form>
        </AuthWrapper>
    );
};

export default JoinAccountSecond;
