import React from 'react';
import { myStyles } from './styles';
import { useStyles, AntTableCell } from '../../../../../theme/Table';
import { Button, TableRow, TextField } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { DeleteForever } from '@material-ui/icons';
import { useIntl } from 'react-intl';

const Aliase = ({ aliasSslExpire, aliasId, aliasData, aliasSslAuto, aliasSsl, aliasCreated, calculationTypeSsl, aliasSslKey, setIsEdit, aliasSslCrt, setIsDeleted }) => {
    const classes = useStyles();
    const styled = myStyles();
    const { formatMessage } = useIntl();
    const dateFormat = require('dateformat');

    return (
        <>
            <AntTableCell component="th" scope="row">
                {aliasData}
            </AntTableCell>
            <AntTableCell className={styled.checkWrapp} align="center">
                {Boolean(!aliasSslAuto) && Boolean(!aliasSsl) && <CheckIcon className={styled.checkColor} />}
            </AntTableCell>
            <AntTableCell className={styled.checkWrapp} align="center">
                {Boolean(aliasSslAuto) && <CheckIcon className={styled.checkColor} />}
            </AntTableCell>
            <AntTableCell className={styled.checkWrapp} align="center">
                {Boolean(aliasSslAuto) && Boolean(aliasSsl) ? '' : Boolean(aliasSsl) && <CheckIcon className={styled.checkColor} />}
            </AntTableCell>
            <AntTableCell style={{ padding: '4px 10px' }} align="center">
                {Boolean(aliasSslAuto) && Boolean(aliasSsl) ? '' : Boolean(aliasSsl) && `(Exp. date: ${dateFormat(aliasSslExpire * 1000, 'dd/mm/yy h:MM:ssTT')})`}
            </AntTableCell>
            <AntTableCell align="center">{dateFormat(aliasCreated * 1000, 'dd/mm/yy h:MM:ssTT')}</AntTableCell>
            <AntTableCell className={styled.checkWrapp} align="left">
                <TextField value={calculationTypeSsl(aliasSslAuto, aliasSsl)} disabled variant="outlined" className={styled.input} />
                <Button
                    color="primary"
                    variant="outlined"
                    className={styled.editBtn}
                    onClick={() => {
                        setIsEdit({
                            edit: true,
                            aliasId,
                            ssl: calculationTypeSsl(aliasSslAuto, aliasSsl),
                            crt: aliasSslCrt,
                            key: aliasSslKey,
                        });
                    }}
                >
                    {formatMessage({ id: 'webProtection.general.edit' })}
                </Button>
            </AntTableCell>
            <AntTableCell align="center">
                <DeleteForever
                    viewBox="0 0 24 24"
                    className={classes.iconDelete}
                    onClick={() =>
                        setIsDeleted({
                            deleted: true,
                            id: aliasId,
                            name: aliasData,
                        })
                    }
                />
            </AntTableCell>
        </>
    );
};

export default Aliase;
