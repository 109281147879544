import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Paper, Tabs, Tab } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    dateTabsWrapper: {
        margin: '0 20px',
    },
}));

export const AntDatePaper = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.primary.main,
    },
}))(Paper);

export const AntDateTabs = withStyles(heme => ({
    root: {
        minHeight: 40,
        color: 'rgba(255, 255, 255, 0.5)',
    },
    indicator: {
        height: 0,
    },
}))(Tabs);

export const AntDateTab = withStyles(theme => ({
    root: {
        minWidth: 80,
        minHeight: 40,
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '16px',
        letterSpacing: '1.25px',
        mixBlendMode: 'normal',
        '&:hover': {
            color: 'rgba(255, 255, 255, 0.7)',
        },
        '&:focus': {
            color: theme.palette.common.white,
        },
        '&$selected': {
            color: theme.palette.common.white,
        },
    },
    selected: {},
}))(Tab);
