import React from 'react';
import { useStyles } from './styles';
import { Link } from 'react-router-dom';
import routes from '../../../routes';
import AuthWrapper from '../../../components/global/Wrappers/authWrapper';
import { useLocation } from 'react-router';
import spLogoMain from "../../../../assets/img/logo-welness.svg";
import {checkHosts} from "../../../branding";
import {logosAuth} from "../../../branding/logos";

const ForgotPasswordDone = () => {
    const classes = useStyles();
    const params = useLocation();
    const searchParams = new URLSearchParams(params.search);
    const email = searchParams.get('email');

    return (
        <AuthWrapper>
            <div>
                <div className={classes.logo}>
                    { checkHosts(window.location.host, logosAuth ) }
                </div>
                <div className={classes.title}>Done!</div>
                <div className={classes.description}>
                    Password reset instructions have been sent to {email}
                    <br/>
                    <br/>
                    <br/>
                    If you didn’t receive the email, check the spam folder or contact us.
                </div>
                <Link to={routes.auth} className={classes.link}>
                    Return to log in
                </Link>
            </div>
        </AuthWrapper>
    );
};

export default ForgotPasswordDone;
