import React, { useContext, useState } from 'react';
import { TableContainer } from '@material-ui/core';
import PopoverBar from './popoverBar';
import ModalCreateResource from '../../../components/modals/modalsWebProtection/createResource';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
    GET_CLIENT_ACCOUNT_EMPLOYEES,
    GET_CLIENT_ACCOUNT_EMPLOYEES_ACCESS,
    GET_L7_RESOURCES_VENDOR
} from '../../../actions/get';
import { useDebouncedEffect } from '../../../hooks/useDebouncedEffect';
import ModalDeleteResource from '../../../components/modals/modalsWebProtection/deleteResource';
import { AuthContext } from '../../../context/auth';
import { TablePaginationComponent } from '../../../components/global/TablePagination';
import { PageContext } from '../../../context/pages';
import Preloader from '../../../components/global/Preloader';
import ResourcesL7Desktop from './ResourcesL7Desktop';
import withWidth from '@material-ui/core/withWidth';

const Resources = ({ width }) => {
    const { filterActive, setFilterActive } = useContext(PageContext);
    const { user } = useContext(AuthContext);

    // const { data: access, loading:loadingAccess } = useQuery(GET_CLIENT_ACCOUNT_EMPLOYEES_ACCESS, {
    //     variables: {
    //         clientAccountEmployeeId: user && user.id,
    //     },
    // });

    const [getFilter, { data, loading }] = useLazyQuery(GET_L7_RESOURCES_VENDOR);

    const getVariables = () => {
        const proxy = {
            limit: filterActive.limit,
            page: filterActive.page + 1,
            name: filterActive?.name || '',
            createdAt: 'ASC',
        };
        if (filterActive?.id) proxy.id = Number(filterActive?.id);
        return proxy;
    };
    const { data: dataClient, load } = useQuery(GET_CLIENT_ACCOUNT_EMPLOYEES, {
        variables: {
            id: user && user.id,
        },
    });

    useDebouncedEffect(
        () => {
            getFilter({ variables: getVariables() });
        },
        500,
        [filterActive]
    );

    const [anchorEl, setAnchorEl] = useState(null);
    const [index, setIndex] = useState('');
    const [isCreated, setIsCreated] = useState(false);
    const [isDeleted, setIsDeleted] = useState({
        deleted: false,
    });

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'accountList' : undefined;

    if (loading && load) return <Preloader />;

    return (
        <>
            <ModalCreateResource
                show={isCreated}
                hideModal={() => setIsCreated(false)}
                id={dataClient && dataClient.clientAccountEmployees.items[0].clientAccountId}
                query={GET_L7_RESOURCES_VENDOR}
                getVariables={getVariables()}
            />
            <ModalDeleteResource
                show={isDeleted.deleted}
                hideModal={() => setIsDeleted(!isDeleted.deleted)}
                id={index}
                query={GET_L7_RESOURCES_VENDOR}
                getVariables={getVariables()}
            />
            <TableContainer style={{ padding: 0 }}>
                {width === 'xs' ? (
                    <div>1</div>
                    // <ResourcesL7Mobile store={store} handleClick={handleClick} setIndex={setIndex} setIsCreated={setIsCreated} />
                ) : (
                    <ResourcesL7Desktop
                        data={data}
                        setIsCreated={setIsCreated}
                        filter={filterActive}
                        setFilter={setFilterActive}
                        handleClick={handleClick}
                        setIndex={setIndex}
                        // statusCreateL7={access && access.clientAccountEmployeeAccess.create.includes('L7')}
                        // loadingAccess={loadingAccess}
                    />
                )}
                <TablePaginationComponent
                    width={width}
                    filter={filterActive}
                    setFilterActive={setFilterActive}
                    count={data && data.l7ResourcesVendor.info.totalCount}
                    getFilter={getFilter}
                    getVariables={getVariables()}
                    type={'FILTER_ACTIVE'}
                />
            </TableContainer>
            <PopoverBar id={id} open={open} anchorEl={anchorEl} handleClose={handleClose} setIsDeleted={setIsDeleted} index={index} />
        </>
    );
};
export default withWidth()(Resources);
