import React from 'react';
import { Link } from 'react-router-dom';
import { useStyles, AntTableCell } from '../../../../../theme/Table';
import { TableRow, Button } from '@material-ui/core';
import { MoreHoriz, Description, Assessment } from '@material-ui/icons';
import { valueCalculation, valueTimeCalculation } from '../../../../../helpers/calculation';

const Anomaly = ({
    alertId,
    importance,
    alertResourceCidr,
    misuseTypes,
    setAnchorElMore,
    setTypePopover,
    alertManagedObjectName,
    start,
    stop,
    duration,
    stateSwitch,
    maxImpactBps,
    maxImpactPps,
    arborResources,
    setFilter,
    filter
}) => {
    const classes = useStyles();
    const dateFormat = require('dateformat');

    return (
        <TableRow key={alertId}>
            <AntTableCell>{alertId}</AntTableCell>
            <AntTableCell>{importance}</AntTableCell>
            <AntTableCell>{alertResourceCidr}</AntTableCell>
            <AntTableCell>
                {misuseTypes.length === 1 ? (
                    misuseTypes[0]
                ) : (
                    <div>
                        {misuseTypes[0]}
                        <Button
                            className={classes.buttonMore}
                            aria-describedby={alertId}
                            onClick={event => {
                                setAnchorElMore(event.currentTarget);
                                setTypePopover(misuseTypes);
                            }}
                        >
                            <span className={classes.buttonMoreSpan}>
                                <MoreHoriz className={classes.buttonMoreIcon} viewBox="3 0 25 5" /> more
                            </span>
                        </Button>
                    </div>
                )}
            </AntTableCell>
            <AntTableCell>{alertManagedObjectName}</AntTableCell>
            <AntTableCell>{dateFormat(start, 'dd/mm/yy h:MM:ssTT')}</AntTableCell>
            <AntTableCell>{stop ? dateFormat(stop, 'dd/mm/yy h:MM:ssTT') : 'Ongoing'}</AntTableCell>
            <AntTableCell>{stop ? valueTimeCalculation(duration) : valueTimeCalculation((new Date() - new Date(start)) / 1000)}</AntTableCell>
            <AntTableCell>{stateSwitch.bps ? valueCalculation(maxImpactBps, 'bps') : valueCalculation(maxImpactPps, 'pps')}</AntTableCell>
            <AntTableCell>
                <Link
                    to={`/anomaly-details/l3-protection/${alertId}/1`}
                >
                    <Description color="action" className={classes.icon} />
                </Link>
            </AntTableCell>
            <AntTableCell>
                <Link
                    to={'/l3-protection/2'}
                    onClick={() => setFilter({
                        ...filter,
                        traffic: {
                            indexTab: false,
                            proxy: true,
                            select: null,
                            arborResources,
                            limit: 10,
                            page: 0,
                        }})}
                >
                    <Assessment color="action" className={classes.icon} />
                </Link>
            </AntTableCell>
        </TableRow>
    );
};
export default Anomaly;
