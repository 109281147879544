import React from 'react';
import { useStyles, AntTableCell } from '../../../../../theme/Table';
import { DeleteForever, BorderColor } from '@material-ui/icons';

const BlackList = ({ blacklistData, blacklistComment, blacklistId, blacklistCreated, setIsEdit, setIsEditComment, setIsDeleted }) => {
    const classes = useStyles();
    const dateFormat = require('dateformat');

    return (
        <>
            <AntTableCell component="th" scope="row">
                {blacklistData}
            </AntTableCell>
            <AntTableCell align="left" className={classes.comment}>
                <div title={blacklistComment}>{blacklistComment.substr(0, 50)}</div>
                <span className={classes.comment}>
                    <BorderColor
                        viewBox="0 0 24 24"
                        className={classes.icon}
                        onClick={() =>
                            setIsEditComment({
                                edit: true,
                                id: blacklistId,
                                ip: blacklistData,
                                comment: blacklistComment,
                            })
                        }
                    />
                </span>
            </AntTableCell>
            <AntTableCell align="left">{dateFormat(blacklistCreated * 1000, 'mm/dd/yy h:MM:ssTT')}</AntTableCell>
            <AntTableCell align="center">
                <DeleteForever
                    viewBox="0 0 24 24"
                    className={classes.iconDelete}
                    onClick={() =>
                        setIsDeleted({
                            deleted: true,
                            id: blacklistId,
                            ip: blacklistData,
                        })
                    }
                />
            </AntTableCell>
        </>
    );
};

export default BlackList;
