import React, { useState } from 'react';
import { useStyles } from '../../../theme/Table';
import { Table, TableBody, FormControl, Select, Typography, TableRow } from '@material-ui/core';
import { useIntl } from 'react-intl';
import ResourceL3 from './ResourceL3';

const ResourcesL3Mobile = ({ data, setIpsPopover, setAnchorEl }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const [state, setState] = useState(1);

    const handleChange = event => {
        setState(Number(event.target.value));
    };

    return (
        <>
            <div className={classes.header}>
                <Typography variant="h1">Resources list</Typography>
            </div>
            <FormControl className={classes.selectWrwppMobile}>
                <Typography variant="subtitle2" color="textSecondary">
                    Resource characteristics
                </Typography>
                <Select
                    native
                    variant="outlined"
                    defaultValue={state}
                    inputProps={{
                        id: 'uncontrolled-native',
                    }}
                    onChange={handleChange}
                    className={classes.selectMobile}
                >
                    <option value={1}>{formatMessage({ id: 'resource.ips' })}</option>
                    <option value={2}>{formatMessage({ id: 'resource.date' })}</option>
                </Select>
            </FormControl>
            <Typography variant="subtitle2" color="textSecondary" className={classes.titleMobile}>
                {`${formatMessage({ id: 'resource.id' })}/${formatMessage({
                    id: 'resource.name',
                })}`}
            </Typography>
            <Table>
                <TableBody>
                    {data &&
                        data.arborResources.items.map(({ id, name, ips, createdAt }) => (
                            <TableRow key={id}>
                                <ResourceL3
                                    id={id}
                                    name={name}
                                    state={state}
                                    ips={ips}
                                    createdAt={createdAt}
                                    setAnchorEl={setAnchorEl}
                                    setIpsPopover={setIpsPopover}
                                />
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </>
    );
};
export default ResourcesL3Mobile;
