import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { ApolloLink } from '@apollo/client/link/core/ApolloLink';
import { AuthProvider } from './common/context/auth';
import { MenuProvider } from './common/context/menu';
import Helmet from "react-helmet";
import {checkHosts} from "./common/branding";
import {favIcons, titles} from "./common/branding/head";

const httpLink = createUploadLink({
    uri: process.env.REACT_APP_BACKEND_API,
});

const authLink = setContext(async (_, { headers }) => {
    const token = localStorage.getItem('jwtToken') || localStorage.getItem('join');
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

const client = new ApolloClient({
    link: ApolloLink.from([authLink, httpLink]),
    cache: new InMemoryCache(),
});

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <ApolloProvider client={client}>
                <AuthProvider>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <link
                            rel="icon"
                            href={ checkHosts(window.location.host, favIcons )}
                        />
                        <meta name="viewport" content="width=device-width, initial-scale=1" />
                        <meta name="theme-color" content="#000000" />
                        <meta name="description" content="" />
                        <link rel="apple-touch-icon" href={ checkHosts(window.location.host, favIcons )} />
                        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
                        <title>
                            { checkHosts(window.location.host, titles ) }
                        </title>
                    </Helmet>
                    <MenuProvider>
                        <App />
                    </MenuProvider>
                </AuthProvider>
            </ApolloProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);
