import React, { useEffect } from 'react';
import { useLazyQuery } from "@apollo/client";
import { GET_L7_CAPTCHA } from "../../actions/get";
import { useStyles } from "../../theme/Chart";
import {Chart, Interaction} from "chart.js";
import {CrosshairPlugin, Interpolate} from "chartjs-plugin-crosshair";
import Preloader from "../global/Preloader";
import {useIntl} from "react-intl";
import {valueCalculation} from "../../helpers/calculation";
import {Line} from "react-chartjs-2";

Chart.register(CrosshairPlugin);
Interaction.modes.interpolate = Interpolate;

const AreaChartsCaptcha = ({ date, l7ResourceId, setFilterActive }) => {
    const dateFormat = require('dateformat');
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const [getCaptcha, { data, loading, error }] = useLazyQuery(GET_L7_CAPTCHA, {
        variables: {
            from: (Date.parse(date.startDate) - (Date.parse(date.startDate) % 1000)) / 1000 || (date.startDate - (date.startDate % 1000)) / 1000,
            until: (Date.parse(date.endDate) - (Date.parse(date.endDate) % 1000)) / 1000 || (date.endDate - (date.endDate % 1000)) / 1000,
            l7ResourceId
        },
    });
    useEffect(() => {
        getCaptcha();
        setFilterActive({...date, activeZoomChart: true});
    }, [l7ResourceId]);

    if (loading) return <Preloader />;
    if (error || data?.l7GraphiteCaptcha?.timestamp.length < 1) return <div className={classes.errorCharts}>{formatMessage({ id: 'charts.error' })}</div>;
    const series = {
        labels: data?.l7GraphiteCaptcha?.timestamp,
        datasets: [
            {
                label: 'captcha show',
                data: data?.l7GraphiteCaptcha?.captchaShow,
                borderColor: '#40C4FF',
                backgroundColor: 'rgb(64, 196, 255, 0.2)',
                borderWidth: 1,
                radius: 0,
                hoverRadius: 3,
            },
            {
                label: 'captcha submit',
                data: data?.l7GraphiteCaptcha?.captchaSubmit,
                borderColor: '#69F0AE',
                backgroundColor: 'rgba(105, 240, 174, 0.6)',
                borderWidth: 0.5,
                radius: 0,
                hoverRadius: 3,
            },
            {
                label: 'captcha pass',
                data: data?.l7GraphiteCaptcha?.captchaPass,
                borderColor: '#E57373',
                backgroundColor: 'rgba(229, 115, 115, 0.7)',
                borderWidth: 0.5,
                radius: 0,
                hoverRadius: 3,
            },
        ],
    };
    const options = {
        interaction: {
            mode: 'index'
        },
        maintainAspectRatio: false,
        elements: {
            line: {
                borderJoinStyle: 'bevel',
                borderCapStyle: 'square',
                fill: true,
            },
        },
        animation: false,
        scales: {
            y: {
                grid: {
                    display: false,
                    drawBorder: false,
                },
                stacked: false,
                ticks: {
                    display: true,
                    callback: function (value, index, values) {
                        return valueCalculation(value);
                    },
                },
            },

            x: {
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: 'fpr',
                    fontStyle: 'bold'
                },
                grid: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    stepSize: 5,
                    maxTicksLimit: 15,
                    maxRotation: 0,
                    autoSkip: true,
                    display: true,
                    callback: function (value, index, values) {
                        return dateFormat(this.getLabelForValue(value) * 1000, 'h:MM:ssTT')
                    },
                },
            },
        },
        plugins: {
            maintainAspectRatio: false,
            legend: {
                position: 'bottom',
            },
            filler: {
                propagate: false,
            },
            crosshair: {
                line: {
                    color: '#848484',
                    width: 0.5,
                },
                sync: {
                    enabled: false,
                },
                zoom: {
                    enabled: true,
                    zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',
                    zoomboxBorderColor: '#48F',
                    zoomButtonText: 'reset zoom',
                    zoomButtonClass: 'zoom',
                },
                callbacks: {
                    afterZoom: () => function(start, end) {
                        const afterZoomDate = data?.l7GraphiteCaptcha?.timestamp.filter((item, index) => {
                            if(index === start) return item ;
                            else if(index === end) return item;
                        });
                        const startDate = afterZoomDate[0];
                        const endDate = afterZoomDate[1];
                        getCaptcha({
                            variables: {
                                from: startDate,
                                until: endDate,
                                l7ResourceId
                            }
                        });
                        setFilterActive({...date, activeZoomChart: false});
                    }
                }
            },
            tooltip: {
                animation: false,
                intersect: false,
                callbacks: {
                    title: function (context) {
                        return dateFormat(context[0].label * 1000, 'dd/mm/yy h:MM:ssTT');
                    },
                    label: function (context) {
                        return `${context.dataset.label}: ${valueCalculation(context.raw)}`;
                    },
                },
            },
        },
    };
    return (
        <>
            <div className={classes.chart}>
                <button className={date.activeZoomChart ? classes.visibleZoomBtn : classes.zoomBtn}
                        onClick={() => {
                            getCaptcha();
                            setFilterActive({...date, activeZoomChart: true});
                        }}
                >
                    RESET ZOOM !
                </button>
                <Line data={series} options={options} />
            </div>
        </>
    );
};
export default AreaChartsCaptcha;