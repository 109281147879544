import React, { useEffect, useState, useRef, useContext } from 'react';
import { TableContainer } from '@material-ui/core';
import ModalMore from '../../../components/modals/modalsTable/more';
import ModalThreatLevel from '../../../components/modals/modalsTable/threatLevel';
import TableScroll from '../../../components/global/TableScroll';
import { useDebouncedEffect } from '../../../hooks/useDebouncedEffect';
import { useLazyQuery } from '@apollo/client';
import { GET_ARBOR_ALERTS } from '../../../actions/get';
import { PageContext } from '../../../context/pages';
import Preloader from '../../../components/global/Preloader';
import withWidth from '@material-ui/core/withWidth';
import AnomaliesDesktop from './AnomaliesDesktop';
import AnomaliesMobile from '../../../components/mobile/AnomaliesMobile';
import { TablePaginationComponent } from '../../../components/global/TablePagination';

const Anomalies = ({ width }) => {
    const table = useRef(null);
    const { filterActive, setFilterActive } = useContext(PageContext);
    const [anchorElMore, setAnchorElMore] = useState(null);
    const [anchorElThreatLevel, setAnchorElThreatLevel] = useState(null);
    const [typePopover, setTypePopover] = useState([]);
    const dateFormat = require('dateformat');
    const [getFilter, { data, loading }] = useLazyQuery(GET_ARBOR_ALERTS);

    const getVariables = () => {
        const proxy = {
            limit: filterActive.limit,
            page: filterActive.page + 1,
            importance: filterActive?.threatLevel || ['low', 'medium', 'high'],
            filterStartLte: dateFormat(filterActive?.startDate, 'isoDateTime') || null,
            filterStartGte: dateFormat(filterActive?.endDate, 'isoDateTime') || null,
            [filterActive?.sortActive]: filterActive?.sort?.[filterActive?.sortActive]?.toUpperCase() || 'DESC',
        };
        if (filterActive?.id) proxy.alertId = Number(filterActive?.id);
        if (filterActive?.resourceName) proxy.alertManagedObjectName = filterActive?.resourceName;
        if (filterActive?.durationIps) proxy.alertResourceCidr = filterActive?.durationIps;
        return proxy;
    };

    useEffect(() => {
        getFilter({ variables: getVariables() });
        filterActive?.threatLevel &&
            Object.entries(filterActive?.threatLevel || {})?.reduce((acc, value) => {
                if (value[1]) {
                    acc.push(value[0]);
                }
                return acc;
            }, []);
    }, []);

    useDebouncedEffect(
        () => {
            getFilter({ variables: getVariables() });
        },
        1000,
        [filterActive]
    );

    if (loading) return <Preloader />;

    const openModalMore = Boolean(anchorElMore);
    const openModalThreatLevel = Boolean(anchorElThreatLevel);
    const idPopoverMore = openModalMore ? 'more' : undefined;
    const idPopoverThreatLevel = openModalThreatLevel ? 'threat-Level' : undefined;

    return (
        <div>
            <ModalMore id={idPopoverMore} open={openModalMore} anchorEl={anchorElMore} setAnchorEl={setAnchorElMore} value={typePopover} title="Type list" />
            <ModalThreatLevel
                id={idPopoverThreatLevel}
                open={openModalThreatLevel}
                anchorEl={anchorElThreatLevel}
                setAnchorEl={setAnchorElThreatLevel}
                filter={filterActive}
                setFilter={setFilterActive}
            />
            <TableScroll tableRef={table}>
                <TableContainer ref={table}>
                    {width === 'xs' ? (
                        <AnomaliesMobile
                            data={data}
                            setAnchorElMore={setAnchorElMore}
                            setAnchorElThreatLevel={setAnchorElThreatLevel}
                            setTypePopover={setTypePopover}
                            filter={filterActive}
                            setFilter={setFilterActive}
                            width={width}
                        />
                    ) : (
                        <AnomaliesDesktop
                            data={data}
                            filter={filterActive}
                            setFilter={setFilterActive}
                            setAnchorElMore={setAnchorElMore}
                            setAnchorElThreatLevel={setAnchorElThreatLevel}
                            setTypePopover={setTypePopover}
                        />
                    )}
                    <TablePaginationComponent
                        width={width}
                        filter={filterActive}
                        setFilterActive={setFilterActive}
                        count={data && data.arborAlerts.info.totalCount}
                        getFilter={getFilter}
                        getVariables={getVariables()}
                        type={'FILTER_ACTIVE'}
                    />
                </TableContainer>
            </TableScroll>
        </div>
    );
};
export default withWidth()(Anomalies);
