import React from 'react';
import { useIntl } from 'react-intl';
import { FilterTextField } from './styles';

const TableFilter = ({ name, filter, setFilter, maxWidth }) => {
    const { formatMessage } = useIntl();
    const onChangeFilter = event => {
        setFilter({ ...filter, [name]: event.currentTarget.value, page: 0 }, 'FILTER_ACTIVE');
    };

    return (
        <FilterTextField
            label={formatMessage({ id: 'table.filter' })}
            style={{ width: maxWidth + 'px' }}
            variant="outlined"
            value={filter[name]}
            onChange={onChangeFilter}
        />
    );
};
export default TableFilter;
