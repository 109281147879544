import { makeStyles } from '@material-ui/core/styles';

export const useStyled = makeStyles(theme => ({
    wrapperButton: {
        display: 'flex',
        justifyContent: 'row',
        alignItems: 'center',
    },
    helpText: {
        padding: 10,
        fontSize: 14,
    },
    helpIcon: {
        fontSize: 20,
        marginLeft: 5,
    },
    popover: {
        pointerEvents: 'none',
        '& .MuiPopover-paper': {
            borderRadius: 15,
        },
    },
}));
