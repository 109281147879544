import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles(theme => ({
    titleIp: {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 20,
        lineHeight: '30px',
        letterSpacing: '0.15px',
        color: 'rgba(0, 0, 0, 0.6)',
        padding: '6px 0',
    },
    buttonIp: {
        padding: 11,
        boxSizing: 'content-box',
    },
    requestWrapper: {
        background: '#E3F2FD',
        borderRadius: 8,
        fontStyle: 'normal',
        fontSize: 20,
        lineHeight: '30px',
        letterSpacing: '0.15px',
        color: 'rgba(0, 0, 0, 0.87)',
        padding: '20px',
        marginTop: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    requestTitle: {
        fontWeight: 300,
        marginBottom: 5,
    },
    requestText: {
        fontWeight: 400,
    },
}));
