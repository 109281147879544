import React from 'react';
import { useStyles } from './styles';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { FiberManualRecord } from '@material-ui/icons';
import { useIntl } from 'react-intl';

const ListPassword = ({ inputVariable, checkUppercase, checkLowercase, checkNumber, checkSpecialCharacter }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();

    return (
        <>
            <List className={classes.list}>
                <ListItem className={`${classes.listItem} ${inputVariable?.split('').length >= 8 ? classes.active : classes.disable}`}>
                    <FiberManualRecord fontSize="inherit" className={classes.listIcon} />
                    <ListItemText primary={formatMessage({ id: 'profile.pass.8' })} />
                </ListItem>
                <ListItem className={`${classes.listItem} ${checkUppercase.test(inputVariable) ? classes.active : classes.disable}`}>
                    <FiberManualRecord fontSize="inherit" className={classes.listIcon} />
                    <ListItemText primary={formatMessage({ id: 'profile.pass.upper' })} />
                </ListItem>
                <ListItem className={`${classes.listItem} ${checkLowercase.test(inputVariable) ? classes.active : classes.disable}`}>
                    <FiberManualRecord fontSize="inherit" className={classes.listIcon} />
                    <ListItemText primary={formatMessage({ id: 'profile.pass.lower' })} />
                </ListItem>
                <ListItem className={`${classes.listItem} ${checkNumber.test(inputVariable) ? classes.active : classes.disable}`}>
                    <FiberManualRecord fontSize="inherit" className={classes.listIcon} />
                    <ListItemText primary={formatMessage({ id: 'profile.pass.number' })} />
                </ListItem>
                <ListItem className={`${classes.listItem} ${checkSpecialCharacter.test(inputVariable) ? classes.active : classes.disable}`}>
                    <FiberManualRecord fontSize="inherit" className={classes.listIcon} />
                    <ListItemText primary={formatMessage({ id: 'profile.pass.sign' })} />
                </ListItem>
            </List>
        </>
    );
};

export default ListPassword;
