import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    logo: {
        marginBottom: 64,
    },
    title: {
        marginBottom: 40,
        fontFamily: 'Manrope',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '35px',
        letterSpacing: '0.25px',
        color: '#041613',
        textAlign: 'left',
        lineHeight: '46px',
    },
    description: {
        marginBottom: 40,
        fontFamily: 'Manrope',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        letterSpacing: '0.5px',
        color: '#041613',
        textAlign: 'left',
        lineHeight: '22px',
        [theme.breakpoints.only('xs')]: {
            padding: 0,
        },
    },
    form: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    input: {
        marginBottom: 20,
        width: '100%',
        [theme.breakpoints.only('xs')]: {
            marginBottom: 0,
        },
    },

    list: {
        marginBottom: 30,
        width: '100%',
        [theme.breakpoints.only('xs')]: {
            marginBottom: 20,
        },
    },
    listItem: {
        padding: '0',
    },
    listIcon: {
        marginRight: 15,
    },
    active: {
        color: theme.palette.primary.main,
    },
    disable: {
        color: theme.palette.grey.greyDisable,
    },
    nextLink: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '100%',
    },
    link: {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '1.25px',
        textTransform: 'uppercase',
        textDecoration: 'none',
        color: theme.palette.grey.greyHighEmphasis,
        position: 'relative',
        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: 'transparent',
        },
    },
    button: {
        margin: '42px 0 115px 0',
        width: '100%',
        padding: '15px 0',
        background: '#01CDCB',
        borderRadius: '100px',
    },
    footer: {
        fontFamily: 'Manrope',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '22px',
        letterSpacing: '0.5px',
        color: '#828B89',
        marginBottom: 20,
        textAlign: 'left',
    }
}));
