import React, {useContext, useState} from "react";
import { Grid, Typography } from "@material-ui/core";
import {TrafficTextField, useStyles} from "./styles"
import Autocomplete from "@material-ui/lab/Autocomplete";
import TableDatePicker from "../../../components/global/TableDatePicker";
import LineTraffic from "../../../components/charts/LineTraffic";
import { useQuery } from "@apollo/client";
import { GET_ARBOR_MITIGATIONS, GET_ARBOR_RESOURCES } from "../../../actions/get";
import Preloader from "../../../components/global/Preloader";
import { useIntl } from "react-intl";
import { PageContext } from "../../../context/pages";
import TrafficMitigations from "./mitigation";
import { AntTab, AntTabs } from "../anomalies/anomalyDetails/summary/styles";
import SelectorDate from "../../../components/global/SelectorDate";
import AreaDosgateTraffic from "../../../components/charts/AreaDosgateTraffic";

const Traffic = () => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const dateFormat = require('dateformat');
    const { filterActive, setFilterActive } = useContext(PageContext);
    const [indexTab, setIndexTab] = useState('bps' ? 0 : 1);
    const handleChange = (event, newValue) => {
        setIndexTab(newValue);
    };
    const a11yProps = index => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    const { traffic, traffic: { proxy, select }} = filterActive;

    const { data: resources, loading} = useQuery(GET_ARBOR_RESOURCES);

    const filterManageObjectNameFotMitigation = !select ? resources?.arborResources?.items[0]?.managedObjectName
        : resources?.arborResources?.items.filter(({manageObjectId}) => select === manageObjectId)[0]?.manageObjectName;

    const { data: mitigations, loading: load} = useQuery(GET_ARBOR_MITIGATIONS, {
        variables: {
            limit: filterActive.traffic.limit,
            page: filterActive.traffic.page + 1,
            filterStartLte: dateFormat(filterActive.startDate, 'isoDateTime'),
            filterStartGte: dateFormat(filterActive.endDate, 'isoDateTime'),
            managedObjectName: filterManageObjectNameFotMitigation,
        },
        fetchPolicy: 'network-only',
    });

    if(loading || load) return <Preloader/>;

    const idForSelect = resources?.arborResources?.items.map(({ name, manageObjectId }) => String(`(${manageObjectId}) ${name}`));
    const selectedId = proxy && !select ?
        resources?.arborResources?.items.filter(({manageObjectId, name}) => manageObjectId === filterActive.traffic.arborResources?.manageObjectId).map(({ name, manageObjectId }) => String(`(${manageObjectId}) ${name}`))[0]
        : select ? resources?.arborResources?.items.filter(({manageObjectId, name}) => manageObjectId === select).map(({ name, manageObjectId }) => String(`(${manageObjectId}) ${name}`))[0]
            : idForSelect[0];
    const activeResource = resources?.arborResources?.items
        .filter(({manageObjectId}) => {
            if(select) return +select === manageObjectId;
            else return proxy ? manageObjectId === filterActive.traffic.arborResources?.manageObjectId
                : manageObjectId === resources?.arborResources?.items[0].manageObjectId;
        });
    return (
        <div>
            <Grid container>
                <Grid container item direction="row" alignItems="center" className={classes.wrapperTable}>
                    <Grid item xs={5}>
                        <Typography variant="h1">
                            {formatMessage({ id: 'l3Protection.traffic.title' })}
                        </Typography>
                    </Grid>
                    <Grid item xs={7} container justify='space-between'>
                        <Grid item xs={4}>
                            {
                                idForSelect &&
                                <Autocomplete
                                    size="small"
                                    className={classes.closeIcon}
                                    id="resellerId"
                                    options={idForSelect}
                                    value={selectedId}
                                    getOptionLabel={option => option}
                                    onChange={(event, value) => {
                                        const regExp = /\(([^)]+)\)/;
                                        const matches = regExp.exec(value);
                                        setFilterActive({...filterActive, traffic: {...traffic, select: Number(matches[1])}});
                                    }}
                                    renderInput={params => <TrafficTextField {...params} variant="outlined" size="small" className={classes.select} defaultValue={selectedId}/>}
                                />
                            }
                        </Grid>
                        <Grid item xs={2}>
                            <SelectorDate filter={filterActive} setFilter={setFilterActive}/>
                        </Grid>
                        <Grid item xs={5}>
                            <TableDatePicker filter={filterActive} setFilter={setFilterActive} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container xs={12}>
                    {
                        activeResource[0]?.dosGateName && !activeResource[0]?.alwaysOnFiltration &&
                            <Grid item xs={12}>
                                <div className={classes.tabs}>
                                    <AntTabs value={indexTab} onChange={handleChange}>
                                        <AntTab label="BPS" {...a11yProps(0)} />
                                        <AntTab label="PPS" {...a11yProps(1)} />
                                    </AntTabs>
                                </div>
                                <div className={classes.chartBox}>
                                    <div className={classes.cardTitle}>Bandwidth, {indexTab === 0 ? 'BPS' : 'PPS'}</div>
                                    <LineTraffic
                                        indexTab={indexTab}
                                        dosgate={activeResource[0]?.dosGateName}
                                        filter={filterActive}
                                        id={!select && !proxy ? resources?.arborResources?.items[0]?.manageObjectId : !select && proxy ? +filterActive.traffic.arborResources.manageObjectId : select}
                                        setFilter={setFilterActive}
                                        type={'bps'}
                                    />
                                </div>
                                <div className={classes.chartBox}>
                                    <div className={classes.cardTitle}>Bandwidth, {indexTab === 0 ? 'BPS' : 'PPS'}</div>
                                    <AreaDosgateTraffic
                                        filter={filterActive}
                                        id={!select && !proxy ? resources?.arborResources?.items[0]?.manageObjectId : !select && proxy ? +filterActive.traffic.arborResources.manageObjectId : select}
                                        setFilter={setFilterActive}
                                        type={'pps'}
                                        indexTab={indexTab}
                                        alwaysOn={activeResource[0].alwaysOnFiltration}
                                    />
                                </div>
                            </Grid>
                    }
                    {
                        activeResource[0]?.dosGateName && activeResource[0]?.alwaysOnFiltration &&
                        <Grid item xs={12}>
                            <div className={classes.chartBox}>
                                <div className={classes.cardTitle}>Bandwidth, BPS</div>
                                <AreaDosgateTraffic
                                    filter={filterActive}
                                    id={!select && !proxy ? resources?.arborResources?.items[0]?.manageObjectId : !select && proxy ? +filterActive.traffic.arborResources.manageObjectId : select}
                                    setFilter={setFilterActive}
                                    type={'BYTES'}
                                    indexTab={indexTab}
                                    alwaysOn={true}
                                />
                            </div>
                            <div className={classes.chartBox}>
                                <div className={classes.cardTitle}>Bandwidth, PPS</div>
                                <AreaDosgateTraffic
                                    filter={filterActive}
                                    id={!select && !proxy ? resources?.arborResources?.items[0]?.manageObjectId : !select && proxy ? +filterActive.traffic.arborResources.manageObjectId : select}
                                    setFilter={setFilterActive}
                                    type={'PACKETS'}
                                    indexTab={indexTab}
                                    alwaysOn={true}
                                />
                            </div>
                        </Grid>
                    }
                    {
                        !activeResource[0]?.dosGateName &&
                        <Grid item xs={12}>
                            <div className={classes.chartBox}>
                                <div className={classes.cardTitle}>Bandwidth, BPS</div>
                                <LineTraffic
                                    indexTab={indexTab}
                                    dosgate={activeResource?.dosGateName}
                                    filter={filterActive}
                                    id={!select && !proxy ? resources?.arborResources?.items[0]?.manageObjectId : !select && proxy ? +filterActive.traffic.arborResources.manageObjectId : select}
                                    setFilter={setFilterActive}
                                    type={'bps'}
                                />
                            </div>
                            <div className={classes.chartBox}>
                                <div className={classes.cardTitle}>Bandwidth, PPS</div>
                                <LineTraffic
                                    indexTab={indexTab}
                                    dosgate={activeResource?.dosGateName}
                                    filter={filterActive}
                                    id={!select && !proxy ? resources?.arborResources?.items[0]?.manageObjectId : !select && proxy ? +filterActive.traffic.arborResources.manageObjectId : select}
                                    setFilter={setFilterActive}
                                    type={'pps'}
                                />
                            </div>
                        </Grid>
                    }
                </Grid>
            </Grid>
            <Grid container style={{marginTop: 50}} className={classes.wrapperTable}>
                <Grid item xs={12}>
                    {
                        !activeResource[0]?.dosGateName &&
                        mitigations?.arborMitigations?.items?.length >= 1 &&
                        <TrafficMitigations data={mitigations} filter={filterActive} setFilter={setFilterActive}/>
                    }
                </Grid>
            </Grid>
        </div>
    )
};

export default Traffic;