import React from "react";
import {useStyles} from "./styles";
import {Controller, useForm} from "react-hook-form";
import {Button, Dialog, TextField, Typography} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";

const AuthTwoStepVerificationModal = ({ show, hideModal, submit }) => {
    const classes = useStyles();
    const { handleSubmit, control } = useForm();

    return(
        <div>
            <Dialog
                className={classes.dialog}
                maxWidth="md"
                open={show}
                onClose={() => {
                    hideModal();
                }}
            >
                <div className={classes.modal}>
                    <div className={classes.header}>
                        <Typography variant="h1">
                            Enter authenticator passcode
                        </Typography>
                        <Button className={classes.close} onClick={() => hideModal()}>
                            <CloseIcon />
                        </Button>
                    </div>
                    <div className={classes.modalBody}>
                        <div className={classes.subtitle}>
                            Enter the six-digit code from your authenticator app to continue. Or use your backup code.
                        </div>
                        <form onSubmit={handleSubmit(submit)}>
                            <div className={classes.dsc} style={{marginBottom: 10}}>Verification code</div>
                            <Controller
                                name="code"
                                control={control}
                                defaultValue=''
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        placeholder=""
                                        variant="outlined"
                                        className={classes.input}
                                    />
                                )}
                            />
                            <div className={classes.modalFooter}>
                                <Button
                                    className={classes.cancel}
                                    onClick={() => {
                                        hideModal();
                                    }}
                                >
                                    cancel
                                </Button>
                                <Button type="submit" className={classes.invite}>
                                    sign in
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </Dialog>
        </div>
    )
};

AuthTwoStepVerificationModal.propTypes = {
    show: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired
}

export default AuthTwoStepVerificationModal;
