import React from 'react';

import AnomalyMobile from './Component.js'

const ConnectedAnomalyMobile = (props) => {
    return (
        <AnomalyMobile {...props}/>
    )
}

export default ConnectedAnomalyMobile