import React from "react";
import { Document, Page, Text, View } from '@react-pdf/renderer';
import { styles } from './styles';
import Header from "./components/Header";
import FirstPage from "./components/FirstPage";
import Table from "./components/Table";
import {valueCalculation} from "../../../../../helpers/calculation";
import ReactCountryFlag from "react-country-flag";

export const PdfDocument = ({data, store}) => {
    const customDataDetails = {
        srcTcpPorts: () =>
            data && data?.arborAlertDetail?.src_tcp_ports_bytes.map((item, index) => ({
                ...item,
                avg_packets: data?.arborAlertDetail?.src_tcp_ports_packets[index]?.avg_packets,
                max_packets: data?.arborAlertDetail?.src_tcp_ports_packets[index]?.max_packets,
            })) || [],
        srcIps: () =>
            data && data?.arborAlertDetail?.src_addr_bytes.map((item, index) => ({
                ...item,
                avg_packets: data?.arborAlertDetail?.src_addr_packets[index]?.avg_packets,
                max_packets: data?.arborAlertDetail?.src_addr_packets[index]?.max_packets,
            })) || [],
        destIps: () =>
            data && data?.arborAlertDetail?.dest_addr_bytes.map((item, index) => ({
                ...item,
                avg_packets: data?.arborAlertDetail?.dest_addr_packets[index]?.avg_packets,
                max_packets: data?.arborAlertDetail?.dest_addr_packets[index]?.max_packets,
            })) || [],
        destTcp: () =>
            data && data?.arborAlertDetail?.dest_tcp_ports_bytes.map((item, index) => ({
                ...item,
                avg_packets: data?.arborAlertDetail?.dest_tcp_ports_packets[index]?.avg_packets,
                max_packets: data?.arborAlertDetail?.dest_tcp_ports_packets[index]?.max_packets,
            })) || [],
        srcUdp: () =>
            data && data?.arborAlertDetail?.src_udp_ports_bytes?.map((item, index) => ({
                ...item,
                avg_packets: data?.arborAlertDetail?.src_udp_ports_packets[index]?.avg_packets,
                max_packets: data?.arborAlertDetail?.src_udp_ports_packets[index]?.max_packets,
            })) || [],
        destUdp: () =>
            data && data?.arborAlertDetail?.dest_udp_ports_bytes.map((item, index) => ({
                ...item,
                avg_packets: data?.arborAlertDetail?.dest_udp_ports_packets[index]?.avg_packets,
                max_packets: data?.arborAlertDetail?.dest_udp_ports_packets[index]?.max_packets,
            })) || [],
        srcCntr: () =>
            data && data?.arborAlertDetail?.src_countries_bytes.map((item, index) => ({
                ...item,
                avg_packets: data?.arborAlertDetail?.src_countries_packets[index]?.avg_packets,
                max_packets: data?.arborAlertDetail?.src_countries_packets[index]?.max_packets,
            })) || [],
        srcAsn: () =>
            data && data?.arborAlertDetail?.src_asn_bytes.map((item, index) => ({
                ...item,
                avg_packets: data?.arborAlertDetail?.src_asn_packets[index]?.avg_packets,
                max_packets: data?.arborAlertDetail?.src_asn_packets[index]?.max_packets,
            })) || [],
        protocols: () =>
            data && data?.arborAlertDetail?.protos_bytes.map((item, index) => ({
                ...item,
                avg_packets: data?.arborAlertDetail?.protos_packets[index]?.avg_packets,
                max_packets: data?.arborAlertDetail?.protos_packets[index]?.max_packets,
            })) || [],
        tcpFlags: () =>
            data && data?.arborAlertDetail?.tcp_flags_bytes.map((item, index) => ({
                ...item,
                avg_packets: data?.arborAlertDetail?.tcp_flags_packets[index]?.avg_packets,
                max_packets: data?.arborAlertDetail?.tcp_flags_packets[index]?.max_packets,
            })) || [],
        misuseType: () =>
            data && data?.arborAlertDetail?.misuse_types_bytes.map((item, index) => ({
                ...item,
                avg_packets: data?.arborAlertDetail?.misuse_types_packets[index]?.avg_packets,
                max_packets: data?.arborAlertDetail?.misuse_types_packets[index]?.max_packets,
            })) || [],

    };

    return (
        <Document>
            <Page size="A4" style={styles.page} wrap={true}>
                <Header/>
                <FirstPage store={store} data={data}/>
            </Page>
            <Page size="A4" style={styles.page} wrap={true}>
                <Header/>
                <Table
                    title={'Source TCP Ports'}
                    headTitles={['Port', 'Name', 'Avg (Max), bps', 'Avg (Max), pps']}
                    data={data && customDataDetails.srcTcpPorts()}
                    renderRow={data =>
                        data &&
                        data.map(item => {
                            return (
                                <View key={new Date().getTime() + item.name_bytes} style={styles.wrapper}>
                                    <View style={styles.wrapperBox4}>
                                        <View style={styles.card}>
                                            <Text style={styles.tableText}>{item.id_bytes }</Text>
                                        </View>
                                    </View>
                                    <View style={styles.wrapperBox4}>
                                        <View style={styles.card}>
                                            <Text style={styles.tableText}>{item.name_bytes }</Text>
                                        </View>
                                    </View>
                                    <View style={styles.wrapperBox4}>
                                        <View style={styles.card}>
                                            <Text style={styles.tableText}>
                                                {valueCalculation(item.avg_bytes, 'bps')} ({valueCalculation(item.max_bytes, 'bps')})
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={styles.wrapperBox4}>
                                        <View style={styles.card}>
                                            <Text style={styles.tableText}>
                                                {valueCalculation(item.avg_packets, 'pps')} ({valueCalculation(item.max_packets, 'pps')})
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                            );
                        })
                    }
                />
            </Page>
            <Page size="A4" style={styles.page} wrap={true}>
                <Header/>
                <Table
                    title={'Destination TCP Ports'}
                    headTitles={['Port', 'Name', 'Avg (Max), bps', 'Avg (Max), pps']}
                    data={data && customDataDetails.destTcp()}
                    renderRow={data =>
                        data &&
                        data.map(item => {
                            return (
                                <View key={new Date().getTime() + item.name_bytes} style={styles.wrapper}>
                                    <View style={styles.wrapperBox4}>
                                        <View style={styles.card}>
                                            <Text style={styles.tableText}>{item.id_bytes }</Text>
                                        </View>
                                    </View>
                                    <View style={styles.wrapperBox4}>
                                        <View style={styles.card}>
                                            <Text style={styles.tableText}>{item.name_bytes }</Text>
                                        </View>
                                    </View>
                                    <View style={styles.wrapperBox4}>
                                        <View style={styles.card}>
                                            <Text style={styles.tableText}>
                                                {valueCalculation(item.avg_bytes, 'bps')} ({valueCalculation(item.max_bytes, 'bps')})
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={styles.wrapperBox4}>
                                        <View style={styles.card}>
                                            <Text style={styles.tableText}>
                                                {valueCalculation(item.avg_packets, 'pps')} ({valueCalculation(item.max_packets, 'pps')})
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                            );
                        })
                    }
                />
            </Page>
            <Page size="A4" style={styles.page} wrap={true}>
                <Header/>
                <Table
                    title={'Source IP Addresses'}
                    headTitles={['IP', 'Avg (Max), bps', 'Avg (Max), pps', ' ']}
                    data={data && customDataDetails.srcIps()}
                    renderRow={data =>
                        data &&
                        data.map(item => {
                            return (
                                <View key={new Date().getTime() + item.name_bytes} style={styles.wrapper}>
                                    <View style={styles.wrapperBox4}>
                                        <View style={styles.card}>
                                            <Text style={styles.tableText}>{item.name_bytes === '0.0.0.0/0' ? 'Highly Distributed' : item.name_bytes}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.wrapperBox4}>
                                        <View style={styles.card}>
                                            <Text style={styles.tableText}>
                                                {valueCalculation(item.avg_bytes, 'bps')} ({valueCalculation(item.max_bytes, 'bps')})
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={styles.wrapperBox4}>
                                        <View style={styles.card}>
                                            <Text style={styles.tableText}>
                                                {valueCalculation(item.avg_packets, 'pps')} ({valueCalculation(item.max_packets, 'pps')})
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={styles.wrapperBox4}/>
                                </View>
                            );
                        })
                    }
                />
            </Page>
            <Page size="A4" style={styles.page} wrap={true}>
                <Header/>
                <Table
                    title={'Destination IP Addresses'}
                    headTitles={['IP', 'Avg (Max), bps', 'Avg (Max), pps', ' ']}
                    data={data && customDataDetails.destIps()}
                    renderRow={data =>
                        data &&
                        data.map(item => {
                            return (
                                <View key={new Date().getTime() + item.name_bytes} style={styles.wrapper}>
                                    <View style={styles.wrapperBox4}>
                                        <View style={styles.card}>
                                            <Text style={styles.tableText}>{item.name_bytes === '0.0.0.0/0' ? 'Highly Distributed' : item.name_bytes}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.wrapperBox4}>
                                        <View style={styles.card}>
                                            <Text style={styles.tableText}>
                                                {valueCalculation(item.avg_bytes, 'bps')} ({valueCalculation(item.max_bytes, 'bps')})
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={styles.wrapperBox4}>
                                        <View style={styles.card}>
                                            <Text style={styles.tableText}>
                                                {valueCalculation(item.avg_packets, 'pps')} ({valueCalculation(item.max_packets, 'pps')})
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={styles.wrapperBox4}/>
                                </View>
                            );
                        })
                    }
                />
            </Page>
            <Page size="A4" style={styles.page} wrap={true}>
                <Header/>
                <Table
                    title={'Source UDP Ports'}
                    headTitles={['Port', 'Name', 'Avg (Max), bps', 'Avg (Max), pps']}
                    data={data && customDataDetails.srcUdp()}
                    renderRow={data =>
                        data &&
                        data.map(item => {
                            return (
                                <View key={new Date().getTime() + item.name_bytes} style={styles.wrapper}>
                                    <View style={styles.wrapperBox4}>
                                        <View style={styles.card}>
                                            <Text style={styles.tableText}>{item.id_bytes }</Text>
                                        </View>
                                    </View>
                                    <View style={styles.wrapperBox4}>
                                        <View style={styles.card}>
                                            <Text style={styles.tableText}>{item.name_bytes }</Text>
                                        </View>
                                    </View>
                                    <View style={styles.wrapperBox4}>
                                        <View style={styles.card}>
                                            <Text style={styles.tableText}>
                                                {valueCalculation(item.avg_bytes, 'bps')} ({valueCalculation(item.max_bytes, 'bps')})
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={styles.wrapperBox4}>
                                        <View style={styles.card}>
                                            <Text style={styles.tableText}>
                                                {valueCalculation(item.avg_packets, 'pps')} ({valueCalculation(item.max_packets, 'pps')})
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                            );
                        })
                    }
                />
            </Page>
            <Page size="A4" style={styles.page} wrap={true}>
                <Header/>
                <Table
                    title={'Destination UDP Ports'}
                    headTitles={['Port', 'Name', 'Avg (Max), bps', 'Avg (Max), pps']}
                    data={data && customDataDetails.destUdp()}
                    renderRow={data =>
                        data &&
                        data.map(item => {
                            return (
                                <View key={new Date().getTime() + item.name_bytes} style={styles.wrapper}>
                                    <View style={styles.wrapperBox4}>
                                        <View style={styles.card}>
                                            <Text style={styles.tableText}>{item.id_bytes }</Text>
                                        </View>
                                    </View>
                                    <View style={styles.wrapperBox4}>
                                        <View style={styles.card}>
                                            <Text style={styles.tableText}>{item.name_bytes }</Text>
                                        </View>
                                    </View>
                                    <View style={styles.wrapperBox4}>
                                        <View style={styles.card}>
                                            <Text style={styles.tableText}>
                                                {valueCalculation(item.avg_bytes, 'bps')} ({valueCalculation(item.max_bytes, 'bps')})
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={styles.wrapperBox4}>
                                        <View style={styles.card}>
                                            <Text style={styles.tableText}>
                                                {valueCalculation(item.avg_packets, 'pps')} ({valueCalculation(item.max_packets, 'pps')})
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                            );
                        })
                    }
                />
            </Page>
            <Page size="A4" style={styles.page} wrap={true}>
                <Header/>
                <Table
                    title={'Source Countries'}
                    headTitles={['Country', 'Avg (Max), bps', 'Avg (Max), pps', '']}
                    data={data && customDataDetails.srcCntr()}
                    renderRow={data =>
                        data &&
                        data.map(item => {
                            return (
                                <View key={new Date().getTime() + item.name_bytes} style={styles.wrapper}>
                                    <View style={styles.wrapperBox4}>
                                        <View style={styles.card}>
                                            <Text style={styles.tableText}>{item.country_bytes}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.wrapperBox4}>
                                        <View style={styles.card}>
                                            <Text style={styles.tableText}>
                                                {valueCalculation(item.avg_bytes, 'bps')} ({valueCalculation(item.max_bytes, 'bps')})
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={styles.wrapperBox4}>
                                        <View style={styles.card}>
                                            <Text style={styles.tableText}>
                                                {valueCalculation(item.avg_packets, 'pps')} ({valueCalculation(item.max_packets, 'pps')})
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={styles.wrapperBox4}/>
                                </View>
                            );
                        })
                    }
                />
            </Page>
            <Page size="A4" style={styles.page} wrap={true}>
                <Header/>
                <Table
                    title={'Source ASNs'}
                    headTitles={['Port', 'Name', 'Avg (Max), bps', 'Avg (Max), pps']}
                    data={data && customDataDetails.srcAsn()}
                    renderRow={data =>
                        data &&
                        data.map(item => {
                            return (
                                <View key={new Date().getTime() + item.name_bytes} style={styles.wrapper}>
                                    <View style={styles.wrapperBox4}>
                                        <View style={styles.card}>
                                            <Text style={styles.tableText}>{item.id_bytes }</Text>
                                        </View>
                                    </View>
                                    <View style={styles.wrapperBox4}>
                                        <View style={styles.card}>
                                            <Text style={styles.tableText}>{item.name_bytes }</Text>
                                        </View>
                                    </View>
                                    <View style={styles.wrapperBox4}>
                                        <View style={styles.card}>
                                            <Text style={styles.tableText}>
                                                {valueCalculation(item.avg_bytes, 'bps')} ({valueCalculation(item.max_bytes, 'bps')})
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={styles.wrapperBox4}>
                                        <View style={styles.card}>
                                            <Text style={styles.tableText}>
                                                {valueCalculation(item.avg_packets, 'pps')} ({valueCalculation(item.max_packets, 'pps')})
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                            );
                        })
                    }
                />
            </Page>
            <Page size="A4" style={styles.page} wrap={true}>
                <Header/>
                <Table
                    title={'Protocols'}
                    headTitles={['Name', 'Avg (Max), bps', 'Avg (Max), pps', ' ']}
                    data={data && customDataDetails.protocols()}
                    renderRow={data =>
                        data &&
                        data.map(item => {
                            return (
                                <View key={new Date().getTime() + item.name_bytes} style={styles.wrapper}>
                                    <View style={styles.wrapperBox4}>
                                        <View style={styles.card}>
                                            <Text style={styles.tableText}>{item.name_bytes }</Text>
                                        </View>
                                    </View>
                                    <View style={styles.wrapperBox4}>
                                        <View style={styles.card}>
                                            <Text style={styles.tableText}>
                                                {valueCalculation(item.avg_bytes, 'bps')} ({valueCalculation(item.max_bytes, 'bps')})
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={styles.wrapperBox4}>
                                        <View style={styles.card}>
                                            <Text style={styles.tableText}>
                                                {valueCalculation(item.avg_packets, 'pps')} ({valueCalculation(item.max_packets, 'pps')})
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={styles.wrapperBox4}/>
                                </View>
                            );
                        })
                    }
                />
            </Page>
            <Page size="A4" style={styles.page} wrap={true}>
                <Header/>
                <Table
                    title={'TCP Flags'}
                    headTitles={['Flag', 'Name', 'Avg (Max), bps', 'Avg (Max), pps']}
                    data={data && customDataDetails.tcpFlags()}
                    renderRow={data =>
                        data &&
                        data.map(item => {
                            return (
                                <View key={new Date().getTime() + item.name_bytes} style={styles.wrapper}>
                                    <View style={styles.wrapperBox4}>
                                        <View style={styles.card}>
                                            <Text style={styles.tableText}>{item.id_bytes }</Text>
                                        </View>
                                    </View>
                                    <View style={styles.wrapperBox4}>
                                        <View style={styles.card}>
                                            <Text style={styles.tableText}>{item.name_bytes }</Text>
                                        </View>
                                    </View>
                                    <View style={styles.wrapperBox4}>
                                        <View style={styles.card}>
                                            <Text style={styles.tableText}>
                                                {valueCalculation(item.avg_bytes, 'bps')} ({valueCalculation(item.max_bytes, 'bps')})
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={styles.wrapperBox4}>
                                        <View style={styles.card}>
                                            <Text style={styles.tableText}>
                                                {valueCalculation(item.avg_packets, 'pps')} ({valueCalculation(item.max_packets, 'pps')})
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                            );
                        })
                    }
                />
            </Page>
            <Page size="A4" style={styles.page} wrap={true}>
                <Header/>
                <Table
                    title={'Misuse Types'}
                    headTitles={['Type', 'Avg (Max), bps', 'Avg (Max), pps', ' ']}
                    data={data && customDataDetails.misuseType()}
                    renderRow={data =>
                        data &&
                        data.map(item => {
                            return (
                                <View key={new Date().getTime() + item.name_bytes} style={styles.wrapper}>
                                    <View style={styles.wrapperBox4}>
                                        <View style={styles.card}>
                                            <Text style={styles.tableText}>{item.name_bytes }</Text>
                                        </View>
                                    </View>
                                    <View style={styles.wrapperBox4}>
                                        <View style={styles.card}>
                                            <Text style={styles.tableText}>
                                                {valueCalculation(item.avg_bytes, 'bps')} ({valueCalculation(item.max_bytes, 'bps')})
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={styles.wrapperBox4}>
                                        <View style={styles.card}>
                                            <Text style={styles.tableText}>
                                                {valueCalculation(item.avg_packets, 'pps')} ({valueCalculation(item.max_packets, 'pps')})
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={styles.wrapperBox4}/>
                                </View>
                            );
                        })
                    }
                />
            </Page>
        </Document>
    )
};
