import React from 'react';
import { useStyles, AntTableCell } from '../../../../theme/Table';
import { TableRow } from '@material-ui/core';
import { DeleteForever, BorderColor } from '@material-ui/icons';

const BlackList = ({ blacklistId, blacklistData, blacklistComment, setIsEditComment, setIsDeleted }) => {
    const classes = useStyles();

    return (
        <>
            <AntTableCell component="th" scope="row">
                {blacklistData}
            </AntTableCell>
            <AntTableCell align="center" className={classes.comment} title={blacklistComment} style={{ whiteSpace: 'normal' }}>
                {blacklistComment.substr(0, 50)}
            </AntTableCell>
            <AntTableCell>
                <BorderColor
                    viewBox="0 0 24 24"
                    className={classes.icon}
                    onClick={() =>
                        setIsEditComment({
                            edit: true,
                            id: blacklistId,
                            ip: blacklistData,
                            comment: blacklistComment,
                        })
                    }
                />
            </AntTableCell>
            <AntTableCell align="center">
                <DeleteForever
                    viewBox="0 0 24 24"
                    className={classes.iconDelete}
                    onClick={() =>
                        setIsDeleted({
                            deleted: true,
                            id: blacklistId,
                            ip: blacklistData,
                        })
                    }
                />
            </AntTableCell>
        </>
    );
};

export default BlackList;
