import React, {useContext, useEffect, useState} from 'react';
import { AntTab, AntTabs, useStyles } from '../../theme/Tabs';
import { AntContainerHead } from '../../theme/Wrapper';
import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router';
import { useIntl } from 'react-intl';
import DosgateAnomalies from "./anomalies";
import TabPanelLayout from "../../components/global/TabPanelLayout";
import DosgateResource from "./resources";
import DosgateTraffic from "./trafficStatistic";
import {PageContext} from "../../context/pages";
import {dateTabsColculation} from "../../helpers/calculation";

const Dosgate = () => {
    const classes = useStyles();
    const [indexTab, setIndexTab] = useState(0);
    const { tab } = useParams();
    const { formatMessage } = useIntl();
    const { deleteFilter, filterActive, setFilterActive } = useContext(PageContext);

    const handleChange = (event, newValue) => {
        setIndexTab(newValue);
    };
    const a11yProps = index => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    useEffect(() => {
        setIndexTab(Number(tab));
    }, [tab]);

    return (
        <>
            <div position="static" className={classes.bar}>
                <AntContainerHead maxWidth={false}>
                    <AntTabs value={indexTab} onChange={handleChange} className={classes.itemTab}>
                        <AntTab
                            component={NavLink}
                            label={formatMessage({ id: 'l3Protection.resource' })}
                            {...a11yProps(0)}
                            to="/dosgate/0"
                            className={`${classes.link} ${classes.marginRight}`}
                            activeClassName={classes.linkActive}
                            onClick={() => deleteFilter()}
                        />
                        <AntTab
                            component={NavLink}
                            label={formatMessage({ id: 'l3Protection.anomalies' })}
                            {...a11yProps(1)}
                            to="/dosgate/1"
                            className={`${classes.link} ${classes.marginRight}`}
                            activeClassName={classes.linkActive}
                            onClick={() => deleteFilter()}
                        />
                        <AntTab
                            component={NavLink}
                            label={formatMessage({ id: 'l3Protection.traffic.title' })}
                            {...a11yProps(2)}
                            to="/dosgate/2"
                            className={`${classes.link} ${classes.marginRight}`}
                            activeClassName={classes.linkActive}
                            onClick={() => {
                                setFilterActive({ ...filterActive, startDate: dateTabsColculation(indexTab, true), endDate: new Date().setDate(new Date().getDate()), indexTab: 0 });
                            }}
                        />
                    </AntTabs>
                </AntContainerHead>
            </div>
            <TabPanelLayout value={indexTab} index={0}>
                <div className={classes.wrapperTable}>
                    <DosgateResource/>
                </div>
            </TabPanelLayout>
            <TabPanelLayout value={indexTab} index={1}>
                <div className={classes.wrapperTable}>
                    <DosgateAnomalies/>
                </div>
            </TabPanelLayout>
            <TabPanelLayout value={indexTab} index={2}>
                <div className={classes.wrapperTable}>
                    <DosgateTraffic/>
                </div>
            </TabPanelLayout>
        </>
    )

};

export default Dosgate;