import React, { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import CloseIcon from '@material-ui/icons/Close';
import { useIntl } from 'react-intl';
import { Button, TextField, Dialog, Typography, FormHelperText } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { CREATE_L7_RESOURCE } from '../../../../actions/create';
import { toast } from 'react-toastify';
import { useStyles } from '../../../../theme/Modals';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const ModalCreateResource = ({ show, hideModal, id, query, getVariables }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();

    const checkDomain = /^(?:[-A-Za-z0-9А-Яа-яёЁ]+\.)+[-A-Za-z0-9А-Яа-яёЁ]{2,30}$/;
    const checkIp = /\b((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}\b/;

    const validationSchema = Yup.object().shape({
        domain: Yup.string().required('Current domain is required').matches(checkDomain, 'Domain is not correct'),
        ip: Yup.string().required('Current ip is required').matches(checkIp, 'IP is not correct'),
    });

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const [setResource] = useMutation(CREATE_L7_RESOURCE);
    const domain = useRef(null);
    const punycode = require('punycode');

    const resetValueInput = () => {
        reset({
            domain: '',
            ip: '',
        });
    };

    const onSubmit = inputValue => {
        const regexp = /[а-яёЁА-Я]/i;
        let punycodeDomain = inputValue.domain;
        if(regexp.test(inputValue.domain)) punycodeDomain = punycode.toASCII(inputValue.domain);
        setResource({
            variables: {
                l7ResourceName: punycodeDomain,
                originData: inputValue.ip,
                clientAccountId: id,
            },
            refetchQueries: [{ query: query, variables: getVariables }],
        })
            .then(() => {
                toast.success('Success');
                resetValueInput();
                hideModal();
            })
            .catch(err => {
                toast.error(err.message);
            });
    };

    return (
        <div>
            <Dialog
                className={classes.dialog}
                maxWidth="md"
                open={show}
                onClose={() => {
                    resetValueInput();
                    hideModal();
                }}
            >
                <div className={classes.modal}>
                    <div className={classes.header}>
                        <Typography variant="h1">{formatMessage({ id: 'webProtection.create.title' })}</Typography>

                        <Button
                            className={classes.close}
                            onClick={() => {
                                resetValueInput();

                                hideModal();
                            }}
                        >
                            <CloseIcon />
                        </Button>
                    </div>

                    <form form onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <div className={classes.modalBody}>
                                <div className={classes.inputLabel}>{formatMessage({ id: 'webProtection.create.input' })}</div>
                                <Controller
                                    name="domain"
                                    control={control}
                                    defaultValue=""
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            onChange={onChange}
                                            ref={domain}
                                            value={value}
                                            placeholder="Example: servicepipe.ru"
                                            variant="outlined"
                                            className={classes.input}
                                            error={!!errors?.domain}
                                        />
                                    )}
                                />
                                <FormHelperText style={{ marginBottom: 10, height: 20 }} error={!!errors?.domain}>
                                    {errors?.domain?.message}
                                </FormHelperText>

                                <div className={classes.inputLabel}>Type in original IP address (for clean traffic)</div>
                                <Controller
                                    name="ip"
                                    control={control}
                                    defaultValue=""
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            onChange={onChange}
                                            value={value}
                                            variant="outlined"
                                            className={classes.input}
                                            placeholder="Example: 128.125.128.30"
                                            error={!!errors?.ip}
                                        />
                                    )}
                                />
                                <FormHelperText style={{ height: 10 }} error={!!errors?.ip}>
                                    {errors?.ip?.message}
                                </FormHelperText>
                            </div>
                            <div className={classes.modalFooter}>
                                <Button
                                    className={classes.cancel}
                                    onClick={() => {
                                        hideModal();
                                        resetValueInput();
                                    }}
                                >
                                    {formatMessage({ id: 'webProtection.create.cancel' })}
                                </Button>
                                <Button type="submit" className={classes.invite}>
                                    {formatMessage({ id: 'webProtection.create.accept' })}
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </Dialog>
        </div>
    );
};

export default ModalCreateResource;
