import React from 'react';
import { useStyles } from './styles';
import AuthWrapper from '../../../components/global/Wrappers/authWrapper';
import { Link } from 'react-router-dom';
import routes from '../../../routes';
import spLogoMain from "../../../../assets/img/logo-welness.svg";
import {checkHosts} from "../../../branding";
import {logosAuth} from "../../../branding/logos";

const JoinAccountDisabledToken = () => {
    const classes = useStyles();

    return (
        <AuthWrapper>
            <div>
                <div className={classes.logo}>
                    { checkHosts(window.location.host, logosAuth ) }
                </div>
                <div className={classes.title}>Join account</div>
                <div className={classes.description}>
                    Invite link is no longer active!
                    <br/>
                    <br/>
                    <br/>
                    Most likely you have already used it and you already have an account in our system. If this is not the case, please contact our support.
                </div>
                <Link to={routes.auth} className={classes.link}>
                    sign in
                </Link>
            </div>
        </AuthWrapper>
    );
};

export default JoinAccountDisabledToken;
