import React, {useEffect} from 'react';
import {useLazyQuery} from '@apollo/client';
import { GET_L7_GRAPHITE_RESPONSE_TIMES } from '../../actions/get';
import { useIntl } from 'react-intl';
import Preloader from "../global/Preloader";
import { Bar } from 'react-chartjs-2';
import ChartjsPluginStacked100 from 'chartjs-plugin-stacked100';
import { Chart, Interaction } from 'chart.js';
import { CrosshairPlugin, Interpolate } from 'chartjs-plugin-crosshair';
import { useStyles } from '../../theme/Chart';

Chart.register(CrosshairPlugin, ChartjsPluginStacked100);
Interaction.modes.interpolate = Interpolate;

const ColumnChartsResponseTime = ({ id, date, setFilter }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const dateFormat = require('dateformat');

    const [getResponseTimes, { data, loading, error }] = useLazyQuery(GET_L7_GRAPHITE_RESPONSE_TIMES, {
        variables: {
            from: (Date.parse(date.startDate) - (Date.parse(date.startDate) % 1000)) / 1000 || (date.startDate - (date.startDate % 1000)) / 1000,
            until: (Date.parse(date.endDate) - (Date.parse(date.endDate) % 1000)) / 1000 || (date.endDate - (date.endDate % 1000)) / 1000,
            l7ResourceId: id,
        },
        fetchPolicy: 'network-only',
    });

    const obj = {
        lessOneThird: {
            label: '< 0.3 sec.',
            backgroundColor: '#69F0AE',
        },
        moreOneThirdLessOne: {
            label: '0.3 - 1 sec.',
            backgroundColor: '#80CBC4',
        },
        moreOneLessThree: {
            label: '1-3 sec.',
            backgroundColor: '#FFD740',
        },
        moreThreeLessTen: {
            label: '3-10 sec.',
            backgroundColor: '#FF6E40',
        },
        moreTen: {
            label: '> 10 sec.',
            backgroundColor: '#FF3D00',
        },
    };

    let customData = [];

    useEffect(() => {
        getResponseTimes();
        setFilter({...date, activeZoomChart: true});
    }, []);

    if(data) {
        customData = Object.entries(data.l7GraphiteResponseTimes).reduce((acc, item) => {
            if(item[1]?.length && Array.isArray(item[1]) && item[0] !== 'timestamp') {
                return [...acc, {
                    label: obj[item[0]]?.label,
                    data: item[1],
                    backgroundColor: obj[item[0]]?.backgroundColor
                }]
            } else {
                return acc;
            }
        }, []);
    }

    if (loading) return <Preloader/>;
    if (error || data?.l7GraphiteResponseTimes?.timestamp.length < 1) return <div className={classes.errorCharts}>{formatMessage({ id: 'charts.error' })}</div>;

    return (
        <div className={classes.chart}>
            <button className={date.activeZoomChart ? classes.visibleZoomBtn : classes.zoomBtnDashboard}
                    onClick={() => {
                        getResponseTimes();
                        setFilter({...date, activeZoomChart: true});
                    }}
            >
                RESET ZOOM
            </button>
            <Bar
                data={{
                    labels: data?.l7GraphiteResponseTimes?.timestamp,
                    datasets: customData,
                }}
                options={{
                    maintainAspectRatio: false,
                    indexAxis: "x",
                    animation: false,
                    scales: {
                        x: {
                            grid: {
                                display: false,
                                drawBorder: false,
                            },
                            ticks: {
                                display: true,
                                callback: function (value, index, values) {
                                    return index % 2 === 0 ? dateFormat(this.getLabelForValue(value) * 1000, 'h:MM:ssTT') : '';
                                }
                            }
                        }
                    },
                    plugins: {
                        stacked100: { enable: true },
                        legend: {
                            position: 'bottom',
                        },
                        crosshair: {
                            line: {
                                color: '#848484',
                                width: 0.5
                            },
                            sync: {
                                enabled: false
                            },
                            zoom: {
                                enabled: true,
                                zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',
                                zoomboxBorderColor: '#48F',
                                zoomButtonText: 'reset zoom',
                                zoomButtonClass: 'zoom',
                            },
                            callbacks: {
                                afterZoom: () => function(start, end) {
                                    const afterZoomDate = data?.l7GraphiteResponseTimes?.timestamp.filter((item, index) => {
                                        if(index === start) return item ;
                                        else if(index === end) return item;
                                    });
                                    const startDate = afterZoomDate[0];
                                    const endDate = afterZoomDate[1];
                                    getResponseTimes({
                                        variables: {
                                            from: startDate,
                                            until: endDate,
                                            l7ResourceId: id,
                                        }
                                    });
                                    setFilter({...date, activeZoomChart: false});
                                }
                            }
                        },
                        tooltip: {
                            animation: false,
                            mode: "interpolate",
                            intersect: false,
                            callbacks: {
                                title: function(context) {
                                    return dateFormat(context[0].label * 1000, 'dd/mm/yy h:MM:ssTT');
                                },
                            }
                        }
                    }
                }} />
        </div>
    );
};

export default ColumnChartsResponseTime;
