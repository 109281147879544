import React, {useState} from "react";
import {AntTab, AntTabs, useStyles} from "../../../../theme/Tabs";
import {useIntl} from "react-intl";
import {AntContainerHead} from "../../../../theme/Wrapper";
import {Link} from "react-router-dom";
import MitigationTrafficDetails from "./traffic";
import TabPanelLayout from "../../../../components/global/TabPanelLayout";
import { useParams } from 'react-router';
import back from "../../../../../assets/img/back.svg";
import TabPanel from "../../../../components/global/TabPanel";

const MitigationsDetails = () => {
    const classes = useStyles();
    const [indexTab, setIndexTab] = useState(0);
    const { formatMessage } = useIntl();
    const { backLink, indexPage } = useParams();

    const handleChange = (event, newValue) => {
        setIndexTab(newValue);
    };
    const a11yProps = index => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };
    return (
        <>
            <div position="static" className={classes.bar}>
                <AntContainerHead maxWidth={false} className={classes.container}>
                    <div className={classes.itemBackLink}>
                        <Link to={`/${backLink}/${indexPage}`} className={classes.backLink}>
                            <img src={back} alt="#" className={classes.backIcon}/>
                        </Link>
                    </div>
                    <AntTabs value={indexTab} onChange={handleChange} className={classes.itemTab}>
                        <AntTab label={formatMessage({ id: 'mitigations.details.tab1' })} {...a11yProps(0)} className={classes.marginLeft} />
                    </AntTabs>
                </AntContainerHead>
            </div>
            <TabPanel value={indexTab} index={0}>
                <MitigationTrafficDetails/>
            </TabPanel>
        </>
    );
};

export default MitigationsDetails;
