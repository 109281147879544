/* eslint-disable import/no-anonymous-default-export */
import { LOCALES } from '../locales';

export default {
    [LOCALES.ENGLISH]: {
        // table
        'table.pagination': 'Rows per page:',
        'table.filter': 'Filter',
        // table.setFilter
        'table.filter.title': 'Filter',
        'table.filter.label': 'Treat level',
        // date
        'date.minute': '1 hour',
        'date.4hours': '4 hours',
        'date.day': '24 hours',
        'date.week': 'Week',
        'date.month': 'Month',
        // header
        'header.support': 'Support',
        'header.account': 'Account ID:',
        'headerTab.l3protection': 'Network protection',
        'headerTab.webProtection': 'Application protection',
        'headerTab.dashboard': 'Dashboard',
        'headerTab.l3protection20': 'Network protection ver 2.0',
        // profile
        'profile.tab.profile': 'my profile',
        'profile.tab.pass': 'password',
        'profile.tab.members': 'Account members',
        'profile.tab.not': 'Notification',
        'profile.pop.profile': 'My profile',
        'profile.pop.pass': 'Change password',
        'profile.pop.members': 'Members',
        'profile.pop.not': 'Notification',
        'profile.pop.out': 'log out',
        // profile.info
        'profile.info.title': 'User Information',
        'profile.info.name1': 'First Name',
        'profile.info.name2': 'Last Name',
        'profile.info.btn': 'Save',
        // profile.pass
        'profile.pass.title': 'Secure',
        'profile.pass.btn': 'restore password',
        'profile.pass.now': 'Current Password',
        'profile.pass.new': 'New Password',
        'profile.pass.dbl': 'Confirm New Password',
        'profile.pass.8': '8 characters minimum',
        'profile.pass.lower': 'one lowercase character',
        'profile.pass.upper': 'one uppercase character',
        'profile.pass.number': 'one number',
        'profile.pass.sign': 'one special character',
        // profile.members
        'profile.members.title': 'Account Members',
        'profile.members.btn': 'invite member',
        'profile.members.id': 'ID',
        'profile.members.name': 'Name',
        'profile.members.email': 'Email',
        'profile.members.role': 'Role',
        'profile.members.status': 'Status',
        'profile.members.pop.settings': 'Access settings',
        'profile.members.pop.del': 'Delete',
        // profile.not
        'profile.not.title': 'Notification',
        'profile.not.switch': 'L3-4 Protection alert notification',
        'profile.not.subtitle': 'Alert severity level',
        'profile.not.high': 'High',
        'profile.not.medium': 'Medium',
        'profile.not.low': 'Low',
        // settingsMembers
        'settingsMembers.title': 'Resource settings',
        'settingsMembers.back': 'back',
        'settingsMembers.tab.network': 'network protection',
        'settingsMembers.tab.application': 'application protection',
        // settingsMembersNet
        'settingsMembersNet.title': 'Access to network protection resources for ',
        'settingsMembersNet.tableTitle.resName': 'Resource Name',
        'settingsMembersNet.checkLabel.view': 'View',
        // settingsMembersApp
        'settingsMembersApp.title': 'Access to application protection resources for ',
        'settingsMembersApp.checkLabel.l7': 'create application protection resources',
        'settingsMembersApp.tableTitle.resName': 'Resource Name',
        'settingsMembersApp.checkLabel.view': 'View',
        'settingsMembersApp.checkLabel.edit': 'Edit',
        'settingsMembersApp.checkLabel.delete': 'Delete',
        // modal.user
        'modal.user.invite.title': 'Invite user',
        'modal.user.invite.accept': 'Invite user',
        'modal.user.invite.cancel': 'cancel',
        'modal.user.delete.title': 'Delete user',
        'modal.user.delete.text': 'Are you sure that you want to delete the user?',
        'modal.user.delete.accept': 'delete',
        'modal.user.delete.cancel': 'cancel',
        // l3Protection
        'l3Protection.resource': 'Resources',
        'l3Protection.anomalies': 'anomalies',
        'l3Protection.mitigations': 'Mitigations',
        'l3Protection.traffic': 'Traffic',
        'l3Protection.reports': 'reports',
        'l3Protection.traffic.title': 'Traffic statistics',
        // resource
        'resource.title': 'L3-4 DDoS Protection resource list',
        'resource.id': 'ID',
        'resource.name': 'Resource Name',
        'resource.ips': 'IPs',
        'resource.date': 'Created At',
        // anomalies
        'anomalies.title': 'Detected traffic anomalies list',
        'anomalies.id': 'ID',
        'anomalies.lvl': 'Threat level',
        'anomalies.ips': 'Destination IPs',
        'anomalies.type': 'Type',
        'anomalies.name': 'Resource Name',
        'anomalies.start': 'Started At',
        'anomalies.end': 'Ended At',
        'anomalies.duration': 'Duration',
        'anomalies.max': 'Max volume',
        'anomalies.setFilter': 'Set filter',
        // mitigations
        'mitigations.title': 'Mitigations list',
        'mitigations.id': 'ID',
        'mitigations.id-anom': 'Anomaly ID',
        'mitigations.ip': 'Protected IPs',
        'mitigations.name': 'Resource Name',
        'mitigations.start': 'Started At',
        'mitigations.end': 'Ended At',
        'mitigations.duration': 'Duration',
        // reports
        'reports.title': 'Monthly reports',
        'reports.title.summary': 'Summary traffic',
        'reports.title.topAnomaliesTraffic': 'TOP 10 anomalies by traffic volume',
        'reports.title.topAnomaliesDuration': 'TOP 10 anomalies by duration',
        'reports.title.topMitigationsDuration': 'TOP 10 mitigations by duration',
        'reports.title.anomaliesSeverity': 'Anomalies severity level distribution',
        'reports.title.misuseType': 'Anomalies misuse type distribution',
        // reports.cards
        'reports.cards.anomalies': 'Anomalies total',
        'reports.cards.mitigations': 'Mitigations total',
        'reports.cards.attack': ' Max attack volume',
        'reports.cards.target': ' Popular target',
        // reportsTableAnomaliesDuration
        'reportsTableAnomaliesDuration.severity': 'Severity',
        'reportsTableAnomaliesDuration.ip': 'Destination IPs',
        'reportsTableAnomaliesDuration.type': 'Misuse types',
        'reportsTableAnomaliesDuration.name': 'Resource Name',
        'reportsTableAnomaliesDuration.start': 'Started At',
        'reportsTableAnomaliesDuration.ended': 'Ended At',
        'reportsTableAnomaliesDuration.duration': 'Duration',
        'reportsTableAnomaliesDuration.volume': 'Max volume',
        // reportsTableAnomaliesTraffic
        'reportsTableAnomaliesTraffic.severity': 'Severity',
        'reportsTableAnomaliesTraffic.ip': 'Destination IPs',
        'reportsTableAnomaliesTraffic.type': 'Misuse types',
        'reportsTableAnomaliesTraffic.name': 'Resource Name',
        'reportsTableAnomaliesTraffic.start': 'Started At',
        'reportsTableAnomaliesTraffic.ended': 'Ended At',
        'reportsTableAnomaliesTraffic.duration': 'Duration',
        'reportsTableAnomaliesTraffic.volume': 'Max volume',
        // reportsTableMitigationsDuration
        'reportsTableMitigationsDuration.anomalyId': 'Anomalies ID',
        'reportsTableMitigationsDuration.protected': 'Protected',
        'reportsTableMitigationsDuration.name': 'Resource Name',
        'reportsTableMitigationsDuration.start': 'Started At',
        'reportsTableMitigationsDuration.ended': 'Ended At',
        'reportsTableMitigationsDuration.duration': 'Duration',
        // mitigations.details
        'mitigations.details.tab1': 'mitigation traffic details',
        'mitigations.details.title': 'Mitigation',
        // detailsAnomalyTab
        'detailsAnomalyTab.summary': 'Summary',
        'detailsAnomalyTab.back': 'BACK',
        'detailsAnomalyTab.traffic': 'Traffic details',
        //detailsAnomalyTab cards
        'detailsAnomaly.title': 'Anomaly details',
        'detailsAnomaly.lvl': 'Severity Level',
        'detailsAnomaly.maxBit': 'Max bit rate',
        'detailsAnomaly.maxPacket': 'Max packet rate',
        'detailsAnomaly.name': 'Resource name',
        'detailsAnomaly.ip': 'Target IP',
        //detailsAnomalySummary
        'detailsAnomalySummary.areaChart': 'Anomaly traffic',
        'detailsAnomalySummary.characterization': 'Alert Characterization',
        'detailsAnomalySummary.barCharts': 'Packet Size Distribution',
        // detailsAnomalySummary.characteristic
        'detailsAnomalySummary.characteristic.ip': 'Destination IP Addresses',
        'detailsAnomalySummary.characteristic.type': 'Misuse Types',
        'detailsAnomalySummary.characteristic.protocol': 'Protocols',
        'detailsAnomalySummary.characteristic.tcp': 'Source TCP Ports',
        'detailsAnomalySummary.characteristic.country': 'Source Countries',
        'detailsAnomalySummary.characteristic.ips': 'Source IP Addresses',
        'detailsAnomalySummary.characteristic.tcp-p': 'Destination TCP Ports',
        'detailsAnomalySummary.characteristic.tcp-f': 'TCP Flags',
        'detailsAnomalySummary.characteristic.asn': 'Source ASNs',
        // anomalyDetailsTraffic.tableTop
        'anomalyDetailsTraffic.tableTop.noDataText':
            'No patterns found in the last 5 minutes of the selected timeframe.',
        'anomalyDetailsTraffic.tableTop.top': 'Top Traffic Patterns (last 5 minutes)',
        'anomalyDetailsTraffic.tableTop.source': 'Source',
        'anomalyDetailsTraffic.tableTop.protocol': 'Protocol',
        'anomalyDetailsTraffic.tableTop.flags': 'Flags',
        'anomalyDetailsTraffic.tableTop.sourcePort': 'Source Port',
        'anomalyDetailsTraffic.tableTop.destination': 'Destination',
        'anomalyDetailsTraffic.tableTop.destPort': 'Dest Port',
        'anomalyDetailsTraffic.tableTop.maxTraffic': 'Max traffic',
        // detailsAnomaly.trafficTitle
        'detailsAnomaly.trafficTitle.source-ip': 'Source IP Addresses',
        'detailsAnomaly.trafficTitle.dst-ip': 'Destination IP Addresses',
        'detailsAnomaly.trafficTitle.source-tcp': 'Source TCP Ports',
        'detailsAnomaly.trafficTitle.dst-tcp': 'Destination TCP Ports',
        'detailsAnomaly.trafficTitle.source-udp': 'Source UDP Ports',
        'detailsAnomaly.trafficTitle.dst-udp': 'Destination UDP Ports',
        'detailsAnomaly.trafficTitle.source-cnt': 'Source Countries',
        'detailsAnomaly.trafficTitle.source-asn': 'Source ASNs',
        'detailsAnomaly.trafficTitle.proto': 'Protocols',
        'detailsAnomaly.trafficTitle.tcp-f': 'TCP Flags',
        'detailsAnomaly.trafficTitle.type': 'Misuse Types',

        //anomalyDetailsTraffic
        'anomalyDetailsTraffic.name': 'Name',
        'anomalyDetailsTraffic.port': 'Port',
        'anomalyDetailsTraffic.country': 'Country',
        'anomalyDetailsTraffic.number': 'Number',
        'anomalyDetailsTraffic.flag': 'Flag',
        'anomalyDetailsTraffic.type': 'Type',
        'anomalyDetailsTraffic.avg': 'Avg (Max)',
        'anomalyDetailsTraffic.error': 'No items available.',
        'anomalyDetailsTraffic.more': ' View more',
        // webProtection
        'webProtection.tab.resource': 'resources',
        'webProtection.tab.statistics': 'traffic statistics',
        'webProtection.tab.analytics': 'traffic analytics',
        // webProtection.resource
        'webProtection.resource.title': 'Web protection resources',
        'webProtection.resource.btn': 'create resource',
        'webProtection.resource.id': 'ID',
        'webProtection.resource.name': 'Resource Name',
        'webProtection.resource.ddos': 'L7 DDoS protection',
        'webProtection.resource.bot': ' Active bot protection',
        'webProtection.resource.ip': 'Service IP',
        'webProtection.resource.date': 'Created At',
        'webProtection.resource.text': 'Are you sure that you want to delete the resource id',
        'webProtection.resource.active': 'Active',
        'webProtection.resource.dis': 'Disabled',
        'webProtection.resource.help1': 'For creation rights please ',
        'webProtection.resource.help2': 'contact your administrator.',
        // webProtection.pop
        'webProtection.pop.general': 'general settings',
        'webProtection.pop.aliases': 'aliases',
        'webProtection.pop.policy': 'Geo filtering',
        'webProtection.pop.white': 'white list',
        'webProtection.pop.black': 'black list',
        'webProtection.pop.delete': 'Delete',
        // webProtection.create
        'webProtection.create.title': 'Create resource',
        'webProtection.create.step1': 'Step 1',
        'webProtection.create.input': 'Type in the domain name (without www)',
        'webProtection.create.next': 'next',
        'webProtection.create.cancel': 'Cancel',
        'webProtection.create.step2': 'Step 2',
        'webProtection.create.input2': 'Type in original IP address (for clean traffic)',
        'webProtection.create.accept': 'create resource',
        // webProtection.delete
        'webProtection.delete.title': 'Delete resource',
        'webProtection.delete.subtitle': 'Are you sure that you want to delete the resource?',
        'webProtection.delete.text': 'After confirming the deletion, we will stop accepting and serving traffic for this resource!',
        'webProtection.delete.accept': 'Delete',
        'webProtection.delete.cancel': 'Cancel',
        // webProtection.tabs
        'webProtection.tabs.general': 'GENERAL SETTINGS',
        'webProtection.tabs.aliases': 'ALIASES',
        'webProtection.tabs.white': 'White list',
        'webProtection.tabs.black': 'black list',
        'webProtection.tabs.politic': 'Geo filtering',
        'webProtection.tabs.back': 'back',
        // webProtection.general
        'webProtection.general.title': 'General resource settings',
        'webProtection.general.domen': 'The domain name of the protected web resource',
        'webProtection.general.ssl': 'SSL Certificate',
        'webProtection.general.ip': 'Original IP addresses:',
        'webProtection.general.type': 'Type:',
        'webProtection.general.weight': 'Weight:',
        'webProtection.general.hash': 'Load balancing mode:',
        'webProtection.general.redirect': 'Redirect from www to the primary domain',
        'webProtection.general.edit': 'EDIT',
        'webProtection.general.switch1': 'Redirect from www to the primary domain',
        'webProtection.general.switch2': 'Global whitelist',
        'webProtection.general.switch2.dsc': 'Access for search engine bots and payment system',
        'webProtection.general.switch3': 'Redirect from http to https',
        'webProtection.general.switch4': 'Proxy https to origin http',
        'webProtection.general.switch4.dsc': 'Communicate with your server by HTTP instead of HTTPS',
        // webProtection.aliases
        'webProtection.aliases.title': 'Resource aliases',
        'webProtection.aliases.btn': 'add alias',
        'webProtection.aliases.main': 'Alias',
        'webProtection.aliases.general': 'General SSL',
        'webProtection.aliases.scrp': 'Let’s Encrypt SSL',
        'webProtection.aliases.custom': 'Custom SSL',
        'webProtection.aliases.date': 'Created At',
        'webProtection.aliases.ssl': 'SSL Certificate',
        'webProtection.aliases.delete.title': 'Delete Alias',
        'webProtection.aliases.delete.text': 'Are you sure that you want to delete this alias',
        'webProtection.aliases.delete.accept': 'delete',
        'webProtection.aliases.delete.cancel': 'cancel',
        'webProtection.aliases.create.title': 'Add alias',
        'webProtection.aliases.create.subtitle': 'Type in the domain name',
        'webProtection.aliases.create.span': 'Example: servicepipe.ru',
        'webProtection.aliases.create.accept': 'add',
        'webProtection.aliases.create.cancel': 'Cancel',
        // webProtection.white
        'webProtection.white.title': 'White list',
        'webProtection.white.btn': 'Add new',
        'webProtection.white.ip': 'IP',
        'webProtection.white.comment': 'Comment',
        'webProtection.white.date': 'Created At',
        'webProtection.white.create.title': 'Add new IP to white list',
        'webProtection.white.create.accept': 'add',
        'webProtection.white.create.cancel': 'Cancel',
        'webProtection.white.comment.title': 'Add comment for IP',
        'webProtection.white.comment.cancel': 'Cancel',
        'webProtection.white.comment.accept': 'save',
        // webProtection.black
        'webProtection.black.title': 'Black list',
        'webProtection.black.create.title': 'Add new IP to black list',
        // webProtection.policy
        'webProtection.policy.title': 'Country access policy',
        'webProtection.policy.subtitle': 'Allow access for all countries except for:',
        'webProtection.policy.block': 'Deny access for all countries except for:',
        'webProtection.policy.input': 'Enter at least 1 country',
        'webProtection.policy.radio0': 'None',
        'webProtection.policy.radio1': 'Allow by default',
        'webProtection.policy.radio2': 'Block by default',
        'webProtection.policy.save': 'Save',
        'webProtection.policy.valid': 'Please enter at least 1 country',
        // webProtection.charts
        'webProtection.charts.title': 'Realtime traffic analytics',
        'webProtection.charts.request': 'Requests',
        'webProtection.charts.band': 'BandWidth',
        'webProtection.charts.analytics': 'Requests Analytics ',
        'webProtection.charts.times': 'Response Times',
        'webProtection.charts.codes': 'Response Codes',
        'webProtection.charts.errors': '5xx Errors',
        'webProtection.charts.geo': 'Requests geography',
        //webProtection.requestsGeography
        'webProtection.requestsGeography.source': 'Source Countries',
        'webProtection.requestsGeography.requests': 'Requests',
        'webProtection.requestsGeography.total': '% of Total',
        'webProtection.requestsGeography.countries': 'TOP 10 source countries distribution',
        // webProtection.analytic
        'webProtection.analytic.title': 'Traffic analytics',
        'webProtection.analytic.tab.osDist': 'OS distribution',
        'webProtection.analytic.tab.uaDist': 'UA distribution',
        'webProtection.analytic.tab.botIp': 'bots IP-addresses',
        'webProtection.analytic.tab.botSunb': 'Bots subnets',
        'webProtection.analytic.tab.botReq': 'bots requests',
        'webProtection.analytic.botIp.tableTitle': 'TOP 30 bots IP-addresses',
        'webProtection.analytic.botIp.ip.title': 'IP-address',
        'webProtection.analytic.botIp.ip.btn1': 'show blocked requests',
        'webProtection.analytic.botIp.ip.btn2': 'count',
        'webProtection.analytic.botIp.ip.requests': 'Requests',
        'webProtection.analytic.botIp.validIp.required': 'Current ip is required',
        'webProtection.analytic.botIp.validIp.matches': 'IP is not correct',
        'webProtection.analytic.botReq.tableTitle': 'TOP 30 bots requests',
        'webProtection.analytic.osDist.titleBot': 'Bots OS',
        'webProtection.analytic.osDist.titleUser': 'Users OS',
        'webProtection.analytic.uaDist.titleBot': 'TOP 30 bots UA',
        'webProtection.analytic.uaDist.titleUser': 'TOP 30 users UA',
        'webProtection.analytic.disabled': 'Bot protection is not activated for this resource',

        // dashboard
        'dashboard.net.title': 'Network protection',
        'dashboard.app.title': 'Application protection',
        // dosgate
        'dosgate.trafficStatistic.title': 'Traffic statistics',
        // charts
        'charts.error': 'NO DATA',
        // chartsAreaAllL7Resource
        'chartsAreaAllL7Resource.in': 'IN',
        'chartsAreaAllL7Resource.out': 'OUT',
        'chartsAreaAllL7Resource.95in': '95ptl (IN)',
        'chartsAreaAllL7Resource.96out': '95ptl (OUT)',
        'chartsAreaAllL7Resource.totalBand': 'Total Bandwidth',
        // chartsAreaAllTraffic
        'chartsAreaAllTraffic.in': 'IN',
        'chartsAreaAllTraffic.out': 'OUT',
        'chartsAreaAllTraffic.95in': '95ptl (IN)',
        'chartsAreaAllTraffic.96out': '95ptl (OUT)',
        'chartsAreaAllTraffic.totalBand': 'Total Bandwidth',
        'chartsAreaAllTraffic.anomalies': 'Anomalies',
        'chartsAreaAllTraffic.mitigations': 'Mitigations',
        // chartsAreaChartsBandWidth
        'chartsAreaChartsBandWidth.inbound': 'Inbound traffic',
        'chartsAreaChartsBandWidth.passed': 'Passed to origin',
        'chartsAreaChartsBandWidth.outbound': 'Outbound traffic',
        'chartsAreaChartsBandWidth.tspFrom': '[tcp stream] Passed to origin',
        'chartsAreaChartsBandWidth.tspTo': '[tcp stream] Outbound traffic',
        // chartsAreaChartsRequest
        'chartsAreaChartsRequest.legitimateRequest': 'Legitimate',
        'chartsAreaChartsRequest.blockedRequest': 'Blocked',
        // chartsAreaChartsRequestAnalytics
        'chartsAreaChartsRequestAnalytics.humans': 'Humans',
        'chartsAreaChartsRequestAnalytics.good': 'Good bots',
        'chartsAreaChartsRequestAnalytics.bad': 'Bad bots',
        'chartsAreaChartsRequestAnalytics.white': 'White listed',
        // chartsAreaCountryTop10
        'chartsAreaChartsRequestAnalytics.title': 'TOP 10 source countries in dynamics',
        // chartsAreaDosgateTraffic
        'chartsAreaDosgateTraffic.accept': 'Accept',
        'chartsAreaDosgateTraffic.reply': 'Reply',
        'chartsAreaDosgateTraffic.drop': 'Drop',
        'chartsAreaDosgateTraffic.title': 'Mitigation statistics',
        // chartsAreaDropPass
        'chartsAreaDropPass.drop': 'DROP',
        'chartsAreaDropPass.pass': 'PASS',
        // chartsAreaSummaryTraffic
        'chartsAreaSummaryTraffic.in': 'IN',
        'chartsAreaSummaryTraffic.out': 'OUT',
        // chartsBarChartsBasic
        'chartsBarChartsBasic.jumboFrames': 'jumbo frames',
        // chartsLineTraffic
        'chartsLineTraffic.in': 'IN',
        'chartsLineTraffic.out': 'OUT',
        // chartsPieCountryTop10
        'chartsPieCountryTop10.title': 'TOP 10 Source Countries distribution',
        // chartsPieMisuseTypes
        'chartsPieMisuseTypes.title': 'Anomalies severity level distribution',
        // chartsPieSeverityLvl
        'chartsPieSeverityLvl.title': 'Anomalies severity level distribution',
        //  chartsAreaMitigationsStatistic
        'chartsAreaMitigationsStatistic.rTotal': 'Rates Total',
        'chartsAreaMitigationsStatistic.rHttp': 'Rates Http',
        'chartsAreaMitigationsStatistic.rFilter': 'Rates Filter',
        'chartsAreaMitigationsStatistic.rProtocol': 'Rates Protocol Baseline',
        'chartsAreaMitigationsStatistic.rSource24': 'Rates Source24 Baseline',
        'chartsAreaMitigationsStatistic.rSynAuth': 'Rates Syn Auth',
        'chartsAreaMitigationsStatistic.rDnsAuth': 'Rates Dns Auth',
        'chartsAreaMitigationsStatistic.rDnsMalformed': 'Rates Dns Malformed',
        'chartsAreaMitigationsStatistic.rHttpRegex': 'Rates Http Regex',
        'chartsAreaMitigationsStatistic.rDnsRegex': 'Rates Dns Regex',
        'chartsAreaMitigationsStatistic.rIdleReset': 'Rates Idle Reset',
        'chartsAreaMitigationsStatistic.rHttpMalformed': 'Rates Http Malformed',
        'chartsAreaMitigationsStatistic.rSipRateLimit': 'Rates Sip Rate limit',
        'chartsAreaMitigationsStatistic.rSipMalformed': 'Rates Sip Malformed',
        'chartsAreaMitigationsStatistic.rZombie': 'Rates Zombie',
        'chartsAreaMitigationsStatistic.rRateLimit': 'Rates Rate limit',
        'chartsAreaMitigationsStatistic.rRegex': 'Rates Regex',
        'chartsAreaMitigationsStatistic.rInvalidPkts': 'Rates Invalid Pkts',
        'chartsAreaMitigationsStatistic.rDnsRateLimit': 'Rates Dns Rate limit',
        'chartsAreaMitigationsStatistic.rDnsNxrRateLimit': 'Rates Dns Nx Rate limit',
        'chartsAreaMitigationsStatistic.rGeoIpFilter': 'Rates Geo ip Filter',
        'chartsAreaMitigationsStatistic.rGeoIpRateLimit': 'Rates Geoip Rate limit',
        'chartsAreaMitigationsStatistic.rSrcNetFilter': 'Rates Srcnet Filter',
        'chartsAreaMitigationsStatistic.rConnLimit': 'Rates Conn limit',

        //modals
        //modalEditSSLCertificate
        'modalEditSSLCertificate.title': 'SSL Settings',
        'modalEditSSLCertificate.none.dsc': "We will use HTTP protocol if you haven't added an SSL Certificate for this domain.",
        'modalEditSSLCertificate.general.dsc': 'We will use the SSL Certificate which you set up at the ',
        'modalEditSSLCertificate.general.dscLink': 'General settings tab.',
        'modalEditSSLCertificate.encrypt.des':
            "We will issue Let's Encrypt SSL Certificate for this domain and automatic prolongate. Warning! This option will work only if you have already transferred traffic to our IP address.",
        'modalEditSSLCertificate.custom.desCertificate': 'Certificate chain (Should be in PEM format)',
        'modalEditSSLCertificate.custom.desPrivate': 'Private key (Should be in PEM format)',
        'modalEditSSLCertificate.btn.cancel': 'cancel',
        'modalEditSSLCertificate.btn.save': 'save changes',
        //trial
        'trial.full': 'Your free trial ends in',
        'trial.days': 'days',
        'trial.end': 'Free trial is ended',
        'trial.title': 'Free trial is ended.',
        'trial.subtitle': 'Please contact your account manager to switch to the commercial operation of the service.',

    },
};
