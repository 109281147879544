import React from 'react';
import { useStyles, AntDatePaper, AntDateTabs, AntDateTab } from './styles';
import { dateTabsColculation } from '../../../helpers/calculation';
import { useIntl } from 'react-intl';

const TableDateTabs = ({ filter, setFilter, dosGate, disabled }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();

    return (
        <div className={classes.dateTabsWrapper}>
            <AntDatePaper elevation={3} square>
                <AntDateTabs
                    value={filter.indexTab}
                    disabled={true}
                    onChange={(event, indexTab) => {
                        setFilter({
                            ...filter,
                            startDate: dateTabsColculation(indexTab, dosGate),
                            endDate: new Date().setDate(new Date().getDate()),
                            indexTab,
                        });
                    }}
                >
                    {dosGate && <AntDateTab label={formatMessage({ id: 'date.minute' })} disabled={disabled} />}
                    <AntDateTab label={formatMessage({ id: 'date.day' })} disabled={disabled} />
                    <AntDateTab label={formatMessage({ id: 'date.week' })} disabled={disabled} />
                    {!dosGate && <AntDateTab label={formatMessage({ id: 'date.month' })} disabled={disabled} />}
                </AntDateTabs>
            </AntDatePaper>
        </div>
    );
};
export default TableDateTabs;
