import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Tabs, Tab, LinearProgress } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    wrapper: {
        [theme.breakpoints.only('xs')]: {
            padding: '25px 16px 25px 16px',
            backgroundColor: '#FAFAFA',
        },
    },
    headerWrapper: {
        padding: 30,
    },
    cardsHeaderWrapper: {
        display: 'flex',
        flexDirection: 'row',
    },
    titleWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    tabs: {
        display: 'flex',
    },
    wrapperBox: {
        [theme.breakpoints.only('xs')]: {
            border: '1px solid #E5E5E5',
            borderRadius: '4px',
            backgroundColor: '#fff',
            padding: '10px 5px',
        },
    },
    cartTitle: {
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#363636',
        marginLeft: 30,
    },
    itemWrapper: {
        padding: '0 10px',
        height: '100%',
        maxHeight: 477,
        [theme.breakpoints.only('xs')]: {
            border: 'none',
        },
    },
    itemTitle: {
        color: theme.palette.grey.dark,
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        padding: '20px 16px',
        whiteSpace: 'nowrap',
        [theme.breakpoints.only('xs')]: {
            fontWeight: 500,
            fontSize: '20px',
            padding: '0 16px',
        },
    },
    charts: {
        paddingTop: 20,
        backgroundColor: '#F6F7FB',
    },
    linear: {
        minWidth: '80px',
    },
    greyColor: {
        color: theme.palette.grey.main,
    },
    displeyBoxActive: {
        display: 'block',
    },
    displeyBoxNoneMob: {
        [theme.breakpoints.only('xs')]: {
            display: 'none',
        },
    },
    displeyBoxNoneDesck: {
        display: 'none',
        [theme.breakpoints.only('xs')]: {
            display: 'block',
        },
    },
    cardsHederMobile: {
        padding: '16px 0',
    },
    high: {
        backgroundColor: '#FDE3E3',
        opacity: 0.7,
    },
    medium: {
        backgroundColor: '#FFECB5',
        opacity: 0.7,
    },
    low: {
        backgroundColor: '#F9FDFB',
        opacity: 0.7,
    },
    download: {
        background: 'transparent',
        border: 'none',
        color: '#26BF83',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '15px',
        lineHeight: '18px',
        letterSpacing: '0.4px',
        cursor: 'pointer',
    },
}));

export const AntTabs = withStyles(theme => ({
    root: {
        padding: '0 10px',
        margin: 0,
        minHeight: 0,
    },
    indicator: {
        backgroundColor: 'transparent',
    },
}))(Tabs);

export const AntTab = withStyles(theme => ({
    root: {
        minHeight: 22,
        minWidth: 0,
        fontWeight: 400,
        fontSize: '20px',
        lineHeight: '16px',
        textAlign: 'center',
        padding: '0 10px',
        letterSpacing: '0.5px',
        textTransform: 'lowercase',
        color: 'rgba(0, 0, 0, 0.6)',
        '&:hover': {
            color: theme.palette.primary.main,
            opacity: 1,
        },
        '&$selected': {
            color: theme.palette.primary.main,
        },
        '&:focus': {
            color: theme.palette.primary.main,
        },
    },
    selected: {},
}))(props => <Tab disableRipple {...props} />);

export const BorderLinearProgress = withStyles(theme => ({
    root: {
        height: 10,
    },
    colorPrimary: {
        backgroundColor: theme.palette.primary.main,
    },
    bar: {
        backgroundColor: theme.palette.common.white,
    },
}))(LinearProgress);
