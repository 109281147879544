import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_MITIGATION_STATISTIC } from '../../../../../actions/get';
import Preloader from '../../../../../components/global/Preloader';
import { useIntl } from 'react-intl';
import { Container, Typography } from '@material-ui/core';
import { useStyles } from '../../../../../theme/Chart';
import { AntTab, AntTabs } from '../../../anomalies/anomalyDetails/summary/styles';
import AreaDropPass from '../../../../../components/charts/AreaDropPass';
import AreaMitigationsStatistic from '../../../../../components/charts/AreaMitigationsStatistic';
import { useParams } from 'react-router';

const MitigationTrafficDetails = () => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const [indexTab, setIndexTab] = useState('_bps' ? 0 : 1);
    const format = indexTab === 0 ? '_bps' : '_pps';
    const calcFormat = indexTab === 0 ? 'bps' : 'pps';
    const { id, start, mitigationId } = useParams();

    const handleChange = (event, newValue) => {
        setIndexTab(newValue);
    };

    const a11yProps = index => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    const { data, loading, error } = useQuery(GET_MITIGATION_STATISTIC, {
        variables: {
            arborMitigationId: Number(id),
        },
    });

    if (loading) return <Preloader />;
    if (error) return <div className={classes.errorCharts}>{formatMessage({ id: 'charts.error' })}</div>;

    const timestamp = [new Date(start).getTime()];
    const minute = 60000;
    const limit = data?.arborMitigationStatistic.ratesTotal[`drop${format}`].length - 1;

    for (let i = 0; i < limit; i++) {
        const proxy = timestamp[i] + minute;
        timestamp.push(proxy);
    }

    return (
        <>
            <Container maxWidth={false} style={{ marginTop: 30 }}>
                <Typography variant="h6">{formatMessage({ id: 'mitigations.details.title' })}</Typography>
                <Typography color="textSecondary" variant="h6">
                    (id {mitigationId})
                </Typography>
            </Container>
            <div className={classes.tabs}>
                <AntTabs value={indexTab} onChange={handleChange}>
                    <AntTab label="BPS" {...a11yProps(0)} />
                    <AntTab label="PPS" {...a11yProps(1)} />
                </AntTabs>
            </div>
            <div className={classes.chart} style={{ backgroundColor: '#F6F7FB', marginTop: 20, padding: '20px 10px'}}>
                <AreaDropPass data={data} calcFormat={calcFormat} format={format} timestamp={timestamp} />
            </div>
            <div className={classes.chart} style={{ backgroundColor: '#F6F7FB', marginTop: 20, padding: '20px 10px'}}>
                <AreaMitigationsStatistic timestamp={timestamp} calcFormat={calcFormat} format={format} data={data} />
            </div>
        </>
    );
};

export default MitigationTrafficDetails;
