import React, {useContext, useEffect} from 'react';
import 'chartjs-plugin-style';
import { Line } from 'react-chartjs-2';
import { useLazyQuery } from '@apollo/client';
import { GET_ARBOR_GRAPHITE_TRAFFIC_FOR_ALL_RESOURSES } from '../../actions/get';
import Preloader from '../global/Preloader';
import { useIntl } from 'react-intl';
import { valueCalculation } from '../../helpers/calculation';
import { Chart, Interaction, registerables } from 'chart.js';
import { CrosshairPlugin, Interpolate } from 'chartjs-plugin-crosshair';
import { useStyles } from '../../theme/Chart';
import { AuthContext } from '../../context/auth';

Chart.register(CrosshairPlugin, ...registerables);
Interaction.modes.interpolate = Interpolate;

const AreaSummaryTraffic = ({ filter, setFilter }) => {
    const classes = useStyles();
    const { user } = useContext(AuthContext);
    const { formatMessage } = useIntl();
    const dateFormat = require('dateformat');

    const  [getTrafficForAllResources, { data, loading, error }] = useLazyQuery(GET_ARBOR_GRAPHITE_TRAFFIC_FOR_ALL_RESOURSES, {
        variables: {
            from: Date.parse(filter.timeReportStart) / 1000,
            until: Date.parse(filter.timeReportEnd) / 1000,
            clientAccountId: user.clientAccountId,
            unit: 'BPS',
        },
    });

    useEffect(() => {
        getTrafficForAllResources();
        setFilter({...filter, activeZoomChart: true});
    }, []);

    if (loading) return <Preloader />;
    if (error || data?.arborGraphiteTrafficForAllResources?.timestamp.length < 1)
        return <div className={classes.errorCharts}>{formatMessage({ id: 'charts.error' })}</div>;

    const series = {
        labels: data?.arborGraphiteTrafficForAllResources?.timestamp,
        datasets: [
            {
                label: formatMessage({ id: 'chartsAreaSummaryTraffic.in' }),
                data: data?.arborGraphiteTrafficForAllResources?.trafficIn,
                borderColor: '#40C4FF',
                backgroundColor: 'rgb(64, 196, 255, 0.2)',
                borderWidth: 1,
                radius: 0,
                hoverRadius: 3,
            },
            {
                label: formatMessage({ id: 'chartsAreaSummaryTraffic.out' }),
                data: data?.arborGraphiteTrafficForAllResources?.trafficOut,
                borderColor: '#69F0AE',
                backgroundColor: 'rgba(105, 240, 174, 0.6)',
                borderWidth: 0.5,
                radius: 0,
                hoverRadius: 3,
            },
        ],
    };

    const options = {
        interaction: {
            mode: 'index'
        },
        maintainAspectRatio: false,
        elements: {
            line: {
                borderJoinStyle: 'bevel',
                borderCapStyle: 'square',
                fill: true,
            },
        },
        animation: false,
        scales: {
            y: {
                grid: {
                    display: false,
                    drawBorder: false,
                },
                stacked: false,
                ticks: {
                    display: true,
                    callback: function (value, index, values) {
                        return valueCalculation(value, 'bps');
                    },
                },
            },
            x: {
                grid: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    display: true,
                    callback: function (value, index, values) {
                        return index % 2 === 0 ? dateFormat(this.getLabelForValue(value) * 1000, 'h:MM:ssTT') : '';
                    },
                },
            },
        },
        plugins: {
            maintainAspectRatio: false,
            legend: {
                position: 'bottom',
            },
            filler: {
                propagate: false,
            },
            crosshair: {
                line: {
                    color: '#848484',
                    width: 0.5,
                },
                sync: {
                    enabled: false,
                },
                zoom: {
                    enabled: true,
                    zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',
                    zoomboxBorderColor: '#48F',
                    zoomButtonText: 'reset zoom',
                    zoomButtonClass: 'zoom',
                },
                callbacks: {
                    afterZoom: () => function(start, end) {
                        const afterZoomDate = data?.arborGraphiteTrafficForAllResources?.timestamp.filter((item, index) => {
                            if(index === start) return item ;
                            else if(index === end) return item;
                        });
                        const startDate = afterZoomDate[0];
                        const endDate = afterZoomDate[1];
                        getTrafficForAllResources({
                            variables: {
                                from: startDate,
                                until: endDate,
                                clientAccountId: user.clientAccountId,
                                unit: 'BPS',
                            }
                        });
                        setFilter({...filter, activeZoomChart: false});
                    }
                }
            },
            tooltip: {
                animation: false,
                intersect: false,
                callbacks: {
                    title: function (context) {
                        return dateFormat(context[0].label * 1000, 'dd/mm/yy h:MM:ssTT');
                    },
                    label: function (context) {
                        return valueCalculation(context.raw, 'bps');
                    },
                },
            },
        },
    };

    return (
        <>
            <div className={classes.chart}>
                <button className={filter.activeZoomChart ? classes.visibleZoomBtn : classes.zoomBtnReports}
                        onClick={() => {
                            getTrafficForAllResources();
                            setFilter({...filter, activeZoomChart: true});
                        }}
                >
                    RESET ZOOM
                </button>
                <Line data={series} options={options} />
            </div>
        </>
    );
};

export default AreaSummaryTraffic;
