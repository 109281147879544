import React, { useState, useRef } from 'react';
import { AntTableCell, AntTableCellFilter, useStyles } from "../../../theme/Table";
import { useIntl } from "react-intl";
import ModalMore from "../../modals/modalsTable/more";
import TableScroll from "../TableScroll";
import {Button, Table, TableBody, TableContainer, TableHead, TableRow, Typography} from "@material-ui/core";
import TableFilter from "../TableFilter";
import { MoreHoriz } from "@material-ui/icons";
import { textWidthCalculation } from "../../../helpers/calculation";
import Preloader from "../Preloader";
import { TablePaginationComponent } from "../TablePagination";


const TableResources = ({ loading, data, filter, setFilter, count, getFilter, getVariables, type }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const table = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [ipsPopover, setIpsPopover] = useState([]);

    const open = Boolean(anchorEl);
    const idPopover = open ? 'popover-more' : undefined;
    const dateFormat = require('dateformat');

    if (loading) return <Preloader/>;

    const maxWidth = {
        id: Math.max.apply(Math, data && data.map(items => textWidthCalculation(items.id))),
        name: Math.max.apply(Math, data && data.map(items => textWidthCalculation(items.name))),
    };
    return (
        <div>
            <ModalMore id={idPopover} open={open} anchorEl={anchorEl} setAnchorEl={setAnchorEl} value={ipsPopover} title="IPs list" />
            <TableScroll tableRef={table}>
                <TableContainer ref={table}>
                    <div className={classes.header}>
                        <Typography variant="h1" >
                            {formatMessage({ id: 'resource.title' })}
                        </Typography>
                    </div>
                    <Table size="small">
                        <TableHead>
                            {/* SORT */}
                            <TableRow>
                                <AntTableCell className={classes.title}>{formatMessage({ id: 'resource.id' })}</AntTableCell>
                                <AntTableCell className={classes.title}>{formatMessage({ id: 'resource.name' })}</AntTableCell>
                                <AntTableCell className={classes.title}>{formatMessage({ id: 'resource.ips' })}</AntTableCell>
                                <AntTableCell className={classes.title}>{formatMessage({ id: 'resource.date' })}</AntTableCell>
                            </TableRow>
                            <TableRow>
                                <AntTableCellFilter>
                                    <TableFilter name="id"
                                                 filter={filter}
                                                 setFilter={setFilter}
                                                 maxWidth={maxWidth.id} />
                                </AntTableCellFilter>
                                <AntTableCellFilter>
                                    <TableFilter
                                        name="name"
                                        filter={filter}
                                        setFilter={setFilter}
                                        maxWidth={maxWidth.name}
                                    />
                                </AntTableCellFilter>
                                <AntTableCellFilter />
                                <AntTableCellFilter />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data && data.map(({ id, name, ips, createdAt }) => (
                                <TableRow key={id}>
                                    <AntTableCell>{id}</AntTableCell>
                                    <AntTableCell>{name}</AntTableCell>
                                    <AntTableCell>
                                        {ips.length === 0 ? '-' : ips.length === 1 ? (
                                            ips[0]
                                        ) : (
                                            <div>
                                                {ips[0]}
                                                <Button
                                                    className={classes.buttonMore}
                                                    aria-describedby={id}
                                                    onClick={event => {
                                                        setAnchorEl(event.currentTarget);
                                                        setIpsPopover(ips);
                                                    }}
                                                >
                                                        <span className={classes.buttonMoreSpan}>
                                                            <MoreHoriz className={classes.buttonMoreIcon} viewBox="3 0 25 5" /> more
                                                        </span>
                                                </Button>
                                            </div>
                                        )}
                                    </AntTableCell>
                                    <AntTableCell className={classes.nowrap}>{dateFormat(createdAt, 'mm/dd/yy h:MM:ssTT')}</AntTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePaginationComponent
                        filter={filter}
                        setFilterActive={setFilter}
                        count={count}
                        getFilter={getFilter}
                        getVariables={getVariables}
                        type={type}
                    />
                </TableContainer>
            </TableScroll>
        </div>
    )
};

export default TableResources;