import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    title: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '15px',
        letterSpacing: '0.4px',
        color: '#363636',
        marginBottom: 10,
    },
    subtitle: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '18px',
        lineHeight: '28px',
        color: theme.palette.grey.greyHighEmphasis,
        marginBottom: 30,
    }
}));