import React, { useState } from 'react';
import { useStyles } from './styles';
import {  InputAdornment, IconButton, Button, List, ListItem, ListItemText } from '@material-ui/core';
import { Visibility, VisibilityOff, Error } from '@material-ui/icons';
import AuthWrapper from '../../../components/global/Wrappers/authWrapper';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useLocation } from 'react-router';
import { useMutation } from '@apollo/client';
import { Login, SET_PASSWORD } from '../../../actions/update';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import routes from '../../../routes';
import withWidth from '@material-ui/core/withWidth';
import { saveState } from '../../../helpers/localStorage';
import {AuthTextField} from "../../../components/global/Wrappers/authWrapper/styles";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import spLogoMain from "../../../../assets/img/logo-welness.svg";
import {checkHosts} from "../../../branding";
import {logosAuth} from "../../../branding/logos";

const JoinAccountFirst = props => {
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    const params = useLocation();
    const searchParams = new URLSearchParams(params.search);
    const token = searchParams.get('token');
    const id = searchParams.get('id');
    const email = searchParams.get('email');
    const { push } = useHistory();
    const [setPassword] = useMutation(SET_PASSWORD);
    const [login] = useMutation(Login);

    const onSubmit = data => {
        setPassword({
            variables: {
                newPassword: data.doublePassword,
                verifyToken: token,
                email: email,
                type: 'CLIENT_ACCOUNT_EMPLOYEE',
            },
        })
            .then(() => toast.success('Success'))
            .then(() => {
                login({
                    variables: {
                        email: email,
                        password: data.doublePassword,
                        type: 'CLIENT_ACCOUNT_EMPLOYEE',
                    },
                }).then(res => localStorage.setItem('join', res.data.login.accessToken));
            })
            .then(() => {
                saveState(
                    {
                        id,
                        email,
                        password: data.doublePassword,
                        type: 'CLIENT_ACCOUNT_EMPLOYEE',
                    },
                    'secondStep'
                );
                push(routes.joinAccountSecond);
            })
            .catch(err => {
                toast.error(err.message);
                if (err.message === 'VerifyTokenWrongForbiddenProblem') {
                    push(routes.joinAccountDisabled);
                }
            });
    };

    const checkUppercase = /([A-Z])|([А-Я])/;
    const checkLowercase = /([a-z])|([а-я])/;
    const checkNumber = /([0-9])/;
    const checkSpecialCharacter = /[^A-Za-zА-Яа-яёЁ0-9\s]/;

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .min(8, 'New password must be at least 8 characters long')
            .matches(checkUppercase, 'Password must be a single uppercase character ')
            .matches(checkLowercase, 'Password must be a single valid case character')
            .matches(checkNumber, 'Password must be received one digit')
            .matches(checkSpecialCharacter, 'Password released one special character')
            .required('Enter password'),
        doublePassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords do not match')
            .required('Please re-enter your password'),
    });

    const {
        handleSubmit,
        watch,
        formState: { errors },
        control,
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const inputVariable = !watch('password') ? '' : watch('password');

    return (
        <AuthWrapper>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                <div className={classes.logo}>
                    { checkHosts(window.location.host, logosAuth ) }
                </div>
                <div className={classes.title}>Join account</div>
                <div className={classes.description}>Please, setup your password.</div>
                <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                        <AuthTextField
                            error={!!errors.password}
                            disableUnderline={true}
                            id="password"
                            type={showPassword ? 'text' : 'password'}
                            value={
                                value.charCodeAt(value.length - 1) >= 33 && value.charCodeAt(value.length - 1) <= 126
                                    ? value
                                    : value.replace(value[value.length - 1], '')
                            }
                            placeholder='Password'
                            onChange={onChange}
                            variant="filled"
                            helperText={errors?.password?.message ?? ' '}
                            className={classes.input}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {errors.password ? <Error color="error" /> : showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    )}
                />
                <List className={classes.list}>
                    <ListItem className={`${classes.listItem} ${inputVariable?.split('').length >= 8 ? classes.active : classes.disable}`}>
                        <CheckCircleOutlineIcon fontSize="inherit" className={classes.listIcon} />
                        <ListItemText primary="8 characters minimum" />
                    </ListItem>
                    <ListItem className={`${classes.listItem} ${checkUppercase.test(inputVariable) ? classes.active : classes.disable}`}>
                        <CheckCircleOutlineIcon fontSize="inherit" className={classes.listIcon} />
                        <ListItemText primary="one uppercase character" />
                    </ListItem>
                    <ListItem className={`${classes.listItem} ${checkLowercase.test(inputVariable) ? classes.active : classes.disable}`}>
                        <CheckCircleOutlineIcon fontSize="inherit" className={classes.listIcon} />
                        <ListItemText primary="one lowercase character" />
                    </ListItem>
                    <ListItem className={`${classes.listItem} ${checkNumber.test(inputVariable) ? classes.active : classes.disable}`}>
                        <CheckCircleOutlineIcon fontSize="inherit" className={classes.listIcon} />
                        <ListItemText primary="one number" />
                    </ListItem>
                    <ListItem className={`${classes.listItem} ${checkSpecialCharacter.test(inputVariable) ? classes.active : classes.disable}`}>
                        <CheckCircleOutlineIcon fontSize="inherit" className={classes.listIcon} />
                        <ListItemText primary="one special character" />
                    </ListItem>
                </List>
                <Controller
                    name="doublePassword"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                        <AuthTextField
                            disableUnderline={true}
                            error={!!errors.doublePassword}
                            id="doublePassword"
                            type={showPassword2 ? 'text' : 'password'}
                            value={value}
                            placeholder="Confirm password"
                            onChange={onChange}
                            variant="filled"
                            helperText={errors?.doublePassword?.message ?? ' '}
                            className={classes.input}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton edge="end" onClick={() => setShowPassword2(!showPassword2)}>
                                        {errors.doublePassword ? <Error color="error" /> : showPassword2 ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    )}
                />
                {props.width === 'xs' ? (
                    <Button type="submit" color="primary" variant="contained" fullWidth={true} className={classes.button}>
                        Next
                    </Button>
                ) : (
                    <div className={classes.nextLink}>
                        <Button type="submit" color="primary" variant="contained" fullWidth={true} className={classes.button}>
                            Next
                        </Button>
                    </div>
                )}

            </form>
        </AuthWrapper>
    );
};

export default withWidth()(JoinAccountFirst);
