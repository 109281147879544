import React, { useContext } from 'react';
import { useStyles, CustomMenuItem } from './styles';
import { Button, ListItemIcon } from '@material-ui/core';
import { Group, AssignmentInd, NotificationImportant } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { AuthContext } from '../../../../../context/auth';
import { button } from '../../../../../theme/PopoverButton/styles';
import { MenuContext } from '../../../../../context/menu';
import { AntPopover } from '../../../../../theme/AntPopover';
import { PageContext } from "../../../../../context/pages";
import {useLocation} from 'react-router';

const UserPopover = ({ id, open, anchorEl, handleClose, firstName, lastName, email, l3Limit, role, dosGateLimit }) => {
    const classes = useStyles();
    const style = button();
    const { formatMessage } = useIntl();
    const { logout } = useContext(AuthContext);
    const { deleteFilter } = useContext(PageContext);
    const location = useLocation();

    const notificationSwitch = (l3Limit !== 0 && l3Limit !== null) || (dosGateLimit !== null && dosGateLimit !== 0);

    return (
        <AntPopover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            className={classes.popover}
        >
            <div className={classes.popoverWrapper}>
                <div className={classes.popoverHeader}>
                    <div className={classes.userName}>
                        {firstName} {lastName}
                    </div>
                    <div className={classes.userEmail}>{email}</div>
                </div>

                <div className={classes.popoverBody}>
                    <Link
                        to="/my-profile/0"
                        className={location.pathname.includes('/my-profile/0') ? `${classes.linkItemActive}` : `${classes.linkItem}`}
                        onClickCapture={() => {
                            handleClose();
                        }}
                    >
                        <CustomMenuItem selected={location.pathname.includes('/my-profile/0')}>
                            <ListItemIcon>
                                <AssignmentInd />
                            </ListItemIcon>
                            {formatMessage({ id: 'profile.pop.profile' })}
                        </CustomMenuItem>
                    </Link>
                    {role === 'ADMIN' && (
                        <Link
                            to="/my-profile/1"
                            className={location.pathname.includes('/my-profile/1') ? `${classes.linkItemActive}` : `${classes.linkItem}`}
                            onClickCapture={() => {
                                handleClose();
                                deleteFilter();
                            }}
                        >
                            <CustomMenuItem selected={location.pathname.includes('/my-profile/1')}>
                                <ListItemIcon>
                                    <Group />
                                </ListItemIcon>
                                {formatMessage({ id: 'profile.pop.members' })}
                            </CustomMenuItem>
                        </Link>
                    )}

                    {notificationSwitch && (
                        <Link
                            to="/my-profile/2"
                            className={location.pathname.includes('/my-profile/2') ? `${classes.linkItemActive}` : `${classes.linkItem}`}
                            onClickCapture={() => {
                                handleClose();
                            }}
                        >
                            <CustomMenuItem selected={location.pathname.includes('/my-profile/2')}>
                                <ListItemIcon>
                                    <NotificationImportant />
                                </ListItemIcon>
                                {formatMessage({ id: 'profile.pop.not' })}
                            </CustomMenuItem>
                        </Link>
                    )}
                    <div className={classes.buttonBox}>
                        <Button
                            color="secondary"
                            variant="contained"
                            className={style.button}
                            onClick={() => {
                                logout();
                                handleClose();
                            }}
                        >
                            {formatMessage({ id: 'profile.pop.out' })}
                        </Button>
                    </div>
                </div>
            </div>
        </AntPopover>
    );
};

export default UserPopover;
