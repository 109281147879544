import React, {useEffect} from 'react';
import 'chartjs-plugin-style';
import { Line } from 'react-chartjs-2';
import { useLazyQuery } from '@apollo/client';
import { GET_DOSGATE_GRAPHITE } from '../../actions/get';
import Preloader from '../global/Preloader';
import { useIntl } from 'react-intl';
import { valueCalculation } from '../../helpers/calculation';
import { Chart, Interaction, registerables } from 'chart.js';
import { CrosshairPlugin, Interpolate } from 'chartjs-plugin-crosshair';
import { useStyles } from '../../theme/Chart';

Chart.register(CrosshairPlugin, ...registerables);
Interaction.modes.interpolate = Interpolate;

const AreaDosgateTraffic = ({ filter, id, type, setFilter, indexTab, alwaysOn }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const dateFormat = require('dateformat');
    const format = indexTab === 0 ? 'bps' : 'pps';
    const unitForDosGate = format === 'bps' ? 'BYTES' : 'PACKETS';
    const formatAlwaysOn = type === 'BYTES' ? 'bps' : 'pps';

    const [getDosgate, { data, loading, error}] = useLazyQuery(GET_DOSGATE_GRAPHITE, {
        variables: {
            from: (Date.parse(filter.startDate) - (Date.parse(filter.startDate) % 1000)) / 1000 || (filter.startDate - (filter.startDate % 1000)) / 1000,
            until: (Date.parse(filter.endDate) - (Date.parse(filter.endDate) % 1000)) / 1000 || (filter.endDate - (filter.endDate % 1000)) / 1000,
            manageObjectId: id,
            unit: alwaysOn ? type : unitForDosGate,
        },
    });

    useEffect(() => {
        getDosgate();
        setFilter({...filter, activeZoomChart: true});
    }, []);

    if (loading) return <Preloader />;
    if (error || data?.dosGateGraphiteTraffic?.timestamp.length < 1) return <div className={classes.errorCharts}>{formatMessage({ id: 'charts.error' })}</div>;

    const series = {
        labels: data?.dosGateGraphiteTraffic?.timestamp,
        datasets: [
            {
                label: formatMessage({ id: 'chartsAreaDosgateTraffic.accept' }),
                data: data?.dosGateGraphiteTraffic?.accept,
                borderColor: '#40C4FF',
                backgroundColor: 'rgb(64, 196, 255, 0.2)',
                borderWidth: 1,
                radius: 0,
                hoverRadius: 3,
            },
            {
                label: formatMessage({ id: 'chartsAreaDosgateTraffic.reply' }),
                data: data?.dosGateGraphiteTraffic?.reply,
                borderColor: '#F4FA58',
                backgroundColor: 'rgba(244, 250, 88, 0.3)',
                borderWidth: 0.5,
                radius: 0,
                hoverRadius: 3,
            },
            {
                label: formatMessage({ id: 'chartsAreaDosgateTraffic.drop' }),
                data: data?.dosGateGraphiteTraffic?.drop,
                borderColor: '#FF3D00',
                backgroundColor: 'rgba(255, 61, 0, 0.6)',
                borderWidth: 1,
                radius: 0,
                hoverRadius: 3,
            },
        ],
    };

    const options = {
        interaction: {
            mode: 'index'
        },
        maintainAspectRatio: false,
        elements: {
            line: {
                borderJoinStyle: 'bevel',
                borderCapStyle: 'square',
                fill: true,
            },
        },
        animation: false,
        scales: {
            y: {
                grid: {
                    display: false,
                    drawBorder: false,
                },
                stacked: false,
                ticks: {
                    display: true,
                    callback: function (value, index, values) {
                        return valueCalculation(value, alwaysOn ? formatAlwaysOn : format);
                    },
                },
            },
            x: {
                grid: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    display: true,
                    callback: function (value, index, values) {
                        return index % 2 === 0 ? dateFormat(this.getLabelForValue(value) * 1000, 'h:MM:ssTT') : '';
                    },
                },
            },
        },
        plugins: {
            maintainAspectRatio: false,
            legend: {
                position: 'bottom',
            },
            filler: {
                propagate: false,
            },
            crosshair: {
                line: {
                    color: '#848484',
                    width: 0.5,
                },
                sync: {
                    enabled: false,
                },
                zoom: {
                    enabled: true,
                    zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',
                    zoomboxBorderColor: '#48F',
                    zoomButtonText: 'reset zoom',
                    zoomButtonClass: 'zoom',
                },
                callbacks: {
                    afterZoom: () => function(start, end) {
                        const afterZoomDate = data?.dosGateGraphiteTraffic?.timestamp.filter((item, index) => {
                            if(index === start) return item ;
                            else if(index === end) return item;
                        });
                        const startDate = afterZoomDate[0];
                        const endDate = afterZoomDate[1];
                        getDosgate({
                            variables: {
                                from: startDate,
                                until: endDate,
                                id: id,
                                unit: alwaysOn ? type : unitForDosGate,
                            }
                        });
                        setFilter({...filter, activeZoomChart: false});
                    }
                }
            },
            tooltip: {
                animation: false,
                intersect: false,
                callbacks: {
                    title: function (context) {
                        return dateFormat(context[0].label * 1000, 'dd/mm/yy h:MM:ssTT');
                    },
                    label: function (context) {
                        return `${context.dataset.label}: ${valueCalculation(context.raw, format)}`;
                    },
                },
            },
        },
    };

    return (
        <>
            <div className={classes.chart}>
                <button className={filter.activeZoomChart ? classes.visibleZoomBtn : classes.zoomBtn}
                        onClick={() => {
                            getDosgate();
                            setFilter({...filter, activeZoomChart: true});
                        }}
                >
                    RESET ZOOM
                </button>
                <Line data={series} options={options} />
            </div>
        </>
    );
};

export default AreaDosgateTraffic;
