import React from 'react';
import { useStyles } from './styles';
import DatePicker from 'react-datepicker';

const TableDatePickerMonth = ({ filter, setFilter }) => {
    const classes = useStyles();

    return (
        <div className={classes.pickerWrapper}>
            <DatePicker
                className={classes.dataPicker}
                selected={Date.parse(filter.timeReportStart)}
                onChange={date => {
                    setFilter({
                        ...filter,
                        timeReportStart: new Date(date.getFullYear(), date.getMonth(), 1, 0, 0),
                        timeReportEnd: new Date(date.getFullYear(), date.getMonth() + 1, date.getDate() - 1, 23, 59, 59),
                    });
                }}
                dateFormat="MMMM yyyy"
                popperClassName={classes.popper}
                showMonthYearPicker
            />
        </div>
    );
};
export default TableDatePickerMonth;
