import React from 'react';
import { TablePagination } from '@material-ui/core';
import { useIntl } from 'react-intl';

export const TablePaginationComponent = ({ width, filter, setFilterActive, count, getFilter, getVariables, type }) => {
    const handleChangePage = (event, newPage) => {
        setFilterActive({...filter, page: newPage}, type);
        getFilter && getFilter({ variables: { ...getVariables, limit: filter.limit, page: newPage + 1 } });
    };
    const handleChangeRowsPerPage = event => {
        setFilterActive({...filter, limit: parseInt(event.target.value, 10), page: 0}, type);
        getFilter && getFilter({ variables: { ...getVariables, limit: parseInt(event.target.value, 10) } });
    };

    const { formatMessage } = useIntl();

    return (
        <TablePagination
            component="div"
            count={count}
            page={filter?.page}
            onChangePage={handleChangePage}
            rowsPerPage={filter?.limit}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 50, 100]}
            labelRowsPerPage={width === 'xs' || width === 'sm' ? '' : formatMessage({ id: 'table.pagination' })}
        />
    );
};
