import React, { useState } from 'react';
import { useStyles } from './styles';
import { Link } from 'react-router-dom';
import { InputAdornment, IconButton, List, ListItem, ListItemText, Button } from '@material-ui/core';
import { Visibility, VisibilityOff, Error } from '@material-ui/icons';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import routes from '../../../routes';
import AuthWrapper from '../../../components/global/Wrappers/authWrapper';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { SET_PASSWORD } from '../../../actions/update';
import { useLocation } from 'react-router';
import Preloader from '../../../components/global/Preloader';
import {AuthLabel, AuthTextField} from "../../../components/global/Wrappers/authWrapper/styles";
import FormControl from "@material-ui/core/FormControl";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import spLogoMain from "../../../../assets/img/logo-welness.svg";
import {checkHosts} from "../../../branding";
import {logosAuth} from "../../../branding/logos";

const ResetPassword = () => {
    const classes = useStyles();
    const { push } = useHistory();
    const params = useLocation();
    const searchParams = new URLSearchParams(params.search);
    const token = searchParams.get('token');
    const email = searchParams.get('email');

    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showDoubleNewPassword, setShowDoubleNewPassword] = useState(false);

    const [setPassword, { loading }] = useMutation(SET_PASSWORD);
    const onSubmit = inputData => {
        setPassword({
            variables: {
                newPassword: inputData.newPassword,
                email: email,
                resetToken: token,
                type: 'CLIENT_ACCOUNT_EMPLOYEE',
            },
        })
            .then(() => toast.success('Success'))
            .then(() => push('/'))
            .catch(err => toast.error(err.message));
    };

    const checkUppercase = /([A-Z])|([А-Я])/;
    const checkLowercase = /([a-z])|([а-я])/;
    const checkNumber = /([0-9])/;
    const checkSpecialCharacter = /[^A-Za-zА-Яа-яёЁ0-9\s]/;

    const validationSchema = Yup.object().shape({
        newPassword: Yup.string()
            .min(8, 'New Password must be at least 8 characters')
            .matches(checkUppercase, 'Password must have one uppercase character')
            .matches(checkLowercase, 'Password must have one lowercase character')
            .matches(checkNumber, 'Password must have one number')
            .matches(checkSpecialCharacter, 'Password must have one special character')
            .required('Current New Password is required'),
        doubleNewPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'Confirm New Password must match')
            .required('Confirm New Password is required'),
    });

    const {
        handleSubmit,
        watch,
        formState: { errors },
        control,
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const inputVariable = !watch('newPassword') ? '' : watch('newPassword');

    if (loading) return <Preloader />;

    return (
        <AuthWrapper>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                <div className={classes.logo}>
                    { checkHosts(window.location.host, logosAuth ) }
                </div>
                <div className={classes.title}>Reset your password</div>
                <div className={classes.description}>Almost done, just enter your new password.</div>
                <Controller
                    name="newPassword"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                        <FormControl>
                            <AuthLabel shrink htmlFor="newPassword"  error={!!errors.newPassword}>
                                New password
                            </AuthLabel>
                            <AuthTextField
                                disableUnderline={true}
                                id="newPassword"
                                error={!!errors.newPassword}
                                type={showNewPassword ? 'text' : 'password'}
                                value={
                                    value.charCodeAt(value.length - 1) >= 33 && value.charCodeAt(value.length - 1) <= 126
                                        ? value
                                        : value.replace(value[value.length - 1], '')
                                }
                                onChange={onChange}
                                placeholder='Enter your password'
                                helperText={errors?.newPassword?.message ?? ' '}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setShowNewPassword(!showNewPassword)}
                                        >
                                            {errors.newPassword ? <Error color="error" /> : showNewPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    )}
                />
                <List className={classes.list}>
                    <ListItem className={`${classes.listItem} ${inputVariable?.split('').length >= 8 ? classes.active : classes.disable}`}>
                        <CheckCircleOutlineIcon fontSize="inherit" className={classes.listIcon} />
                        <ListItemText primary="8 characters minimum" />
                    </ListItem>
                    <ListItem className={`${classes.listItem} ${checkUppercase.test(inputVariable) ? classes.active : classes.disable}`}>
                        <CheckCircleOutlineIcon fontSize="inherit" className={classes.listIcon} />
                        <ListItemText primary="one uppercase character" />
                    </ListItem>
                    <ListItem className={`${classes.listItem} ${checkLowercase.test(inputVariable) ? classes.active : classes.disable}`}>
                        <CheckCircleOutlineIcon fontSize="inherit" className={classes.listIcon} />
                        <ListItemText primary="one lowercase character" />
                    </ListItem>
                    <ListItem className={`${classes.listItem} ${checkNumber.test(inputVariable) ? classes.active : classes.disable}`}>
                        <CheckCircleOutlineIcon fontSize="inherit" className={classes.listIcon} />
                        <ListItemText primary="one number" />
                    </ListItem>
                    <ListItem className={`${classes.listItem} ${checkSpecialCharacter.test(inputVariable) ? classes.active : classes.disable}`}>
                        <CheckCircleOutlineIcon fontSize="inherit" className={classes.listIcon} />
                        <ListItemText primary=" one special character" />
                    </ListItem>
                </List>
                <Controller
                    name="doubleNewPassword"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                        <FormControl>
                            <AuthLabel shrink htmlFor="doubleNewPassword"  error={!!errors.doubleNewPassword}>
                                Confirm new password
                            </AuthLabel>
                            <AuthTextField
                                disableUnderline={true}
                                id="doubleNewPassword"
                                type={showDoubleNewPassword ? 'text' : 'password'}
                                value={value}
                                onChange={onChange}
                                placeholder='Enter your password'
                                error={!!errors.doubleNewPassword}
                                helperText={errors?.doubleNewPassword?.message ?? ' '}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setShowDoubleNewPassword(!showDoubleNewPassword)}
                                        >
                                            {errors.doubleNewPassword ? <Error color="error" /> : showDoubleNewPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    )}
                />
                <Button type="submit" color="primary" variant="contained" className={classes.button}>
                    save
                </Button>
            </form>
            <Link to={routes.auth} className={classes.link}>
                Return to log in
            </Link>
        </AuthWrapper>
    );
};

export default ResetPassword;
