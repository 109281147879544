import { makeStyles } from '@material-ui/core/styles';
import { InputBase, withStyles, Select, MenuItem } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    menuHeader: {
        display: 'flex',
        alignItems: 'center',
    },
    menuLink: {
        padding: '24px 21px 26px 21px',
        color: '#363636',
        textDecoration: 'none',
        textTransform: 'none',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontSize: '14px',
        letterSpacing: '0.25px',
        lineHeight: '21px',
        boxSizing: 'border-box',
        '&:hover': {
            backgroundColor: theme.palette.grey.greyLight,
        },
    },
    menuLinkActive: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.light,
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
        },
    },
    navBar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: '#363636',
    },
    navBarItem: {
        padding: '8px 15px',
    },
    navBarItemHover: {
        '&:hover': {
            background: theme.palette.grey.greyLight,
        },
    },
    lang: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        margin: '14px 0',
        color: '#828B89',
        '& .MuiSelect-icon': {
            display: 'none',
        },
    },
    langIcon: {
        position: 'absolute',
        right: -2,
    },
    imgBoxLogo: {
        padding: '10px 0',
        display: 'flex',
        alignItems: 'center',
        paddingRight: 32,
    },
    imgBoxUser: {
        cursor: 'pointer',
        padding: '10px 0 10px 15px',
        display: 'flex',
        alignItems: 'center',

    },
    headerItems: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    },
    avatar: {
        border: '2px solid',
        borderColor: theme.palette.primary.main,
        backgroundColor: '#FAFAFA !important',
        color: '#BDBDBD !important',
    },
    support: {
        cursor: 'pointer',
        color: '#363636',
        display: 'flex',
        alignItems: 'center',

    },
    dropDown: {
        paddingLeft: 5,
    },
}));

export const CustomInput = withStyles(theme => ({
    input: {
        borderRadius: 0,
        position: 'relative',
        backgroundColor: 'transparent',
        border: 'none',
        padding: 0,
        color: '#828B89',
        fontSize: '14px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        lineHeight: '21px',
        zIndex: 999,
        '&:focus': {
            borderRadius: 0,
            borderColor: 'transparent',
            backgroundColor: 'transparent',
        },
    },
}))(InputBase);

export const CustomSelect = withStyles(theme => ({
    select: {
        color: '#363636',
        '&.MuiSelect-select': {
            paddingRight: 14,
        },
    },
}))(Select);

export const CustomMenuItem = withStyles(theme => ({
    root: {
        padding: '9px 20px',
        borderRadius: 4,
        margin: '0 8px',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: '0.1px',
        '&:focus': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.light,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);
