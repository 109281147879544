import React, { useContext } from 'react';
import TableAnomalies from "../../../components/global/TableAnomalies";
import {PageContext} from "../../../context/pages";

const DosgateAnomalies = () => {
    const { filterActive, setFilterActive } = useContext(PageContext);

    return <TableAnomalies propsRoute={'dosgate'}
                           filter={filterActive}
                           setFilter={setFilterActive}
                           type={'FILTER_ACTIVE'}
    />
};

export default DosgateAnomalies;