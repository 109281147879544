import React from "react";
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    itemTitle: {
        fontWeight: 400,
        fontSize: '16px',
        color: 'rgba(0, 0, 0, 0.87)',

    },
    underline: {
        width: '100%',
        height: '2px',
        backgroundColor: '#F6F7FB',
    },
    box: {
        padding: '16px 20px 16px 16px',
        backgroundColor: '#F6F7FB',
        setWidth: 'fit-content',
        border: '1px solid #F6F7FB',
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        width: '50%'
    },
    section: {
        margin: '10px auto',
        width: '100%',
        padding: '10px 3px',
    },
    headerWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'row',
    },
    tableTitle: {
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        color: '#9E9E9E',
        width: '25%',
        marginBottom: 8,
        marginTop: 8,
    }
});

const Table = ({ title, headTitles, data, renderRow }) => {
    return (
        <View style={styles.section}>
            {
                data &&
                <View>
                    <View style={styles.box}>
                        <Text style={styles.itemTitle}>{title}</Text>
                    </View>
                    <View style={styles.underline}/>
                    {data.length < 1 ? (
                        <Text>No data</Text>
                    ) : (
                        <View>
                            <View style={styles.headerWrapper}>
                                {headTitles.map(item => (
                                    <Text style={styles.tableTitle} key={item}>
                                        {item}
                                    </Text>
                                ))}
                            </View>
                            {renderRow(data)}
                        </View>
                    )}
                </View>
            }
        </View>
    );

};

export default Table;
