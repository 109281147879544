import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    title: {
        marginBottom: 30,
    },
    subtitle: {
        marginRight: 50,
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '24px',
        color: theme.palette.grey.greyRgba85,
    },
    display: {
        marginBottom: 20,
        display: 'flex',
        alignItems: 'center',
    },
    box: {
        display: 'flex',
        flexDirection: 'column',
    },
    checkboxTitle: {
        marginBottom: 10,
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.5px',
        color: theme.palette.grey.greyMediumEmphasis,
    },
}));