import { makeStyles } from '@material-ui/core/styles';
import { Tabs, withStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    bar: {
        background: theme.palette.common.white,
        marginBottom: 30,
        borderTopWidth: 0,
        borderBottomWidth: '2px',
        borderColor: theme.palette.grey.greyRgba12,
        borderBlockStyle: 'solid',
    },

    headerTitle: {
        marginBottom: 30,
    },
    date: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 30,
    },
    select: {
        width: '100%',
        maxWidth: '450px',
        padding: '5px 5px 5px 5px',
        '&:focus': {
            background: 'none',
        },
    },
    closeIcon: {
        '& .MuiAutocomplete-clearIndicator': {
            display: 'none',
        },
        '& .MuiAutocomplete-popupIndicator': {
            marginTop: '3px',
        },
    },
    custom: {
        padding: 0,
        '& div': {
            padding: '0 !important'
        }
    },
}));

export const MyTabs = withStyles(theme => ({
    indicator: {
        backgroundColor: theme.palette.primary.main,
    },
    flexContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}))(Tabs);
