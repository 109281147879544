import { makeStyles } from '@material-ui/core/styles';
import { Tab, Tabs, withStyles } from '@material-ui/core';
import LinearProgress from "@material-ui/core/LinearProgress";

export const useStyles = makeStyles(theme => ({
    bar: {
        background: theme.palette.common.grey,
        borderTopWidth: 0,
        borderBottomWidth: '1px',
        borderColor: 'transparent',
        borderBlockStyle: 'solid',
    },
    validation: {
        marginLeft: 30,
    },
    link: {
        textDecoration: 'none',
        color: '#363636',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',
        textAlign: 'center',
        letterSpacing: '1.25px',
        textTransform: 'uppercase',
    },
    linkActive: {
        color: theme.palette.primary.main,
    },
    itemTab: {
        flex: '100%',
    },
    linkDisabled: {
        display: 'none',
    },
    itemBackLink: {
        flex: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: '#363636',
        [theme.breakpoints.only('xs')]: {
            display: 'none',
        },
    },
    backLink: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        color: '#363636',

        fontSize: '14px',
        letterSpacing: '1px',
        textTransform: 'uppercase',
        fontWeight: 500,
        lineHeight: '16px',
        paddingRight: 15,
    },
    backLine: {
        border: '1px solid #D5CECE',
        height: '70%',
    },
    backIcon: {
        marginRight: 50
    },
    wrapperContainer: {
        minHeight: 'calc(100vh - 255px)',
        padding: 30,
        background: 'white',
        [theme.breakpoints.only('xs')]: {
            minHeight: 'calc(100vh - 140px)',
            padding: '25px 16px 5px 16px',
        },
        [theme.breakpoints.only('sm')]: {
            minHeight: 'calc(100vh - 225px)',
        },
    },
    wrapperTable: {
        padding: 30,
        [theme.breakpoints.only('xs')]: {
            padding: '25px 16px 25px 16px',
        },
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
    },
    wrapper: {
        display: 'none',
        padding: '0 5px 0 14px',
        borderBottom: '2px solid rgba(0, 0, 0, 0.12)',
        [theme.breakpoints.only('xs')]: {
            display: 'block',
        },
    },
    title: {
        paddingLeft: 20,
        fontWeight: 500,
        color: 'rgba(0, 0, 0, 0.6)',
    },
    icon: {
        color: theme.palette.grey.greyInactive,
        fontSize: '24px',
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    trialBox: {
        whiteSpace: 'nowrap',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 14,
        letterSpacing: 0.25,
        color: '#363636',
        padding: 10,
        borderRadius: 6,
    },
    trialBoxRed: {
        background: '#FAE1E1',
        whiteSpace: 'nowrap',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 14,
        letterSpacing: 0.25,
        color: '#363636',
        padding: 10,
        borderRadius: 6,
    },
    wrappedBlur: {
        position: 'relative',
    },
    wrappedBlurText: {
        position: 'absolute',
        top: 176,
        left: 100,
        fontWeight: 400,
        fontSize: 24,
        color: '#363636'
    }
}));

export const AntTabs = withStyles(theme => ({
    indicator: {
        backgroundColor: theme.palette.primary.main,
    },
}))(Tabs);

export const AntTab = withStyles(theme => ({
    root: {
        minWidth: 0,
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',
        textAlign: 'center',
        padding: '24px 15px',
        letterSpacing: '1.25px',
        textTransform: 'uppercase',
        color: '#363636',

        '&:hover': {
            background: theme.palette.grey.greyLight,
            opacity: 1,
        },
        '&$selected': {
            color: theme.palette.primary.main,
        },
        '&:focus': {
            color: theme.palette.primary.main,
        },
        [theme.breakpoints.only('xs')]: {
            padding: '18px 10px',
        },
    },
    selected: {},
}))(props => <Tab disableRipple {...props} />);

export const LinearProgressRed = withStyles((theme) => ({
    bar: {
        backgroundColor: '#E53935',
    },
    colorPrimary: {
        backgroundColor: 'pink',
    },
}))(LinearProgress);
