import React from 'react';
import { useStyles } from './styles';
import { ToastContainer } from 'react-toastify';
import Header from '../Header';

const Layout = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.layout}>
            <Header />
            <ToastContainer position="bottom-right" autoClose={8000} />
            {children}
        </div>
    );
};

export default Layout;
