import React, { useEffect } from 'react';
import { useStyle } from './styles';
import { Controller, useForm } from 'react-hook-form';
import CloseIcon from '@material-ui/icons/Close';
import { Button, Dialog, TextareaAutosize, Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useMutation, useLazyQuery } from '@apollo/client';
import { UPDATE_L7_BLACK_LISTS } from '../../../../actions/update';
import { GET_L7_BLACK_LIST } from '../../../../actions/get';
import { useStyles } from '../../../../theme/Modals';
import { useIntl } from 'react-intl';
import Preloader from '../../../global/Preloader';

const ModalAddCommentBlack = ({ show, hideModal, query, getVariables, ip, id, resourcesId }) => {
    const classes = useStyles();
    const style = useStyle();
    const { formatMessage } = useIntl();
    const { handleSubmit, control, watch, reset } = useForm();

    const [getBlackList, { data, loading }] = useLazyQuery(GET_L7_BLACK_LIST);

    const [setUpdateL7BlackList] = useMutation(UPDATE_L7_BLACK_LISTS);

    const onSubmit = dataInput => {
        setUpdateL7BlackList({
            variables: {
                l7ResourceId: resourcesId,
                blackListId: id,
                blacklistComment: dataInput.commentFromInput,
            },
            refetchQueries: [
                {
                    query: query,
                    variables: getVariables,
                },
                {
                    query: GET_L7_BLACK_LIST,
                    variables: {
                        blacklistId: Number(id),
                        l7ResourceId: Number(resourcesId),
                    },
                },
            ],
        })
            .then(() => {
                toast.success('Success');
            })
            .catch(err => toast.error(err.message));
        hideModal();
    };

    useEffect(() => {
        if (id) {
            getBlackList({
                variables: {
                    blacklistId: Number(id),
                    l7ResourceId: Number(resourcesId),
                },
            });
        }
    }, [id]);

    useEffect(() => {
        reset({
            commentFromInput: data && data.l7BlackList.blacklistComment,
        });
    }, [data]);

    if (loading) return <Preloader />;

    const inputVariable = !watch('commentFromInput')
        ? data && data.l7BlackList.blacklistComment && data.l7BlackList.blacklistComment.length
        : watch('commentFromInput').length > 250
        ? 250
        : watch('commentFromInput').length;

    const valueSlice = value => {
        return value.length < 250 ? value : value.slice(0, 250);
    };

    return (
        <div>
            <Dialog
                className={classes.dialog}
                maxWidth="md"
                open={show}
                onClose={() => {
                    hideModal();
                }}
            >
                <div className={classes.modal}>
                    <div className={classes.header}>
                        <Typography variant="h1">
                            {formatMessage({ id: 'webProtection.white.comment.title' })} {ip}
                        </Typography>
                        <Button className={classes.close} onClick={() => hideModal()}>
                            <CloseIcon />
                        </Button>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={classes.modalBody}>
                            <Controller
                                id={id}
                                name="commentFromInput"
                                control={control}
                                defaultValue={data && data.l7BlackList.blacklistComment}
                                render={({ field: { onChange, value } }) => (
                                    <TextareaAutosize
                                        onChange={onChange}
                                        value={value === undefined ? '' : valueSlice(value)}
                                        className={style.area}
                                        aria-label="Comment"
                                        placeholder="Comment"
                                    />
                                )}
                            />
                            <Typography variant="body2">Max length: {250 - inputVariable}</Typography>
                        </div>
                        <div className={classes.modalFooter}>
                            <Button
                                className={classes.cancel}
                                onClick={() => {
                                    hideModal();
                                }}
                            >
                                {formatMessage({ id: 'webProtection.white.comment.cancel' })}
                            </Button>
                            <Button type="submit" className={classes.invite}>
                                {formatMessage({ id: 'webProtection.white.comment.accept' })}
                            </Button>
                        </div>
                    </form>
                </div>
            </Dialog>
        </div>
    );
};

export default ModalAddCommentBlack;
