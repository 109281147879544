import React, {useState} from 'react';
import { useStyles } from "../styles";
import { Button, Typography } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { GET_QR_CODE } from "../../../../actions/get";
import ModalTwoStepVerificationDisabled from "../../../../components/modals/TwoStepVerification/disabled";
import ModalTwoStepVerification from "../../../../components/modals/TwoStepVerification/enabled";

const TwoFactor = ({ data, token, query }) => {
    const classes = useStyles();
    const {clientAccountEmployee: {email, twoFactorAuth }} = data;
    const [isEditEnabled, setIsEditEnabled] = useState(false);
    const [isEditDisabled, setIsEditDisabled] = useState(false);
    const [getQrCode, {data: qrCode}] = useMutation(GET_QR_CODE);

    return(
        <>
            <ModalTwoStepVerification
                show={isEditEnabled}
                hideModal={() => setIsEditEnabled(false)}
                qrCode={qrCode?.activateTwoFactorAuth?.qrCode}
                email={email}
                query={query}
                token={token}
            />
            <ModalTwoStepVerificationDisabled
                show={isEditDisabled}
                hideModal={() => setIsEditDisabled(false)}
                query={query}
                token={token}
            />
            <Typography variant="h1" className={classes.title}>
                2-Step verification
            </Typography>
            <div className={classes.subtitleTwoFactor}>
                Two-step verification increases protection for your account and prevents it from unauthorized access.
            </div>
            <div className={classes.statusTwoFactor}>
                Status: <span>{!twoFactorAuth ? 'Disabled' : 'Enabled'}</span>
            </div>
            <Button type="submit"
                    color="primary"
                    variant="contained"
                    className={classes.button}
                    onClick={() => {
                        if(twoFactorAuth) setIsEditDisabled(true);
                        else getQrCode().then(() => setIsEditEnabled(true));
                    }}
            >
                {!twoFactorAuth ? 'Enable 2-Step verification' : 'Disable 2-Step verification'}
            </Button>
        </>
    )
};

export default TwoFactor;