import React from 'react';
import { myStyles } from './styles';
import { useStyles, AntTableCell } from '../../../../theme/Table';
import { Button } from '@material-ui/core';
import { DeleteForever } from '@material-ui/icons';
import { useIntl } from 'react-intl';

const Aliase = ({ aliasId, aliasData, aliasSslAuto, aliasSsl, aliasSslCrt, aliasSslKey, setIsEdit, calculationTypeSsl, setIsDeleted }) => {
    const classes = useStyles();
    const styled = myStyles();
    const { formatMessage } = useIntl();

    return (
        <>
            <AntTableCell component="th" scope="row">
                {aliasData}
            </AntTableCell>
            <AntTableCell style={{ padding: '4px 10px' }} align="left">
                <Button
                    color="primary"
                    variant="outlined"
                    className={styled.editBtn}
                    onClick={() => {
                        setIsEdit({
                            edit: true,
                            aliasId,
                            ssl: calculationTypeSsl(aliasSslAuto, aliasSsl),
                            crt: aliasSslCrt,
                            key: aliasSslKey,
                        });
                    }}
                >
                    {formatMessage({ id: 'webProtection.general.edit' })}
                </Button>
            </AntTableCell>
            <AntTableCell align="center">
                <DeleteForever
                    viewBox="0 0 24 24"
                    className={classes.iconDelete}
                    onClick={() =>
                        setIsDeleted({
                            deleted: true,
                            id: aliasId,
                            name: aliasData,
                        })
                    }
                />
            </AntTableCell>
        </>
    );
};

export default Aliase;
