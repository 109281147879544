import {makeStyles} from "@material-ui/core/styles";
import React from "react";

export const useStyles = makeStyles(theme => ({
    top: {
        marginBottom: 35,
        padding: 25,
    },
    headerTitle: {
        marginBottom: 30,
    },
    date: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 30,
    },
    container: {
        justifyContent: "space-between" ,
        alignItems: "center",
    }
}));