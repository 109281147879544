import React, {useState} from 'react';
import {useStyles} from "../../../theme/Table";
import {useIntl} from "react-intl";
import {FormControl, Select, Table, TableBody, TableRow, Typography} from "@material-ui/core";
import {FilterList} from "@material-ui/icons";
import TableDatePicker from "../../global/TableDatePicker";
import Anomaly from "./Anomaly";

const AnomaliesMobile = ({ data, setAnchorElMore, setTypePopover, setIndexTabs, filter, setFilter, setAnchorElThreatLevel, width }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const [select, setSelect] = useState(3);

    const handleChange = event => {
        setSelect(Number(event.target.value));
    };

    return (
        <>
            <div className={classes.header}>
                <Typography variant="h1">Detected anomalies</Typography>

                <div>
                    <FilterList
                        className={classes.icon}
                        aria-describedby="threat-Level"
                        onClick={event => {
                            setAnchorElThreatLevel(event.currentTarget);
                        }}
                    />
                </div>
            </div>

            <FormControl className={classes.selectWrwppMobile}>
                <Typography variant="subtitle2" color="textSecondary">
                    Time period
                </Typography>
                <TableDatePicker filter={filter} setFilter={setFilter} width={width} />
                <Typography variant="subtitle2" color="textSecondary" style={{ marginTop: 10 }}>
                    Anomaly characteristics
                </Typography>
                <Select
                    native
                    variant="outlined"
                    defaultValue={select}
                    inputProps={{
                        id: 'uncontrolled-native',
                    }}
                    onChange={handleChange}
                    className={classes.selectMobile}
                >
                    {['lvl', 'ips', 'type', 'name','end', 'duration'].map((el, i) => {
                        return (
                            <option value={i+1}>{formatMessage({ id: `anomalies.${el}` })}</option>
                        )
                    })}
                    <option value={7}>{formatMessage({ id: 'anomalies.max' })} BPS</option>
                    <option value={8}>{formatMessage({ id: 'anomalies.max' })} PPS</option>
                </Select>
            </FormControl>
            <Typography variant="subtitle2" color="textSecondary" className={classes.titleMobile}>
                ID/Started At
            </Typography>
            <Table>
                <TableBody>
                    {data &&
                        data.arborAlerts.items.map(
                            ({
                                 alertId,
                                 importance,
                                 alertResourceCidr,
                                 alertManagedObjectName,
                                 misuseTypes,
                                 start,
                                 stop,
                                 duration,
                                 maxImpactBps,
                                 maxImpactPps,
                                 id,
                                 severityPercent,
                                 threshold,
                                 unit,
                                 impactBpsPoints,
                                 impactPpsPoints,
                                 impactRecorded,
                             }) => (
                                <TableRow key={alertId}>
                                    <Anomaly
                                        alertId={alertId}
                                        importance={importance}
                                        start={start}
                                        setIndexTabs={setIndexTabs}
                                        alertResourceCidr={alertResourceCidr}
                                        misuseTypes={misuseTypes}
                                        select={select}
                                        alertManagedObjectName={alertManagedObjectName}
                                        stop={stop}
                                        duration={duration}
                                        maxImpactBps={maxImpactBps}
                                        maxImpactPps={maxImpactPps}
                                        id={id}
                                        severityPercent={severityPercent}
                                        threshold={threshold}
                                        unit={unit}
                                        impactBpsPoints={impactBpsPoints}
                                        impactPpsPoints={impactPpsPoints}
                                        impactRecorded={impactRecorded}
                                        setAnchorElMore={setAnchorElMore}
                                        setTypePopover={setTypePopover}
                                    />
                                </TableRow>
                            )
                        )}
                </TableBody>
            </Table>
        </>
    );
};

export default AnomaliesMobile