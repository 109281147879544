import React from 'react';
import { useStyles, AntTableCell } from '../../../../theme/Table';
import { TableRow, Table, TableBody, TableHead } from '@material-ui/core';
import { useIntl } from 'react-intl';
import Member from './Member';

const MembersDesktop = ({ data, setIsDeleted, setIndexId, setIndexEmail, setIndexRole, handleClick }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <AntTableCell className={classes.title}>{formatMessage({ id: 'profile.members.id' })}</AntTableCell>
                    <AntTableCell className={classes.title}>{formatMessage({ id: 'profile.members.name' })}</AntTableCell>
                    <AntTableCell className={classes.title}>{formatMessage({ id: 'profile.members.email' })}</AntTableCell>
                    <AntTableCell className={classes.title}>{formatMessage({ id: 'profile.members.role' })}</AntTableCell>
                    <AntTableCell className={classes.title}>{formatMessage({ id: 'profile.members.status' })}</AntTableCell>
                    <AntTableCell />
                </TableRow>
            </TableHead>
            <TableBody>
                {data &&
                    data?.clientAccountEmployees?.items.map(({ id, name: { first, last }, email, role, status, notificationSubscribe }) => (
                        <TableRow key={id}>
                            <Member
                                id={id}
                                email={email}
                                role={role}
                                setIsDeleted={setIsDeleted}
                                first={first}
                                last={last}
                                status={status}
                                setIndexId={setIndexId}
                                setIndexEmail={setIndexEmail}
                                setIndexRole={setIndexRole}
                                handleClick={handleClick}
                            />
                        </TableRow>
                    ))}
            </TableBody>
        </Table>
    );
};
export default MembersDesktop;
