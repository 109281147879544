import React, { useState, useRef, useEffect, useContext } from 'react';
import { useStyles, AntTableCell, AntTableCellFilter } from '../../../theme/Table';
import { TableContainer, Typography, Grid, Table, TableHead, TableRow, TableBody, Checkbox, FormControlLabel } from '@material-ui/core';
import TableScroll from '../../../components/global/TableScroll';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import { GET_ARBOR_RESOURCES, GET_CLIENT_ACCOUNT_EMPLOYEES_ACCESS } from '../../../actions/get';
import { SET_ACCESS } from '../../../actions/update';
import { TablePaginationComponent } from '../../../components/global/TablePagination';
import Preloader from '../../../components/global/Preloader';
import { PageContext } from '../../../context/pages';
import { useDebouncedEffect } from '../../../hooks/useDebouncedEffect';
import { textWidthCalculation } from '../../../helpers/calculation';
import TableFilter from '../../../components/global/TableFilter';
import TableSort from '../../../components/global/TableSort';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';

const NetworkProtection = ({ email, id }) => {
    const { formatMessage } = useIntl();
    const classes = useStyles();
    const table = useRef(null);
    const { secondaryFilter, setSecondaryFilter } = useContext(PageContext);

    const [setAccess] = useMutation(SET_ACCESS);
    const { data: access } = useQuery(GET_CLIENT_ACCOUNT_EMPLOYEES_ACCESS, {
        variables: {
            clientAccountEmployeeId: Number(id),
        },
    });

    const [getFilter, { data, loading }] = useLazyQuery(GET_ARBOR_RESOURCES);
    const getVariables = () => {
        const proxy = {
            limit: secondaryFilter.limit,
            page: secondaryFilter.page + 1,
            name: secondaryFilter?.name || '',
            [secondaryFilter?.sortActive]: secondaryFilter?.sort?.[secondaryFilter?.sortActive]?.toUpperCase() || 'DESC',
        };
        if (secondaryFilter?.id) proxy.id = Number(secondaryFilter?.id);
        return proxy;
    };

    useEffect(() => {
        getFilter({ variables: getVariables() });
    }, []);

    useDebouncedEffect(
        () => {
            getFilter({ variables: getVariables() });
        },
        500,
        [secondaryFilter]
    );

    if (loading) return <Preloader />;

    const maxWidth = {
        id: Math.max.apply(Math, data && data.arborResources.items.map(items => textWidthCalculation(items.id))),
        name: Math.max.apply(Math, data && data.arborResources.items.map(items => textWidthCalculation(items.name))),
    };

    const pushItem = (event, index) => {
        const arr = [];
        arr.push(...(access && access.clientAccountEmployeeAccess?.[index]), Number(event.target.name));
        return arr;
    };

    const returnItem = (event, index) => {
        const arr = [];
        arr.push(...(access && access.clientAccountEmployeeAccess?.[index]));
        const position = arr.indexOf(Number(event.target.name));
        arr.splice(position, 1);
        return arr;
    };

    const handleChange = (event, index) => {
        const variables = event.target.checked
            ? {
                  clientAccountEmployeeId: Number(id),
                  [index]: pushItem(event, index),
              }
            : {
                  clientAccountEmployeeId: Number(id),
                  [index]: returnItem(event, index),
              };
        setAccess({
            variables: variables,
            refetchQueries: [
                {
                    query: GET_CLIENT_ACCOUNT_EMPLOYEES_ACCESS,
                    variables: {
                        clientAccountEmployeeId: Number(id),
                    },
                },
            ],
        })
            .then(() => toast.success('Success'))
            .catch(err => toast.error(err.message));
    };

    const customData =
        data &&
        data.arborResources.items.map(item => {
            return {
                ...item,
                l3Read: access && access.clientAccountEmployeeAccess?.l3Read.includes(item.id),
            };
        });

    return (
        <>
            <TableScroll tableRef={table}>
                <TableContainer ref={table}>
                    <Grid container>
                        <Grid item xs>
                            <Typography variant="h1" className={classes.titleHeader}>
                                {formatMessage({ id: 'settingsMembersNet.title' })} {email}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <AntTableCell className={classes.title}>
                                    <TableSort
                                        name="sortId"
                                        direction={secondaryFilter?.sort?.sortId}
                                        label="ID"
                                        setFilterActive={setSecondaryFilter}
                                        filter={secondaryFilter}
                                        type={'SECONDARY_FILTER'}
                                    />
                                </AntTableCell>
                                <AntTableCell className={classes.title}>{formatMessage({ id: 'settingsMembersNet.tableTitle.resName' })} </AntTableCell>
                                <AntTableCell />
                            </TableRow>
                            <TableRow>
                                <AntTableCellFilter>
                                    <TableFilter name="id"
                                                 filter={secondaryFilter}
                                                 setFilter={setSecondaryFilter}
                                                 maxWidth={maxWidth.id}
                                    />
                                </AntTableCellFilter>
                                <AntTableCellFilter>
                                    <TableFilter
                                        name="name"
                                        filter={secondaryFilter}
                                        setFilter={setSecondaryFilter}
                                        maxWidth={maxWidth.name}
                                    />
                                </AntTableCellFilter>
                                <AntTableCellFilter />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {customData &&
                                access &&
                                access.clientAccountEmployeeAccess &&
                                customData.map(({ id, name, l3Read }) => (
                                    <TableRow key={id}>
                                        <AntTableCell>{id}</AntTableCell>
                                        <AntTableCell>{name}</AntTableCell>
                                        <AntTableCell>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={l3Read}
                                                        onChange={event => {
                                                            handleChange(event, 'l3Read');
                                                        }}
                                                        name={`${id}`}
                                                        color="primary"
                                                    />
                                                }
                                                label={formatMessage({ id: 'settingsMembersNet.checkLabel.view' })}
                                            />
                                        </AntTableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                    <TablePaginationComponent
                        filter={secondaryFilter}
                        setFilterActive={setSecondaryFilter}
                        count={data && data.arborResources.info.totalCount}
                        getFilter={getFilter}
                        getVariables={getVariables()}
                        type={'SECONDARY_FILTER'}
                    />
                </TableContainer>
            </TableScroll>
        </>
    );
};
export default NetworkProtection;
