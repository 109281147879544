import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    logo: {
        marginBottom: 64,
    },
    title: {
        marginBottom: 40,
        fontFamily: 'Manrope',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '35px',
        letterSpacing: '0.25px',
        color: '#041613',
        textAlign: 'left',
        lineHeight: '46px',
    },
    description: {
        marginBottom: 40,
        fontFamily: 'Manrope',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        letterSpacing: '0.5px',
        color: '#041613',
        textAlign: 'left',
        lineHeight: '22px',
        [theme.breakpoints.only('xs')]: {
            padding: 0,
        },
    },
    form: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    input: {
        marginBottom: 10,
        width: '100%',
    },

    button: {
        margin: '42px 0 115px 0',
        width: '100%',
        padding: '15px 0',
        background: '#01CDCB',
        borderRadius: '100px',
    },
    checkboxWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    checkboxText: {
        marginTop: 7,
    },
    text: {
        fontFamily: 'Manrope',
        marginBottom: 20,
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: '0.25px',
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.primary.main,
    },
    footer: {
        fontFamily: 'Manrope',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '22px',
        letterSpacing: '0.5px',
        color: '#828B89',
        marginBottom: 20,
        textAlign: 'left',
    }
}));
