import React, {useContext, useEffect, useState} from 'react';
import {AntTab, AntTabs, LinearProgressRed, useStyles} from '../../theme/Tabs';
import { AntContainerHead } from '../../theme/Wrapper';
import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router';
import Resources from './resources';
import Anomalies from './anomalies';
import { useIntl } from 'react-intl';
import Traffic from './traffic';
import Reports from './reports';
import TabPanel from '../../components/global/TabPanel';
import {PageContext} from "../../context/pages";
import LinearProgress from "@material-ui/core/LinearProgress";
import tableBlur from "../../../assets/img/table.png";

const L3Protection = ({ dataAccount }) => {
    const classes = useStyles();
    const [indexTab, setIndexTab] = useState(0);
    const { tab } = useParams();
    const { formatMessage } = useIntl();
    const { deleteFilter } = useContext(PageContext);
    const normalise = value => (value - 0) * 100 / (14 - 0);
    const accSettings = dataAccount?.clientAccountEmployee?.clientAccount?.accountSettings;
    const trialPeriod = Math.round(( new Date(new Date(accSettings?.l3?.trialPeriod).setDate(new Date(accSettings?.l3?.trialPeriod).getDate() + 1)) - new Date()) / 86400000);

    const handleChange = (event, newValue) => {
        setIndexTab(newValue);
    };
    const a11yProps = index => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    useEffect(() => {
        setIndexTab(Number(tab));
    }, [tab]);

    return (
        <>
            <div position="static" className={classes.bar}>
                <AntContainerHead maxWidth={false} style={{display: 'flex', alignItems: 'center'}}>
                    <AntTabs value={indexTab} onChange={handleChange} className={classes.itemTab} variant="scrollable" scrollButtons="auto">
                        <AntTab
                            component={NavLink}
                            label={formatMessage({ id: 'l3Protection.resource' })}
                            {...a11yProps(0)}
                            to="/l3-protection/0"
                            className={`${classes.link} ${classes.marginRight}`}
                            activeClassName={classes.linkActive}
                            onClick={() => deleteFilter()}
                            disabled={accSettings?.l3?.trialPeriod && trialPeriod < 1}
                        />
                        <AntTab
                            component={NavLink}
                            label={formatMessage({ id: 'l3Protection.anomalies' })}
                            {...a11yProps(1)}
                            to="/l3-protection/1"
                            className={`${classes.link} ${classes.marginRight}`}
                            activeClassName={classes.linkActive}
                            onClick={() => deleteFilter()}
                            disabled={accSettings?.l3?.trialPeriod && trialPeriod < 1}
                        />
                        <AntTab
                            component={NavLink}
                            label={formatMessage({ id: 'l3Protection.traffic' })}
                            {...a11yProps(2)}
                            to="/l3-protection/2"
                            className={`${classes.link} ${classes.marginRight}`}
                            activeClassName={classes.linkActive}
                            onClick={() => deleteFilter()}
                            disabled={accSettings?.l3?.trialPeriod && trialPeriod < 1}
                        />
                        <AntTab
                            component={NavLink}
                            label={formatMessage({ id: 'l3Protection.reports' })}
                            {...a11yProps(3)}
                            to="/l3-protection/3"
                            className={`${classes.link} ${classes.marginRight}`}
                            activeClassName={classes.linkActive}
                            onClick={() => deleteFilter()}
                            disabled={accSettings?.l3?.trialPeriod && trialPeriod < 1}
                        />
                    </AntTabs>
                    {
                        accSettings?.l3?.trialPeriod &&
                        <div className={trialPeriod > 3 ? classes.trialBox : classes.trialBoxRed}>
                            {accSettings?.l3?.trialPeriod && trialPeriod >= 1 ? `${formatMessage({ id: 'trial.full' })} ${trialPeriod} ${formatMessage({ id: 'trial.days' })}` : `${formatMessage({ id: 'trial.end' })}`}
                            {
                                accSettings?.l3?.trialPeriod && trialPeriod > 3 ?
                                    <LinearProgress variant="determinate" value={normalise(trialPeriod)} style={{marginTop: 5}}/> :
                                    <LinearProgressRed variant="determinate" value={normalise(trialPeriod)} style={{marginTop: 5}}/>
                            }
                        </div>
                    }
                </AntContainerHead>
            </div>
            <TabPanel value={indexTab} index={0}>
                {accSettings?.l3?.trialPeriod && trialPeriod < 1 ?
                    <div className={classes.wrappedBlur}>
                        <img src={tableBlur} alt="#" style={{maxWidth: '100%'}}/>
                        <div className={classes.wrappedBlurText}>
                            {formatMessage({ id: 'trial.title' })}
                            <br/>
                            {formatMessage({ id: 'trial.subtitle' })}
                        </div>
                    </div> :
                    <div className={classes.wrapperTable}>
                        <Resources />
                    </div>
                }
            </TabPanel>
            <TabPanel value={indexTab} index={1}>
                <div className={classes.wrapperTable}>
                    <Anomalies />
                </div>
            </TabPanel>
            <TabPanel value={indexTab} index={2}>
                <Traffic />
            </TabPanel>
            <TabPanel value={indexTab} index={3}>
                <div className={classes.wrapperTable}>
                    <Reports />
                </div>
            </TabPanel>
        </>
    );
};
export default L3Protection;
