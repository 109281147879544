import React from 'react';
import { Chart, Interaction } from 'chart.js';
import { CrosshairPlugin, Interpolate } from 'chartjs-plugin-crosshair';
import { Line } from 'react-chartjs-2';
import { useStyles } from '../../theme/Chart';
import { simpleValueCalculation } from '../../helpers/calculation';
import { useIntl } from 'react-intl';

Chart.register(CrosshairPlugin);
Interaction.modes.interpolate = Interpolate;

const AreaCountryTop10 = ({ data, time, getRequestsGeo, setFilter, date, id }) => {
    const { formatMessage } = useIntl();
    const colors = [
        {
            borderColor: '#69F0AE',
            backgroundColor: 'rgb(64, 196, 255, 0.2)',
        },
        {
            borderColor: '#40C4FF',
            backgroundColor: 'rgba(105, 240, 174, 0.2)',
        },
        {
            borderColor: '#FF6E40',
            backgroundColor: 'rgb(64, 196, 255, 0.2)',
        },
        {
            borderColor: '#E0E0E0',
            backgroundColor: 'rgba(105, 240, 174, 0.2)',
        },
        {
            borderColor: '#D358F7',
            backgroundColor: 'rgba(211, 88, 247, 0.3)',
        },
        {
            borderColor: '#FE2E2E',
            backgroundColor: 'rgba(254, 46, 46, 0.3)',
        },
        {
            borderColor: '#BDBDBD',
            backgroundColor: 'rgba(189, 189, 189, 0.5)',
        },
        {
            borderColor: '#F4FA58',
            backgroundColor: 'rgba(244, 250, 88, 0.3)',
        },
        {
            borderColor: '#eb4034',
            backgroundColor: 'rgba(235, 64, 52, 0.4)',
        },
        {
            borderColor: '#9FA8DA',
            backgroundColor: 'rgba(159, 168, 218, 0.7)',
        },
    ];
    let customSeries = [];
    const classes = useStyles();
    const dateFormat = require('dateformat');

    if (time && time.length >= 1) {
        customSeries = data.map((item, index) => {
            return {
                label: item.countryName,
                data: item.requests,
                radius: 0,
                hoverRadius: 3,
                borderWidth: 1,
                ...colors[index],
            };
        });
    }
    const series = {
        labels: time,
        datasets: customSeries,
    };
    const options = {
        interaction: {
            mode: 'index'
        },
        maintainAspectRatio: false,
        elements: {
            line: {
                borderJoinStyle: 'bevel',
                borderCapStyle: 'square',
                fill: true,
            },
        },
        animation: false,
        scales: {
            y: {
                grid: {
                    display: false,
                    drawBorder: false,
                },
                stacked: false,
                ticks: {
                    display: true,
                    callback: function (value) {
                        return `${simpleValueCalculation(value)} rpm`;
                    },
                },
            },
            x: {
                grid: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    display: true,
                    callback: function (value, index, values) {
                        return index % 2 === 0 ? dateFormat(this.getLabelForValue(value) * 1000, 'h:MM:ssTT') : '';
                    },
                },
            },
        },
        plugins: {
            legend: {
                position: 'bottom',
            },
            filler: {
                propagate: false,
            },
            title: {
                align: 'center',
                position: 'top',
                text: formatMessage({ id: 'chartsAreaChartsRequestAnalytics.title' }),
                display: true,
                padding: {
                    top: 10,
                    bottom: 30,
                },
                font: {
                    family: 'Roboto',
                    weight: 500,
                    size: 16,
                },
            },
            crosshair: {
                line: {
                    color: '#848484',
                    width: 0.5,
                },
                sync: {
                    enabled: false,
                },
                zoom: {
                    enabled: true,
                    zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',
                    zoomboxBorderColor: '#48F',
                    zoomButtonText: 'reset zoom',
                    zoomButtonClass: 'zoom',
                },
                callbacks: {
                    afterZoom: () => function(start, end) {
                        const afterZoomDate = time.filter((item, index) => {
                            if(index === start) return item ;
                            else if(index === end) return item;
                        });
                        const startDate = afterZoomDate[0];
                        const endDate = afterZoomDate[1];
                        getRequestsGeo({
                            variables: {
                                from: startDate,
                                until: endDate,
                                l7ResourceId: id,
                            }
                        });
                        setFilter({...date, activeZoomChart: false});
                    }
                }
            },
            tooltip: {
                animation: false,
                intersect: false,
                callbacks: {
                    title: function (context) {
                        return dateFormat(context[0].label * 1000, 'dd/mm/yy h:MM:ssTT');
                    },
                    label: function (context) {
                        return `${context.dataset.label}: ${simpleValueCalculation(context.raw)} rpm`;
                    },
                },
            },
        },
    };

    return (
        <div className={`${classes.chart} ${classes.request}`}>
            <button className={date.activeZoomChart ? classes.visibleZoomBtn : classes.zoomBtnDashboard}
                    onClick={() => {
                        getRequestsGeo();
                        setFilter({...date, activeZoomChart: true});
                    }}
            >
                RESET ZOOM
            </button>
            <Line data={series} options={options} />
        </div>
    );
};

export default AreaCountryTop10;
