import React from 'react';
import { Typography, Grid, Paper } from '@material-ui/core';
import { useStyles } from './styles';
import TableDatePickerMonth from '../../../../components/global/TableDatePickerMonth';
import { valueCalculation } from '../../../../helpers/calculation';
import { useIntl } from 'react-intl';
import { GetApp } from "@material-ui/icons";
import CardsHead from "../../../../components/global/cards/CardsHead";

const HeadCartsReports = ({ filter, setFilter, dataAlerts, target, dataMitigations, linkToPrint }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();

    return (
        <>
            <Grid container spacing={4} justify='flex-end' alignItems='flex-end'>
                <Grid item xs={1} className={classes.btnDwl} onClick={linkToPrint}>
                    Download
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={2} container>
                    <Grid item className={classes.wrapperDatePiker}>
                        <Typography variant="h1" className={classes.title}>
                            {formatMessage({ id: 'reports.title' })}
                            <GetApp style={{ verticalAlign: 'middle', cursor: 'pointer', color: '#26BF83' }} onClick={linkToPrint} />
                        </Typography>
                        <TableDatePickerMonth filter={filter} setFilter={setFilter} />
                    </Grid>
                </Grid>
                <Grid item xs={10} container spacing={1}>
                    <Grid item xs>
                        <CardsHead
                            title={formatMessage({ id: 'reports.cards.anomalies' })}
                            data={dataAlerts ? dataAlerts.info?.totalCount : 0}
                        />
                    </Grid>
                    <Grid item xs>
                        <CardsHead
                            title={formatMessage({ id: 'reports.cards.mitigations' })}
                            data={dataMitigations ? dataMitigations.info?.totalCount : 0}
                        />
                    </Grid>
                    <Grid item xs>
                        <CardsHead
                            title={formatMessage({ id: 'reports.cards.attack' })}
                            data={dataAlerts.items && dataAlerts.items[0]?.maxImpactBps ? valueCalculation(dataAlerts.items[0].maxImpactBps, 'bps') : '-'}
                        />
                    </Grid>
                    <Grid item xs>
                        <CardsHead
                            title={dataAlerts?.items?.length >= 1 ? target.sort(([, a], [, b]) => b - a)[0][0] : '-'}
                            data={formatMessage({ id: 'reports.cards.target' })}
                        />
                    </Grid>
                </Grid>
                {/*<Grid item>*/}
                {/*    <Typography variant="body1" align="center" style={{ cursor: 'pointer' }} onClick={linkToPrint}>*/}
                {/*        Download report <GetApp style={{ verticalAlign: 'middle' }}/>*/}
                {/*    </Typography>*/}
                {/*</Grid>*/}
            </Grid>
        </>
    );
};

export default HeadCartsReports;
