const routes = {
    auth: '/',
    joinAccountFirst: '/invite-client-account-employee',
    joinAccountSecond: '/join-account-second-step',
    joinAccountDisabled: '/join-account-disabled',
    forgotPassword: '/forgot-password',
    forgotPasswordDone: '/forgot-password-done',
    resetPassword: '/reset-password',
    myProfile: '/my-profile/:tab',
    l3Protection: '/l3-protection/:tab',
    anomalyDetails: '/anomaly-details/:backLink/:id/:indexPage',
    webProtection: '/web-protection/:tab',
    settingsAccount: '/settings-account/:id/:tab',
    mitigationsDetails: '/mitigations-details/:backLink/:start/:mitigationId/:id/:indexPage',
    dashboard: '/dashboard',
    settingsAccountMembers: '/settings-account-members/:tab/:id',
    dosgate: '/dosgate/:tab',
};

export default routes;
