import React from "react";
import { Button, Dialog, TextField, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useStyles } from "./styles";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import {toast} from "react-toastify";
import { TWO_FACTOR_AUTH } from "../../../../actions/update";

const ModalTwoStepVerification = ({show, hideModal, qrCode, email, query, token}) => {
    const classes = useStyles();
    const { handleSubmit, control } = useForm();
    const [setTwoFactorAuth] = useMutation(TWO_FACTOR_AUTH);

    const onSubmit = dataInput => {
        setTwoFactorAuth({
            variables: {
                code: dataInput.code,
                email,
            },
            refetchQueries: [
                {
                    query,
                    variables: {
                        id: token?.id,
                    },
                },
            ],
        })
            .then(() => toast.success('Success'))
            .then(() => hideModal())
            .catch(err => toast.error(err.message));
    };
    return(
        <div>
            <Dialog
                className={classes.dialog}
                maxWidth="md"
                open={show}
                onClose={() => {
                    hideModal();
                }}
            >
                <div className={classes.modal}>
                    <div className={classes.header}>
                        <Typography variant="h1">
                            Enable 2-Step Verification
                        </Typography>
                        <Button className={classes.close} onClick={() => hideModal()}>
                            <CloseIcon />
                        </Button>
                    </div>
                    <div className={classes.modalBody}>
                        <div className={classes.dsc}>1. Download and install Google Authenticator app (or analog) for free.</div>
                        <div className={classes.dsc}>2. Scan the QR-code:</div>
                        <div className={classes.qrCode}>
                            <img src={qrCode} alt="#"/>
                        </div>
                        <div className={classes.dsc}>Download:</div>
                        <div className={classes.dsc} style={{marginBottom: 30}}>
                            Google Authenticator
                            (<a href="https://apps.apple.com/us/app/google-authenticator/id388497605" className={classes.link} target="_blank">iOS</a>, <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US&gl=US" className={classes.link} target="_blank">Android</a>)
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className={classes.dsc} style={{marginBottom: 10}}>Please enter ther generated 6-digit code</div>
                            <Controller
                                name="code"
                                control={control}
                                defaultValue=''
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        placeholder="6-digit code"
                                        variant="outlined"
                                        className={classes.input}
                                    />
                                )}
                            />
                            <div className={classes.modalFooter}>
                                <Button
                                    className={classes.cancel}
                                    onClick={() => {
                                        hideModal();
                                    }}
                                >
                                    cancel
                                </Button>
                                <Button type="submit" className={classes.invite}>
                                    check
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </Dialog>
        </div>
    )
};

export default ModalTwoStepVerification;