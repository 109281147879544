import React, { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { AntTableCell } from '../../../../../theme/Table';
import { Typography, Table, Paper, TableBody, TableRow, Grid, TableContainer } from '@material-ui/core';
import CardsHead from '../../../../../components/global/cards/CardsHead';
import TabPanel from '../../../../../components/global/TabPanel';
import BarChartsBasic from '../../../../../components/charts/BarChartsBasic';
import AreaChartsBasic from '../../../../../components/charts/AreaChartsBasic';
import { useStyles, AntTabs, AntTab, BorderLinearProgress } from './styles';
import { percentCalculation, valueCalculation } from '../../../../../helpers/calculation';
import {useMutation, useQuery} from '@apollo/client';
import { GET_ARBOR_ALERT_DETAIL } from '../../../../../actions/get';
import { useIntl } from 'react-intl';
import Preloader from '../../../../../components/global/Preloader';
import TableScroll from '../../../../../components/global/TableScroll';
import { blue } from "@material-ui/core/colors";
import {PDFDownloadLink} from "@react-pdf/renderer";
import {PdfDocument} from "../PdfGenerator";
import {GetApp} from "@material-ui/icons";
import RefreshIcon from "@material-ui/icons/Refresh";
import {LOAD_ALERT_DETAILS} from "../../../../../actions/update";
import {toast} from "react-toastify";

const Summary = ({ indexContetn, store }) => {
    const table = useRef(null);
    const classes = useStyles();
    const { id } = useParams();
    const { formatMessage } = useIntl();

    const a11yProps = index => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };
    const details = store.arborAlerts.items[0];
    const startValue = () => {
        return details.importance === 'low' ? 1 : details.importance === 'medium' ? 2 : details.importance === 'high' ? 3 : '';
    };

    const [indexTab, setIndexTab] = useState(details.unit === 'bps' ? 0 : 1);

    const { data, loading, refetch } = useQuery(GET_ARBOR_ALERT_DETAIL, {
        variables: {
            id: details.id,
        },
    });
    const [refreshAlertDetails, {loading: refresh}] = useMutation(LOAD_ALERT_DETAILS);

    if (loading || refresh) return <Preloader />;

    const type = indexTab === 0 ? 'bytes' : 'packets';
    const format = indexTab === 0 ? 'bps' : 'pps';

    const handleChange = (event, newValue) => {
        setIndexTab(newValue);
    };

    const createData = (name, data, progress) => {
        return { name, data, progress };
    };

    const progress =
        data &&
        data.arborAlertDetail[`dest_addr_${type}`].reduce((p, c) => {
            return p + c[`avg_${type}`];
        }, 0);

    const columns = {
        destIp: data && data?.arborAlertDetail[`dest_addr_${type}`][0]?.[`avg_${type}`],
        misuse: data && data.arborAlertDetail[`misuse_types_${type}`][0]?.[`avg_${type}`],
        protocols: data && data.arborAlertDetail[`protos_${type}`][0]?.[`avg_${type}`],
        srcTcp: data && data.arborAlertDetail[`src_tcp_ports_${type}`][0]?.[`avg_${type}`],
        srcCountries: data && data.arborAlertDetail[`src_countries_${type}`][0]?.[`avg_${type}`],
        srcIp: data && data.arborAlertDetail[`src_addr_${type}`][0]?.[`avg_${type}`],
        destTcp: data && data.arborAlertDetail[`dest_tcp_ports_${type}`][0]?.[`avg_${type}`],
        tcpFlags: data && data.arborAlertDetail[`tcp_flags_${type}`][0]?.[`avg_${type}`],
        srcAsn: data && data.arborAlertDetail[`src_asn_${type}`][0]?.[`avg_${type}`],
    };
    const { destIp, misuse, protocols, srcTcp, srcCountries, srcIp, destTcp, tcpFlags, srcAsn } = columns;

    const rows = [
        createData(
            formatMessage({ id: 'detailsAnomalySummary.characteristic.ip' }),
            data && data?.arborAlertDetail[`dest_addr_${type}`][0]?.[`name_${type}`],
            percentCalculation(destIp, progress)
        ),
        createData(
            formatMessage({ id: 'detailsAnomalySummary.characteristic.type' }),
            data && data?.arborAlertDetail[`misuse_types_${type}`][0]?.[`name_${type}`],
            percentCalculation(misuse, progress)
        ),
        createData(
            formatMessage({ id: 'detailsAnomalySummary.characteristic.protocol' }),
            data && data?.arborAlertDetail[`protos_${type}`][0]?.[`name_${type}`],
            percentCalculation(protocols, progress)
        ),
        createData(
            formatMessage({ id: 'detailsAnomalySummary.characteristic.tcp' }),
            data && data?.arborAlertDetail[`src_tcp_ports_${type}`][0]?.[`name_${type}`],
            percentCalculation(srcTcp, progress)
        ),
        createData(
            formatMessage({ id: 'detailsAnomalySummary.characteristic.country' }),
            data && data?.arborAlertDetail[`src_countries_${type}`][0]?.[`name_${type}`],
            percentCalculation(srcCountries, progress)
        ),
        createData(
            formatMessage({ id: 'detailsAnomalySummary.characteristic.ips' }),
            data && data?.arborAlertDetail[`src_addr_${type}`][0]?.[`name_${type}`] === '0.0.0.0/0'
                ? 'Highly Distributed'
                : data?.arborAlertDetail[`src_addr_${type}`][0]?.[`name_${type}`],
            percentCalculation(srcIp, progress)
        ),
        createData(
            formatMessage({ id: 'detailsAnomalySummary.characteristic.tcp-p' }),
            data && data?.arborAlertDetail[`dest_tcp_ports_${type}`][0]?.[`name_${type}`],
            percentCalculation(destTcp, progress)
        ),
        createData(
            formatMessage({ id: 'detailsAnomalySummary.characteristic.tcp-f' }),
            data && data?.arborAlertDetail[`tcp_flags_${type}`][0]?.[`name_${type}`],
            percentCalculation(tcpFlags, progress)
        ),
        createData(
            formatMessage({ id: 'detailsAnomalySummary.characteristic.asn' }),
            data && data?.arborAlertDetail[`src_asn_${type}`][0]?.[`name_${type}`],
            percentCalculation(srcAsn, progress)
        ),
    ];

    const points = data && [...data.arborAlertDetail.packet_distribution];

    return (
        <div className={classes.wrapper} style={{ overflowX: 'clip'}}>
            <Grid container className={`${classes.headerWrapper} ${classes.displeyBoxNoneMob}`}>
                <button className={classes.download}
                        style={{display: 'flex', justifyContent: 'flex-end', width: '100%', marginBottom: 15, alignItems: 'center'}}
                        onClick={() => {
                            refreshAlertDetails({
                                variables: {
                                    ids: details?.id
                                },
                            })
                                .then(res => res.data.success && refetch({ id: details?.id, }))
                                .catch(err => toast.error(err.message))
                        }}>
                    <RefreshIcon style={{ verticalAlign: 'middle' }}/>
                    Refresh
                </button>
                <Grid item xs={12} md={2} className={classes.titleWrapper}>
                    <Typography variant="h6">{formatMessage({ id: 'detailsAnomaly.title' })}</Typography>
                    <Typography color="textSecondary" variant="h6" style={{marginBottom: 15}}>
                        (id {id})
                    </Typography>
                    <PDFDownloadLink document={<PdfDocument store={store} data={data}/>}>
                        <button className={classes.download}>
                            <GetApp style={{ verticalAlign: 'middle' }}/>
                            Download PDF
                        </button>
                    </PDFDownloadLink>
                </Grid>
                <Grid item xs>
                    <Grid container spacing={1}>
                        <Grid item xs>
                            <CardsHead title={formatMessage({ id: 'detailsAnomaly.lvl' })} data={details.importance} starValue={startValue()} />
                        </Grid>
                        <Grid item xs>
                            <CardsHead
                                title={formatMessage({ id: 'detailsAnomaly.maxBit' })}
                                data={`${valueCalculation(details.maxImpactBps, 'bps')}/${valueCalculation(details.maxImpactPps, 'pps')}`}
                            />
                        </Grid>
                        <Grid item xs>
                            <CardsHead title={formatMessage({ id: 'detailsAnomaly.maxPacket' })} data={details.misuseTypes[0]} />
                        </Grid>
                        <Grid item xs md={3}>
                            <CardsHead title={formatMessage({ id: 'detailsAnomaly.name' })} data={details.alertManagedObjectName} />
                        </Grid>
                        <Grid item xs>
                            <CardsHead title={formatMessage({ id: 'detailsAnomaly.ip' })} data={details.alertResourceCidr} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <div className={`${classes.headerWrapper} ${classes.displeyBoxNoneDesck}`}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Paper variant="outlined" className={classes.cardsHederMobile}>
                            <Typography variant="h1" align="center" color="textSecondary">
                                {id}
                            </Typography>
                            <Typography variant="body1" align="center" color="textSecondary">
                                Anomaly ID
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper variant="outlined" className={`${classes.cardsHederMobile} ${classes[details.importance]}`}>
                            <Typography variant="h1" align="center" color="textSecondary">
                                {details.importance}
                            </Typography>
                            <Typography variant="body1" align="center" color="textSecondary">
                                Severti level
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper variant="outlined" className={classes.cardsHederMobile}>
                            <Typography variant="h1" align="center" color="textSecondary">
                                {details.alertManagedObjectName}
                            </Typography>
                            <Typography variant="body1" align="center" color="textSecondary">
                                Resource name
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper variant="outlined" className={classes.cardsHederMobile}>
                            <Typography variant="h1" align="center" color="textSecondary">
                                {details.misuseTypes[0]}
                            </Typography>
                            <Typography variant="body1" align="center" color="textSecondary">
                                Misuse types
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper variant="outlined" className={classes.cardsHederMobile}>
                            <Typography variant="h1" align="center" color="textSecondary">
                                {details.alertResourceCidr}
                            </Typography>
                            <Typography variant="body1" align="center" color="textSecondary">
                                Target
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.wrapperBox}>
                <div className={classes.charts}>
                    <div className={classes.tabs}>
                        <div className={classes.cartTitle}>{formatMessage({ id: 'detailsAnomalySummary.areaChart' })}</div>
                        <AntTabs value={indexTab} onChange={handleChange}>
                            <AntTab label="BPS" {...a11yProps(0)} />
                            <AntTab label="PPS" {...a11yProps(1)} />
                        </AntTabs>
                    </div>
                    <TabPanel value={indexTab} index={0} className={indexContetn === 'anomalyChart' ? classes.displeyBoxActive : classes.displeyBoxNoneMob}>
                        <AreaChartsBasic formatter="bps" time={details.impactRecorded} points={data.arborAlertDetail.misuse_types_bytes} details={details} />
                    </TabPanel>
                    <TabPanel value={indexTab} index={1} className={indexContetn === 'anomalyChart' ? classes.displeyBoxActive : classes.displeyBoxNoneMob}>
                        <AreaChartsBasic formatter="pps" time={details.impactRecorded} points={data.arborAlertDetail.misuse_types_packets} details={details} />
                    </TabPanel>
                </div>
                <Grid container spacing={2} className={classes.headerWrapper}>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        className={indexContetn === 'anomalyCharacterisation' ? classes.displeyBoxActive : classes.displeyBoxNoneMob}
                    >
                        <TableScroll tableRef={table}>
                            <TableContainer ref={table}>
                                <div className={classes.itemTitle}>{formatMessage({ id: 'detailsAnomalySummary.characterization' })}</div>
                                <Table>
                                    <TableBody>
                                        {rows.map(row => (
                                            row.data &&
                                            <TableRow key={row.name}>
                                                <AntTableCell>{row.name}</AntTableCell>
                                                <AntTableCell>{row.data}</AntTableCell>
                                                <AntTableCell className={`${classes.linear} ${classes.displeyBoxNoneMob}`}>
                                                    <BorderLinearProgress variant="determinate" value={100 - row.progress} />
                                                </AntTableCell>
                                                <AntTableCell className={classes.greyColor}>{row.progress} %</AntTableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TableScroll>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} className={indexContetn === 'anomalyDistribution' ? classes.displeyBoxActive : classes.displeyBoxNoneMob}>
                        <div className={classes.itemWrapper}>
                            <div className={classes.itemTitle}>{formatMessage({ id: 'detailsAnomalySummary.barCharts' })}</div>
                            <BarChartsBasic
                                points={data && points.slice(0, points.length - 1)}
                                lastPoint={data && data.arborAlertDetail.packet_distribution[data.arborAlertDetail.packet_distribution.length - 1]}
                                color={'#26BF83'}
                            />
                            <div style={{ marginBottom: '11px' }}/>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default Summary;
