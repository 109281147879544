import { useQuery } from '@apollo/client';
import { Grid, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import { GET_L7_ANALYTICS } from '../../../../actions/get';
import Preloader from '../../../../components/global/Preloader';
import TableScroll from '../../../../components/global/TableScroll';
import { AntTableCell } from '../../../../theme/Table';
import { AntTableCellHead, AntTableCellLabel, useStyles } from '../styles';

const UaDistribution = ({ date, l7ResourceId }) => {
    const classes = useStyles();
    const table = useRef(null);
    const { formatMessage } = useIntl();

    const {
        data: botsUa,
        loading: loadingBotsUa,
        error: errorBotsUa,
    } = useQuery(GET_L7_ANALYTICS, {
        variables: {
            from: Math.round(date.startDate / 1000),
            until: Math.round(date.endDate / 1000),
            l7ResourceId: l7ResourceId,
            metric: 'REQUEST_UA_FOR_BOT',
        },
    });

    const {
        data: humanUa,
        loading: loadingHumanUa,
        error: errorHumanUa,
    } = useQuery(GET_L7_ANALYTICS, {
        variables: {
            from: Math.round(date.startDate / 1000),
            until: Math.round(date.endDate / 1000),
            l7ResourceId: l7ResourceId,
            metric: 'REQUEST_UA_FOR_HUMAN',
        },
    });

    if (loadingBotsUa && loadingHumanUa) return <Preloader />;

    let x = 0;
    const sumCountBotsUa = botsUa && botsUa.l7Analytics.map(i => (x += i.c), (x = 0)).reverse()[0];
    const sumCountHumanUa = humanUa && humanUa.l7Analytics.map(i => (x += i.c), (x = 0)).reverse()[0];

    return (
        <div className={classes.wrapper}>
            {!loadingBotsUa && !loadingHumanUa && (
                <Grid container spacing={5}>
                    <Grid item xs={6}>
                        {errorBotsUa || (botsUa && botsUa.l7Analytics.length === 0) ? (
                            <div className={classes.errorCharts}>{formatMessage({ id: 'charts.error' })}</div>
                        ) : (
                            <TableScroll tableRef={table}>
                                <TableContainer ref={table}>
                                    <Grid container>
                                        <Grid item>
                                            <Typography className={classes.tableTitle}>
                                                {formatMessage({ id: 'webProtection.analytic.uaDist.titleBot' })}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <AntTableCellHead colSpan={5}></AntTableCellHead>
                                            </TableRow>
                                        </TableHead>
                                        <TableHead>
                                            <TableRow>
                                                <AntTableCellLabel />
                                                <AntTableCellLabel className={classes.tableLabel}>http_user_agent</AntTableCellLabel>
                                                <AntTableCellLabel className={classes.tableLabel} align="right">
                                                    count
                                                </AntTableCellLabel>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {botsUa &&
                                                botsUa.l7Analytics.map(({ c, ua }, index) => (
                                                    <TableRow key={index}>
                                                        <AntTableCell className={classes.numberStyle}>{index + 1}</AntTableCell>
                                                        <AntTableCell>
                                                            <div title={ua}>
                                                                {ua.substr(0, 80)}
                                                                {ua.length > 80 ? '...' : ''}
                                                            </div>
                                                        </AntTableCell>
                                                        <AntTableCell align="right">
                                                            {c}&nbsp;(<span className={classes.blueText}>{((c / sumCountBotsUa) * 100).toFixed(2)}%</span>)
                                                        </AntTableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </TableScroll>
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        {errorHumanUa || (humanUa && humanUa.l7Analytics.length === 0) ? (
                            <div className={classes.errorCharts}>{formatMessage({ id: 'charts.error' })}</div>
                        ) : (
                            <TableScroll tableRef={table}>
                                <TableContainer ref={table}>
                                    <Grid container>
                                        <Grid item>
                                            <Typography className={classes.tableTitle}>
                                                {formatMessage({ id: 'webProtection.analytic.uaDist.titleUser' })}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <AntTableCellHead colSpan={5}></AntTableCellHead>
                                            </TableRow>
                                        </TableHead>
                                        <TableHead>
                                            <TableRow>
                                                <AntTableCellLabel />
                                                <AntTableCellLabel className={classes.tableLabel}>http_user_agent</AntTableCellLabel>
                                                <AntTableCellLabel className={classes.tableLabel} align="right">
                                                    count
                                                </AntTableCellLabel>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {humanUa &&
                                                humanUa.l7Analytics.map(({ c, ua }, length) => (
                                                    <TableRow key={length}>
                                                        <AntTableCell className={classes.numberStyle}>{length + 1}</AntTableCell>
                                                        <AntTableCell>
                                                            <div title={ua}>
                                                                {ua.substr(0, 80)}
                                                                {ua.length > 80 ? '...' : ''}
                                                            </div>
                                                        </AntTableCell>
                                                        <AntTableCell align="right">
                                                            {c}&nbsp;(<span className={classes.blueText}>{((c / sumCountHumanUa) * 100).toFixed(2)}%</span>)
                                                        </AntTableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </TableScroll>
                        )}
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default UaDistribution;
