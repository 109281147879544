import React from 'react';
import {View, Image, Text} from '@react-pdf/renderer';
import logo from '../../../../../../../../assets/img/logo-pdf.png'
import { styles } from "../../styles";

const Header = () => {
    return (
        <View style={styles.header}>
            <Text render={({ pageNumber, totalPages }) => (
                `${pageNumber} / ${totalPages}`
            )} fixed />
            <Image style={styles.img} src={logo} />
        </View>
    )

};

export default Header;
