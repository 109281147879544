import React from 'react';
import { useStyles } from './styles';
import { Button, InputAdornment } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import AuthWrapper from '../../components/global/Wrappers/authWrapper';
import { Link } from 'react-router-dom';
import routes from '../../routes';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { RESET_PASSWORD } from '../../actions/update';
import spLogoMain from "../../../assets/img/logo-welness.svg";
import {AuthLabel, AuthTextField} from "../../components/global/Wrappers/authWrapper/styles";
import FormControl from "@material-ui/core/FormControl";
import {checkHosts} from "../../branding";
import {logosAuth} from "../../branding/logos";

const ForgotPassword = () => {
    const classes = useStyles();

    const { push } = useHistory();

    const [resPass, { loading }] = useMutation(RESET_PASSWORD);
    const onSubmit = data => {
        resPass({
            variables: {
                email: data.email,
                type: 'CLIENT_ACCOUNT_EMPLOYEE',
            },
        })
            .then(() => {
                toast.success('Success');
                push(`${routes.forgotPasswordDone}?email=${data.email}`);
            })
            .catch(err => console.log(err.message));
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string().required('Current Email Address is required').email('User with this email does not exist'),
    });

    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    return (
        <>
            <AuthWrapper>
                <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                    <div className={classes.logo}>
                        { checkHosts(window.location.host, logosAuth ) }
                    </div>
                    <div className={classes.title}>Reset your password</div>
                    <div className={classes.description}>We’ll email you instructions to reset your password.</div>
                    <FormControl style={{ marginBottom: 40 }}>
                        <AuthLabel shrink htmlFor="email" error={!!errors.email}>
                            Email Address
                        </AuthLabel>
                        <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value } }) => (
                                <AuthTextField id="email"
                                               placeholder='Enter your email address'
                                               onChange={onChange}
                                               value={value}
                                               error={!!errors.email}
                                               disableUnderline={true}
                                               helperText={errors?.email?.message ?? ' '}
                                               InputProps={{
                                                   endAdornment: <InputAdornment position="end">{errors.email ? <Error color="error" /> : ''}</InputAdornment>,
                                               }}
                                />
                            )}
                        />
                    </FormControl>
                    <Button type="submit" color="primary" variant="contained" className={classes.button}>
                        reset password
                    </Button>
                    <Link to={routes.auth} className={classes.link}>
                        Return to log in
                    </Link>

                </form>
            </AuthWrapper>
        </>
    );
};

export default ForgotPassword;
