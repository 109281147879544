import React, { useReducer, createContext } from 'react';
import { loadState, saveState } from "../helpers/localStorage";

if (localStorage.getItem('jwtToken') === null) {
    localStorage.removeItem('FILTER_ACTIVE');
    localStorage.removeItem('SECONDARY_FILTER');
}

export const initialState = {
    filterActive: loadState('FILTER_ACTIVE') || {
        limit: 10,
        page: 0,
        startDate: new Date().setDate(new Date().getDate() - 1),
        endDate: new Date().setDate(new Date().getDate()),
        timeReportStart: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1, 0, 0),
        timeReportEnd: new Date(new Date().getFullYear(), new Date().getMonth(), 0, 23, 59, 59),
        indexTab: 0,
        thrLvlState: { low: true, medium: true, high: true },
        sortActive: 'sortStart',
        activeZoomChart: true,
        traffic: {
            indexTab: false,
            proxy: false,
            page: 0,
            limit: 10,
            select: null,
            arborResources: [],
        }
    },
    secondaryFilter: loadState('SECONDARY_FILTER') || {
        limit: 10,
        page: 0,
        startDate: new Date().setDate(new Date().getDate() - 1),
        endDate: new Date().setDate(new Date().getDate()),
        indexTab: 0,
        sortActive: 'sortId',
        activeZoomChart: true,
    },
};

const PageContext = createContext({
    filterActive: {
        limit: 10,
        page: 0,
        startDate: new Date().setDate(new Date().getDate() - 1),
        endDate: new Date().setDate(new Date().getDate()),
        timeReportStart: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1, 0, 0),
        timeReportEnd: new Date(new Date().getFullYear(), new Date().getMonth(), 0, 23, 59, 59),
        indexTab: 0,
        thrLvlState: { low: true, medium: true, high: true },
        sortActive: 'sortStart',
        activeZoomChart: true,
        traffic: {
            indexTab: false,
            proxy: false,
            page: 0,
            limit: 10,
            select: null,
            arborResources: [],
        }
    },
    secondaryFilter: {
        limit: 10,
        page: 0,
        startDate: new Date().setDate(new Date().getDate() - 1),
        endDate: new Date().setDate(new Date().getDate()),
        indexTab: 0,
        sortActive: 'sortId',
        activeZoomChart: true,
    },
    setFilterActive: data => {},
    setSecondaryFilter: data => {},
    deleteFilter: () => {},
    deleteSecondaryFilter: () => {},
});

function pagesReducer(state, action) {
    switch (action.type) {
        case 'FILTER_ACTIVE':
            return {
                ...state,
                filterActive: action.payload,
            };
        case 'SECONDARY_FILTER': {
            return {
                ...state,
                secondaryFilter: action.payload,
            }
        }
        case 'DELETE_SECONDARY_FILTER':
            return {
                ...state,
                secondaryFilter: {
                    limit: 10,
                    page: 0,
                    startDate: new Date().setDate(new Date().getDate() - 1),
                    endDate: new Date().setDate(new Date().getDate()),
                    indexTab: 0,
                    sortActive: 'sortId',
                    activeZoomChart: true,

                },
            };
        case 'DELETE_FILTER':
            return {
                ...state,
                filterActive: {
                    limit: 10,
                    page: 0,
                    startDate: new Date().setDate(new Date().getDate() - 1),
                    endDate: new Date().setDate(new Date().getDate()),
                    timeReportStart: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1, 0, 0),
                    timeReportEnd: new Date(new Date().getFullYear(), new Date().getMonth(), 0, 23, 59, 59),
                    indexTab: 0,
                    thrLvlState: { low: true, medium: true, high: true },
                    sortActive: 'sortStart',
                    activeZoomChart: true,
                    traffic: {
                        indexTab: false,
                        proxy: false,
                        page: 0,
                        limit: 10,
                        select: null,
                        arborResources: [],
                    }
                },
            };
        default:
            return state;
    }
}

function PageProvider(props) {
    const [state, dispatch] = useReducer(pagesReducer, initialState);

    function setFilterActive(data) {
        saveState(data, 'FILTER_ACTIVE');
        dispatch({
            type: 'FILTER_ACTIVE',
            payload: data,
        });
    }

    function setSecondaryFilter(data) {
        saveState(data, 'SECONDARY_FILTER');
        dispatch({
            type: 'SECONDARY_FILTER',
            payload: data,
        });
    }

    function deleteSecondaryFilter() {
        localStorage.removeItem('SECONDARY_FILTER');
        dispatch({ type: 'DELETE_SECONDARY_FILTER' });
    }

    function deleteFilter() {
        localStorage.removeItem('FILTER_ACTIVE');
        dispatch({ type: 'DELETE_FILTER' });
    }

    return (
        <PageContext.Provider
            value={{
                setFilterActive,
                deleteFilter,
                deleteSecondaryFilter,
                setSecondaryFilter,
                filterActive: state.filterActive,
                secondaryFilter: state.secondaryFilter,
            }}
            {...props}
        />
    );
}

export { PageContext, PageProvider };
