import { LOCALES } from '../locales';
/* eslint-disable import/no-anonymous-default-export */

export default {
    [LOCALES.RUSSIAN]: {
        // table
        'table.pagination': 'Строк на странице:',
        'table.filter': 'Поиск',
        // table.setFilter
        'table.filter.title': 'Фильтр',
        'table.filter.label': 'Уровень угрозы',

        // date
        'date.minute': '1 час',
        'date.4hours': '4 часа',
        'date.day': '24 часа',
        'date.week': 'Неделя',
        'date.month': 'Месяц',
        // header
        'header.support': 'Техническая поддержка',
        'header.account': 'Аккаунт ID:',
        'headerTab.l3protection': 'Защита сети',
        'headerTab.webProtection': 'Защита веб-приложений',
        'headerTab.dashboard': 'Обзор',
        'headerTab.l3protection20': 'Защита сети ver 2.0',
        // profile
        'profile.tab.profile': 'Мой профиль',
        'profile.tab.pass': 'Пароль',
        'profile.tab.members': 'Пользователи',
        'profile.tab.not': 'Уведомления',
        'profile.pop.profile': 'Профиль',
        'profile.pop.pass': 'Смена пароля',
        'profile.pop.members': 'Пользователи',
        'profile.pop.not': 'Уведомления',
        'profile.pop.out': 'Выйти',
        // profile.info
        'profile.info.title': 'Информация о пользователе',
        'profile.info.name1': 'Имя',
        'profile.info.name2': 'Фамилия',
        'profile.info.btn': 'Сохранить',
        // profile.pass
        'profile.pass.title': 'Смена пароля',
        'profile.pass.btn': 'Сменить пароль',
        'profile.pass.now': 'Текущий пароль',
        'profile.pass.new': 'Новый пароль',
        'profile.pass.dbl': 'Подтвердите новый пароль',
        'profile.pass.8': '8 знаков',
        'profile.pass.lower': 'одна буква в нижнем регистре',
        'profile.pass.upper': 'одна буква в вернем регистре',
        'profile.pass.number': 'одна цифра',
        'profile.pass.sign': 'один спецсимвол',
        // profile.members
        'profile.members.title': 'Пользователи',
        'profile.members.btn': 'добавить пользователя',
        'profile.members.id': 'ID',
        'profile.members.name': 'Имя',
        'profile.members.email': 'Email',
        'profile.members.role': 'Роль',
        'profile.members.status': 'Статус',
        'profile.members.pop.settings': 'Настройки',
        'profile.members.pop.del': 'Удалить',
        // profile.not
        'profile.not.title': 'Уведомления',
        'profile.not.switch': 'Уведомления от сервиса защиты сети',
        'profile.not.subtitle': 'Уровень аномалий по которым будут приходить уведомления',
        'profile.not.high': 'Высокий',
        'profile.not.medium': 'Средний',
        'profile.not.low': 'Низкий',
        // settingsMembers
        'settingsMembers.title': 'Настройки ресурса',
        'settingsMembers.back': 'назад',
        'settingsMembers.tab.network': 'защита сети',
        'settingsMembers.tab.application': 'защита приложений',
        // settingsMembersNet
        'settingsMembersNet.title': 'Доступ к ресурсам защиты сети для ',
        'settingsMembersNet.tableTitle.resName': 'Имя ресурса',
        'settingsMembersNet.checkLabel.view': 'Просмотр',
        // settingsMembersApp
        'settingsMembersApp.title': 'Доступ к ресурсам сервиса защиты веб-приложений для ',
        'settingsMembersApp.checkLabel.l7': 'создание ресурсов защиты приложений',
        'settingsMembersApp.tableTitle.resName': 'Имя ресурса',
        'settingsMembersApp.checkLabel.view': 'Просмотр',
        'settingsMembersApp.checkLabel.edit': 'Редактирование',
        'settingsMembersApp.checkLabel.delete': 'Удаление',
        //modal.user
        'modal.user.invite.title': 'Приглашение пользователя',
        'modal.user.invite.accept': 'Пригласить',
        'modal.user.invite.cancel': 'Отмена',
        'modal.user.delete.title': 'Удалить пользователя',
        'modal.user.delete.text': 'Вы уверены что хотите удалить данного пользователя?',
        'modal.user.delete.accept': 'Удалить',
        'modal.user.delete.cancel': 'отмена',
        // l3Protection
        'l3Protection.resource': 'Ресурсы',
        'l3Protection.anomalies': 'аномалии',
        'l3Protection.mitigations': 'митигации',
        'l3Protection.traffic': 'Трафик',
        'l3Protection.reports': 'Отчеты',
        'l3Protection.traffic.title': 'Статистика по трафику',
        // resource
        'resource.title': 'Список  защищённых ресурсов',
        'resource.id': 'ID',
        'resource.name': 'Имя ресурса',
        'resource.ips': 'IP-адреса',
        'resource.date': 'Дата создания',
        // anomalies
        'anomalies.title': 'Фиксируемые аномалии',
        'anomalies.id': 'ID',
        'anomalies.lvl': 'Уровень',
        'anomalies.ips': 'Целевой IP-адрес',
        'anomalies.type': 'Тип аномалии',
        'anomalies.name': 'Имя ресурса',
        'anomalies.start': 'Начало',
        'anomalies.end': 'Конец',
        'anomalies.duration': 'Длительность',
        'anomalies.max': 'Макс.мощность',
        'anomalies.setFilter': 'Фильтр',
        // reports
        'reports.title': 'Сводный отчёт за',
        'reports.title.summary': 'Общий трафик',
        'reports.title.topAnomaliesTraffic': 'TOP 10 аномалий по объёму трафика ',
        'reports.title.topAnomaliesDuration': 'TOP 10 аномалий по продолжительности',
        'reports.title.topMitigationsDuration': 'TOP 10 митигаций по продолжительности',
        'reports.title.anomaliesSeverity': 'Распределение аномалий по уровню опасности',
        'reports.title.misuseType': 'Распределение аномалий по типу',
        // reports.cards
        'reports.cards.anomalies': 'Аномалий',
        'reports.cards.mitigations': 'Митигаций',
        'reports.cards.attack': 'Максимальная мощность атаки',
        'reports.cards.target': 'Чаще всего атакуют',
        // reportsTableAnomaliesDuration
        'reportsTableAnomaliesDuration.severity': 'Уровень',
        'reportsTableAnomaliesDuration.ip': 'Целевой IP-адрес',
        'reportsTableAnomaliesDuration.type': 'Тип аномалии',
        'reportsTableAnomaliesDuration.name': 'Имя ресурса',
        'reportsTableAnomaliesDuration.start': 'Начало',
        'reportsTableAnomaliesDuration.ended': 'Конец',
        'reportsTableAnomaliesDuration.duration': 'Длительность',
        'reportsTableAnomaliesDuration.volume': 'Макс. мощность',
        // reportsTableAnomaliesTraffic
        'reportsTableAnomaliesTraffic.severity': 'Уровень',
        'reportsTableAnomaliesTraffic.ip': 'Целевой IP-адрес',
        'reportsTableAnomaliesTraffic.type': 'Тип аномалии',
        'reportsTableAnomaliesTraffic.name': 'Имя ресурса',
        'reportsTableAnomaliesTraffic.start': 'Начало',
        'reportsTableAnomaliesTraffic.ended': 'Конец',
        'reportsTableAnomaliesTraffic.duration': 'Длительность',
        'reportsTableAnomaliesTraffic.volume': 'Макс. мощность',
        // reportsTableMitigationsDuration
        'reportsTableMitigationsDuration.anomalyId': 'ID аномалии',
        'reportsTableMitigationsDuration.protected': 'Защищаемый IP',
        'reportsTableMitigationsDuration.name': 'Имя ресурса',
        'reportsTableMitigationsDuration.start': 'Начало',
        'reportsTableMitigationsDuration.ended': 'Конец',
        'reportsTableMitigationsDuration.duration': 'Длительность',

        // mitigations
        'mitigations.title': 'Список митигаций',
        'mitigations.id': 'ID',
        'mitigations.id-anom': 'ID аномалии',
        'mitigations.ip': 'Защищаемые IP',
        'mitigations.name': 'Имя ресурса',
        'mitigations.start': 'Начало',
        'mitigations.end': 'Конец',
        'mitigations.duration': 'Длительность',
        // mitigations.details
        'mitigations.details.tab1': 'детали по митигации',
        'mitigations.details.title': 'Митигация',
        // detailsAnomalyTab
        'detailsAnomalyTab.summary': 'Сводка по аномалии',
        'detailsAnomalyTab.back': 'Назад',
        'detailsAnomalyTab.traffic': 'детальная информация',
        //detailsAnomalyTab cards
        'detailsAnomaly.title': 'Детали аномалии',
        'detailsAnomaly.lvl': 'Уровень опасности',
        'detailsAnomaly.maxBit': 'Максимальный битрейт',
        'detailsAnomaly.maxPacket': 'Максимальная скорость пакета',
        'detailsAnomaly.name': 'Имя ресурса',
        'detailsAnomaly.ip': 'Целевой IP',
        //detailsAnomalySummary
        'detailsAnomalySummary.areaChart': 'Аномальный трафик',
        'detailsAnomalySummary.characterization': 'Характеристика аномалии',
        'detailsAnomalySummary.barCharts': 'Распределение размера пакетов',
        // detailsAnomalySummary.characteristic
        'detailsAnomalySummary.characteristic.ip': 'Целевой IP адрес',
        'detailsAnomalySummary.characteristic.type': 'Тип аномалии',
        'detailsAnomalySummary.characteristic.protocol': 'Протоколы',
        'detailsAnomalySummary.characteristic.tcp': 'TCP-порты источников',
        'detailsAnomalySummary.characteristic.country': 'Страны источников',
        'detailsAnomalySummary.characteristic.ips': 'IP-адреса источников',
        'detailsAnomalySummary.characteristic.tcp-p': 'Целевые TCP-порты',
        'detailsAnomalySummary.characteristic.tcp-f': 'TCP флаги',
        'detailsAnomalySummary.characteristic.asn': 'ASN источников',
        //detailsTab.traffic

        'detailsAnomaly.trafficTitle.source-ip': 'IP-адреса источники',
        'detailsAnomaly.trafficTitle.dst-ip': 'Целевые IP-адреса',
        'detailsAnomaly.trafficTitle.source-tcp': 'TCP-порты источники',
        'detailsAnomaly.trafficTitle.dst-tcp': 'Целевые TCP-порты',
        'detailsAnomaly.trafficTitle.source-udp': 'UDP-порты источники',
        'detailsAnomaly.trafficTitle.dst-udp': 'Целевые UDP-порты',
        'detailsAnomaly.trafficTitle.source-cnt': 'Страны источники',
        'detailsAnomaly.trafficTitle.source-asn': 'ASN источники',
        'detailsAnomaly.trafficTitle.tcp-f': 'TCP флаги',
        'detailsAnomaly.trafficTitle.proto': 'Протоколы',
        'detailsAnomaly.trafficTitle.type': 'Типы аномалии',
        // anomalyDetailsTraffic.tableTop
        'anomalyDetailsTraffic.tableTop.noDataText':
            'За последние 5 минут выбранного таймфрейма паттернов не обнаружено.',
        'anomalyDetailsTraffic.tableTop.top':
            'ТОП паттернов  (за последние 5 минут)',
        'anomalyDetailsTraffic.tableTop.source': 'Источник',
        'anomalyDetailsTraffic.tableTop.protocol': 'Протокол',
        'anomalyDetailsTraffic.tableTop.flags': 'Флаги',
        'anomalyDetailsTraffic.tableTop.sourcePort': 'Исходный порт',
        'anomalyDetailsTraffic.tableTop.destination': 'Назначение',
        'anomalyDetailsTraffic.tableTop.destPort': 'Порт назначения',
        'anomalyDetailsTraffic.tableTop.maxTraffic': 'Макс трафик',
        // anomalyDetailsTraffic
        'anomalyDetailsTraffic.name': 'Название',
        'anomalyDetailsTraffic.port': 'Порт',
        'anomalyDetailsTraffic.country': 'Страна',
        'anomalyDetailsTraffic.number': 'Число',
        'anomalyDetailsTraffic.flag': 'Флаг',
        'anomalyDetailsTraffic.type': 'Тип',
        'anomalyDetailsTraffic.avg': 'Среднее (макс.)',
        'anomalyDetailsTraffic.error': 'Нет данных.',
        'anomalyDetailsTraffic.more': 'Подробнее',
        // webProtection
        'webProtection.tab.resource': 'ресурсы',
        'webProtection.tab.statistics': 'статистика трафика',
        'webProtection.tab.analytics': 'аналитика трафика',
        // webProtection.resource
        'webProtection.resource.title': 'Список защищённых ресурсов',
        'webProtection.resource.btn': 'создать ресурс',
        'webProtection.resource.id': 'ID',
        'webProtection.resource.name': 'Имя ресурса',
        'webProtection.resource.ddos': 'L7 защита от DDoS',
        'webProtection.resource.bot': 'Фильтрация ботов',
        'webProtection.resource.ip': 'Сервис IP',
        'webProtection.resource.date': 'Дата создания',
        'webProtection.resource.text':
            'Вы уверены что хотите удалить ресурс? После подтверждения удаления мы перестанем принимать и обслуживать трафик для этого ресурса!',
        'webProtection.resource.active': 'Включена',
        'webProtection.resource.dis': 'Выключена',
        'webProtection.resource.help1': 'Для прав на создание, пожалуйста ',
        'webProtection.resource.help2': 'свяжитесь с вашим администратором.',
        // webProtection.pop
        'webProtection.pop.general': 'Основное',
        'webProtection.pop.policy': 'политики доступа',
        'webProtection.pop.black': 'чёрный список',
        'webProtection.pop.white': 'белый список',
        'webProtection.pop.delete': 'Удалить',
        'webProtection.pop.aliases': 'алиасы',
        // webProtection.create
        'webProtection.create.title': 'Создание ресурса',
        'webProtection.create.step1': 'Шаг 1',
        'webProtection.create.input': 'Укажите доменное имя (без www)',
        'webProtection.create.next': 'Дальше',
        'webProtection.create.cancel': 'Отмена',
        'webProtection.create.step2': 'Шаг 2',
        'webProtection.create.input2': 'Укажите IP-адрес хостинга (для очищенного трафика)',
        'webProtection.create.accept': 'создать ресурс',
        // webProtection.delete
        'webProtection.delete.title': 'Удаление ресурса',
        'webProtection.delete.subtitle': 'Вы уверены что хотите удалить ресурс ?',
        'webProtection.delete.text': 'После подтверждения удаления мы перестанем принимать и обслуживать трафик для этого ресурса!',
        'webProtection.delete.accept': 'удалить',
        'webProtection.delete.cancel': 'отмена',
        // webProtection.tabs
        'webProtection.tabs.general': 'Основное',
        'webProtection.tabs.aliases': 'алиасы',
        'webProtection.tabs.white': 'белый список',
        'webProtection.tabs.black': 'чёрный список',
        'webProtection.tabs.politic': 'политики доступа',
        'webProtection.tabs.back': 'назад',
        // webProtection.general
        'webProtection.general.redirect': 'Редирект с www на основной домен',
        'webProtection.general.title': 'Основные настройки',
        'webProtection.general.domen': 'Доменное имя защищаемого веб-ресурса',
        'webProtection.general.ssl': 'SSL сертификат',
        'webProtection.general.ip': 'Оригинальный IP-адрес:',
        'webProtection.general.type': 'Тип:',
        'webProtection.general.weight': 'Вес:',
        'webProtection.general.hash': 'Режим балансировки:',
        'webProtection.general.edit': 'редактировать',
        'webProtection.general.switch1': 'Редирект с www на основной домен',
        'webProtection.general.switch2': 'Глобальный белый список',
        'webProtection.general.switch2.dsc': 'Не блокируются поисковые и платёжные системы',
        'webProtection.general.switch3': 'Редирект с http на https',
        'webProtection.general.switch4': 'Проксирование с https на http',
        'webProtection.general.switch4.dsc': 'Мы будем коммуницировать с вашим сервером по HTTP даже в случае обработки HTTPS-запросов к защищаемому ресурсу',
        // webProtection.aliases
        'webProtection.aliases.title': 'Алиасы',
        'webProtection.aliases.btn': 'добавить алиас',
        'webProtection.aliases.main': 'Алиас',
        'webProtection.aliases.general': 'General SSL',
        'webProtection.aliases.scrp': 'Let’s Encrypt SSL',
        'webProtection.aliases.custom': 'Custom SSL',
        'webProtection.aliases.date': 'Дата создания',
        'webProtection.aliases.ssl': 'SSL сертификат',
        'webProtection.aliases.delete.title': 'Удаление алиаса',
        'webProtection.aliases.delete.text': 'Вы уверены что хотите удалить алиас',
        'webProtection.aliases.delete.accept': 'удалить',
        'webProtection.aliases.delete.cancel': 'отмена',
        'webProtection.aliases.create.title': 'Добавление алиаса',
        'webProtection.aliases.create.subtitle': 'Укажите доменное имя',
        'webProtection.aliases.create.span': 'Пример: servicepipe.ru',
        'webProtection.aliases.create.accept': 'добавить',
        'webProtection.aliases.create.cancel': 'Отмена',
        // webProtection.white
        'webProtection.white.title': 'Белый список',
        'webProtection.white.btn': 'добавить IP',
        'webProtection.white.ip': 'IP',
        'webProtection.white.comment': 'Комментарий',
        'webProtection.white.date': 'Дата создания',
        'webProtection.white.create.title': 'Добавление IP-адреса в белый список',
        'webProtection.white.create.accept': 'добавить',
        'webProtection.white.create.cancel': 'отмена',
        'webProtection.white.comment.title': 'Добавление комментария',
        'webProtection.white.comment.cancel': 'отмена',
        'webProtection.white.comment.accept': 'сохранить',
        // webProtection.black
        'webProtection.black.title': 'Чёрный список',
        'webProtection.black.create.title': 'Добавление IP-адреса в черный список',
        // webProtection.policy
        'webProtection.policy.title': 'Политика доступа к ресурсу  по  географии запросов',
        'webProtection.policy.subtitle': 'Разрешён доступ из всех стран кроме:',
        'webProtection.policy.block': 'Запрещён доступ из всех стран кроме:',
        'webProtection.policy.radio0': 'Отсутствует',
        'webProtection.policy.radio1': 'По умолчанию разрешено',
        'webProtection.policy.radio2': 'По умолчанию запрещено',
        'webProtection.policy.input': 'Выберите страну',
        'webProtection.policy.save': 'Сохранить',
        'webProtection.policy.valid': 'Пожалуйста, укажите хотя бы 1 страну',
        // webProtection.charts
        'webProtection.charts.title': 'Аналитика по трафику',
        'webProtection.charts.request': 'Запросы ',
        'webProtection.charts.band': 'Полоса',
        'webProtection.charts.analytics': 'Разметка запросов',
        'webProtection.charts.times': 'Время ответа сервера',
        'webProtection.charts.codes': 'Коды ответов сервера',
        'webProtection.charts.errors': '5XX ошибки',
        'webProtection.charts.geo': 'География запросов',
        //webProtection.requestsGeography
        'webProtection.requestsGeography.source': 'Страны происхождения',
        'webProtection.requestsGeography.requests': 'Запросы',
        'webProtection.requestsGeography.total': '% от общего',
        'webProtection.requestsGeography.countries': 'ТОП-10 стран-источников',
        //  webProtection.analytic
        'webProtection.analytic.title': 'Аналитика трафика',
        'webProtection.analytic.tab.osDist': 'OC распределение',
        'webProtection.analytic.tab.uaDist': 'UA распределение',
        'webProtection.analytic.tab.botIp': 'IP-адреса ботов',
        'webProtection.analytic.tab.botSunb': 'Подсети ботов',
        'webProtection.analytic.botIp.ip.title': 'IP-адрес',
        'webProtection.analytic.botIp.ip.btn1': 'показать число заблокированных',
        'webProtection.analytic.botIp.ip.btn2': 'запросов',
        'webProtection.analytic.botIp.ip.requests': 'Запросы',
        'webProtection.analytic.botIp.validIp.required': 'Введите IP-адрес',
        'webProtection.analytic.botIp.validIp.matches': 'Не корректный IP-адрес',
        'webProtection.analytic.tab.botReq': 'запросы от ботов',
        'webProtection.analytic.botIp.tableTitle': 'TOP 30 ботовых IP-адресов',
        'webProtection.analytic.botReq.tableTitle': 'TOP 30 ботовых запросов',
        'webProtection.analytic.osDist.titleBot': 'Операционные системы ботов',
        'webProtection.analytic.osDist.titleUser': 'Операционные системы пользователей',
        'webProtection.analytic.uaDist.titleBot': 'TOP 30 ботовых UA',
        'webProtection.analytic.uaDist.titleUser': 'TOP 30 пользовательских UA',
        'webProtection.analytic.disabled': 'Для данного ресурса не активирована защита от ботов',
        // dashboard
        'dashboard.net.title': 'Защита сети',
        'dashboard.app.title': 'Защита веб-приложений',
        // dosgate
        'dosgate.trafficStatistic.title': 'Статистика по трафику',
        // charts
        'charts.error': 'НЕТ ДАННЫХ',

        // chartsAreaAllL7Resource
        'chartsAreaAllL7Resource.in': 'Входящий',
        'chartsAreaAllL7Resource.out': 'Исходящий',
        'chartsAreaAllL7Resource.95in': '95ptl (Входящий)',
        'chartsAreaAllL7Resource.96out': '95ptl (Исходящий)',
        'chartsAreaAllL7Resource.totalBand': 'Суммарный трафик',
        // chartsAreaAllTraffic
        'chartsAreaAllTraffic.in': 'Входящий',
        'chartsAreaAllTraffic.out': 'Исходящий',
        'chartsAreaAllTraffic.95in': '95ptl (Входящий)',
        'chartsAreaAllTraffic.96out': '95ptl (Исходящий)',
        'chartsAreaAllTraffic.totalBand': 'Суммарный трафик',
        'chartsAreaAllTraffic.anomalies': 'Аномалии',
        'chartsAreaAllTraffic.mitigations': 'Митигации',
        // chartsAreaChartsBandWidth
        'chartsAreaChartsBandWidth.inbound': 'Входящий трафик',
        'chartsAreaChartsBandWidth.passed': 'Пропущено на источник',
        'chartsAreaChartsBandWidth.outbound': 'Исходящий трафик',
        'chartsAreaChartsBandWidth.tspFrom': '[tcp stream] Пропущено на источник',
        'chartsAreaChartsBandWidth.tspTo': '[tcp stream] Исходящий трафик',
        // chartsAreaChartsRequest
        'chartsAreaChartsRequest.blockedRequest': 'Заблокировано',
        'chartsAreaChartsRequest.legitimateRequest': 'Передано в источник',
        // chartsAreaChartsRequestAnalytics
        'chartsAreaChartsRequestAnalytics.humans': 'Люди',
        'chartsAreaChartsRequestAnalytics.good': 'Хорошие боты',
        'chartsAreaChartsRequestAnalytics.bad': 'Плохие боты',
        'chartsAreaChartsRequestAnalytics.white': 'Белый список',
        // chartsAreaCountryTop10
        'chartsAreaChartsRequestAnalytics.title': 'ТОП-10 стран источник в динамике',
        // chartsAreaDosgateTraffic
        'chartsAreaDosgateTraffic.accept': 'Пропущено',
        'chartsAreaDosgateTraffic.reply': 'Отвечено',
        'chartsAreaDosgateTraffic.drop': 'Заблокировано',
        'chartsAreaDosgateTraffic.title': 'Статистика митигации',
        // chartsAreaDropPass
        'chartsAreaDropPass.drop': 'Заблокировано',
        'chartsAreaDropPass.pass': 'Пропущено',
        // chartsAreaSummaryTraffic
        'chartsAreaSummaryTraffic.in': 'Входящий',
        'chartsAreaSummaryTraffic.out': 'Исходящий',
        // chartsBarChartsBasic
        'chartsBarChartsBasic.jumboFrames': 'гигантские кадры',
        // chartsLineTraffic
        'chartsLineTraffic.in': 'Входящий',
        'chartsLineTraffic.out': 'Исходящий',
        // chartsPieCountryTop10
        'chartsPieCountryTop10.title': 'ТОП-10 стран-источников',
        // chartsPieMisuseTypes
        'chartsPieMisuseTypes.title': 'Распределение уровней серьезности аномалий',
        // chartsPieSeverityLvl
        'chartsPieSeverityLvl.title': 'Распределение уровней серьезности аномалий',
        //  chartsAreaMitigationsStatistic -------------- сергей должен дать перевод
        'chartsAreaMitigationsStatistic.rTotal': 'Rates Total',
        'chartsAreaMitigationsStatistic.rHttp': 'Rates Http',
        'chartsAreaMitigationsStatistic.rFilter': 'Rates Filter',
        'chartsAreaMitigationsStatistic.rProtocol': 'Rates Protocol Baseline',
        'chartsAreaMitigationsStatistic.rSource24': 'Rates Source24 Baseline',
        'chartsAreaMitigationsStatistic.rSynAuth': 'Rates Syn Auth',
        'chartsAreaMitigationsStatistic.rDnsAuth': 'Rates Dns Auth',
        'chartsAreaMitigationsStatistic.rDnsMalformed': 'Rates Dns Malformed',
        'chartsAreaMitigationsStatistic.rHttpRegex': 'Rates Http Regex',
        'chartsAreaMitigationsStatistic.rDnsRegex': 'Rates Dns Regex',
        'chartsAreaMitigationsStatistic.rIdleReset': 'Rates Idle Reset',
        'chartsAreaMitigationsStatistic.rHttpMalformed': 'Rates Http Malformed',
        'chartsAreaMitigationsStatistic.rSipRateLimit': 'Rates Sip Rate limit',
        'chartsAreaMitigationsStatistic.rSipMalformed': 'Rates Sip Malformed',
        'chartsAreaMitigationsStatistic.rZombie': 'Rates Zombie',
        'chartsAreaMitigationsStatistic.rRateLimit': 'Rates Rate limit',
        'chartsAreaMitigationsStatistic.rRegex': 'Rates Regex',
        'chartsAreaMitigationsStatistic.rInvalidPkts': 'Rates Invalid Pkts',
        'chartsAreaMitigationsStatistic.rDnsRateLimit': 'Rates Dns Rate limit',
        'chartsAreaMitigationsStatistic.rDnsNxrRateLimit': 'Rates Dns Nx Rate limit',
        'chartsAreaMitigationsStatistic.rGeoIpFilter': 'Rates Geo ip Filter',
        'chartsAreaMitigationsStatistic.rGeoIpRateLimit': 'Rates Geoip Rate limit',
        'chartsAreaMitigationsStatistic.rSrcNetFilter': 'Rates Srcnet Filter',
        'chartsAreaMitigationsStatistic.rConnLimit': 'Rates Conn limit',

        //modals
        //modalEditSSLCertificate
        'modalEditSSLCertificate.title': 'Настройки SSL',
        'modalEditSSLCertificate.none.dsc': 'Если вы не добавите SSL сертификат мы не сможем обрабатывать HTTPS запросы к вашему веб-ресурсу.',
        'modalEditSSLCertificate.general.dsc': 'Мы будем использовать SSL сертификат который вы установили в табе ',
        'modalEditSSLCertificate.general.dscLink': 'основные настройки.',
        'modalEditSSLCertificate.encrypt.des':
            "Мы выпустим Let's Encrypt SSL сертификат для этого домена и будем автоматически продлевать его. Важно! Эта опция будет работать только если вы уже перевели трафик на сервисный IP-адрес.",
        'modalEditSSLCertificate.custom.desCertificate': 'Цепочка сертификатов (Обязательно в PEM формате)',
        'modalEditSSLCertificate.custom.desPrivate': 'Приватный ключ (Обязательно в PEM формате)',
        'modalEditSSLCertificate.btn.cancel': 'отмена',
        'modalEditSSLCertificate.btn.save': 'сохранить',
        //trial
        'trial.full': 'Tриальный период звершится через',
        'trial.days': 'дней',
        'trial.end': 'Триальный период завершился',
        'trial.title': 'Бесплатный триалный период закончился.',
        'trial.subtitle': ' Пожалуйста обратитесь к вашему менеджеру, чтобы перейти на коммерческую эксплуатацию сервиса.',
    },
};
