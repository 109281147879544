import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    errorCharts: {
        fontSize: 20,
        margin: '50px 0px',
        textAlign: 'center',
    },
    tabs: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginBottom: 30,
    },
    tabsRpm: {
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%',
        marginBottom: 10,
    },
    chartBar: {
        position: 'relative',
        margin: 'auto',
        height: 'calc(100% - 90px)',
        width: '100%',
    },
    chartAll: {
        height: 'calc(100vh - 45vh) !important',
        width: 'calc(100% - 1px) !important',
    },
    chart: {
        position: 'relative',
        margin: 'auto',
        height: 'calc(100vh - 40vh)',
        maxHeight: 550,
        width: 'calc(100% - 50px)',
        '& .zoom': {
            position: 'absolute',
            padding: '7px 16px',
            color: '#FFFFFF',
            backgroundColor: '#2196f3',
            fontSize: 14,
            minWidth: 64,
            boxSizing: 'border-box',
            transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            fontFamily: "Roboto",
            fontWeight: 500,
            borderRadius: 4,
            textTransform: 'uppercase',
            borderColor: 'transparent',
            cursor: 'pointer',
            top: 20,
            left: 80,
        },
        '& .zoom-btn': {
            position: 'absolute',
            padding: '7px 16px',
            color: '#FFFFFF',
            backgroundColor: '#2196f3',
            fontSize: 14,
            minWidth: 64,
            boxSizing: 'border-box',
            transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            fontFamily: "Roboto",
            fontWeight: 500,
            borderRadius: 4,
            textTransform: 'uppercase',
            borderColor: 'transparent',
            cursor: 'pointer',
            top: 0,
            right: 0,
        },
    },
    zoomBtn: {
        position: 'absolute',
        padding: '7px 16px',
        color: '#FFFFFF',
        backgroundColor: '#2196f3',
        fontSize: 14,
        minWidth: 64,
        boxSizing: 'border-box',
        transition:
            'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        fontFamily: 'Roboto',
        fontWeight: 500,
        borderRadius: 4,
        textTransform: 'uppercase',
        borderColor: 'transparent',
        cursor: 'pointer',
        top: -40,
        left: 0
    },
    visibleZoomBtn: {
      display: 'none',
    },
    request: {
        '&:after': {
            position: 'absolute',
            content: 'rpm - requests per minute',
            bottom: 5,
            left: 0,
            fontSize: 14,
        },
    },
    zoomBtnDashboard: {
        position: 'absolute',
        padding: '7px 16px',
        color: '#FFFFFF',
        backgroundColor: '#2196f3',
        fontSize: 14,
        minWidth: 64,
        boxSizing: 'border-box',
        transition:
            'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        fontFamily: 'Roboto',
        fontWeight: 500,
        borderRadius: 4,
        textTransform: 'uppercase',
        borderColor: 'transparent',
        cursor: 'pointer',
        top: 15,
        right: 0
    },
    zoomBtnReports: {
        top: -30,
        right: -10,
        position: 'absolute',
        padding: '7px 16px',
        color: '#FFFFFF',
        backgroundColor: '#2196f3',
        fontSize: 14,
        minWidth: 64,
        boxSizing: 'border-box',
        transition:
            'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        fontFamily: 'Roboto',
        fontWeight: 500,
        borderRadius: 4,
        textTransform: 'uppercase',
        borderColor: 'transparent',
        cursor: 'pointer',
    },
}));
