import React from 'react';
import { Dialog, Button, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useMutation } from '@apollo/client';
import { DELETE_L7_ALIAS } from '../../../../actions/delete';
import { toast } from 'react-toastify';
import { useStyles } from '../../../../theme/Modals';
import { useIntl } from 'react-intl';

const ModalDeleteAliases = ({ show, hideModal, name, query, getVariables, aliasId, l7ResourceId }) => {
    const classes = useStyles();
    const [deleteAlias] = useMutation(DELETE_L7_ALIAS);
    const { formatMessage } = useIntl();

    const onClickDelete = () => {
        deleteAlias({
            variables: {
                aliasId,
                l7ResourceId,
            },
            refetchQueries: [{ query: query, variables: getVariables }],
        })
            .then(() => toast.success('Success'))
            .catch(err => toast.error(err.message));
        hideModal();
    };

    return (
        <div>
            <Dialog
                className={classes.dialog}
                maxWidth="md"
                open={show}
                onClose={() => {
                    hideModal();
                }}
            >
                <div className={classes.modal}>
                    <div className={classes.header}>
                        <Typography variant="h1">{formatMessage({ id: 'webProtection.aliases.delete.title' })}</Typography>
                        <Button className={classes.close} onClick={() => hideModal()}>
                            <CloseIcon />
                        </Button>
                    </div>
                    <div className={classes.modalBody}>
                        <div className={classes.dsc}>
                            {formatMessage({ id: 'webProtection.aliases.delete.text' })} <strong>{name}</strong>?
                        </div>
                    </div>
                    <div className={classes.modalFooter}>
                        <Button className={classes.cancel} onClick={() => hideModal()}>
                            {formatMessage({ id: 'webProtection.aliases.delete.cancel' })}
                        </Button>
                        <Button className={classes.delete} onClick={onClickDelete}>
                            {formatMessage({ id: 'webProtection.aliases.delete.accept' })}
                        </Button>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default ModalDeleteAliases;
