import React from 'react';
import { useStyles } from './styles';
import DatePicker from 'react-datepicker';
import {subDays, addDays} from "date-fns";


const TableDatePicker = ({ filter, setFilter, width, minDate, disabled, maxDate }) => {
    const classes = useStyles();
    const dateNow = new Date();
    const showTime = width === 'xs' ? false : true;
    const {endDate, startDate} = filter;

    return (
        <div className={classes.pickerWrapperTraffic}>
            <DatePicker
                className={classes.dataPickerTraffic}
                selected={startDate}
                onChange={date => {
                    if(minDate && addDays(date, 30) < endDate)
                        setTimeout(() => setFilter({ ...filter, startDate: Date.parse(date), page: 0, indexTab: false, endDate: addDays(date, 30) }), 50);
                    else if(minDate && date > endDate && addDays(date, 30) < dateNow)
                        setTimeout(() => setFilter({ ...filter, startDate: Date.parse(date), page: 0, indexTab: false, endDate: addDays(date, 30) }), 50);
                    else if(minDate && date > endDate && addDays(date, 30) > dateNow)
                        setTimeout(() => setFilter({ ...filter, startDate: Date.parse(date), page: 0, indexTab: false, endDate: dateNow }), 50);
                    setFilter({ ...filter, startDate: Date.parse(date), page: 0, indexTab: false });
                }}
                showTimeSelect={showTime}
                startDate={startDate}
                endDate={endDate}
                maxDate={dateNow}
                dateFormat="dd.MM.yyyy, h:mm aa"
                popperClassName={classes.poper}
                showPopperArrow={false}
                withPortal={width === 'xs'}
                portalId="root-portal"
                showTimeInput={width === 'xs'}
                disabled={disabled}
            />
            <span>-</span>
            <DatePicker
                className={classes.dataPickerTraffic}
                selected={endDate}
                onChange={date => {
                    if(minDate && subDays(date, 30) > startDate)
                        setTimeout(() => setFilter({ ...filter, endDate: Date.parse(date), page: 0, indexTab: false, startDate: subDays(date, 30) }), 50);
                    else if(minDate && subDays(date, 30) > startDate && startDate > date)
                        setTimeout(() => setFilter({ ...filter, endDate: Date.parse(date), page: 0, indexTab: false, startDate: subDays(date, 30) }), 50);
                    setFilter({ ...filter, endDate: Date.parse(date), page: 0, indexTab: false });
                }}
                showTimeSelect={showTime}
                startDate={startDate}
                endDate={endDate}
                maxDate={maxDate ? !maxDate : dateNow}
                minDate={startDate}
                dateFormat="dd.MM.yyyy, h:mm aa"
                popperClassName={classes.poper}
                popperPlacement="bottom-end"
                showPopperArrow={false}
                withPortal={width === 'xs'}
                portalId="root-portal"
                showTimeInput={width === 'xs'}
                disabled={disabled}
            />
        </div>
    );
};
export default TableDatePicker;
