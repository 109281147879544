import React from 'react';
import { Link } from 'react-router-dom';
import { MenuItem, Typography, Popover } from '@material-ui/core';
import { useStyles } from '../../../../theme/PopoverBar';
import { useIntl } from 'react-intl';

const MembersPopover = ({ id, open, anchorEl, handleClose, setIsDeleted, indexId, indexEmail, indexRole }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            className={classes.popover}
        >
            <div className={classes.popoverWrapper}>
                <div className={classes.popoverBody}>
                    {indexRole !== 'ADMIN' && (
                        <div
                            className={classes.popoverItem}
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            <Link to={`/settings-account-members/0/${indexId}`} className={classes.link}>
                                <MenuItem className={classes.popoverMenuItem}>
                                    <Typography variant="inherit">{formatMessage({ id: 'profile.members.pop.settings' })}</Typography>
                                </MenuItem>
                            </Link>
                        </div>
                    )}
                    <div
                        className={classes.popoverItem}
                        onClick={() => {
                            handleClose();
                            setIsDeleted({
                                deleted: true,
                                id: indexId,
                            });
                        }}
                    >
                        <MenuItem className={classes.popoverMenuItem}>
                            <Typography variant="inherit">{formatMessage({ id: 'profile.members.pop.del' })}</Typography>
                        </MenuItem>
                    </div>
                </div>
            </div>
        </Popover>
    );
};

export default MembersPopover;
