import React, { useContext } from 'react';
import { useStyles, AntTableCell, AntTableCellFilter } from '../../../../theme/Table';
import { Table, TableHead, TableBody, TableRow, Typography } from '@material-ui/core';
import { textWidthCalculation } from '../../../../helpers/calculation';
import TableFilter from '../../../../components/global/TableFilter';
import { useIntl } from 'react-intl';
import { PageContext } from '../../../../context/pages';
import Resource from './Resource';

const ResourcesL3Desktop = ({ data, filter, setFilter, setIpsPopover, setAnchorEl }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();

    const maxWidth = {
        id: Math.max.apply(Math, data && data.arborResources.items.map(items => textWidthCalculation(items.id))),
        name: Math.max.apply(Math, data && data.arborResources.items.map(items => textWidthCalculation(items.name))),
    };
    return (
        <>
            <div className={classes.header}>
                <Typography variant="h1">{formatMessage({ id: 'resource.title' })}</Typography>
            </div>
            <Table>
                <TableHead>
                    <TableRow>
                        <AntTableCell className={classes.title}>{formatMessage({ id: 'resource.id' })}</AntTableCell>
                        <AntTableCell className={classes.title}>{formatMessage({ id: 'resource.name' })}</AntTableCell>
                        <AntTableCell className={classes.title}>{formatMessage({ id: 'resource.ips' })}</AntTableCell>
                        <AntTableCell className={classes.title}>{formatMessage({ id: 'resource.date' })}</AntTableCell>
                    </TableRow>
                    <TableRow>
                        <AntTableCellFilter>
                            <TableFilter name="id" filter={filter} setFilter={setFilter} maxWidth={maxWidth.id} />
                        </AntTableCellFilter>
                        <AntTableCellFilter>
                            <TableFilter name="name" filter={filter} setFilter={setFilter} maxWidth={maxWidth.name} />
                        </AntTableCellFilter>
                        <AntTableCellFilter />
                        <AntTableCellFilter />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data &&
                        data.arborResources.items.map(({ manageObjectId, name, ips, createdAt }) => (
                            <TableRow key={manageObjectId}>
                                <Resource id={manageObjectId} name={name} ips={ips} createdAt={createdAt} setAnchorEl={setAnchorEl} setIpsPopover={setIpsPopover} />
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </>
    );
};
export default ResourcesL3Desktop;
