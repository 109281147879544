import React from 'react';
import { useStyles, AntTableCell, AntTableCellFilter } from '../../../../theme/Table';
import { TableRow, TableHead, Table, TableBody, Grid, Typography, Button } from '@material-ui/core';
import { textWidthCalculation } from '../../../../helpers/calculation';
import TableFilter from '../../../../components/global/TableFilter';
import { useIntl } from 'react-intl';
import BlackList from './BlackList';

const BlackListsDesktop = ({ data, filter, setFilter, setIsEditComment, setIsDeleted, setIsEdit }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();

    const maxWidth = {
        ip: Math.max.apply(Math, data && data.l7BlackLists.items.map(items => textWidthCalculation(items.blacklistData))),
    };

    return (
        <>
            <Grid container>
                <Grid item xs>
                    <Typography variant="h1">{formatMessage({ id: 'webProtection.black.title' })}</Typography>
                </Grid>
                <Grid item xs={12} sm="auto">
                    <Button color="primary" variant="contained" className={classes.button} onClick={() => setIsEdit(true)}>
                        {formatMessage({ id: 'webProtection.white.btn' })}
                    </Button>
                </Grid>
            </Grid>
            <Table>
                <TableHead>
                    <TableRow>
                        <AntTableCell align="left" className={classes.title} style={{ width: 150 }}>
                            {formatMessage({ id: 'webProtection.white.ip' })}
                        </AntTableCell>
                        <AntTableCell align="left" className={classes.title} style={{ width: 550 }}>
                            {formatMessage({ id: 'webProtection.white.comment' })}
                        </AntTableCell>
                        <AntTableCell align="left" className={classes.title}>
                            {formatMessage({ id: 'webProtection.white.date' })}
                        </AntTableCell>
                        <AntTableCell />
                    </TableRow>
                    <TableRow>
                        <AntTableCellFilter>
                            <TableFilter name="ip" filter={filter} setFilter={setFilter} maxWidth={maxWidth.ip} />
                        </AntTableCellFilter>
                        <AntTableCellFilter />
                        <AntTableCellFilter />
                        <AntTableCellFilter />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data &&
                        data.l7BlackLists.items.map(({ blacklistData, blacklistComment, blacklistCreated, blacklistId }) => (
                            <TableRow key={blacklistId}>
                                <BlackList
                                    blacklistData={blacklistData}
                                    blacklistComment={blacklistComment}
                                    blacklistId={blacklistId}
                                    blacklistCreated={blacklistCreated}
                                    setIsEdit={setIsEdit}
                                    setIsEditComment={setIsEditComment}
                                    setIsDeleted={setIsDeleted}
                                />
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </>
    );
};

export default BlackListsDesktop;
