import React from 'react';
import { Icon } from '@material-ui/core';

const IconNetwork = () => {
    return (
        <Icon>
            <svg
                class="MuiSvgIcon-root"
                focusable="false"
                aria-hidden="true"
                width="20"
                height="19"
                viewBox="0 0 20 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15.1613 2.49773C13.6629 3.3329 11.8694 4.64137 10.0128 6.31493L9.00848 5.20076C10.9249 3.47327 12.8097 2.09122 14.431 1.18751C15.4718 0.607387 16.4073 0.222561 17.1757 0.07178C17.9309 -0.0764048 18.5916 -0.0104134 19.0158 0.413819C19.4401 0.838051 19.5061 1.49877 19.3579 2.25395C19.2071 3.02238 18.8223 3.95782 18.2422 4.99863C18.1161 5.22471 17.981 5.45552 17.8373 5.69038L16.5579 4.90743C16.6917 4.68874 16.8165 4.4755 16.9319 4.26833C17.4749 3.29419 17.778 2.51527 17.886 1.96512C17.9311 1.73516 17.9344 1.58775 17.9274 1.50227C17.8419 1.4953 17.6945 1.49859 17.4645 1.54371C16.9144 1.65166 16.1355 1.95476 15.1613 2.49773ZM3.46501 13.9149C3.32268 14.1464 3.19043 14.3718 3.06853 14.5905C2.52557 15.5647 2.22244 16.3436 2.1145 16.8937C2.06938 17.1237 2.0661 17.2711 2.07307 17.3566C2.15856 17.3636 2.30596 17.3603 2.53592 17.3152C3.08605 17.2072 3.86499 16.9041 4.83913 16.3611C6.3375 15.526 8.13105 14.2175 9.98768 12.5439L10.992 13.6581C9.07551 15.3856 7.19076 16.7676 5.56943 17.6713C4.52865 18.2515 3.59317 18.6363 2.82473 18.7871C2.06958 18.9353 1.40885 18.8693 0.984621 18.445C0.560392 18.0208 0.494404 17.3601 0.642567 16.6049C0.793344 15.8365 1.1782 14.901 1.75831 13.8602C1.89134 13.6216 2.03448 13.3776 2.18722 13.1292L3.46501 13.9149Z"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.4456 9.74147L7.51589 2.70247L12.4846 7.67117L5.4456 9.74147ZM9.63721 6.94511L8.24196 5.54985L7.66061 7.52646L9.63721 6.94511Z"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14.5551 9.1174L12.4848 16.1564L7.51605 11.1877L14.5551 9.1174ZM10.3634 11.9138L11.7587 13.309L12.34 11.3324L10.3634 11.9138Z"
                />
                <path d="M4.6971 14.7327C1.76812 11.8038 1.76812 7.05512 4.6971 4.12613C6.30803 2.51521 8.46923 1.79066 10.5753 1.95147C11.1636 1.50504 11.7416 1.09538 12.3027 0.727413C9.30745 -0.0621264 5.9846 0.717314 3.63644 3.06547C0.121711 6.58021 0.121711 12.2787 3.63644 15.7934C4.32913 16.4861 5.10676 17.0421 5.93566 17.4618C6.42249 17.1759 6.93081 16.8499 7.45492 16.4863C6.44838 16.1241 5.50373 15.5394 4.6971 14.7327Z" />
                <path d="M9.42553 16.9074C8.8372 17.3538 8.25922 17.7635 7.69808 18.1315C10.6934 18.921 14.0162 18.1416 16.3644 15.7934C19.8791 12.2787 19.8791 6.58021 16.3644 3.06547C15.6717 2.37278 14.894 1.81682 14.0651 1.39706C13.5783 1.68294 13.0703 2.00922 12.5461 2.37278C13.5527 2.73505 14.4971 3.3195 15.3037 4.12613C18.2327 7.05512 18.2327 11.8038 15.3037 14.7327C13.6928 16.3437 11.5316 17.0682 9.42553 16.9074Z" />
            </svg>
        </Icon>
    );
};

export default IconNetwork;
