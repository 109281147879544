import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    logo: {
        marginBottom: 64,
    },
    title: {
        marginBottom: 40,
        fontFamily: 'Manrope',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '35px',
        letterSpacing: '0.25px',
        color: '#041613',
        textAlign: 'left',
        lineHeight: '46px',
    },
    description: {
        marginBottom: 40,
        fontFamily: 'Manrope',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        letterSpacing: '0.5px',
        color: '#041613',
        textAlign: 'left',
        lineHeight: '22px',
        [theme.breakpoints.only('xs')]: {
            padding: 0,
        },
    },
    form: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    input: {
        marginBottom: 10,
        width: '100%',
    },
    button: {
        margin: '42px 0 50px 0',
        width: '100%',
        padding: '15px 131px',
        background: '#01CDCB',
        borderRadius: '100px',
    },
    link: {
        display: 'flex',
        justifyContent: 'center',
        fontWeight: 700,
        fontFamily: 'Manrope',
        fontSize: '16px',
        lineHeight: '22px',
        textAlign: 'center',
        letterSpacing: '1.25px',
        color: theme.palette.grey.grey500,
        textDecoration: 'none',
        marginBottom: 36,
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    list: {
        width: '100%',
        padding: 0,
        margin: '42px 0'
    },
    listItem: {
        padding: '0',
    },
    listIcon: {
        marginRight: 15,
    },
    active: {
        color: theme.palette.primary.main,
    },
    disable: {
        color: '#c6c4c4',
    },
    footer: {
        fontFamily: 'Manrope',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '22px',
        letterSpacing: '0.5px',
        color: '#828B89',
        marginBottom: 20,
        textAlign: 'left',
    }
}));
