import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TableCell, LinearProgress } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10,
    },
    titleHeader: {
        [theme.breakpoints.only('xs')]: {
            marginBottom: 30,
        },
    },

    button: {
        padding: '7px 16px',
        marginLeft: 20,
        [theme.breakpoints.only('xs')]: {
            marginBottom: 10,
            marginLeft: 0,
            padding: 16,
            width: '100%',
        },
    },
    icon: {
        color: '#363636',
        cursor: 'pointer',
        verticalAlign: 'middle',
        fontSize: '22px',
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    iconDelete: {
        color: theme.palette.grey.greyInactive,
        cursor: 'pointer',
        verticalAlign: 'middle',
        fontSize: '22px',
        '&:hover': {
            color: theme.palette.error.main,
        },
    },
    filterWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    date: {
        display: 'flex',
        alignItems: 'center',
    },
    dateTitle: {
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: '0.1px',
        color: 'rgba(0, 0, 0, 0.6)',
        marginRight: 10,
    },
    buttonMore: {
        textTransform: 'lowercase',
        color: theme.palette.primary.primary300,
        fontSize: '8px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '7px',
        letterSpacing: '0.25px',
        padding: 0,
        minWidth: 0,
    },
    buttonMoreSpan: {
        display: 'flex',
        flexDirection: 'column',
    },
    buttonMoreIcon: {
        fontSize: '15px',
    },
    btnFilter: {
        fontSize: '10px',
        width: '50px',
        height: '29px',
        letterSpacing: 0.25,
        borderColor: theme.palette.grey.grey500,
        color: theme.palette.grey.grey500,
        background: theme.palette.grey.greyLight,
        '&:hover': {
            color: theme.palette.primary.grey500,
            background: theme.palette.grey.greyLight,
            borderColor: theme.palette.primary.grey500,
        },
    },
    switcher: {
        display: 'flex',
        alignItems: 'center',
    },
    linear: {
        minWidth: '70px',
    },
    comment: {
        minHeight: 22,
        display: 'flex',
        justifyContent: 'space-between',
        textOverflow: 'fade(10px) fade(10px)',
        '& span': {
            marginRight: '20px',
        },
    },
    errorCharts: {
        fontSize: 25,
    },
    titleMobile: {
        marginLeft: 5,
    },
    selectWrwppMobile: {
        marginTop: 30,
        borderBottom: '1px solid #E5E5E5',
    },
    selectMobile: {
        marginBottom: 10,
        '& select': {
            padding: '10px 16px',
        },
    },
    high: {
        backgroundColor: '#FDE3E3',
        opacity: 0.7,
    },
    medium: {
        backgroundColor: '#FFECB5',
        opacity: 0.7,
    },
    low: {
        backgroundColor: '#F9FDFB',
        opacity: 0.7,
    },
    mitigationColor: {
        backgroundColor: '#F1FCF2',
        opacity: 0.7,
    },
    noData: {
        borderTop: '1px solid rgba(0, 0, 0, 0.2)',
        marginTop: 15,
        paddingTop: 10,
    },
}));

export const AntTableCellFilter = withStyles({
    root: {
        whiteSpace: 'nowrap',
        padding: '9px 10px',
        borderBottom: '1px solid transparent',

        '&.MuiTableCell-sizeSmall': {
            '&:last-child': {
                paddingRight: 16,
            },
            // '&:first-child': {
            //     paddingLeft: 16,
            // },
        },
    },
})(TableCell);

export const TableCellMitigation = withStyles({
    root: {
        '&.MuiTableCell-body': {
            borderBottom: '0px solid transparent',

        },
        '&.MuiTableCell-head': {
            borderBottom: '0px solid transparent',
            color: '#9E9E9E',
        }


    }

})(TableCell);

export const AntTableCell = withStyles(theme => ({
    root: {
        whiteSpace: 'nowrap',
        padding: '12.5px 10px',
        borderBottom: '1px solid transparent',

        [theme.breakpoints.between('xs', 'sm')]: {
            padding: '12.5px 5px',
            fontSize: '13px',
        },
        '&.MuiTableCell-sizeSmall': {
            '&:last-child': {
                [theme.breakpoints.between('xs', 'sm')]: {
                    paddingRight: 0,
                },
            },
            '&:first-child': {
                [theme.breakpoints.between('xs', 'sm')]: {
                    paddingLeft: 0,
                },
            },
        },
    },
    head: {
        color: theme.palette.grey.grey500,
    },
    body: {
        color: '#363636',
    },
}))(TableCell);

export const AntTableCellWhite = withStyles(theme => ({
    head: {
        backgroundColor: '#FFFFFF',
    },
}))(AntTableCell);

export const AntTableCellGrey = withStyles(theme => ({
    head: {
        backgroundColor: '#FAFAFA',
    },
}))(AntTableCell);

export const BorderLinearProgress = withStyles(theme => ({
    root: {
        height: 10,
    },
    colorPrimary: {
        backgroundColor: theme.palette.primary.main,
    },
    bar: {
        backgroundColor: theme.palette.common.white,
    },
}))(LinearProgress);
