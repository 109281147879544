import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    header: {
        borderBottom: '2px solid white',
        padding: '0',
        background: theme.palette.common.grey,
        color: 'rgba(0, 0, 0, 0.6)',
        fontSize: '14px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        lineHeight: '21px',
    },
    disabledHeader: {
        display: 'none',
    },
}));
