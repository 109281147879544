import React, {useState} from 'react';
import { AntTab, AntTabs, useStyles } from '../../../../theme/Tabs/index';
import Summary from './summary';
import Traffic from './traffic';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { AntContainerHead } from '../../../../theme/Wrapper';
import BarMobileAnomalyDetails from './BarMobileAnomalyDetails';
import { useParams } from 'react-router';
import { GET_ARBOR_ALERTS } from "../../../../actions/get";
import { useQuery } from "@apollo/client";
import Preloader from "../../../../components/global/Preloader";
import TabPanel from "../../../../components/global/TabPanel";
import back from "../../../../../assets/img/back.svg";

const AnomalyDetails = () => {
    const classes = useStyles();
    const [indexTab, setIndexTab] = useState(0);
    const [indexContetnSummary, setIndexContentSummary] = useState('anomalyChart');
    const [indexContetnTraffic, setIndexContentTraffic] = useState('sourceIp');
    const { formatMessage } = useIntl();

    const handleChange = (event, newValue) => {
        setIndexTab(newValue);
    };
    const a11yProps = index => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };
    const { backLink, id, indexPage } = useParams();

    const { data, loading } = useQuery(GET_ARBOR_ALERTS, {
        variables: { alertId: Number(id)}
    });

    if (loading) return <Preloader />;

    return (
        <>
            {/* mobile header */}
            <BarMobileAnomalyDetails setIndexContentSummary={setIndexContentSummary} setIndexContentTraffic={setIndexContentTraffic} indexTab={indexTab} />

            {/* descktop header */}
            <div className={classes.bar}>
                <AntContainerHead maxWidth={false} className={classes.container}>
                    <div className={classes.itemBackLink}>
                        <Link to={`/${backLink}/${indexPage}`} className={classes.backLink}>
                            <img src={back} alt="#" className={classes.backIcon}/>
                        </Link>
                    </div>
                    <AntTabs value={indexTab} onChange={handleChange} className={classes.itemTab} variant="scrollable" scrollButtons="auto">
                        <AntTab label={formatMessage({ id: 'detailsAnomalyTab.summary' })} {...a11yProps(0)} className={classes.marginLeft} />
                        <AntTab label={formatMessage({ id: 'detailsAnomalyTab.traffic' })} {...a11yProps(1)} className={classes.marginLeft} />
                    </AntTabs>
                </AntContainerHead>
            </div>
            <TabPanel value={indexTab} index={0}>
                <Summary indexContetn={indexContetnSummary} store={data}/>
            </TabPanel>
            <TabPanel value={indexTab} index={1}>
                <Traffic indexContetn={indexContetnTraffic} store={data}/>
            </TabPanel>

        </>
    );
};

export default AnomalyDetails;
