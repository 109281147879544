import React from 'react';
import {useIntl} from "react-intl";
import {useStyles} from "./styles";
import Grid from "@material-ui/core/Grid";
import {valueCalculation} from "../../../../helpers/calculation";

const ApplicationBoxes = ({ store }) => {
    const { formatMessage } = useIntl();
    const classes = useStyles();

    return (
        <Grid container direction="column" alignItems="flex-start" style={{ marginTop: 70 }}>
            <Grid item>
                <div className={classes.title}>{formatMessage({ id: 'chartsAreaAllL7Resource.95in' })}</div>
                <div className={classes.subtitle}>{valueCalculation(store?.l7GraphiteBandWidthForAllResources?.nPercentile95ToClientBandWidth, 'bps')}</div>
            </Grid>
            <Grid item>
                <div className={classes.title}>{formatMessage({ id: 'chartsAreaAllL7Resource.96out' })}</div>
                <div className={classes.subtitle}>{valueCalculation(store?.l7GraphiteBandWidthForAllResources?.nPercentile95FromClientBandWidth, 'bps')}</div>
            </Grid>
            {/*<Grid item>*/}
            {/*    <div className={classes.title}>Requests</div>*/}
            {/*    <div className={classes.subtitle}>2</div>*/}
            {/*</Grid>*/}
            {/*<Grid item>*/}
            {/*    <div className={classes.title}>Errors (5XX)</div>*/}
            {/*    <div className={classes.subtitle}>1</div>*/}
            {/*</Grid>*/}
        </Grid>
    );
};

export default ApplicationBoxes