import React from "react";
import {useStyles} from "./styles";
import Preloader from "../../components/global/Preloader";
import {AntContainer, AntPaper} from "../../theme/Wrapper";
import {Grid} from "@material-ui/core";
import DashboardNetwork from "./network";
import DashboardApplication from "./application";
import PropTypes from "prop-types";

const DashboardPage = ({data, loading}) => {
    const classes = useStyles();


    if (loading) return <Preloader />;

    return (
        <AntContainer maxWidth={false}>
            <Grid container>
                <Grid item xs={12}>
                    {data?.clientAccountEmployee?.clientAccount?.accountSettings?.l3?.l3Limit !== 0 &&
                        data?.clientAccountEmployee?.clientAccount?.accountSettings?.l3?.l3Limit !== null && (
                            <DashboardNetwork clientAccountId={data?.clientAccountEmployee?.clientAccountId} />
                        )}
                </Grid>
                <Grid item xs={12}>
                    {data?.clientAccountEmployee?.clientAccount?.accountSettings?.l7?.l7Limit !== 0 &&
                        data?.clientAccountEmployee?.clientAccount?.accountSettings?.l7?.l7Limit !== null && (
                            <DashboardApplication clientAccountId={data?.clientAccountEmployee?.clientAccountId} />
                        )}
                </Grid>
            </Grid>
        </AntContainer>
    );
};

DashboardPage.propTypes = {
    data: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired
}

export default DashboardPage;