import React from 'react';
import { Dialog, Button, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { DELETE_L7_BLACK_LIST, DELETE_L7_WHITE_LIST } from '../../../../actions/delete';
import { useStyles } from '../../../../theme/Modals';

const ModalDeleteL7List = ({ show, hideModal, text, query, getVariables, resourcesId, id, type, ip }) => {
    const classes = useStyles();

    const typeQuery = type === 'blackList' ? DELETE_L7_BLACK_LIST : type === 'whiteList' ? DELETE_L7_WHITE_LIST : '';

    const [setDeleteL7List] = useMutation(typeQuery);

    const onClickDelete = () => {
        if (type === 'blackList') {
            setDeleteL7List({
                variables: {
                    l7ResourceId: resourcesId,
                    blacklistId: id,
                },
                refetchQueries: [
                    {
                        query: query,
                        variables: getVariables,
                    },
                ],
            })
                .then(() => {
                    toast.success('Success');
                })
                .catch(err => toast.error(err.message));
        }
        if (type === 'whiteList') {
            setDeleteL7List({
                variables: {
                    l7ResourceId: resourcesId,
                    whitelistId: id,
                },
                refetchQueries: [
                    {
                        query: query,
                        variables: getVariables,
                    },
                ],
            })
                .then(() => {
                    toast.success('Success');
                })
                .catch(err => toast.error(err.message));
        }

        hideModal();
    };

    return (
        <div>
            <Dialog
                className={classes.dialog}
                maxWidth="md"
                open={show}
                onClose={() => {
                    hideModal();
                }}
            >
                <div className={classes.modal}>
                    <div className={classes.header}>
                        <Typography variant="h1">{text}</Typography>
                        <Button className={classes.close} onClick={() => hideModal()}>
                            <CloseIcon />
                        </Button>
                    </div>

                    <div className={classes.modalBody}>
                        <div className={classes.dsc}>Are you sure that you want to delete this IP {ip}?</div>
                    </div>
                    <div className={classes.modalFooter}>
                        <Button className={classes.cancel} onClick={() => hideModal()}>
                            Cancel
                        </Button>
                        <Button className={classes.delete} onClick={onClickDelete}>
                            Delete
                        </Button>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default ModalDeleteL7List;
