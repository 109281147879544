import React, { useContext, useEffect, useState } from 'react';
import {AntTab, AntTabs, LinearProgressRed, useStyles} from '../../theme/Tabs/index';
import TabPanelLayout from '../../components/global/TabPanelLayout';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import Resources from './resources';
import TrafficAnalytics from './trafficAnalytics';
import TrafficStatistics from './trafficStatistics';
import { AntContainerHead } from '../../theme/Wrapper';
import { useIntl } from 'react-intl';
import { PageContext } from '../../context/pages';
import {dateTabsColculation} from "../../helpers/calculation";
import LinearProgress from "@material-ui/core/LinearProgress";
import tableBlur from "../../../assets/img/table.png";
import TabPanel from "../../components/global/TabPanel";

const WebProtection = ({ dataAccount }) => {
    const classes = useStyles();
    const { tab } = useParams();
    const [indexTab, setIndexTab] = useState(0);
    const { formatMessage } = useIntl();
    const { deleteFilter, filterActive, setFilterActive } = useContext(PageContext);
    const normalise = value => (value - 0) * 100 / (14 - 0);
    const accSettings = dataAccount?.clientAccountEmployee?.clientAccount?.accountSettings;
    const trialPeriod = Math.round(( new Date(new Date(accSettings?.l7?.trialPeriod).setDate(new Date(accSettings?.l7?.trialPeriod).getDate() + 1)) - new Date()) / 86400000);

    const handleChange = (event, newValue) => {
        setIndexTab(newValue);
    };

    const a11yProps = index => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    useEffect(() => {
        setIndexTab(Number(tab));
    }, [tab]);

    return (
        <>
            <div position="static" className={classes.bar}>
                <AntContainerHead maxWidth={false} style={{display: 'flex', alignItems: 'center'}}>
                    <AntTabs value={indexTab} onChange={handleChange} className={classes.itemTab}>
                        <AntTab
                            label={formatMessage({ id: 'webProtection.tab.resource' })}
                            {...a11yProps(0)}
                            component={NavLink}
                            className={`${classes.link} ${classes.marginRight}`}
                            activeClassName={classes.linkActive}
                            to={'/web-protection/0'}
                            onClick={() => deleteFilter()}
                            disabled={accSettings?.l7?.trialPeriod && trialPeriod < 1}
                        />
                        <AntTab
                            label={formatMessage({ id: 'webProtection.tab.statistics' })}
                            {...a11yProps(1)}
                            component={NavLink}
                            className={`${classes.link} ${classes.marginRight}`}
                            activeClassName={classes.linkActive}
                            to={'/web-protection/1'}
                            onClick={() => deleteFilter()}
                            disabled={accSettings?.l7?.trialPeriod && trialPeriod < 1}
                        />
                        <AntTab
                            label={formatMessage({ id: 'webProtection.tab.analytics' })}
                            {...a11yProps(1)}
                            component={NavLink}
                            className={`${classes.link} ${classes.marginRight}`}
                            activeClassName={classes.linkActive}
                            to={'/web-protection/2'}
                            onClick={() => {
                                setFilterActive({ ...filterActive, startDate: dateTabsColculation(0, true), endDate: new Date().setDate(new Date().getDate()), indexTab: 0 });
                            }}
                            disabled={accSettings?.l7?.trialPeriod && trialPeriod < 1}
                        />

                    </AntTabs>
                    {
                        accSettings?.l7?.trialPeriod &&
                        <div className={trialPeriod > 3 ? classes.trialBox : classes.trialBoxRed}>
                            {accSettings?.l7?.trialPeriod && trialPeriod >= 1 ? `${formatMessage({ id: 'trial.full' })} ${trialPeriod} ${formatMessage({ id: 'trial.days' })}` : `${formatMessage({ id: 'trial.end' })}`}
                            {
                                accSettings?.l7?.trialPeriod && trialPeriod > 3 ?
                                    <LinearProgress variant="determinate" value={normalise(trialPeriod)} style={{marginTop: 5}}/> :
                                    <LinearProgressRed variant="determinate" value={normalise(trialPeriod)} style={{marginTop: 5}}/>
                            }
                        </div>
                    }
                </AntContainerHead>
            </div>
            <TabPanel value={indexTab} index={0}>
                {accSettings?.l7?.trialPeriod && trialPeriod < 1 ?
                    <div className={classes.wrappedBlur}>
                        <img src={tableBlur} alt="#" style={{maxWidth: '100%'}}/>
                        <div className={classes.wrappedBlurText}>
                            {formatMessage({ id: 'trial.title' })}
                            <br/>
                            {formatMessage({ id: 'trial.subtitle' })}
                        </div>
                    </div> :
                    <div className={classes.wrapperTable}>
                        <Resources />
                    </div>
                }
            </TabPanel>
            <TabPanel value={indexTab} index={1}>
                <div className={classes.wrapperTable}>
                    <TrafficStatistics />
                </div>
            </TabPanel>
            <TabPanel value={indexTab} index={2}>
                <div className={classes.wrapperTable}>
                    <TrafficAnalytics />
                </div>
            </TabPanel>
        </>
    );
};
export default WebProtection;
