import DashboardNetworkPage from "./Component";
import {useLazyQuery} from "@apollo/client";
import {GET_ARBOR_ALL_TRAFFIC} from "../../../actions/get";
import {useContext} from "react";
import {PageContext} from "../../../context/pages";

const ConnectedDashboardNetworkPage = (props) => {
    const { filterActive, setFilterActive } = useContext(PageContext);
    const [getAllL7Traffic, handleQuery] = useLazyQuery(GET_ARBOR_ALL_TRAFFIC, {
        variables: {
            from: (Date.parse(filterActive.startDate) - (Date.parse(filterActive.startDate) % 1000)) / 1000 || (filterActive.startDate - (filterActive.startDate % 1000)) / 1000,
            until: (Date.parse(filterActive.endDate) - (Date.parse(filterActive.endDate) % 1000)) / 1000 || (filterActive.endDate - (filterActive.endDate % 1000)) / 1000,
            clientAccountId: props.clientAccountId,
            unit: 'BPS',
        },
    });
    return (
        <DashboardNetworkPage
            {...props}
            getAllL7Traffic={getAllL7Traffic}
            handleQuery={handleQuery}
            filterActive={filterActive}
            setFilterActive={setFilterActive}
        />
    )
}

export default ConnectedDashboardNetworkPage;