import React, {useContext} from 'react';

import DashboardApplication from './Component.js'
import {PageContext} from "../../../context/pages";
import {useLazyQuery} from "@apollo/client";
import {GET_ALL_L7_RESOURCES} from "../../../actions/get";

const ConnectedDashboardApplication = ({clientAccountId}) => {
    const { secondaryFilter, setSecondaryFilter } = useContext(PageContext);

    const [getAllL7Res, handleQuery] = useLazyQuery(GET_ALL_L7_RESOURCES, {
        variables: {
            from: (Date.parse(secondaryFilter.startDate) - (Date.parse(secondaryFilter.startDate) % 1000)) / 1000 || (secondaryFilter.startDate - (secondaryFilter.startDate % 1000)) / 1000,
            until: (Date.parse(secondaryFilter.endDate) - (Date.parse(secondaryFilter.endDate) % 1000)) / 1000 || (secondaryFilter.endDate - (secondaryFilter.endDate % 1000)) / 1000,
            clientAccountId: clientAccountId,
        },
    });
    return (
        <DashboardApplication
            clientAccountId={clientAccountId}
            secondaryFilter={secondaryFilter}
            setSecondaryFilter={setSecondaryFilter}
            getAllL7Res={getAllL7Res}
            handleQuery={handleQuery}
        />
    )
}

export default ConnectedDashboardApplication