import React from "react";
import NetWorkBoxes from "./Component";
import {useQuery} from "@apollo/client";
import {GET_ARBOR_ALERTS, GET_ARBOR_MITIGATIONS} from "../../../../actions/get";

const ConnectedNetWorkBoxes = (props) => {
    const dateFormat = require('dateformat');

    const { data } = useQuery(GET_ARBOR_ALERTS, {
        variables: {
            filterStartLte: dateFormat(props.date.startDate, 'isoDateTime'),
            filterStartGte: dateFormat(props.date.endDate, 'isoDateTime'),
        },
    });
    const { data: mitigations } = useQuery(GET_ARBOR_MITIGATIONS, {
        variables: {
            filterStartLte: dateFormat(props.date.startDate, 'isoDateTime'),
            filterStartGte: dateFormat(props.date.endDate, 'isoDateTime'),
        },
    });
    return (
        <NetWorkBoxes {...props} data={data} mitigations={mitigations}/>
    );
};

export default ConnectedNetWorkBoxes;
