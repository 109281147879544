import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { GET_CLIENT_ACCOUNT_EMPLOYEES } from '../../../actions/get';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_CLIENT_ACCOUNT_EMPLOYEE } from '../../../actions/update';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { BaseSwitch } from '../../../theme/Switch';
import Preloader from '../../../components/global/Preloader';

const NotificationProfile = ({ token }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const [switcher, setSwitcher] = useState(false);
    const [checkbox, setCheckbox] = useState({
        high: true,
        medium: true,
        low: true,
    });

    const objAlerts = {
        high: ['CREATE_HIGH_ALERT', 'UPDATE_HIGH_ALERT'],
        medium: ['CREATE_MEDIUM_ALERT', 'UPDATE_MEDIUM_ALERT'],
        low: ['CREATE_LOW_ALERT', 'UPDATE_LOW_ALERT'],
    };

    const { data, loading } = useQuery(GET_CLIENT_ACCOUNT_EMPLOYEES, {
        variables: {
            id: token && token,
        },
    });

    const [updateNotificationSubscribe] = useMutation(UPDATE_CLIENT_ACCOUNT_EMPLOYEE);

    useEffect(() => {
        if (data && data.clientAccountEmployees.items[0].notificationSubscribe.length === 0) {
            setCheckbox({
                high: false,
                medium: false,
                low: false,
            });
            setSwitcher(false);
        } else if (data && data.clientAccountEmployees.items[0].notificationSubscribe.length) {
            setCheckbox({
                high: data.clientAccountEmployees.items[0].notificationSubscribe.includes('CREATE_HIGH_ALERT'),
                medium: data.clientAccountEmployees.items[0].notificationSubscribe.includes('CREATE_MEDIUM_ALERT'),
                low: data.clientAccountEmployees.items[0].notificationSubscribe.includes('CREATE_LOW_ALERT'),
            });
            setSwitcher(true);
        }
    }, [data]);

    const handleChange = event => {
        setSwitcher(event.target.checked);
        if (!event.target.checked) {
            updateNotificationSubscribe({
                variables: {
                    id: token && token,
                    notificationSubscribe: [],
                },
                refetchQueries: [
                    {
                        query: GET_CLIENT_ACCOUNT_EMPLOYEES,
                        variables: {
                            id: token && token,
                        },
                    },
                ],
            })
                .then(() => toast.success('Success'))
                .catch(err => toast.error(err.message));
        }
    };
    const handleChangeCheckbox = event => {
        const arrAlerts = [];
        const proxy = { ...checkbox, [event.target.name]: event.target.checked };
        for (let i in proxy) {
            if (proxy[i]) arrAlerts.push(...objAlerts[i]);
        }
        if(arrAlerts.length) arrAlerts.push('CLOSE_TRACKED_ALERT');
        updateNotificationSubscribe({
            variables: {
                id: token && token,
                notificationSubscribe: arrAlerts,
            },
            refetchQueries: [
                {
                    query: GET_CLIENT_ACCOUNT_EMPLOYEES,
                    variables: {
                        id: token && token,
                    },
                },
            ],
        })
            .then(() => toast.success('Success'))
            .catch(err => toast.error(err.message));
        setCheckbox(prev => ({ ...prev, [event.target.name]: event.target.checked }));
    };

    if (loading) return <Preloader />;

    return (
        <>
            <Typography variant="h1" className={classes.title}>
                {formatMessage({ id: 'profile.not.title' })}
            </Typography>
            <div className={classes.display}>
                <div className={classes.subtitle}>{formatMessage({ id: 'profile.not.switch' })}</div>
                <BaseSwitch name="checkedA" checked={switcher} onChange={handleChange} />
            </div>
            <div className={classes.box}>
                <div className={classes.checkboxTitle}>{formatMessage({ id: 'profile.not.subtitle' })}</div>
                <FormControlLabel
                    control={<Checkbox checked={checkbox.high} onChange={handleChangeCheckbox} name="high" disabled={!switcher} color="primary" />}
                    label={formatMessage({ id: 'profile.not.high' })}
                />
                <FormControlLabel
                    control={<Checkbox checked={checkbox.medium} onChange={handleChangeCheckbox} name="medium" disabled={!switcher} color="primary" />}
                    label={formatMessage({ id: 'profile.not.medium' })}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            className={classes.checkbox}
                            checked={checkbox.low}
                            onChange={handleChangeCheckbox}
                            name="low"
                            disabled={!switcher}
                            color="primary"
                        />
                    }
                    label={formatMessage({ id: 'profile.not.low' })}
                />
            </div>
        </>
    );
};

export default NotificationProfile;
