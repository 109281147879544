import React, { useState } from 'react';
import { useStyles, AntTableCell, AntTableCellFilter } from '../../../../theme/Table';
import { Typography, Table, TableHead, TableRow, Button, TableBody, Popover } from '@material-ui/core';
import TableFilter from '../../../../components/global/TableFilter';
import { textWidthCalculation } from '../../../../helpers/calculation';
import { useIntl } from 'react-intl';
import Resource from './Resource';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { useStyled } from './styles';

const ResourcesL7Desktop = ({ data, setIsCreated, filter, setFilter, handleClick, setIndex, statusCreateL7, loadingAccess }) => {
    const classes = useStyles();
    const styled = useStyled();
    const { formatMessage } = useIntl();
    const [anchorEl, setAnchorEl] = useState(null);

    const maxWidth = {
        id: Math.max.apply(Math, data && data.l7ResourcesVendor.items.map(items => textWidthCalculation(items.l7ResourceId))),
        name: Math.max.apply(Math, data && data.l7ResourcesVendor.items.map(items => textWidthCalculation(items.l7ResourceName))),
    };

    const handlePopoverOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <div className={classes.header}>
                <Typography variant="h1">{formatMessage({ id: 'webProtection.resource.title' })}</Typography>
                <Button color="primary" variant="contained" className={classes.button} onClick={() => setIsCreated(true)}>
                    {formatMessage({ id: 'webProtection.resource.btn' })}
                </Button>
                {/*{!loadingAccess && (*/}
                {/*    <div className={styled.wrapperButton}>*/}
                {/*        */}
                {/*        {!statusCreateL7 && (*/}
                {/*            <>*/}
                {/*                <HelpOutlineIcon*/}
                {/*                    color="primary"*/}
                {/*                    className={styled.helpIcon}*/}
                {/*                    aria-owns={open ? 'mouse-over-popover' : undefined}*/}
                {/*                    aria-haspopup="true"*/}
                {/*                    onMouseEnter={handlePopoverOpen}*/}
                {/*                    onMouseLeave={handlePopoverClose}*/}
                {/*                />*/}
                {/*                <Popover*/}
                {/*                    className={styled.popover}*/}
                {/*                    id="mouse-over-popover"*/}
                {/*                    open={open}*/}
                {/*                    anchorEl={anchorEl}*/}
                {/*                    anchorOrigin={{*/}
                {/*                        vertical: 'bottom',*/}
                {/*                        horizontal: 'right',*/}
                {/*                    }}*/}
                {/*                    transformOrigin={{*/}
                {/*                        vertical: 'bottom',*/}
                {/*                        horizontal: 'right',*/}
                {/*                    }}*/}
                {/*                    onClose={handlePopoverClose}*/}
                {/*                    disableRestoreFocus*/}
                {/*                >*/}
                {/*                    <Typography className={styled.helpText}>*/}
                {/*                        {formatMessage({ id: 'webProtection.resource.help1' })}*/}
                {/*                        <br /> {formatMessage({ id: 'webProtection.resource.help2' })}*/}
                {/*                    </Typography>*/}
                {/*                </Popover>*/}
                {/*            </>*/}
                {/*        )}*/}
                {/*    </div>*/}
                {/*)}*/}
            </div>
            <Table>
                <TableHead>
                    <TableRow>
                        <AntTableCell align="left" className={classes.title}>
                            {formatMessage({ id: 'webProtection.resource.id' })}
                        </AntTableCell>
                        <AntTableCell align="left" className={classes.title}>
                            {formatMessage({ id: 'webProtection.resource.name' })}
                        </AntTableCell>
                        <AntTableCell align="left" className={classes.title}>
                            {formatMessage({ id: 'webProtection.resource.ip' })}
                        </AntTableCell>
                        <AntTableCell align="left" className={classes.title}>
                            {formatMessage({ id: 'webProtection.resource.ddos' })}
                        </AntTableCell>
                        <AntTableCell align="left" className={classes.title}>
                            {formatMessage({ id: 'webProtection.resource.bot' })}
                        </AntTableCell>
                        <AntTableCell align="left" className={classes.title}>
                            {formatMessage({ id: 'webProtection.resource.date' })}
                        </AntTableCell>
                        <AntTableCell align="left" className={classes.title} />
                    </TableRow>
                    <TableRow>
                        <AntTableCellFilter>
                            <TableFilter name="id" filter={filter} setFilter={setFilter} maxWidth={maxWidth.id} />
                        </AntTableCellFilter>
                        <AntTableCellFilter>
                            <TableFilter name="name" filter={filter} setFilter={setFilter} maxWidth={maxWidth.name} />
                        </AntTableCellFilter>
                        <AntTableCellFilter />
                        <AntTableCellFilter />
                        <AntTableCellFilter />
                        <AntTableCellFilter />
                        <AntTableCellFilter />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data &&
                        data?.l7ResourcesVendor?.items.map(
                            ({ serviceIp, serviceId, serviceCreated, serviceDeleted, serviceName, iauthDisableL7, serviceAbpMode }) =>
                                !serviceDeleted && (
                                    <TableRow key={serviceId}>
                                        <Resource
                                            id={serviceId}
                                            name={serviceName}
                                            ip={serviceIp}
                                            created={serviceCreated}
                                            handleClick={handleClick}
                                            setIndex={setIndex}
                                            iauthDisableL7={iauthDisableL7}
                                            serviceAbpMode={serviceAbpMode}
                                        />
                                    </TableRow>
                                )
                        )}
                </TableBody>
            </Table>
        </>
    );
};
export default ResourcesL7Desktop;
