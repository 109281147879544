// Применяется в src/index.js,
// scheme/index.js, Header/index.js, SupportPopover/index.js

export const favIcons = {
    sp: 'favicon.ico',
    cdn: 'wellness.ico',
};

export const titles = {
    sp: 'SP APP',
    wellness: 'Security Wellness',
};

export const hosts = {
    sp: 'demo.servicepipe.ru',
    wellness: 'demo.sec-well.com',
};
