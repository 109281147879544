import React from "react";
import {Grid} from "@material-ui/core";
import {CustomSwitchOnOff} from "../../../../theme/Switch";
import {useStyles} from "../styles";
import {useIntl} from "react-intl";

const GeneralSettingsSwitchers = ({resourceName, handleChange, switches}) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();

    return (
        <Grid container className={classes.wrapperSwitchContainer}>
            <Grid item xs={12}>
                <div className={classes.switchBox}>
                    <div>
                        <div className={classes.formTitle}>{formatMessage({ id: 'webProtection.general.redirect' })}</div>
                        <span className={classes.formSubTitle}>
                                        www.{resourceName} -&gt; {resourceName}
                                    </span>
                    </div>
                    <div className={classes.switch}>
                        <CustomSwitchOnOff name="serviceWwwredir" checked={switches.serviceWwwredir} onChange={handleChange} />
                    </div>
                </div>
                <div className={classes.switchBox}>
                    <div>
                        <div className={classes.formTitle}>{formatMessage({ id: 'webProtection.general.switch2' })}</div>
                        <span className={classes.formSubTitle}>{formatMessage({ id: 'webProtection.general.switch2.dsc' })}</span>
                    </div>
                    <div className={classes.switch}>
                        <CustomSwitchOnOff name="serviceGlobalWhitelist" checked={switches.serviceGlobalWhitelist} onChange={handleChange} />
                    </div>
                </div>
                <div className={classes.switchBox}>
                    <div>
                        <div className={classes.formTitle}>{formatMessage({ id: 'webProtection.general.switch3' })}</div>
                        <span className={classes.formSubTitle}>
                                        http://{resourceName} -&gt; https://{resourceName}
                                    </span>
                    </div>
                    <div className={classes.switch}>
                        <CustomSwitchOnOff name="serviceForcessl" checked={switches.serviceForcessl} onChange={handleChange} />
                    </div>
                </div>
                <div className={classes.switchBox}>
                    <div>
                        <div className={classes.formTitle}>{formatMessage({ id: 'webProtection.general.switch4' })}</div>
                        <span className={classes.formSubTitle}>{formatMessage({ id: 'webProtection.general.switch4.dsc' })}</span>
                    </div>
                    <div className={classes.switch}>
                        <CustomSwitchOnOff name="serviceHttps2http" checked={switches.serviceHttps2http} onChange={handleChange} />
                    </div>
                </div>
                <div className={classes.switchBox}>
                    <div>
                        <div className={classes.formTitle}>DDoS protection on application layer</div>
                        <span className={classes.formSubTitle}>By default, protection from DDoS on application layer is active and we recommend not to disable it.</span>
                    </div>
                    <div className={classes.switch}>
                        <CustomSwitchOnOff name="iauthDisableL7" checked={switches.iauthDisableL7} onChange={handleChange} />
                    </div>
                </div>
            </Grid>
        </Grid>
    )
};

export default GeneralSettingsSwitchers;