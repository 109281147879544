import React, { useState } from 'react';
import { useStyles } from '../styles';
import { TextField, InputAdornment, IconButton, Button, Grid, Typography } from '@material-ui/core';
import { Visibility, VisibilityOff, Error } from '@material-ui/icons';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import withWidth from '@material-ui/core/withWidth';
import ListPassword from './listPassword';
import { SET_PASSWORD } from "../../../../actions/update";

const Password = ({ width, token, data }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showDoubleNewPassword, setShowDoubleNewPassword] = useState(false);

    const [setPassword, { loading }] = useMutation(SET_PASSWORD);

    const onSubmit = inputData => {
        setPassword({
            variables: {
                oldPassword: inputData.oldPassword,
                newPassword: inputData.newPassword,
                email: data.clientAccountEmployee.email,
                type: token && token.type,
            },
        })
            .then(() => toast.success('Success'))
            .catch(err => toast.error(err.message));
    };

    const checkUppercase = /([A-Z])|([А-Я])/;
    const checkLowercase = /([a-z])|([а-я])/;
    const checkNumber = /([0-9])/;
    const checkSpecialCharacter = /[^A-Za-zА-Яа-яёЁ0-9\s]/;

    const validationSchema = Yup.object().shape({
        oldPassword: Yup.string().required('Current Password is required'),
        newPassword: Yup.string()
            .min(8, 'New Password must be at least 8 characters')
            .matches(checkUppercase, 'Password must have one uppercase character')
            .matches(checkLowercase, 'Password must have one lowercase character')
            .matches(checkNumber, 'Password must have one number')
            .matches(checkSpecialCharacter, 'Password must have one special character')
            .required('Current New Password is required'),
        doubleNewPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'Confirm New Password must match')
            .required('Confirm New Password is required'),
    });

    const {
        handleSubmit,
        watch,
        formState: { errors },
        control,
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const inputVariable = !watch('newPassword') ? '' : watch('newPassword');

    return (
        <>
            <Typography variant="h1" className={classes.title}>
                {formatMessage({ id: 'profile.pass.title' })}
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} className={classes.form}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Controller
                            name="oldPassword"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    error={!!errors.oldPassword}
                                    type={showOldPassword ? 'text' : 'password'}
                                    value={value}
                                    label={formatMessage({ id: 'profile.pass.now' })}
                                    onChange={onChange}
                                    variant="filled"
                                    helperText={errors?.oldPassword?.message ?? ' '}
                                    className={classes.input}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton edge="end" onClick={() => setShowOldPassword(!showOldPassword)}>
                                                    {errors.oldPassword ? <Error color="error" /> : showOldPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name="newPassword"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    error={!!errors.newPassword}
                                    type={showNewPassword ? 'text' : 'password'}
                                    value={
                                        value.charCodeAt(value.length - 1) >= 33 && value.charCodeAt(value.length - 1) <= 126
                                            ? value
                                            : value.replace(value[value.length - 1], '')
                                    }
                                    label={formatMessage({ id: 'profile.pass.new' })}
                                    onChange={onChange}
                                    variant="filled"
                                    helperText={errors?.newPassword?.message ?? ' '}
                                    className={classes.input}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton edge="end" onClick={() => setShowNewPassword(!showNewPassword)}>
                                                    {errors.newPassword ? <Error color="error" /> : showNewPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                        {width === 'xs' || width === 'sm' ? (
                            <ListPassword
                                inputVariable={inputVariable}
                                checkUppercase={checkUppercase}
                                checkLowercase={checkLowercase}
                                checkNumber={checkNumber}
                                checkSpecialCharacter={checkSpecialCharacter}
                            />
                        ) : (
                            ''
                        )}
                        <Controller
                            name="doubleNewPassword"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    type={showDoubleNewPassword ? 'text' : 'password'}
                                    value={value}
                                    label={formatMessage({ id: 'profile.pass.dbl' })}
                                    onChange={onChange}
                                    variant="filled"
                                    className={classes.input}
                                    error={!!errors.doubleNewPassword}
                                    helperText={errors?.doubleNewPassword?.message ?? ' '}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton edge="end" onClick={() => setShowDoubleNewPassword(!showDoubleNewPassword)}>
                                                    {errors.doubleNewPassword ? (
                                                        <Error color="error" />
                                                    ) : showDoubleNewPassword ? (
                                                        <Visibility />
                                                    ) : (
                                                        <VisibilityOff />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                        <div className={classes.buttonWrapper}>
                            <Button type="submit" color="primary" variant="contained" className={classes.button}>
                                {formatMessage({ id: 'profile.pass.btn' })}
                            </Button>
                        </div>
                    </form>
                </Grid>
                {width !== 'xs' && width !== 'sm' ? (
                    <Grid item xs>
                        <ListPassword
                            inputVariable={inputVariable}
                            checkUppercase={checkUppercase}
                            checkLowercase={checkLowercase}
                            checkNumber={checkNumber}
                            checkSpecialCharacter={checkSpecialCharacter}
                        />
                    </Grid>
                ) : (
                    ''
                )}
            </Grid>
        </>
    );
};

export default withWidth()(Password);