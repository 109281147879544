import React, { useContext, useState } from 'react';
import { Typography, Grid, Container } from '@material-ui/core';
import { useStyles, MyTabs } from './styles';
import TableDateTabs from '../../../components/global/TableDateTabs';
import TableDatePicker from '../../../components/global/TableDatePicker';
import { AntTab } from '../../../theme/Tabs';
import TabPanel from '../../../components/global/TabPanel';
import { GET_L7_RESOURCES_VENDOR } from '../../../actions/get';
import { useQuery } from '@apollo/client';
import AreaChartsRequest from '../../../components/charts/AreaChartsRequest';
import AreaChartsRequestAnalytics from '../../../components/charts/AreaChartsRequestAnalytics';
import AreaChartsBandWidth from '../../../components/charts/AreaChartsBandWidth';
import ColumnChartsResponseTime from '../../../components/charts/ColumnChartsResponseTime';
import AreaChartsErrors from '../../../components/charts/AreaChartsErrors';
import ColumnChartsResponseCodes from '../../../components/charts/ColumnChartsResponseCodes';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useIntl } from 'react-intl';
import Preloader from '../../../components/global/Preloader';
import RequestsGeography from './RequestsGeography';
import { PageContext } from '../../../context/pages';

const TrafficStatistics = () => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const { filterActive, setFilterActive } = useContext(PageContext);
    const [tab, setTab] = useState(0);
    const [select, setSelect] = useState(null);

    const { data, loading } = useQuery(GET_L7_RESOURCES_VENDOR);

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };
    const a11yProps = index => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    if (loading) return <Preloader />;

    const {
        l7ResourcesVendor: { items },
    } = data;

    const options = items.map(({ l7ResourceId, serviceName }) => {
        return {
            l7ResourceId,
            serviceName,
        };
    });

    return (
        <div>
            <Grid item xs={12}>
                <Typography variant="h1" className={classes.headerTitle}>
                    {formatMessage({ id: 'webProtection.charts.title' })}
                </Typography>
            </Grid>
            <Grid container direction="row" justify="space-between" alignItems="flex-start">
                <Grid item xs={4}>
                    <Autocomplete
                        size="small"
                        className={classes.closeIcon}
                        id="resellerId"
                        options={options}
                        defaultValue={options[0]}
                        getOptionLabel={option => `(${option.l7ResourceId}) ${option.serviceName}`}
                        renderOption={option => `(${option.l7ResourceId}) ${option.serviceName}`}
                        onChange={(event, value) => {
                            setSelect(value.l7ResourceId);
                        }}
                        renderInput={params => <TextField {...params} variant="outlined" size="small" className={classes.select} />}
                    />
                </Grid>
                <Grid item>
                    <div className={classes.date}>
                        <TableDateTabs filter={filterActive} setFilter={setFilterActive} />
                        <TableDatePicker filter={filterActive} setFilter={setFilterActive} maxDate={true}/>
                    </div>
                </Grid>
            </Grid>
            <div position="static" className={classes.bar}>
                <Container disableGutters maxWidth={false}>
                    <MyTabs value={tab} onChange={handleChange} className={classes.itemTab}>
                        <AntTab
                            label={formatMessage({ id: 'webProtection.charts.request' })}
                            {...a11yProps(0)}
                            className={classes.link}
                            activeClassName={classes.linkActive}
                        />
                        <AntTab
                            label={formatMessage({ id: 'webProtection.charts.band' })}
                            {...a11yProps(1)}
                            className={classes.link}
                            activeClassName={classes.linkActive}
                        />
                        <AntTab
                            label={formatMessage({ id: 'webProtection.charts.analytics' })}
                            {...a11yProps(2)}
                            className={classes.link}
                            activeClassName={classes.linkActive}
                        />
                        <AntTab
                            label={formatMessage({ id: 'webProtection.charts.times' })}
                            {...a11yProps(3)}
                            className={classes.link}
                            activeClassName={classes.linkActive}
                        />
                        <AntTab
                            label={formatMessage({ id: 'webProtection.charts.codes' })}
                            {...a11yProps(4)}
                            className={classes.link}
                            activeClassName={classes.linkActive}
                        />
                        <AntTab
                            label={formatMessage({ id: 'webProtection.charts.errors' })}
                            {...a11yProps(5)}
                            className={classes.link}
                            activeClassName={classes.linkActive}
                        />
                        <AntTab
                            label={formatMessage({ id: 'webProtection.charts.geo' })}
                            {...a11yProps(6)}
                            className={classes.link}
                            activeClassName={classes.linkActive}
                        />
                    </MyTabs>
                </Container>
            </div>
            <TabPanel value={tab} index={0} className={classes.custom}>
                <AreaChartsRequest id={!select ? data?.l7ResourcesVendor?.items[0]?.l7ResourceId : select} date={filterActive} setFilter={setFilterActive}/>
            </TabPanel>
            <TabPanel value={tab} index={1} className={classes.custom}>
                <AreaChartsBandWidth id={!select ? data?.l7ResourcesVendor?.items[0]?.l7ResourceId : select} date={filterActive} setFilter={setFilterActive}/>
            </TabPanel>
            <TabPanel value={tab} index={2} className={classes.custom}>
                <AreaChartsRequestAnalytics id={!select ? data?.l7ResourcesVendor?.items[0]?.l7ResourceId : select} date={filterActive} setFilter={setFilterActive}/>
            </TabPanel>
            <TabPanel value={tab} index={3} className={classes.custom}>
                <ColumnChartsResponseTime id={!select ? data?.l7ResourcesVendor?.items[0]?.l7ResourceId : select} date={filterActive} setFilter={setFilterActive}/>
            </TabPanel>
            <TabPanel value={tab} index={4} className={classes.custom}>
                <ColumnChartsResponseCodes id={!select ? data?.l7ResourcesVendor?.items[0]?.l7ResourceId : select} date={filterActive} setFilter={setFilterActive}/>
            </TabPanel>
            <TabPanel value={tab} index={5} className={classes.custom}>
                <AreaChartsErrors id={!select ? data?.l7ResourcesVendor?.items[0]?.l7ResourceId : select} date={filterActive} setFilter={setFilterActive}/>
            </TabPanel>
            <TabPanel value={tab} index={6}>
                <RequestsGeography id={!select ? data?.l7ResourcesVendor?.items[0]?.l7ResourceId : select} date={filterActive} setFilter={setFilterActive}/>
            </TabPanel>
        </div>
    );
};

export default TrafficStatistics;
