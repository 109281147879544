import React, { useState } from 'react';
import { useStyles } from '../../theme/Auth';
import { InputAdornment, IconButton, Button } from '@material-ui/core';
import {Error, Visibility, VisibilityOff} from '@material-ui/icons';
import AuthWrapper from '../../components/global/Wrappers/authWrapper';
import { Link, useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import routes from '../../routes';
import Preloader from '../../components/global/Preloader';
import ModalAuthTwoStepVerification from "../../components/modals/TwoStepVerification/authTwoStepVerification";
import spLogoMain from '../../../assets/img/logo-welness.svg';
import FormControl from "@material-ui/core/FormControl";
import {AuthLabel, AuthTextField} from "../../components/global/Wrappers/authWrapper/styles";
import {checkHosts} from "../../branding";
import {logosAuth} from "../../branding/logos";


const Auth = ({context, login, loading, error}) => {
    const classes = useStyles();
    const { push } = useHistory();

    const [showPassword, setShowPassword] = useState(false);
    const [authModal, setAuthModal] = useState(false);
    const [authEmail, setAuthEmail] = useState(null);


    const handleMouseDownPassword = event => {
        event.preventDefault();
    };

    const onSubmit = data =>
        login({
            variables: {
                password: data.password,
                email: data.email,
                type: 'CLIENT_ACCOUNT_EMPLOYEE',
            },
        })
            .then(res => {
                if(res.data.login.accessToken === 'TWO_FACTOR') {
                    setAuthModal(true);
                    setAuthEmail(data.email)
                } else {
                    context.login(res.data.login.accessToken);
                    push('/dashboard');
                }
            })
            .catch(err => console.log(err.message));

    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm();

    if (loading) return <Preloader />;

    return (
        <>
            <ModalAuthTwoStepVerification
                show={authModal}
                hideModal={() => setAuthModal(false)}
                email={authEmail}
            />
            <AuthWrapper>
                <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                    <div className={classes.logo}>
                        { checkHosts(window.location.host, logosAuth ) }
                    </div>
                    <div className={classes.title}>Sign in with email</div>
                    <div className={error || errors.email || errors.password ? classes.errorBox : classes.errorNone}>
                        <div>{error ? 'Email or password is incorrect.' : ''}</div>
                    </div>
                    <FormControl>
                        <AuthLabel shrink htmlFor="email" error={error}>
                            Email
                        </AuthLabel>
                        <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value } }) => (
                                <AuthTextField id="email"
                                               placeholder='Enter your email address'
                                               onChange={onChange}
                                               value={value}
                                               error={error}
                                               disableUnderline={true}
                                />
                            )}
                        />
                    </FormControl>
                    <Controller
                        name="password"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value } }) => (
                            <FormControl>
                                <AuthLabel shrink htmlFor="password" error={error}>
                                    Password
                                </AuthLabel>
                                <AuthTextField
                                    disableUnderline={true}
                                    id="password"
                                    error={error}
                                    type={showPassword ? 'text' : 'password'}
                                    value={value}
                                    onChange={onChange}
                                    placeholder='Enter your password'
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setShowPassword(!showPassword)}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {error ? <Error color="error" /> : showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        )}
                    />
                    <div className={classes.support}>Password must be at least 8 characters</div>
                    <Button type="submit" color="primary" variant="contained" className={classes.button}>
                        sign in
                    </Button>
                    <Link to={routes.forgotPassword} className={classes.link}>
                        Forgot password?
                    </Link>
                </form>
            </AuthWrapper>
        </>
    );
};

export default Auth;
