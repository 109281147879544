import React from 'react';
import { useStyles } from './styles';
import { Typography, MenuItem, ListItemIcon } from '@material-ui/core';
import { Email, Phone } from '@material-ui/icons';
import { AntPopover } from '../../../../../theme/AntPopover';

const SupportPopover = ({ id, open, anchorEl, handleClose }) => {
    const classes = useStyles();

    return (
        <AntPopover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            className={classes.popover}
        >
            <div className={classes.popoverWrapper}>
                <div className={classes.popoverBody}>
                    <a href="mailto:tech-support@servicepipe.ru" className={classes.linkItem} onClick={() => handleClose()}>
                        <MenuItem className={classes.menuItem}>
                            <ListItemIcon>
                                <Email className={classes.icon} />
                            </ListItemIcon>
                            <Typography variant="inherit" className={classes.text}>
                                tech-support@servicepipe.ru
                            </Typography>
                        </MenuItem>
                    </a>
                    <a href="tel:+74992887281" className={classes.linkItem} onClick={() => handleClose()}>
                        <MenuItem className={classes.menuItem}>
                            <ListItemIcon>
                                <Phone className={classes.icon} />
                            </ListItemIcon>
                            <Typography variant="inherit" className={classes.text}>
                                +7 (499) 288-72-81
                            </Typography>
                        </MenuItem>
                    </a>
                </div>
            </div>
        </AntPopover>
    );
};

export default SupportPopover;
